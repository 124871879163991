import React, { useEffect } from 'react'

import heroImg from '../assets/images/pms-banner.png'
import checkwriterImg from '../assets/images/CheckwriterDashboard.png'
import roadmapImg from '../assets/images/roadmap.png'
import teamImg from '../assets/images/people.png'
import solarSystem from '../assets/images/solar-system.png'
import carouselImg from '../assets/images/Imageprofile.png'
import cavalaryLogo from '../assets/images/calavary.jpg'
import zoomTechLogo from '../assets/images/zoom-tech.jpg'
import cofinanceLogo from '../assets/images/cofinance.jpg'
import choose1Img from '../assets/images/choose-1.png'
import choose2Img from '../assets/images/choose-2.png'
import choose3Img from '../assets/images/choose-3.png'
import feature1Img from '../assets/images/feature-1.png'
import feature2Img from '../assets/images/feature-2.png'
import feature3Img from '../assets/images/feature-3.png'
import feature4Img from '../assets/images/feature-4.png'
import feature5Img from '../assets/images/feature-5.png'
import customerImg from '../assets/images/customer-img.png'
import tryFreeImg from '../assets/images/try-free.png'

import {
	barChartIcon,
	calenderIcon,
	customizeCheck,
	invoiceIcon,
	lockIcon,
	mailIcon,
	moduleSyncIcon,
	paymentIcon,
	peopleGroup,
	productsIcon,
	quoteIcon,
	reportsIcon,
	securityEyeIcon,
	shieldIcon,
	simplifiedIcon,
	solutionIcon,
	streamIcon,
	trendingUp,
	userGroupIcon,
	verifyShieldIcon
} from '../assets/svg'
import { useNavigate } from 'react-router'
import { Avatar, FloatButton, Tooltip } from 'antd'
import { ArrowUpward } from '@mui/icons-material'

type AnimationName =
	| 'slide-in-fwd-bottom'
	| 'slide-in-fwd-left'
	| 'slide-in-fwd-top'
	| 'slide-in-fwd-right'

type Animations = {
	[key in AnimationName]: string
}

const Home = () => {
	const navigate = useNavigate()

	useEffect(() => {
		const animations: Animations = {
			'slide-in-fwd-bottom':
				'slide-in-fwd-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both',

			'slide-in-fwd-left':
				'slide-in-fwd-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both',
			'slide-in-fwd-top':
				'slide-in-fwd-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both',

			'slide-in-fwd-right':
				'slide-in-fwd-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both'
		}

		let topElm: HTMLCollectionOf<Element> =
			document.getElementsByClassName('slide-in-fwd-top')
		let bottomElm: HTMLCollectionOf<Element> = document.getElementsByClassName(
			'slide-in-fwd-bottom'
		)
		let leftElm: HTMLCollectionOf<Element> =
			document.getElementsByClassName('slide-in-fwd-left')
		let rightElm: HTMLCollectionOf<Element> =
			document.getElementsByClassName('slide-in-fwd-right')

		let elms = [...topElm, ...bottomElm, ...leftElm, ...rightElm]

		const observer = new IntersectionObserver(
			(
				entries: IntersectionObserverEntry[],
				observer: IntersectionObserver
			) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						// Do something when the element is visible in the viewport

						const targetElement = entry.target as HTMLElement

						const animationType =
							targetElement.dataset.animation || 'slide-in-fwd-bottom'

						targetElement.style.webkitAnimation =
							animations[animationType as AnimationName]
						targetElement.style.animation =
							animations[animationType as AnimationName]

						// Unobserve the target element so that the callback is not called again
						observer.unobserve(entry.target)
					}
				})
			},
			{
				root: null, // Use the viewport as the root element
				threshold: 0.5, // Call the callback when at least 50% of the element is visible
				rootMargin: '0px' // No margin around the root element
			}
		)

		elms.forEach((elem) => {
			observer.observe(elem)
		})
	}, [])

	useEffect(() => {
		let elems: HTMLCollectionOf<Element> =
			document.getElementsByClassName('fade-animation')

		const elemsArr = [...elems]

		const observer = new IntersectionObserver(
			(
				entries: IntersectionObserverEntry[],
				observer: IntersectionObserver
			) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						const targetElement = entry.target as HTMLElement
						// Do something when the element is visible in the viewport
						targetElement.style.opacity = '1'

						// Unobserve the target element so that the callback is not called again
						observer.unobserve(entry.target)
					}
				})
			},
			{
				root: null, // Use the viewport as the root element
				threshold: 0.5, // Call the callback when at least 50% of the element is visible
				rootMargin: '0px' // No margin around the root element
			}
		)

		elemsArr.length !== 0 &&
			elemsArr.forEach((elem) => {
				observer.observe(elem)
			})
	}, [])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<>
			;
			<div className="container-fluid m-0 p-0">
				{/* Hero section */}

				<div className="hero-section my-5 py-5">
					<div className="container position-relative">
						<div className="row">
							<div className="col-12 col-md-6">
								<h1
									className="primary-header fs-60 fw-bold mb-4 slide-in-fwd-bottom font-poppins"
									data-animation="slide-in-fwd-bottom"
								>
									Your{' '}
									<span
										style={{
											color: '#3571A7'
										}}
									>
										Product
									</span>{' '}
									<br />
									<span
										style={{
											color: '#3571A7'
										}}
									>
										Journey
									</span>
									, Simplified
								</h1>
								<p
									className="common-txt fw-normal fs-5 mb-5 slide-in-fwd-top"
									data-animation="slide-in-fwd-top"
								>
									Unlock the Full Potential of Your Product Team with Our
									Cutting-Edge Product Management SAAS. From Ideation to Market
									Success, We've Got You Covered
								</p>
								<div
									className="d-flex align-items-start flex-column slide-in-fwd-top"
									data-animation="slide-in-fwd-top"
								>
									<button
										className="common-btn blue-btn animation-right text-uppercase fw-bold fs-6 mb-3"
										onClick={(e) => {
											e.preventDefault()
											navigate('/auth/signup')
										}}
									>
										Launch Synccos Pms
										<span className="icon ms-3">
											<i className="fa-solid fa-arrow-right"></i>
										</span>
									</button>
									<div className="review-avatars mt-5">
										<div className="d-flex align-items-center">
											<Avatar.Group
												//maxCount={2}
												maxPopoverTrigger="click"
												size="large"
												maxStyle={{
													color: '#f56a00',
													backgroundColor: '#fde3cf',
													cursor: 'pointer'
												}}
											>
												<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
												<Avatar
													style={{
														backgroundColor: '#f56a00'
													}}
												>
													K
												</Avatar>
												<Avatar
													style={{
														backgroundColor: '#87d068'
													}}
													//icon={<User />}
												/>

												{/* <Tooltip title="Ant User" placement="top">
												<Avatar
													style={{
														backgroundColor: '#87d068'
													}}
													//icon={<User />}
												/>
											</Tooltip> */}
											</Avatar.Group>
											<div className="ms-3">
												<p className="text-black font-poppins fs-5 fw-500 mb-0">
													Happy customers
												</p>
												<p className="fs-5 mb-0 fw-semibold text-black">
													<span
														style={{
															color: '#F4C417'
														}}
													>
														★
													</span>{' '}
													4.7{' '}
													<span
														className="fs-6"
														style={{
															color: '#787878'
														}}
													>
														(1.2K reviews)
													</span>
												</p>
											</div>
										</div>
										<div className="short-features mt-5">
											<div className="row">
												<div className="col-12 col-md-6">
													<div className="feature-name">Manage Projects</div>
												</div>
												<div className="col-12 col-md-6">
													<div className="feature-name">Detailed Analysis</div>
												</div>

												<div className="col-12 col-md-6">
													<div className="feature-name">Manage employees</div>
												</div>

												<div className="col-12 col-md-6">
													<div className="feature-name">
														Collaborate with clients
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div
									className="d-flex align-items-center justify-content-center slide-in-fwd-right banner-img-div"
									data-animation="slide-in-fwd-right"
								>
									<img src={heroImg} alt="" className="banner-img" />
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Wht Choose Us section */}
				<div
					className="choose-us-section mt-5 mb-5 pt-5 pb-5"
					id="why-choose-us"
				>
					<div className="container pt-5">
						<div className="row m-0 p-0 align-items-center">
							<div className="col-12 col-md-6">
								<div
									className="slide-in-fwd-left"
									data-animation="slide-in-fwd-left"
								>
									<h2 className="section-header fs-48 fw-bold text-left">
										Why choose Synccos PMS?
									</h2>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div
									className="slide-in-fwd-right"
									data-animation="slide-in-fwd-right"
								>
									<p className="common-txt fw-normal font-poppins ms-4">
										Because Success Deserves a Partner: Elevate Your Product
										Management Game with Synccos PMS
									</p>
								</div>
							</div>
						</div>

						<div className="row m-0 p-0 mt-5">
							<div className="col-12 col-md-4">
								<div className="choose-div">
									<div className="icon">{simplifiedIcon}</div>
									<h4 className="fw-semibold font-poppins mb-1 text-black">
										Simplified PMS
									</h4>
									<p className="common-txt fs-6 fw-normal m-0 ms-4">
										Experience the Power of Simplicity: Choose Simplified PMS
										for Effortless Product Management Excellence
									</p>

									<img src={choose1Img} alt="choose-img" />
								</div>
							</div>
							<div className="col-12 col-md-4">
								<div className="choose-div">
									<div className="icon">{moduleSyncIcon}</div>
									<h4 className="fw-semibold font-poppins mb-1 text-black">
										Multiple Modules Sync
									</h4>
									<p className="common-txt fs-6 fw-normal">
										Efficiency in Every Module: Elevate Your Product Management
										with Multiple Modules Sync
									</p>
									<img src={choose2Img} alt="choose-img" />
								</div>
							</div>

							<div className="col-12 col-md-4">
								<div className="choose-div">
									<div className="icon">{solutionIcon}</div>
									<h4 className="fw-semibold font-poppins mb-1 text-black">
										One stop solution
									</h4>
									<p className="common-txt fs-6 fw-normal">
										Your Complete Product Management Solution: One-Stop
										Excellence with Synccos PMS
									</p>
									<img src={choose3Img} alt="choose-img" />
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Features sections */}

				<div className="features-section pt-3 mb-5 pb-5" id="our-features">
					<div className="container mt-5 pt-5">
						<div className="row m-0 p-0 align-items-center">
							<div className="col-12 col-md-6 p-0">
								<div
									className="slide-in-fwd-left"
									data-animation="slide-in-fwd-left"
								>
									<h2 className="section-header fs-48 fw-bold text-left">
										Our features
									</h2>
									<p className="common-txt fw-normal font-poppins ms-0 fs-6">
										Experience Unmatched Product Management Excellence Through
										Our Comprehensive Features and Capabilities
									</p>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div
									className="slide-in-fwd-right"
									data-animation="slide-in-fwd-right"
								>
									<div className="d-flex align-items-center justify-content-end">
										<button
											className="common-btn blue-btn animation-right text-uppercase fw-bold fs-6"
											onClick={() => {
												navigate('/auth/signup')
											}}
										>
											Launch Synccos Pms
											<span className="icon ms-3">
												<i className="fa-solid fa-arrow-right-to-bracket"></i>
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>

						<div className="row mt-3">
							<div className="col-12 col-md-7 col-lg-8">
								<div className="feature-div">
									<div>
										<h3 className="fs-3 fw-semibold text-black font-poppins text-left">
											Project and Task Management
										</h3>
										<p className="common-txt fs-6 fw-normal mb-3">
											Effortlessly Oversee Projects and Tasks with Our Robust
											Project and Task Management Tools, Empowering Your Product
											Team for Greater Achievements
										</p>
									</div>

									<div className="feature-img-div">
										<img
											src={feature1Img}
											alt="feature"
											className="feature-image"
										/>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-5 col-lg-4">
								<div className="feature-div">
									<div>
										<h3 className="fs-3 fw-semibold text-black font-poppins text-left">
											Client collaboration
										</h3>
										<p className="common-txt fs-6 fw-normal mb-3">
											Product Success with Seamless Client Collaboration:
											Empower Your Team with Our Collaborative Tools and Drive
											Results
										</p>
									</div>
									<div className="feature-img-div">
										<img
											src={feature2Img}
											alt="feature"
											className="feature-image"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="row mt-4">
							<div className="col-12 col-md-4">
								<div className="feature-div">
									<div>
										<h3 className="fs-3 fw-semibold text-black font-poppins text-left">
											File management
										</h3>
										<p className="common-txt fs-6 fw-normal mb-3">
											Effortlessly organize and access your files with our file
											management system
										</p>
									</div>
									<div className="feature-img-div">
										<img
											src={feature3Img}
											alt="feature"
											className="feature-image"
										/>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-4">
								<div className="feature-div">
									<div>
										<h3 className="fs-3 fw-semibold text-black font-poppins text-left">
											In built chat system
										</h3>
										<p className="common-txt fs-6 fw-normal mb-3">
											Stay connected and collaborate seamlessly with our
											in-built chat system
										</p>
									</div>
									<div className="feature-img-div">
										<img
											src={feature4Img}
											alt="feature"
											className="feature-image"
										/>
									</div>
								</div>
							</div>

							<div className="col-12 col-md-4">
								<div className="feature-div">
									<div>
										<h3 className="fs-3 fw-semibold text-black font-poppins text-left">
											Detailed analysis
										</h3>
										<p className="common-txt fs-6 fw-normal mb-3">
											Gain deeper insights and make informed decisions with our
											comprehensive detailed analysis tools
										</p>
									</div>
									<div className="feature-img-div">
										<img
											src={feature5Img}
											alt="feature"
											className="feature-image"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Testimonial section */}
				<div className="testimonial-section mt-5 pt-5 mb-5">
					<div className="container d-none">
						<div className="row m-0 p-0 align-items-center">
							<div className="col-12 col-md-6">
								<div
									className="slide-in-fwd-left"
									data-animation="slide-in-fwd-left"
								>
									<h2 className="section-header fs-48 fw-bold text-left">
										Hear from our customers
									</h2>
									<p className="common-txt fw-normal font-poppins ms-0">
										Listen to your customers and drive improvement with our
										customer feedback tools
									</p>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div
									className="slide-in-fwd-right"
									data-animation="slide-in-fwd-right"
								>
									<div className="d-flex align-items-center justify-content-end">
										<button
											className="common-btn white-btn animation-right text-uppercase fw-bold fs-6"
											onClick={() => {
												navigate('/auth/signup')
											}}
											style={{
												border: '1px solid #204464'
											}}
										>
											Launch Synccos Pms
											<span className="icon ms-3">
												<i className="fa-solid fa-arrow-right-to-bracket"></i>
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>

						<div
							id="carouselExampleIndicators"
							className="carousel mt-5 pt-3 carousel-fade d-none"
							data-bs-ride="carousel"
						>
							<div className="carousel-inner">
								<div className="carousel-item active" data-bs-interval="3000">
									<div className="row testinomial-carousel-row">
										<div className="col-12 col-md-4">
											<div className="customer-div">
												<div className="d-flex align-items-center">
													<div className="customer-img-div">
														<img src={customerImg} alt="" />
													</div>
													<div className="ms-3">
														<p className="fw-semibold fs-4 font-poppins text-black mb-0">
															Customer Name
														</p>
														<p className="fs-6 fw-normal font-poppins">
															@customername
														</p>
													</div>
												</div>
												<p className="fs-6 common-txt font-poppins fw-normal mb-0">
													“Unlock the Full Potential of Your Product Team with
													Our Cutting-Edge Product Management.Unlock the Full
													Potential of Your Product Team with Our Cutting-Edge
													Product Management Unlock the Full Potential of Your
													Product Team with Our Cutting-Edge Product Management
													“
												</p>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="customer-div">
												<div className="d-flex align-items-center">
													<div className="customer-img-div">
														<img src={customerImg} alt="" />
													</div>
													<div className="ms-3">
														<p className="fw-semibold fs-4 font-poppins text-black mb-0">
															Customer Name
														</p>
														<p className="fs-6 fw-normal font-poppins">
															@customername
														</p>
													</div>
												</div>
												<p className="fs-6 common-txt font-poppins fw-normal mb-0">
													“Unlock the Full Potential of Your Product Team with
													Our Cutting-Edge Product Management.Unlock the Full
													Potential of Your Product Team with Our Cutting-Edge
													Product Management Unlock the Full Potential of Your
													Product Team with Our Cutting-Edge Product Management
													“
												</p>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="customer-div">
												<div className="d-flex align-items-center">
													<div className="customer-img-div">
														<img src={customerImg} alt="" />
													</div>
													<div className="">
														<p className="fw-semibold fs-4 font-poppins text-black">
															Customer Name
														</p>
														<p className="fs-6 fw-normal font-poppins">
															@customername
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="carousel-item" data-bs-interval="3000">
									<div className="row testinomial-carousel-row">
										<div className="col-12 col-md-4">
											<div className="customer-div">
												<div className="d-flex align-items-center">
													<div className="customer-img-div">
														<img src={customerImg} alt="" />
													</div>
													<div className="">
														<p className="fw-semibold fs-4 font-poppins text-black">
															Customer Name
														</p>
														<p className="fs-6 fw-normal font-poppins">
															@customername
														</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="customer-div">
												<div className="d-flex align-items-center">
													<div className="customer-img-div">
														<img src={customerImg} alt="" />
													</div>
													<div className="">
														<p className="fw-semibold fs-4 font-poppins text-black">
															Customer Name
														</p>
														<p className="fs-6 fw-normal font-poppins">
															@customername
														</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="customer-div">
												<div className="d-flex align-items-center">
													<div className="customer-img-div">
														<img src={customerImg} alt="" />
													</div>
													<div className="">
														<p className="fw-semibold fs-4 font-poppins text-black">
															Customer Name
														</p>
														<p className="fs-6 fw-normal font-poppins">
															@customername
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="carousel-item" data-bs-interval="3000">
									<div className="row testinomial-carousel-row">
										<div className="col-12 col-md-4">
											<div className="customer-div">
												<div className="d-flex align-items-center">
													<div className="customer-img-div">
														<img src={customerImg} alt="" />
													</div>
													<div className="">
														<p className="fw-semibold fs-4 font-poppins text-black">
															Customer Name
														</p>
														<p className="fs-6 fw-normal font-poppins">
															@customername
														</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="customer-div">
												<div className="d-flex align-items-center">
													<div className="customer-img-div">
														<img src={customerImg} alt="" />
													</div>
													<div className="">
														<p className="fw-semibold fs-4 font-poppins text-black">
															Customer Name
														</p>
														<p className="fs-6 fw-normal font-poppins">
															@customername
														</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="customer-div">
												<div className="d-flex align-items-center">
													<div className="customer-img-div">
														<img src={customerImg} alt="" />
													</div>
													<div className="">
														<p className="fw-semibold fs-4 font-poppins text-black">
															Customer Name
														</p>
														<p className="fs-6 fw-normal font-poppins">
															@customername
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="carousel-controllers d-flex align-items-center justify-content-center">
								{/* <button
								className="carousel-control-prev position-relative"
								type="button"
								data-bs-target="#carouselExampleIndicators"
								data-bs-slide="prev"
							>
								<i className="fa-solid fa-chevron-left"></i>
							</button> */}
								<div className="carousel-indicators position-relative">
									<button
										type="button"
										data-bs-target="#carouselExampleIndicators "
										data-bs-slide-to="0"
										className="active testimonial-carousel-indicator"
										aria-current="true"
										aria-label="Slide 1"
									></button>
									<button
										type="button"
										data-bs-target="#carouselExampleIndicators"
										data-bs-slide-to="1"
										aria-label="Slide 2"
										className="testimonial-carousel-indicator"
									></button>
									<button
										type="button"
										data-bs-target="#carouselExampleIndicators"
										data-bs-slide-to="2"
										aria-label="Slide 3"
										className="testimonial-carousel-indicator"
									></button>
								</div>
								{/* <button
								className="carousel-control-next position-relative"
								type="button"
								data-bs-target="#carouselExampleIndicators"
								data-bs-slide="next"
							>
								<i className="fa-solid fa-chevron-right"></i>
							</button> */}
							</div>
						</div>
					</div>
				</div>

				{/* Clients section */}
				<div className="our-clients-section mb-5 pb-5 d-none">
					<div className="container">
						<h2 className="section-header fs-48 fw-bold text-center">
							Our Clients
						</h2>
						<p className="common-txt fw-normal text-center">
							We have been working with some Fortune 500+ clients
						</p>
						<div className="row mb-5">
							<div className="col-12 col-md-4">
								<div className="client-logo">
									<img src={cavalaryLogo} alt="client-logo" />
								</div>
							</div>
							<div className="col-12 col-md-4">
								<div className="client-logo">
									<img src={zoomTechLogo} alt="client-logo" />
								</div>
							</div>
							<div className="col-12 col-md-4">
								<div className="client-logo">
									<img src={cofinanceLogo} alt="client-logo" />
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Lets start section */}
				<div className="start-section position-relative my-5">
					<div className="container">
						<div className="row">
							<div className="col-12 col-md-6">
								<h2
									className="section-header fs-48 fw-bold text-left mt-4"
									data-animation="slide-in-fwd-bottom"
								>
									What are you waiting for? Try it for free
								</h2>
								<p
									className="common-txt fw-normal fs-5 mb-5 slide-in-fwd-top"
									data-animation="slide-in-fwd-top"
								>
									Unlock the Full Potential of Your Product Team with Our
									Cutting-Edge Product Management SAAS. From Ideation to Market
								</p>
								<div
									className="d-flex align-items-start flex-column slide-in-fwd-top"
									data-animation="slide-in-fwd-top"
								>
									<button
										className="common-btn blue-btn animation-right text-uppercase fw-bold fs-6 mb-3"
										onClick={(e) => {
											e.preventDefault()
											navigate('/auth/signup')
										}}
									>
										Launch Synccos Pms
										<span className="icon ms-3">
											<i className="fa-solid fa-arrow-right"></i>
										</span>
									</button>
								</div>
							</div>
							<div className="col-12 col-md-6 position-relative">
								<div
									className="d-flex align-items-center justify-content-center slide-in-fwd-right banner-img-div"
									data-animation="slide-in-fwd-right"
								>
									<img src={tryFreeImg} alt="" className="banner-img" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FloatButton
				onClick={() => {
					window.scrollTo(0, 0)
				}}
				className="homepage-float-btn"
				icon={
					<ArrowUpward
						sx={{
							fontSize: '1rem'
						}}
					/>
				}
			/>
		</>
	)
}

export default Home
