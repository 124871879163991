import { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import Cookies from 'js-cookie'
import { updateUserData, verifyOTP } from '../API/UserAPI'

import FormHeader from '../components/shared/forms/FormHeader'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { message } from 'antd'

import ButtonComponent from '../components/shared/ButtonComponent'
import { checkIntegration } from '../utils/helper'

const OTPVerify = (invite) => {
	const navigate = useNavigate()

	const [otp, setOtp] = useState('')
	const location = useLocation()
	const [formValues, setformValues] = useState(null)

	const [messageApi, contextHolder] = message.useMessage()

	useEffect(() => {
		if (location?.state?.values) {
			setformValues({ ...location?.state?.values })
		} else {
			navigate('/auth/sign-up')
		}
	}, [location])

	// const handleSubmit = async (event) => {
	// 	event.preventDefault()

	// 	if (otp.length !== 6) {
	// 		message.warning('OTP must be of 6 digits.')
	// 		return false
	// 	}

	// 	try {
	// 		const res = await verifyOTP({
	// 			email: formValues?.email,
	// 			otp: otp
	// 		})
	// 		messageApi.success('OTP verification successful!')
	// 		userSignUpNode(formValues)
	// 	} catch (error) {
	// 		const { response } = error
	// 		messageApi.error('OTP verification has failed!')
	// 	}
	// }

	// const userSignUpNode = async (values) => {
	// 	if (values.password !== values.confirmPassword) {
	// 		message.warning('Password Mismatch.')
	// 		return false
	// 	}

	// 	const body = { ...values }
	// 	delete body.confirmPassword

	// 	try {
	// 		const res = await signUpAPIJava(values)
	// 		const { data } = res

	// 		Cookies.set('pms-access-token', data?.accessToken, {
	// 			expires: 1 / 24
	// 		})

	// 		Cookies.set('pms-refresh-token', data?.refreshToken, {
	// 			expires: 1
	// 		})

	// 		Cookies.set('user-status', true, {
	// 			expires: 12 / 24
	// 		})

	// 		Cookies.set('user-email', body?.email, {
	// 			expires: 1 / 24
	// 		})
	// 			navigate('/dashboard/projects')
	// 	} catch (error) {
	// 		debugger
	// 		message.error('Failed to signup.')
	// 	}
	// }

	return (
		<>
			{contextHolder}
			<div className="auth-form-div">
				<div className="pt-5">
					<FormHeader
						head=" Verify Your Email "
						text={`We sent a verification code to ${formValues?.email}`}
					/>
				</div>

				<OtpInput
					value={otp}
					onChange={setOtp}
					numInputs={6}
					className="d-flex align-items-center justify-content-between mb-5"
					renderInput={(props) => (
						<input
							{...props}
							className="otpInput fs-3 text-black mb-5"
							type="number"
						/>
					)}
					containerStyle={{
						justifyContent: 'space-between'
					}}
				/>
				<ButtonComponent
					type="submit"
					text="Continue"
					extraclassName="mt-5 w-100"
					variant="dark"
					//click={handleSubmit}
				/>

				<p className="redirect-txt text-center mt-5">
					Back To <Link to="/auth/signup">SignUp</Link>
				</p>
			</div>
		</>
	)
}

export default OTPVerify
