import Cookies from 'js-cookie'
import { deleteRequest, getRequest, postRequest, putRequest } from '.'

const getCallLogs = async (body) => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await postRequest('/v1/call-log/all', {
		...body,
		companyDetailsId: companyDetailsId
	})
	return res
}

const createCallLog = async (body) => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await postRequest('/v1/call-log', {
		...body,
		companyDetailsId: companyDetailsId
	})
	return res
}

const updateCallLog = async (body) => {
	const res = await putRequest('/v1/call-log', body)
	return res
}

const deleteCallLog = async (body) => {
	const res = await deleteRequest(`/v1/call-log/multiple?ids=${body.join()}`)
	return res
}

const getCallLogById = async (id) => {
	const res = await getRequest(`/v1/call-log/${id}`)
	return res
}

const getDomains = async () => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await getRequest(
		`/v1/skyswitch-credentials/domain?companyDetailsId=${companyDetailsId}`
	)
	return res
}

const getAdminEmail = async (domain) => {
	const res = await getRequest(
		`/v1/skyswitch-credentials/admin?domain=${domain}`
	)
	return res
}

const resendDomainOtp = async (body) => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = postRequest('/v1/skyswitch-credentials/admin/send-email', {
		...body,
		companyDetailsId: companyDetailsId
	})
	return res
}

const createDomain = async (body) => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await postRequest('/v1/skyswitch-credentials/domain', {
		...body,
		companyDetailsId: companyDetailsId
	})

	return res
}

const getActiveCalls = async (domain) => {
	const res = await postRequest('/v1/call-log/active/all', {
		type: 'ACTIVE',
		domain
	})
	return res
}

const getDomainDetails = async (domain) => {
	const res = await getRequest(`/v1/call-log/domain-details?domain=${domain}`)
	return res
}

export {
	getCallLogs,
	updateCallLog,
	deleteCallLog,
	createCallLog,
	getCallLogById,
	getDomains,
	getAdminEmail,
	createDomain,
	resendDomainOtp,
	getActiveCalls,
	getDomainDetails
}
