import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useLocation } from 'react-router'

const AppLayout = (props: any) => {
	const location = useLocation()

	return (
		<>
			{location?.pathname.includes('auth') ||
			location.pathname?.includes('/dashboard') ||
			location?.pathname?.includes('blog') ? null : (
				<Header />
			)}

			<div className="main-container">{props.children}</div>
			{location?.pathname.includes('auth') ||
			location?.pathname?.includes('blog') ||
			location.pathname?.includes('/dashboard') ? null : (
				<Footer />
			)}
		</>
	)
}

export default AppLayout
