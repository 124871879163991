import { useState, useEffect } from 'react'
import { Layout, Input, Button, Avatar, Dropdown, Tooltip } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { Apps } from '@mui/icons-material'
import helpIcon from '../../assets/images/help-icon.svg'
import logoutIcon from '../../assets/images/logout-icon.svg'
import accountCircleIcon from '../../assets/images/account-circle-icon.svg'
import { appLogoWhiteTheme } from '../../assets/svg'
import { logOut } from '../../redux/loginLogout'
import Cookies from 'js-cookie'
import { emptyAppData } from '../../redux/appData'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'antd'
import { getPaticularUserData, getUser } from '../../API/UserAPI'
import RolesAndPermissions from '../../pages/ManageAccounts'
import AvatarGroupComponent from '../shared/AvatarGroupComponent'
import { IconButton } from '@mui/material'
import ProjectApps from '../views/ProjectApps'
import QuickCTA from '../quickCTA'

const { Header } = Layout
const { Search } = Input

const DashboardHeader = () => {
	const onSearch = (value) => console.log(value)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const location = useLocation()

	const [isModalOpen, setIsModalOpen] = useState(false)

	const [userData, setUserData] = useState(null)
	const [userPaticularData, setUserPaticularData] = useState(null)
	const getUserData = async () => {
		try {
			const res = await getUser()
			const { data } = res
			setUserData({ ...data })
		} catch (error) {
			console.log('error while setting user data', error)
		}
	}

	const getPaticularUserDataAPi = async () => {
		try {
			const res = await getPaticularUserData()
			if (res === null) {
				navigate('/auth/company')
			} else {
				const { data } = res
				setUserPaticularData(data)
			}
		} catch (error) {
			console.log('error while setting user data', error)
		}
	}

	useEffect(() => {
		// getUserData()
		getPaticularUserDataAPi()
	}, [])

	const showModal = () => {
		setIsModalOpen(true)
	}

	const handleOk = () => {
		logout()
		setIsModalOpen(false)
	}

	const handleCancel = () => {
		setIsModalOpen(false)
	}

	const logout = () => {
		Cookies.remove('organization')
		dispatch(logOut())
		dispatch(emptyAppData())
		Cookies.remove('pms-refresh-token')
		Cookies.remove('pms-access-token')
		Cookies.remove('check-access-token')
		Cookies.remove('check-refresh-token')
		Cookies.remove('user-status')
		navigate('/')
	}

	const menuItems = [
		{
			label: (
				<div className="d-flex flex-column justify-content-start rounded-0 ms-2">
					<span className="font-roboto fs-6 fw-semibold profile-name">
						{userPaticularData?.firstName || ''}{' '}
						{userPaticularData?.lastName || ''}
					</span>
					<span className="font-roboto fs-12 fw-normal org-name">
						{userPaticularData?.email || ''}
					</span>
				</div>
			),
			icon: (
				<AvatarGroupComponent
					name={
						userPaticularData?.firstName && userPaticularData?.firstName !== ''
							? `${userPaticularData?.firstName || ''} ${
									userPaticularData?.lastName || ''
							  }`
							: userPaticularData?.email && userPaticularData?.email !== ''
							? `${userPaticularData?.email || ''}`
							: ''
					}
					imageUrl={userPaticularData?.imageUrl || ''}
					size={40}
				/>
			),
			key: '0'
		},
		{
			type: 'divider'
		},
		{
			label: (
				<div
					className="py-2"
					onClick={() => navigate('/dashboard/manage-accounts')}
				>
					<img src={accountCircleIcon} alt="account-circle-icon" />
					<span className="ms-2 font-roboto fs-12 fw-normal ">
						Manage your account
					</span>
				</div>
			),
			key: '1'
		},
		{
			label: (
				<div className="py-2">
					<img src={helpIcon} alt="help-icon" />
					<span className="ms-2 font-roboto fs-12 fw-normal ">Get help</span>
				</div>
			),
			key: '2'
		},
		{
			label: (
				<div onClick={showModal} className="py-2">
					<img src={logoutIcon} alt="logout-icon" />
					<span className="ms-2 font-roboto fs-12 fw-normal ">Logout</span>
				</div>
			),
			key: '3'
		}
	]

	return (
		<Header
			className="dashboard-header bg-white px-2 py-0"
			style={{
				height: 'auto',
				lineHeight: 'normal'
			}}
		>
			<nav className="navbar navbar-expand-lg bg-white dashboard-header-inner">
				<div className="container-fluid">
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarTogglerDemo01"
						aria-controls="navbarTogglerDemo01"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarTogglerDemo01">
						<Link to="/dashboard/main" className="app-brand-logo">
							{appLogoWhiteTheme}
						</Link>
						<div className="d-flex align-items-center ms-auto">
							{location?.pathname?.includes('/main') && (
								<QuickCTA originComp="Header" />
							)}

							{/* <Search
								placeholder="Search"
								onSearch={onSearch}
								style={{ width: 120 }}
								className="header-search"
							/>

							<Button
								type="primary"
								className="bg-white border-0 ms-1"
								style={{
									boxShadow: 'none'
								}}
							>
								<i
									className="fa-solid fa-bell"
									style={{
										color: '#204464'
									}}
								></i>
							</Button> */}
							{/* <Dropdown
								menu={{ items: dummyItems }}
								trigger={['click']}
								className="text-white app-header-dropdown mx-3 fs-14"
							>
								<a
									onClick={(e) => e.preventDefault()}
									className="d-flex align-items-center text-decoration-none fw-semibold "
									style={{
										backgroundColor: '#204464',
										border: '1.5px solid #204464',
										lineHeight: 'normal'
									}}
								>
									<AddCircleOutlineRounded />
									<span className="mx-2">Create a new</span>
									<ExpandMoreRounded />
								</a>
							</Dropdown> */}

							<Tooltip
								trigger={['click']}
								title={<ProjectApps />}
								className="project-apps-tooltip me-3 ms-3"
								overlayClassName="project-apps-overlay"
								placement="bottomRight"
								color="#fff"
								arrow={false}
								overlayStyle={{
									maxWidth: '20rem',
									width: 'max-width'
								}}
							>
								<IconButton>
									<Tooltip
										color="rgba(0,0,0,0.5)"
										overlayStyle={{
											width: 'fit-content',
											maxWidth: 'fit-content'
										}}
										title={<p className="mb-0">Our Apps</p>}
										overlayInnerStyle={{
											width: 'fit-content',
											maxWidth: 'fit-content'
										}}
										trigger={['hover']}
									>
										<Apps
											sx={{
												fontSize: '1.5rem',
												color: '#555'
											}}
										/>
									</Tooltip>
								</IconButton>
							</Tooltip>

							<Dropdown
								menu={{ items: menuItems }}
								trigger={['click']}
								className="app-header-dropdown"
								style={{
									//border: '2px solid #434343',
									backgroundColor: 'red'
								}}
							>
								<a onClick={(e) => e.preventDefault()}>
									<AvatarGroupComponent
										name={
											userPaticularData?.firstName &&
											userPaticularData?.firstName !== ''
												? `${userPaticularData?.firstName || ''} ${
														userPaticularData?.lastName || ''
												  }`
												: userPaticularData?.email &&
												  userPaticularData?.email !== ''
												? `${userPaticularData?.email || ''}`
												: ''
										}
										imageUrl={userPaticularData?.imageUrl || ''}
										size={40}
									/>
									{/* <Avatar size="large" className="ms-3">
										<img src={profilePic} alt="profile-pic" />
									</Avatar> */}
								</a>
							</Dropdown>
						</div>

						<Modal
							open={isModalOpen}
							footer={null}
							closable={false}
							centered
							className="square-modal"
						>
							<div className="p-2 d-flex flex-column p-3">
								<h3 className="confirm-logout-heading fw-bolder fs-24 font-roboto">
									Confirm Log Out
								</h3>
								<p className="confirm-logout-heading fs-16 font-roboto fw-normal">
									Logging out will terminate your current session and require
									you to sign in again to access your account
								</p>
								<div className="d-flex justify-content-end align-items-center">
									<button
										onClick={handleCancel}
										className="cancel-btn font-roboto fs-14 fw-bolder py-2 px-4"
									>
										Cancel
									</button>
									<button
										onClick={handleOk}
										className="logout-btn font-roboto fs-14 fw-bolder ms-3 py-2 px-4"
									>
										Log out
									</button>
								</div>
							</div>
						</Modal>
					</div>
				</div>
			</nav>
		</Header>
	)
}

export default DashboardHeader
