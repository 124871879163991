import React from 'react'
import ModalComponent from '../ModalComponent'
import alertIcon from '../../../assets/images/alert-icon.svg'
import ButtonComponent from '../ButtonComponent'

const DeleteClient = ({
	isModalOpen,
	setIsModalOpen,
	dataSelected,
	deletClients
}) => {
	const numOfClients = dataSelected?.length

	return (
		<ModalComponent
			width={'40%'}
			title={
				<div className="py-2 px-3 d-flex justify-content-start align-items-center">
					<img src={alertIcon} alt="icon" />
					<span className="ms-2 fw-bolder fs-4 font-roboto ">
						Delete Clients
					</span>
				</div>
			}
			open={isModalOpen}
			setOpen={setIsModalOpen}
			onClose={() => setIsModalOpen(false)}
			closable={false}
		>
			<div className="d-flex flex-column px-3">
				<span className="font-roboto fw-normal fs-6">{`Are you sure you want to delete "${numOfClients} Client${
					numOfClients > 1 ? 's' : ''
				}"?`}</span>
				<span className="font-roboto fw-normal fs-6 mt-3">
					No need to worry, you can restore them within 30 days if you change
					your mind. For now, they will be archived, but after this period,
					they'll be permanently deleted
				</span>
				<div className="d-flex justify-content-end align-items-center my-3">
					<ButtonComponent
						//variant="light"
						text="Cancel"
						click={() => setIsModalOpen(false)}
						extraclassName="font-roboto fs-6 fw-bolder"
						style={{ borderColor: '#555555', color: '#555555' }}
					/>
					<ButtonComponent
						variant="dark"
						text="Delete"
						click={() => {
							setIsModalOpen(false)
							deletClients()
						}}
						extraclassName="ms-3"
					/>
				</div>
			</div>
		</ModalComponent>
	)
}

export default DeleteClient
