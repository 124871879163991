import { useEffect, useState } from 'react'
import IndividualViewFilters from '../../../shared/TableComponents/IndividualViewFilters'
import TableComponent from '../../../shared/TableComponent'
import SwitchScreen from '../IssueDetails/TasksHeader/SwitchScreen'
import { getSprints } from '../../../../API/sprintAPI'
import Status from './Status'
import AvatarGroupComponent from '../../../shared/AvatarGroupComponent'
import dayjs from 'dayjs'
import { formatDate } from '../../../../utils/helper'
import { Button, Progress } from 'antd'
import ContextMenu from '../../../shared/ContextMenu'
import { Delete, Edit, Launch } from '@mui/icons-material'

const Sprints = ({
	projectId,
	projectName,
	newSprint,
	setEditData,
	addNewSprint,
	activeKey
}) => {
	const [activeFilter, setActiveFilter] = useState('allSprints')
	const [isLoading, setIsLoading] = useState(false)
	const [isKanbanview, setIsKanbanview] = useState(false)
	const [data, setData] = useState([])

	const getData = async () => {
		setIsLoading(true)
		try {
			const res = await getSprints(projectId, projectName)
			const { data = [] } = res
			setData([...data])
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getData()
	}, [addNewSprint, activeKey])

	const sprints = [
		{
			key: 'Upcoming',
			value: 'UPCOMING'
		},
		{
			key: 'Active',
			value: 'ACTIVE'
		},
		{
			key: 'Completed',
			value: 'COMPLETED'
		}
	]

	const onFilterChange = (e) => {
		setActiveFilter(e?.target?.value || 'All Sprints')
	}

	const getStyles = (value) => {
		switch (value) {
			case 'On Hold':
				return {
					border: '1px solid #C9EBFF',
					backgroundColor: '#E6F7FF',
					color: '#1890FF',
					borderRadius: '2px'
				}
			case 'Active':
				return {
					border: '1px solid #DEC3F7',
					backgroundColor: '#F9F0FF',
					color: '#722ED1',
					borderRadius: '2px'
				}
			case 'Completed':
				return {
					border: '1px solid #FFEBC9',
					backgroundColor: '#FFF7E6',
					color: '#E19A0C',
					borderRadius: '2px'
				}
			case 'Upcoming':
				return {
					border: '1px solid #CFEBBA',
					backgroundColor: '#F6FFED',
					color: '#52C41A',
					borderRadius: '2px'
				}
			default:
				return {}
		}
	}

	const dataSource =
		data &&
		data?.length !== 0 &&
		data.map((sprint) => {
			return {
				sprintDetails: {
					name: sprint?.name,
					startDate: sprint?.startDate
				},
				projectLeadDetails: {
					projectLead:
						sprint?.project?.leadName || sprint?.project?.leadEmail || '',
					projectLeadAvatarUrl: sprint?.project?.leadImageUrl || ''
				},
				members: sprint?.members ?? [],
				endDate: sprint?.endDate ?? [],
				status: sprint?.status ?? [],
				progress: sprint?.sprintProgress || 0,
				contactLead: '',
				record: sprint
			}
		})

	const columns = [
		{
			title: 'Sprint name',
			dataIndex: 'sprintDetails',
			key: 'sprintDetails',
			render: (sprintDetails) => (
				<div className="d-flex flex-column">
					<span className="fs-12 font-roboto fw-semibold projectData text-capitalize">
						{sprintDetails?.name}
					</span>
					<span className="fs-11 font-roboto fw-normal projectData">
						{' '}
						Start Date : {formatDate(sprintDetails?.startDate)}
					</span>
				</div>
			)
		},
		{
			title: 'Project Lead',
			dataIndex: 'projectLeadDetails',
			key: 'projectLeadDetails',
			render: (projectLeadDetails) => {
				if (
					projectLeadDetails?.projectLead !== '' ||
					projectLeadDetails?.projectLeadAvatarUrl !== ''
				) {
					return (
						<div className="d-flex align-items-center">
							<AvatarGroupComponent
								size={'large'}
								imageUrl={projectLeadDetails?.projectLeadAvatarUrl}
								name={projectLeadDetails?.projectLead}
							/>{' '}
							<span className="ms-2 font-roboto fs-12 txt-color-primary">
								{projectLeadDetails?.projectLead}
							</span>
						</div>
					)
				} else {
					return (
						<span className="fw-semibold font-roboto fs-12 ms-2 projectData">
							{projectLeadDetails?.projectLeadEmail?.split('@')?.[0] || '----'}
						</span>
					)
				}
			}
		},
		{
			title: 'Members',
			dataIndex: 'members',
			key: 'members',
			render: (members) => {
				if (members && members.length > 0) {
					return <AvatarGroupComponent data={[...members]} />
				} else {
					return (
						<span className="fw-semibold font-roboto fs-12 projectData ms-2">
							----
						</span>
					)
				}
			}
		},
		{
			title: 'End Date',
			dataIndex: 'endDate',
			render: (endDate) => (
				<span className="fw-semibold font-roboto fs-12 projectData">
					{formatDate(endDate)}
				</span>
			)
		},
		{
			title: 'Status',
			dataIndex: 'status',
			render: (text, record) => {
				return (
					<div onClick={(e) => e.stopPropagation()}>
						<Status
							value={record.status}
							// statusOptions={statusOptions}
							// select={(newStatus) => {
							// 	handleStatusChange(record, newStatus)
							// }}
							getStyles={(status) => getStyles(status)}
						/>
					</div>
				)
			}
		},
		{
			title: 'Sprint Progress',
			dataIndex: 'progress',
			key: 'progress',
			render: (progress) => (
				<Progress
					percent={progress && progress?.toFixed(0)}
					strokeColor={'#3571A7'}
					className="table-progress"
				/>
			)
		},
		{
			title: ' ',
			dataIndex: 'contactLead',
			key: 'contactLead',
			render: (contactLead) => {
				if (contactLead && contactLead !== '') {
					return (
						<Button
							href={`mailto:${contactLead}`}
							className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none border-0 bg-transparent p-0 d-flex align-items-center gap-2"
							onClick={(e) => {
								e.stopPropagation()
							}}
							style={{
								boxShadow: 'none',
								height: '100%'
							}}
						>
							Contact Lead{' '}
							<Launch
								sx={{
									fontSize: 'inherit',
									color: 'inherit'
								}}
							/>
						</Button>
					)
				} else {
					return <span className="fs-12 fw-500 font-roboto">----</span>
				}
			}
		},
		{
			title: ' ',
			render: (record) => {
				const options = [
					{
						key: '1',
						label: (
							<div
								className=""
								onClick={() => {
									////Functions here
								}}
							>
								<div className="context-menu-option">
									<div className="d-flex align-items-center">
										<Delete className="context-menu-icon" />

										<span className="fs-14 fw-semibold txt-color-primary font-roboto">
											Delete
										</span>
									</div>
								</div>
							</div>
						)
					},
					{
						key: '2',
						label: (
							<div
								className=""
								onClick={() => {
									setEditData(record?.record)
									newSprint()
								}}
							>
								<div className="context-menu-option">
									<div className="d-flex align-items-center">
										<Edit className="context-menu-icon" />

										<span className="fs-14 fw-semibold txt-color-primary font-roboto">
											Edit
										</span>
									</div>
								</div>
							</div>
						)
					}
				]

				return (
					<div
						onClick={(e) => {
							e.stopPropagation()
						}}
						style={{ width: '25px', height: '25px', borderRadius: '50%' }}
						className="d-flex justify-content-center align-items-center"
					>
						<ContextMenu items={options} />
					</div>
				)
			}
		}
	]

	return (
		<div>
			{/* <div className="mt-1 mb-3 d-flex flex-row justify-content-between">
				<div className="">
					<IndividualViewFilters
						activeFilter={activeFilter}
						filtersData={sprints}
						onchange={onFilterChange}
					/>
					<TaskHeaderTags setSelectedHeaderTag={setSelectedHeaderTag} selectedHeaderTag={selectedHeaderTag} />
				</div>
				<div className="">
					<SwitchScreen
						setIsKanbanview={setIsKanbanview}
						isKanbanview={isKanbanview}
					/>
				</div>
			</div> */}
			<TableComponent
				dataSource={dataSource}
				columns={columns}
				// onRow={(record) => {
				// 	return {
				// 		onClick: () => {
				// 			navigate(`/dashboard/projects/${record?.projectId}`)
				// 		}
				// 	}
				// }}
				loading={isLoading}
			/>
		</div>
	)
}

export default Sprints
