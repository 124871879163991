import React, { useState } from 'react'
import ModalComponent from '../../../ModalComponent'
import { Tabs } from 'antd'
import ProjectForm from './ProjectForm'
import MemberForm from './MemberForm'

const AddClientProject = ({
	open,
	onClose,
	clientId,
	getData = () => console.log('getData'),
	clientProjects,
	control = '',
	originComp = ''
}) => {
	const [activeKey, setActiveKey] = useState('1')
	const [formData, setFormData] = useState({})

	const handleTabChange = (key) => {
		setActiveKey(key)
	}

	const items = [
		{
			key: '1',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '1' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">1</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '1' ? '#262626' : '#9D9D9D' }}
					>
						Add Project Information
					</span>
				</div>
			),
			children: (
				<ProjectForm
					handleTabChange={handleTabChange}
					setFormData={setFormData}
					onClose={onClose}
					formData={formData}
				/>
			)
		},
		{
			key: '2',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '2' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">2</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '2' ? '#262626' : '#9D9D9D' }}
					>
						Add Members
					</span>
				</div>
			),
			children: (
				<MemberForm
					handleTabChange={handleTabChange}
					formData={formData}
					clientId={clientId}
					onClose={onClose}
					getData={getData}
					projects={clientProjects}
					setFormData={setFormData}
					control={control}
					originComp={originComp}
				/>
			)
		}
	]

	return (
		<ModalComponent title={'Create a Project'} open={open} onClose={onClose}>
			<div className="container-fluid">
				<Tabs
					defaultActiveKey={'1'}
					items={items}
					activeKey={activeKey}
					className="create-project-tabs mt-4"
					onChange={handleTabChange}
				/>
			</div>
		</ModalComponent>
	)
}

export default AddClientProject
