import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import ButtonComponent from '../shared/ButtonComponent'
import { AddCircleOutlineOutlined } from '@mui/icons-material'
import './calendar.css'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Card, Modal, Radio } from 'antd'
import TaskEventModal from './TaskEventModal'
import ProjectEventModal from './ProjectEventModal'

const locales = {
	'en-US': require('date-fns/locale/en-US')
}
const localizer = dateFnsLocalizer({
	format,
	parse,
	startOfWeek,
	getDay,
	locales
})

const eventStyleGetter = (event, start, end, isSelected) => {
	const style = {
		marginLeft: '0.75rem',
		marginBottom: '2px',
		borderRadius: '2px'
	}
	if (event.type === 'project') {
		style.borderLeft = '2px solid #4242DF'
		style.background = 'rgba(66, 66, 223, 0.04)'
	} else if (event.type === 'task') {
		style.borderLeft = '2px solid #1890FF'
		style.background = 'rgba(24, 144, 255, 0.04)'
	}
	if (end) {
		style.width = '97%'
	}

	return {
		style,
		className: `event-card ${isSelected ? 'selected-event' : 'normal-event'}`
	}
}

function CalendarComp({ taskData, projectData, setIsReload, isReload }) {
	const [newEvent, setNewEvent] = useState({ title: '', start: '', end: '' })
	const [allEvents, setAllEvents] = useState([])
	const [selectedView, setSelectedView] = useState('month')
	const [selectedEvent, setSelectedEvent] = useState(null)
	const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 })
	const [isModalOpen, setIsModalOpen] = useState(false)
	const cardRef = useRef(null)
	const cardActionRef = useRef(null)

	const initialValues = { title: '', start: '', end: '' }

	useEffect(() => {
		const handleMutation = (mutationsList) => {
			if (mutationsList?.length !== 1) {
				return false
			}

			const tempNodes = [...mutationsList[0]?.addedNodes]

			if (
				tempNodes.length !== 1 ||
				!tempNodes[0].classList.value.includes('rbc-overlay')
			) {
				return false
			}

			const parent = document.querySelector('.rbc-overlay')

			for (const mutation of mutationsList) {
				if (mutation.type === 'childList') {
					const addedNodes = Array.from(mutation.addedNodes)
					const rbcOverlayNodes = addedNodes.filter(
						(node) =>
							node.nodeType === 1 && node.classList.contains('rbc-overlay')
					)

					if (rbcOverlayNodes.length > 0) {
						// When rbc-overlay appears, find rbc-event elements within it
						const rbcEventNodes =
							rbcOverlayNodes[0].querySelectorAll('.rbc-event')

						rbcEventNodes.forEach((eventNode) => {
							// Update draggable attribute to false
							eventNode.setAttribute('draggable', 'false')
							// Move the element to the body
							parent.appendChild(eventNode)
						})
					}
				}
			}
		}

		const observer = new MutationObserver(handleMutation)

		// Start observing changes in the body (or the common ancestor of rbc-overlay and rbc-event)
		const targetNode = document.body // Change this to the actual parent node
		const observerConfig = { childList: true, subtree: true }
		observer.observe(targetNode, observerConfig)

		return () => {
			observer.disconnect()
		}
	}, [])

	const taskEventCreate = (taskCalendar, taskArg) => {
		if (taskArg?.length) {
			taskCalendar = taskArg?.map((task) => {
				const { id, name: title, startDate, dueDate } = task
				let formatedStartDate = startDate?.split('T')?.[0]?.split('-')
				let formatedEndDate = dueDate?.split('T')?.[0]?.split('-')
				let [startYearElement, startMonthElement, startDateElement] =
					formatedStartDate
				let [endYearElement, endMonthElement, endDateElement] = formatedEndDate
				let start = new Date(
					startYearElement,
					+startMonthElement - 1,
					startDateElement
				)
				let end = new Date(
					endYearElement,
					+endMonthElement - 1,
					+endDateElement + 1
				)
				return {
					id,
					title,
					start,
					end,
					type: 'task',
					restAll: task,
					draggable: false
				}
			})
		}
		return taskCalendar
	}

	const projectCreateEvent = (projectCalendar, projectArg) => {
		if (projectArg?.length) {
			projectCalendar = projectArg?.map((project) => {
				const { id, name: title, startDate, dueDate } = project
				let formatedStartDate = startDate?.split('T')?.[0]?.split('-')
				let formatedEndDate = dueDate?.split('T')?.[0]?.split('-')
				let [startYearElement, startMonthElement, startDateElement] =
					formatedStartDate
				let [endYearElement, endMonthElement, endDateElement] = formatedEndDate

				let start = new Date(
					startYearElement,
					startMonthElement - 1,
					+startDateElement + 1
				)
				let end = new Date(
					endYearElement,
					endMonthElement - 1,
					+endDateElement + 2
				)
				return {
					id,
					title,
					start,
					end,
					type: 'project',
					restAll: project
				}
			})
		}
		return projectCalendar
	}

	useEffect(() => {
		// for making the events for the Task in calendar
		let taskCalendar = taskEventCreate([], taskData)

		// for making the events for the Project in calendar
		let projectCalendar = projectCreateEvent([], projectData)
		setAllEvents([...projectCalendar, ...taskCalendar])
	}, [taskData, projectData])

	const handleOutsideClick = (e) => {
		if (
			cardRef?.current &&
			!cardRef?.current?.contains(e.target) &&
			!cardActionRef?.current
		) {
			// Click occurred outside the card, hide the card
			// managing close the modal at click on anySide
			handleCloseModal()
		}
	}

	useEffect(() => {
		// Function to be called when mutation occurs
		const handleMutation = (mutationsList) => {
			for (const mutation of mutationsList) {
				if (mutation.type === 'childList') {
					// Check if the added nodes include an element with the class 'rc-drag'
					const addedNodes = Array.from(mutation.addedNodes)

					const hasRCDrag = addedNodes.some(
						(node) =>
							node.nodeType === 1 && node.classList.contains('rbc-event')
					)

					if (hasRCDrag) {
						console.log('Element with class "rc-drag" added to the DOM')
						// Perform actions when 'rc-drag' is added
					}
				}
			}
		}

		// Create a MutationObserver instance
		const observer = new MutationObserver(handleMutation)

		// Start observing changes in the target node (e.g., the parent of 'rc-drag' elements)
		const targetNode = document.body // Change this to the actual parent node
		const observerConfig = { childList: true, subtree: true }
		observer.observe(targetNode, observerConfig)

		// Cleanup: Disconnect the observer when the component unmounts
		return () => {
			observer.disconnect()
		}
	}, [])

	useEffect(() => {
		if (isModalOpen) {
			// Add event listener when modal is open
			window.addEventListener('mousedown', handleOutsideClick)
		} else {
			// Remove event listener when modal is closed
			window.removeEventListener('mousedown', handleOutsideClick)
		}

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('mousedown', handleOutsideClick)
		}
	}, [isModalOpen])

	const isToday = (date) => {
		const today = new Date()
		return (
			date.getDate() === today.getDate() &&
			date.getMonth() === today.getMonth() &&
			date.getFullYear() === today.getFullYear()
		)
	}

	function handleAddEvent() {
		for (let i = 0; i < allEvents.length; i++) {
			const d1 = new Date(allEvents[i].start)
			const d2 = new Date(newEvent.start)
			const d3 = new Date(allEvents[i].end)
			const d4 = new Date(newEvent.end)
			/*
                console.log(d1 <= d2);
                console.log(d2 <= d3);
                console.log(d1 <= d4);
                console.log(d4 <= d3);
                  */

			if ((d1 <= d2 && d2 <= d3) || (d1 <= d4 && d4 <= d3)) {
				alert('CLASH')
				break
			}
		}

		setAllEvents([...allEvents, newEvent])
		setNewEvent({ ...initialValues })
	}

	const { formats } = useMemo(
		() => ({
			formats: {
				weekdayFormat: (date, culture, localizer) => {
					return localizer.format(date, 'EEE', culture)
				}
			}
		}),
		[]
	)

	const dayPropGetter = (date) => {
		const dayOfWeek = getDay(date)

		//  styles for Saturday and Sunday
		if (dayOfWeek === 0 /* Sunday */ || dayOfWeek === 6 /* Saturday */) {
			return {
				style: {
					backgroundColor: '#FAFAFA' // to change background color of sat and sun
				}
			}
		}
		// if (isToday(date)) {
		// 	in future use for today customization
		// }

		return {}
	}
	const CustomToolbar = (toolbar) => {
		const { label, onNavigate, onView } = toolbar

		const options = [
			{
				key: '1',
				label: (
					<div
						className=""
						onClick={() => {
							onView('month')
						}}
					>
						<div className="context-menu-option">
							<div className="d-flex align-items-center">
								{/* <Delete className="context-menu-icon" /> */}

								<span
									className="fs-14 fw-semibold txt-color-primary font-roboto "
									style={
										selectedView === 'month'
											? {
													color: '#fff'
											  }
											: {}
									}
								>
									Month view
								</span>
							</div>
						</div>
					</div>
				),
				value: 'month'
			},
			{
				key: '2',
				label: (
					<div
						className=""
						onClick={() => {
							onView('week')
						}}
					>
						<div className="context-menu-option">
							<div className="d-flex align-items-center">
								{/* <Edit className="context-menu-icon" /> */}

								<span
									className="fs-14 fw-semibold txt-color-primary font-roboto"
									style={
										selectedView === 'week'
											? {
													color: '#fff'
											  }
											: {}
									}
								>
									Week view
								</span>
							</div>
						</div>
					</div>
				),
				value: 'week'
			}
		]

		return (
			<div className="d-flex justify-content-between align-items-center pb-4">
				{/* <button onClick={() => onNavigate('PREV')}>Previous</button>
            <button onClick={() => onNavigate('NEXT')}>Next</button> */}
				<div>
					<ChevronLeftIcon
						onClick={() => onNavigate('PREV')}
						className="chevron-icon me-2"
					/>
					<ChevronRightIcon
						onClick={() => onNavigate('NEXT')}
						className="chevron-icon me-4"
					/>
					<span className="calendar-date">{label}</span>
				</div>

				<div className="">
					<Radio.Group
						options={options}
						onChange={({ target: { value } }) => setSelectedView(value)}
						value={selectedView}
						optionType="button"
						buttonStyle="solid"
						className="generic-radio-btn"
					/>

					<span onClick={() => onNavigate('TODAY')} className="today-format">
						Today
					</span>
				</div>
			</div>
		)
	}

	const handleEventClick = (event, e) => {
		e.preventDefault()
		const { top, left, right, width } = e.target.getBoundingClientRect()

		const { pageX, pageY } = e

		setModalPosition({ top: pageY - 135, left: pageX })
		setSelectedEvent(event)
		setIsModalOpen(true)

		setTimeout(() => {
			const calendarElement = document.querySelector('.custom-modal')
			const calendarWidth = calendarElement?.clientWidth
			// added to handle the modal design in future with help of width and x and left and width of space

			// case 1 ==> For handling extreme right click owf screen
			// consdition ==>  when the Modalw width is less and equal to that modulus of  ScreenX
		}, 100)
	}

	const CustomEventWrapper = (event, onClose) => {
		// donot remove the default functions and donot change the name of handleDrag it is mandatory
		const handleDragStart = (e) => {
			// Prevent default drag start behavior
			e.preventDefault()
		}

		// const draggableEventIndex = allEvents.findIndex(event => event.id === 'your-overlay-id');

		// // If the event with draggable overlay is found, update the draggable property
		// if (draggableEventIndex !== -1) {
		//   const updatedEvents = [...allEvents]; // Create a copy of the events array
		//   updatedEvents[draggableEventIndex] = {
		// 	...updatedEvents[draggableEventIndex],
		// 	draggable: false, // Set draggable to false for the specific event
		//   }
		// }
		return (
			<>
				<div
					className="custom-event-popup"
					onMouseDown={(e) => {
						console.log('onMouseDown')
						e.preventDefault()
					}}
					onMouseMove={(e) => {
						console.log('onMouseMove')
						e.preventDefault()
					}}
					onMouseUp={(e) => {
						console.log('onMouseUp')
						e.preventDefault()
					}}
					onMouseOut={handleDragStart}
					onMouseOver={handleDragStart}
					onTouchStart={(e) => {
						console.log('onTouchStart')
						e.preventDefault()
					}}
					onTouchMove={(e) => {
						console.log('onTouchMove')
						e.preventDefault()
					}}
					onTouchEnd={(e) => {
						console.log('onTouchEnd')
						e.preventDefault()
					}}
					onDragStart={handleDragStart}
					onDragEnd={(e) => {
						console.log('onDragEnd')
						e.preventDefault()
					}}
					onDrag={(e) => {
						console.log('onDrag')
						e.preventDefault()
					}}
				>
					<div className="popup-body">
						<p className="m-0 p-0">{event.title}</p>
					</div>
				</div>
			</>
		)
	}

	const handleCloseModal = () => {
		setSelectedEvent(null)
		setIsModalOpen(false)
		cardActionRef.current = null
		cardRef.current = null
	}

	return (
		<>
			<Calendar
				localizer={localizer}
				events={allEvents}
				startAccessor="start"
				endAccessor="end"
				style={{ height: '70vh' }}
				components={{
					toolbar: CustomToolbar,
					event: CustomEventWrapper
				}}
				formats={formats}
				dayPropGetter={dayPropGetter}
				className="calendar-design"
				eventPropGetter={eventStyleGetter}
				onSelectEvent={(event, e) => {
					e.preventDefault()

					handleEventClick(event, e)
				}}
				popup
				draggable={false} // Disable dragging for the entire calendar
				resizable={false}
			/>

			{/* For Task */}
			{selectedEvent?.type === 'task' && (
				<TaskEventModal
					modalPosition={modalPosition}
					cardRef={cardRef}
					cardActionRef={cardActionRef}
					selectedEvent={selectedEvent?.restAll}
					setIsReload={setIsReload}
					setSelectedEvent={setSelectedEvent}
					isReload={isReload}
					key={selectedEvent?.id}
				/>
			)}
			{selectedEvent?.type === 'project' && (
				<ProjectEventModal
					modalPosition={modalPosition}
					cardRef={cardRef}
					cardActionRef={cardActionRef}
					selectedEvent={selectedEvent?.restAll}
					key={selectedEvent?.id}
				/>
			)}
		</>
	)
}

export default CalendarComp
