import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import AppRoutes from './routes'
import AppLayout from './components/Layout/AppLayout'

import 'bootstrap/dist/css/bootstrap.min.css'

import 'react-phone-input-2/lib/style.css'

import './App.css'
import './styles/ContentPageStyles.css'
import './styles/Home.css'
import './styles/Forms.css'
import './styles/ComponentsStyles.css'
import './styles/DashboardStyles.css'
import './styles/Table.css'
import './styles/KanbanViewStyles.css'
import './styles/ExtraStyles.css'

import { GoogleOAuthProvider } from '@react-oauth/google'
import { checkData, logOut, login } from './redux/loginLogout'
import { useDispatch, useSelector } from 'react-redux'
import { refreshAccessTokenJava, refreshAccessTokenNode } from './API/authAPI'
import Cookies from 'js-cookie'
import { emptyAppData } from './redux/appData'
import Tidio from './utils/Tidio'
import { getPaticularUserData } from './API/UserAPI'

function App() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let isLoggedIn = useSelector((state) => state.loginLogout.isLoggedIn)

	const location = useLocation()

	const getAccessTokenJava = async () => {
		let refreshToken = Cookies.get('pms-refresh-token')
		if (!refreshToken || refreshToken === '') {
			return false
		}
		try {
			const res = await refreshAccessTokenJava({ refreshToken })
			const { data } = res

			Cookies.set('pms-access-token', data.accessToken, {
				expires: 1 / 24
			})
			// Cookies.set('check-access-token', data.accessTokenCheck, {
			// 	expires: 1 / 24
			// })

			//getAccessTokenNode()
		} catch (err) {
			dispatch(logOut())
			dispatch(emptyAppData())
			Cookies.remove('pms-refresh-token')
			Cookies.remove('pms-access-token')
			Cookies.remove('user-status')
			Cookies.remove('check-access-token')
			Cookies.remove('check-refresh-token')
			navigate('/')
		}
	}

	const getAccessTokenNode = async () => {
		let refreshToken = Cookies.get('refresh-token-1')
		if (!refreshToken || refreshToken === '') {
			return false
		}
		try {
			const res = await refreshAccessTokenNode()
			const { data } = res
			Cookies.set('pms-access-token', data.accessToken, {
				expires: 1 / 24
			})
			dispatch(login({ token: data.accessToken }))
		} catch (err) {
			dispatch(logOut())
			dispatch(emptyAppData())
			Cookies.remove('pms-refresh-token')
			Cookies.remove('pms-access-token')
			Cookies.remove('user-status')
			navigate('/')
		}
	}

	useEffect(() => {
		let interval

		if (isLoggedIn) {
			getAccessTokenJava()
			interval = setInterval(() => {
				getAccessTokenJava()
			}, 1000 * 60 * 10)
		}

		return () => clearInterval(interval)
	}, [isLoggedIn])

	useEffect(() => {
		dispatch(checkData())
	}, [])

	const getCompanyDetails = async () => {
		try {
			const res = await getPaticularUserData()
			if (res === null) {
				navigate('/auth/company')
			}

			const { data } = res

			Cookies.set('companyDetailsId', data?.companyDetailsId, {
				expires: 12 / 24
			})

			Cookies.set('companyDetails', JSON.stringify(data || {}), {
				expires: 12 / 24
			})

			navigate('/dashboard/main')
		} catch (error) {}
	}

	useEffect(() => {
		if (
			location?.search &&
			location?.search !== '' &&
			location?.search.includes('tkn')
		) {
			const params = new URLSearchParams(location?.search)
			location.search = ''
			let token = params.get('tkn')
			let companyDetailsId = params.get('companyDetailsId')
			let email = params.get('mail')
			let rfTkn = params.get('rfTkn')

			let checkaccesstkn = params.get('checkTkn')
			let rfaccesstkn = params.get('checkRfTkn')

			if (token && token !== '') {
				Cookies.set('pms-access-token', token, {
					expires: 1 / 24
				})
				Cookies.set('pms-refresh-token', rfTkn, {
					expires: 12 / 24
				})

				Cookies.set('check-access-token', checkaccesstkn, {
					expires: 2 / 24
				})
				Cookies.set('check-refresh-token', rfaccesstkn, {
					expires: 12 / 24
				})

				Cookies.set('user-status', true, {
					expires: 12 / 24
				})

				Cookies.set('user-email', email, {
					expires: 12 / 24
				})

				Cookies.set('companyDetailsId', companyDetailsId, {
					expires: 12 / 24
				})

				getAccessTokenJava()

				if (companyDetailsId && companyDetailsId !== null) {
					navigate('/dashboard/main')
				} else {
					getCompanyDetails()
				}
			}
		}
	}, [location?.search])

	return (
		<div className="App">
			<GoogleOAuthProvider
				clientId={`${
					process.env?.REACT_APP_GOOGLE_CLIENT_ID ||
					'787275588098-tjg5gnbta3u0k0vlktko61sapfq2ki1e.apps.googleusercontent.com'
				}`}
			>
				<AppLayout>
					<AppRoutes />
					<Tidio tidioKey="pu5qwxyixmjlowy0q7wluvcriinsyy6d" />
				</AppLayout>
			</GoogleOAuthProvider>
		</div>
	)
}

export default App
