import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import '../../../../styles/SelectListStyle.css'
import SelectDropDown from '../../../shared/forms/SelectDropDown'
import profile from '../../../../assets/images/Imageprofile.png'
import { Avatar, Select } from 'antd'
import deletedIcon from '../../../../assets/images/delete_outline.svg'
import ButtonComponent from '../../../shared/ButtonComponent'
import useDebounce from '../../../../hooks/useDebounce'
import { addProject } from '../../../../API/projectsAPI'
import { getAllMembers, inviteMembers } from '../../../../API/MembersAPI'
import { getRoles } from '../../../../API/rolesApi'
import FormComponent from '../../../shared/forms'
import { useMember } from '../../../../utils/componentUtils/memberUtils/useAddMember'
import useInviteInput from '../../../../hooks/useInviteInput'
import { IconButton } from '@mui/material'
import { Delete, ExpandMore } from '@mui/icons-material'
import Cookies from 'js-cookie'
import AvatarGroupComponent from '../../../shared/AvatarGroupComponent'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import TextError from '../../../../hoc/TextError'
//import InviteMember from '../../../shared/member/inviteMember'

const validationSchema = Yup.object({
	roleSelect: Yup.string().required('Required')
})

const MemberList = ({ member, removeMember, allRoles, handleSelect }) => {
	const initialValue = {
		roleSelect: ''
	}

	return (
		<Formik
			initialValues={initialValue}
			validationSchema={validationSchema}
			onSubmit={(values, { setSubmitting }) => {
				// Handle form submission here, if needed
				setSubmitting(true)
			}}
		>
			<Form>
				<div
					className="d-flex align-items-center  justify-content-between mb-4 "
					key={member.id}
				>
					<div className="d-flex  align-items-center ">
						<AvatarGroupComponent
							name={member?.name || member?.email || ''}
							imageUrl={member?.imageUrl}
						/>
						<div className="ms-2">
							<h6 className="fs-12 mb-0 font-roboto leading-1 ">
								{member.name}
							</h6>
							<span className="font-roboto fs-11 fw-normal">
								{member.email}
							</span>
						</div>
					</div>

					<Field name="roleSelect">
						{({ field, meta }) => (
							<div className="d-flex align-items-center">
								<Select
									className="generic-select mb-0 fs-10"
									placeholder="Select a Designation"
									style={{
										maxWidth: '6rem'
									}}
									options={allRoles}
									onSelect={(value) => handleSelect(value, member.id)}
									suffixIcon={
										<ExpandMore
											sx={{
												color: '#d9d9d9',
												fontSize: '1.5rem'
											}}
										/>
									}
									popupClassName="generic-select-dropdown"
									{...field}
								/>
								<IconButton
									className="ms-2"
									onClick={() => removeMember(member.id)}
								>
									<Delete
										sx={{
											fontSize: '1rem'
										}}
									/>
								</IconButton>
							</div>
						)}
					</Field>
					<ErrorMessage component={TextError} name="roleSelect" />
				</div>
			</Form>
		</Formik>
	)
}

const AddMembers = ({
	handleTabChange,
	setMembersData,
	formData,
	setOpen,
	setNewProjectAdded,
	selectedProject
}) => {
	const [allMembers, setAllMembers] = useState([])
	const [allRoles, setAllRoles] = useState([])
	const [selectedRoles, setSelectedRoles] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	const { memberSelect, selectedValues, setSelectedValues } = useMember()

	const { invited, getInviteInput } = useInviteInput()

	useEffect(() => {
		getAllMembersData()
		getAllRolesData()
	}, [])

	useEffect(() => {
		if (
			selectedValues &&
			selectedValues.length !== 0 &&
			selectedValues?.length !== selectedRoles?.length
		) {
			let roles = selectedValues.map((id) => {
				return {
					id: id,
					roleId: 3
				}
			})
			setSelectedRoles([...roles])
		}
	}, [selectedValues])

	const getAllMembersData = async () => {
		try {
			const res = await getAllMembers()
			setAllMembers(res?.data)
		} catch (error) {}
	}

	const getAllRolesData = async () => {
		try {
			const res = await getRoles()
			const membersList = res?.data
			let arr = []
			for (let i = 0; i < membersList?.length; i++) {
				let obj = {}
				obj['value'] = membersList[i]?.id
				obj['label'] = membersList[i]?.name
				arr.push(obj)
			}
			setAllRoles(arr)
		} catch (error) {}
	}

	const addNewProject = async () => {
		const userEmail = Cookies.get('user-email')
		const companyDetails = JSON.parse(Cookies.get('companyDetails') || {})

		const body = {
			name: formData?.projectName,
			status: formData?.projectStatus,
			description: formData?.projectDescription,
			startDate: formData?.startDate,
			dueDate: formData?.dueDate,
			members: selectedRoles,
			imageUrl: formData?.projectImage,
			memberEmail: userEmail,
			clientId: formData?.clientId
		}
		// ----body me validation lagani hai

		let invitedArr
		// for inviting member in project
		if (invited?.length > 0) {
			invitedArr = [...invited]
		}
		if (companyDetails?.hasOwnProperty('firstName')) {
			body[
				'leadName'
			] = `${companyDetails?.firstName} ${companyDetails?.lastName}`
			body['leadEmail'] = `${companyDetails?.email}`
			body['leadImageUrl'] = companyDetails?.imageUrl || ''
		}
		// --- for userRole----
		try {
			const res = await addProject(body)
			// debugger
			if (res?.data && invitedArr?.length > 0) {
				invitedArr[0]['projectId'] = res?.data?.id
				const resInvite = await inviteMembers(invitedArr)
			}
			setIsLoading(true)
			setNewProjectAdded(true)
			setOpen(false)
		} catch (error) {
			setIsLoading(false)

			console.log('Error while adding new project ', error)
		}
	}

	const removeMember = (id) => {
		let temp = [...selectedValues]
		let index = selectedValues.findIndex((mbr) => mbr === id)

		if (index !== -1) {
			temp.splice(index, 1)
		}
		setSelectedValues([...temp])

		let tempRoles = [...selectedRoles]
		let roleIndex = selectedRoles.findIndex((rl) => rl?.id === id)
		if (roleIndex !== -1) {
			tempRoles.splice(roleIndex, 1)
		}
		setSelectedRoles([...tempRoles])
	}

	const handleSelect = (targetRoles, memberID) => {
		// for edit of project
		let arr = [...selectedRoles]

		if (arr.find((el) => el?.id === memberID)) {
			let changedPreviousRole = arr.map((item) => {
				if (item?.id === memberID) {
					return { id: memberID, roleId: targetRoles }
				}
				return item
			})
			setSelectedRoles([...changedPreviousRole])
		} else {
			// let arr = [...selectedRoles]
			setSelectedRoles([...arr, { id: memberID, roleId: targetRoles }])
		}
	}

	return (
		<>
			<div className="">
				<h6
					style={{ color: '#555' }}
					className="fw-semibold font-roboto txt-color-primary fs-14"
				>
					Add Members
				</h6>
				{/*Member Component Render */}
				{memberSelect()}

				{/* <InviteMember /> */}
			</div>
			<div className="mt-4">
				{selectedValues && selectedValues?.length > 0 && (
					<>
						<h6
							style={{ color: '#555' }}
							className="fw-semibold font-roboto txt-color-primary fs-14"
						>
							Add members as
						</h6>
						<p className="font-roboto fs-12 ">
							Define role for each members. You can also customise or add new
							roles in{' '}
							<span role="button" style={{ color: '#204464' }}>
								Settings
							</span>
						</p>
					</>
				)}
				{allMembers &&
					allMembers?.length !== 0 &&
					allMembers?.map((member) => {
						if (selectedValues.includes(member?.id)) {
							return (
								<div
									className="d-flex align-items-center  justify-content-between mb-4 "
									key={member.id}
								>
									<div className="d-flex  align-items-center ">
										<AvatarGroupComponent
											name={member?.name || member?.email || ''}
											imageUrl={member?.imageUrl}
										/>
										<div className="ms-2">
											<h6 className="fs-12 mb-0 font-roboto leading-1 ">
												{member.name}
											</h6>
											<span className="font-roboto fs-11 fw-normal">
												{member.email}
											</span>
										</div>
									</div>

									<div className="d-flex align-items-center">
										<Select
											className="role-select mb-0 fs-10"
											placeholder="Select a Designation"
											style={{
												maxWidth: '7rem'
											}}
											options={allRoles}
											onChange={(value) => handleSelect(value, member.id)}
											suffixIcon={
												<ExpandMore
													sx={{
														color: '#555',
														fontSize: '1.25rem'
													}}
												/>
											}
											defaultValue={
												member?.roleId && member?.roleId !== null
													? member?.roleId
													: 3
											}
											popupClassName="generic-select-dropdown"
											value={
												selectedRoles?.find((rl) => rl?.id === member?.id)
													?.roleId
											}
										/>
										<IconButton
											className="ms-2"
											onClick={() => removeMember(member.id)}
										>
											<Delete
												sx={{
													fontSize: '1rem'
												}}
											/>
										</IconButton>
									</div>
								</div>
							)
						} else {
							return null
						}
					})}

				<div className="mt-4">
					<h6
						style={{ color: '#555' }}
						className="fw-semibold font-roboto txt-color-primary fs-14"
					>
						Invite new members
					</h6>
					{getInviteInput()}
				</div>

				<div className="d-flex align-items-center justify-content-end mt-3">
					<ButtonComponent
						variant="light"
						text="Back"
						click={() => handleTabChange('1')}
					/>
					<ButtonComponent
						disabled={isLoading}
						variant="dark"
						extraclassName="ms-2"
						text="Save"
						click={addNewProject}
					/>
				</div>
			</div>
		</>
	)
}

export default AddMembers
