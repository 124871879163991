import { useState } from 'react'
import ModalComponent from '../ModalComponent'
import { Tabs } from 'antd'
import ComponentsStyles from '../../../styles/ComponentsStyles.css'
import AddMembers from '../../views/Projects/NewTask/AddMembers'
import NewTaskInformation from '../../views/Projects/ProjectsDetails/NewTaskInformation'
import AddNewTaskMembers from '../../views/Projects/ProjectsDetails/AddNewTaskMembers'

const CreateNewProjectTask = ({
	open,
	onClose,
	projectId,
	projectName,
	getData,
	setNewTaskAdded
}) => {
	const [activeKey, setActiveKey] = useState('1')

	const handleTabChange = (key) => {
		setActiveKey(key)
	}

	const [formData, setFormData] = useState({})
	const [dataSelected, setDataSelected] = useState([])
	const [editNewSprint, setEditNewSprint] = useState(false)
	const [projectOptions, setProjectOptions] = useState([])
	const [membersData, setMembersData] = useState({})

	//const [membersData,setMembersData]=useState({})
	//const [formSubmitted,setFormSubmitted]=useState()

	const items = [
		{
			key: '1',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '1' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">1</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '1' ? '#262626' : '#9D9D9D' }}
					>
						Task Information
					</span>
				</div>
			),
			children: (
				<NewTaskInformation
					handleTabChange={handleTabChange}
					setFormData={setFormData}
					onClose={onClose}
					projectId={projectId}
					projectName={projectName}
				/>
			)
		},
		{
			key: '2',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '2' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">2</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '2' ? '#262626' : '#9D9D9D' }}
					>
						Add Members
					</span>
				</div>
			),
			children: (
				<AddMembers
					handleTabChange={handleTabChange}
					setMembersData={setMembersData}
					formData={formData}
					onClose={onClose}
					getData={getData}
					dataSelected={dataSelected}
					editNewTask={editNewSprint}
					setEditNewTask={setEditNewSprint}
					setFormData={setFormData}
					role={''}
					projectOptions={projectOptions}
					setNewTaskAdded={setNewTaskAdded}
				/>
			)
		}
	]
	return (
		<ModalComponent title="Create a task" open={open} onClose={onClose}>
			<div className="container-fluid px-0">
				<Tabs
					defaultActiveKey={'1'}
					items={items}
					activeKey={activeKey}
					className="create-project-tabs mt-4"
					onChange={handleTabChange}
				/>
			</div>
		</ModalComponent>
	)
}

export default CreateNewProjectTask
