import { useState } from 'react'
import TrashIcon from '@mui/icons-material/DeleteOutline'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import TaskCardComponent from '../../../../shared/Cards/Kanban Cards/TaskCardComponent'

function TaskCard({
	task,
	deleteTask,
	updateTask,
	setIsCreateTask,
	coloumnId,
	setEditNewTask,
	editNewTask
}) {
	const [mouseIsOver, setMouseIsOver] = useState(false)
	const [editMode, setEditMode] = useState(false)

	const {
		setNodeRef,
		attributes,
		listeners,
		transform,
		transition,
		isDragging
	} = useSortable({
		id: task.id,
		data: {
			type: 'Task',
			task
		},
		disabled: editMode
	})

	const style = {
		transition,
		transform: CSS.Transform.toString(transform)
	}

	const toggleEditMode = () => {
		setEditMode((prev) => !prev)
		setMouseIsOver(false)
	}

	if (isDragging) {
		return (
			<div
				ref={setNodeRef}
				style={style}
				className="d-flex align-items-center flex-column text-left rounded-xl border-2 border-rose-500 cursor-grab position-relative"
			/>
		)
	}

	if (editMode) {
		return (
			<div
				ref={setNodeRef}
				// style={style}
				{...attributes}
				{...listeners}
				className="d-flex align-items-center flex-column text-left cursor-grab position-relative"
			>
				<textarea
					className="w-100 h-90 border-0 resize-none rounded-0  text-white outline-0"
					style={{
						resize: 'none'
					}}
					value={task.content}
					autoFocus
					placeholder="Task content here"
					onBlur={toggleEditMode}
					onKeyDown={(e) => {
						if (e.key === 'Enter' && e.shiftKey) {
							toggleEditMode()
						}
					}}
					onChange={(e) => updateTask(task.id, e.target.value)}
				/>
			</div>
		)
	}

	return (
		<div
			ref={setNodeRef}
			// style={style}
			{...attributes}
			{...listeners}
			//onClick={toggleEditMode}
			className="d-flex  align-items-center flex-column text-left cursor-grab position-relative task w-100"
			style={{ width: '26rem', maxWidth: '26rem' }}
			onMouseEnter={() => {
				setMouseIsOver(true)
			}}
			onMouseLeave={() => {
				setMouseIsOver(false)
			}}
		>
			<TaskCardComponent
				coloumnId={coloumnId}
				task={task}
				deleteTask={deleteTask}
				setIsCreateTask={setIsCreateTask}
				updateTask={updateTask}
			/>
			{/* <p
				className="my-auto h-90 overflow-auto w-100"
				style={{
					overflowY: 'auto',
					overflowX: 'hidden',
					whiteSpace: 'pre-wrap'
				}}
			>
				{task.content}
			</p> */}

			{/* {mouseIsOver && (
				<button
					onClick={() => {
						deleteTask(task.id)
					}}
					className="stroke-white position-absolute right-4 translate-middle-y bg-columnBackgroundColor p-2 rounded opacity-60"
					style={{
						top: '50%',
						transform: 'translateY(-50%)'
					}}
				>
					<TrashIcon />
				</button>
			)} */}
		</div>
	)
}

export default TaskCard
