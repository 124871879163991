import React, { useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import { Button } from 'antd'
import { verifyIfUserExists } from '../../API/authAPI'
import { appleIcon, googleIcon, microsoftIcon } from '../../assets/svg'
import {
	signUpGoogle,
	appleSignUp,
	getGoogleInfo,
	signInGoogle,
	appleSignIn
} from '../../API/socialAPI'
// import { useMsal } from '@azure/msal-react'
import jwt_decode from 'jwt-decode'
//import { loginRequest } from '../../utils/Providers/microsoftAuth'

//import AppleLogin from 'react-apple-login'
import { useDispatch } from 'react-redux'
import { login } from '../../redux/loginLogout'
// import { updateSnackbar } from '../../redux/snackbarState'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
// import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { message } from 'antd'
import axios from 'axios'
import { checkIntegration } from '../../utils/helper'
//import { checkUser } from '../../API/UserAPI'

const SocialLogin = ({ text }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	// const history = useHistory()
	const location = useLocation()

	const [googleUserData, setGoogleUserData] = useState(null)

	const [messageApi, contextHolder] = message.useMessage()

	//instance for google login
	const googleLogin = useGoogleLogin({
		onSuccess: async (tokenResponse) => {
			const { access_token } = tokenResponse

			///API call to get user data fromm google
			axios
				.get(
					`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
					{
						headers: {
							Authorization: `Bearer ${access_token}`,
							Accept: 'application/json'
						}
					}
				)
				.then(async (res) => {
					const { data } = res

					//setting google data for later pass on to create profile if user not exist
					setGoogleUserData({ ...data })

					//Calling function to check if user exist
					userExist(
						data?.email,
						'google',
						{
							googleAuthToken: access_token
						},
						{
							firstName: data?.given_name,
							lastName: data?.family_name,
							email: data?.email
						}
					)
				})
				.catch((err) => {
					messageApi.error('Google sign-in failed.')
				})
		},
		onError: (error) => console.log('GOOGLE ERROR', error)
	})

	///Function to check if user exist
	const userExist = async (email, loginType, body, userData = {}) => {
		Cookies.set('user-email', email, {
			expires: 1
		})

		try {
			//const res = await checkUser(email)
			const res = await verifyIfUserExists(email)

			/////ToDo :- Check company detaisl in verify response
			Cookies.set('companyDetailsId', res?.data?.companyDetailsId)
			Cookies.set('companyDetails', JSON.stringify(res?.data || {}), {
				expires: 1 / 24
			})

			///Success if user already exist

			if (loginType === 'google') {
				try {
					const resp = await signInGoogle(body)

					const authData = { ...resp?.data }

					Cookies.set('pms-refresh-token', authData.refreshToken, {
						expires: 12 / 24
					})
					Cookies.set('user-status', true, {
						expires: 12 / 24
					})
					Cookies.set('pms-access-token', authData.accessToken, {
						expires: 1 / 24
					})
					Cookies.set('check-refresh-token', authData.refreshTokenCheck, {
						expires: 12 / 24
					})
					Cookies.set('check-access-token', authData.accessTokenCheck, {
						expires: 2 / 24
					})
					dispatch(login({ token: authData.accessToken }))

					messageApi.success(
						location.pathname.includes('/login')
							? 'Google login successful'
							: 'Google Signup successful'
					)

					// To Do integration apis not made
					//checkIntegration(navigate, messageApi)
					navigate('/dashboard/main')
				} catch (err) {
					messageApi.error(
						location.pathname.includes('/login')
							? 'Google login failed'
							: 'Google Signup failed'
					)
				}
			}
		} catch (error) {
			if (loginType === 'google') {
				navigate('/auth/create-profile', {
					state: {
						authData: {
							...body
						},
						userData: {
							...userData
						},
						loginType
					}
				})
			}
		}
	}

	return (
		<div className="social-login">
			<Button
				className="social-btn d-flex flex-row align-items-center justify-content-center"
				onClick={googleLogin}
			>
				<span className="icon me-2">{googleIcon}</span>
				Sign {text} with Google
			</Button>
			{/* <Button
				className="social-btn d-flex flex-row align-items-center justify-content-center"
				onClick={() => microsoftLogin('popup')}
			>
				<span className="icon me-2">{microsoftIcon}</span>
				Sign in with Microsoft
	</Button>*/}
			{/* <AppleLogin
				clientId="com.synccoscheck.auth.client"
				redirectURI={`${window.location?.href}`}
				callback={appleResponse}
				onSuccess={appleResponse}
				onError={(error) => console.log('AppleSignInBtn onError error', error)}
				scope="email name"
				responseType="code id_token"
				usePopup={true}
				responseMode="form_post"
				render={(props) => {
					return (
						<Button
							className="social-btn d-flex flex-row align-items-center justify-content-center"
							onClick={props.onClick}
						>
							<span className="icon me-2">{appleIcon}</span>
							Sign in with Apple
						</Button>
					)
				}}
			/> */}
		</div>
	)
}

export default SocialLogin
