import { useEffect, useState } from 'react'
import IndividualViewFilters from '../../shared/TableComponents/IndividualViewFilters'
import TableComponent from '../../shared/TableComponent'
import { useNavigate } from 'react-router'
import {
	Add,
	Check,
	Clear,
	Delete,
	Edit,
	Launch,
	NorthEastOutlined,
	SouthEastOutlined
} from '@mui/icons-material'
import ContextMenu from '../../shared/ContextMenu'
import { deleteMultipleClient, getAllClients } from '../../../API/clientAPI'
import AvatarGroupComponent from '../../shared/AvatarGroupComponent'
import DeleteClient from '../../shared/Modals/DeleteClient'
import '../../../styles/ComponentsStyles.css'
import Status from '../Projects/ProjectsDetails/Status'
import { updateClient } from '../../../API/clientAPI'
import dayjs from 'dayjs'
import { Avatar, Button, Checkbox, Dropdown, message } from 'antd'
import { getAllProjects, updateBulkProject } from '../../../API/projectsAPI'
import { getAllMembers } from '../../../API/MembersAPI'
import useMessage from 'antd/es/message/useMessage'
import ButtonComponent from '../../shared/ButtonComponent'
import ClientModal from '../Forms/Client'

const MyClients = ({ isModalOpen, setIsModalOpen, setDisableCta }) => {
	const [activeFilter, setActiveFilter] = useState('')
	const [data, setData] = useState([])

	const [isLoading, setIsLoading] = useState(true)

	const [deleteClient, setDeleteClient] = useState(false)

	const [dataSelected, setDataSelected] = useState([])

	const [isUpdate, setIsUpdate] = useState(false)
	const [updateData, setUpdateData] = useState({})
	const navigate = useNavigate()

	const [isAddBtn, setIsAddBtn] = useState(false)
	const [projects, setProjects] = useState([])
	const [members, setMembers] = useState([])

	const [selectedProjects, setSelectedProjects] = useState([])
	const [initialProjects, setInitialProjects] = useState([])

	const [selectedMembers, setSelectedMembers] = useState([])
	const [initialMembers, setInitialMembers] = useState([])

	const [messageApi, contextHolder] = useMessage()

	useEffect(() => {
		if (dataSelected && dataSelected.length !== 0) {
			setDisableCta(true)
		} else {
			setDisableCta(false)
		}
	}, [dataSelected])

	const getClients = async () => {
		setIsLoading(true)
		try {
			const res = await getAllClients(activeFilter)
			const { data = [] } = res
			setData([...data])
			let tempPrs = data.map((client) => {
				return {
					clientId: client.id,
					projects:
						client?.projects && client?.projects.length !== 0
							? client?.projects.map((pr) => {
									return pr.projectId
							  })
							: []
				}
			})

			let tempMbr = data.map((client) => {
				return {
					clientId: client.id,
					members:
						client?.clientMembers && client?.clientMembers.length !== 0
							? client?.clientMembers.map((mbr) => {
									return mbr.memberId
							  })
							: []
				}
			})

			setSelectedProjects([...tempPrs])
			setInitialProjects([...tempPrs])

			setSelectedMembers([...tempMbr])
			setInitialMembers([...tempMbr])
			getProjects()
			getMembers()
		} catch (error) {
			setIsLoading(false)
			setData([])
		}
	}

	const getProjects = async () => {
		try {
			const res = await getAllProjects()
			const { data } = res
			setProjects([...data])
			setIsAddBtn(true)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
		}
	}

	const getMembers = async () => {
		try {
			const res = await getAllMembers()
			const { data } = res

			setMembers([...data])
			setIsLoading(false)
			setIsAddBtn(true)
		} catch (error) {
			setIsLoading(false)
		}
	}

	const addMemberToClient = async (clientId) => {
		let members = selectedMembers.find(
			(mbr) => mbr?.clientId === clientId
		).members
		try {
			const res = await updateClient({
				clientMembers: members.map((mbr) => ({ memberId: mbr })),
				id: clientId
			})
			debugger
		} catch (error) {
			debugger
		}
	}

	const updateProjectsData = async (projectIds, clientId) => {
		try {
			const res = await updateBulkProject(projectIds, clientId)
		} catch (error) {
			console.log(
				'Error in updating project while creating a new client ',
				error
			)
			messageApi.error('Unable to update client in projects.')
		}
	}

	const addProjectToClient = async (clientId) => {
		let projects = selectedProjects.find(
			(mbr) => mbr?.clientId === clientId
		).projects
		try {
			const res = await updateClient({
				projects: projects.map((pr) => ({ projectId: pr })),
				id: clientId
			})
			updateProjectsData(projects, clientId)
			getClients()
			messageApi.success('Project updated to client.')
		} catch (error) {
			messageApi.error('Unable to update project.')
		}
	}

	const handleProjectSelection = (projectId, clientId) => {
		let temp = [...selectedProjects]
		let projects = selectedProjects.find(
			(mbr) => mbr.clientId === clientId
		).projects

		if (projects.includes(projectId)) {
			let index = projects.findIndex((mbr) => mbr === projectId)
			if (index !== -1) {
				projects.splice(index, 1)
			}
		} else {
			projects.push(projectId)
		}

		let objIndex = temp.findIndex((obj) => obj?.clientId === clientId)
		temp[objIndex].projects = [...projects]
		setSelectedProjects([...temp])
	}

	const getRemainProjects = (includedProjects, clientId) => {
		const ids = includedProjects.map((project) => project.id)

		let temp = selectedProjects.find((client) => client.clientId === clientId)

		const remainingMembers = projects.filter(
			(project) => !ids.includes(project.id)
		)
		const itemsArr = [...includedProjects, ...remainingMembers].map(
			(project, index) => {
				return {
					key: index,
					label: (
						<div
							className="d-flex align-items-center px-2 py-2"
							onClick={(e) => {
								handleProjectSelection(project.id, clientId)
								e.stopPropagation()
							}}
						>
							<Checkbox
								checked={temp.projects.includes(project.id)}
								value={project?.id}
								className="me-2"
								onChange={(e) => {
									handleProjectSelection(e.target.value, clientId)
									e.stopPropagation()
								}}
							/>
							<AvatarGroupComponent
								name={project?.name || ''}
								imageUrl={project?.imageUrl || ''}
								size={'small'}
							/>
							<p className="mb-0 font-roboto fs-12 txt-color-primary ms-2">
								{project?.name || project?.email}
							</p>
						</div>
					)
				}
			}
		)

		itemsArr.push({
			key: [...includedProjects, ...remainingMembers].length,
			label: (
				<div
					className="d-flex align-items-center justify-content-end py-2"
					onClick={(e) => {
						e.stopPropagation()
					}}
				>
					<ButtonComponent
						text=""
						icon={
							<Clear
								sx={{
									fontSize: '1rem',
									margin: '0'
								}}
							/>
						}
						variant="light"
						extraclassName="me-2 px-2 py-1 d-flex align-items-center avatar-dropdown-btn"
						click={() => {
							setSelectedProjects([...initialProjects])
						}}
					/>
					<ButtonComponent
						text=""
						icon={
							<Check
								sx={{
									fontSize: '1rem',
									margin: '0'
								}}
							/>
						}
						variant="dark"
						extraclassName="px-2 py-1 d-flex align-items-center avatar-dropdown-btn"
						click={(e) => {
							addProjectToClient(clientId)
							e.stopPropagation()
						}}
					/>
				</div>
			)
		})

		return itemsArr
	}

	const handleMemberSelection = (memberId, clientId) => {
		let temp = [...selectedMembers]
		let members = selectedMembers.find(
			(mbr) => mbr.clientId === clientId
		).members

		if (members.includes(memberId)) {
			let index = members.findIndex((mbr) => mbr === memberId)
			if (index !== -1) {
				members.splice(index, 1)
			}
		} else {
			projects.push(memberId)
		}

		let objIndex = temp.findIndex((obj) => obj?.clientId === clientId)
		temp[objIndex].members = [...members]
		setSelectedMembers([...temp])
	}

	const getRemainMembers = (includedMembers, clientId) => {
		const ids = includedMembers.map((member) => member.id)

		let temp = selectedMembers.find((mbr) => mbr.clientId === clientId)

		const remainingMembers = members.filter(
			(member) => !ids.includes(member.id)
		)
		const items =
			[...includedMembers, ...remainingMembers].length !== 0
				? remainingMembers.map((member, index) => {
						return {
							key: index,
							label: (
								<div
									className="d-flex align-items-center px-2 py-2"
									onClick={(e) => {
										handleMemberSelection(member.id, clientId)
										e.stopPropagation()
									}}
								>
									<Checkbox
										checked={temp.members.includes(member.id)}
										value={member?.id}
										className="me-2"
										onChange={(e) => {
											handleMemberSelection(e.target.value, clientId)
											e.stopPropagation()
										}}
									/>
									<AvatarGroupComponent
										name={member?.name || ''}
										imageUrl={member?.imageUrl || ''}
										size={'small'}
									/>
									<p className="mb-0 font-roboto fs-12 txt-color-primary ms-2">
										{member?.name || member?.email}
									</p>
								</div>
							)
						}
				  })
				: []

		if (items?.length === 0) {
			return []
		} else {
			items.push({
				key: [...includedMembers, ...remainingMembers].length,
				label: (
					<div
						className="d-flex align-items-center justify-content-end py-2"
						onClick={(e) => {
							e.stopPropagation()
						}}
					>
						<ButtonComponent
							text=""
							icon={
								<Clear
									sx={{
										fontSize: '1rem',
										margin: '0'
									}}
								/>
							}
							variant="light"
							extraclassName="me-2 px-2 py-1 d-flex align-items-center avatar-dropdown-btn"
							click={() => {
								setSelectedMembers([...initialMembers])
							}}
						/>
						<ButtonComponent
							text=""
							icon={
								<Check
									sx={{
										fontSize: '1rem',
										margin: '0'
									}}
								/>
							}
							variant="dark"
							extraclassName="px-2 py-1 d-flex align-items-center avatar-dropdown-btn"
							click={(e) => {
								addMemberToClient(clientId)
								e.stopPropagation()
							}}
						/>
					</div>
				)
			})
		}
	}

	useEffect(() => {
		getClients()
	}, [activeFilter])

	const filtersData = [
		{
			key: 'Active',
			value: 'Active'
		},
		{
			key: 'Inactive',
			value: 'Inactive'
		}
	]

	const statusOptions = [
		{
			value: 'Active',
			label: 'Active'
		},
		{
			value: 'Inactive',
			label: 'Inactive'
		}
	]

	const onFilterChange = (e) => {
		setActiveFilter(e?.target?.value || '')
	}

	const formatPhoneNumber = (phoneNumber = '') => {
		// Format the phone number

		return `(${phoneNumber?.substring(0, 3)}) ${phoneNumber?.substring(
			3,
			6
		)}-${phoneNumber?.substring(6, 10)}`
	}

	const handleStatusChange = async (record, status) => {
		try {
			const options = {
				id: record.clientId,
				status: status,
				//email: record.contactPerson
				email: record?.contactPersonDetails?.contactEmail
			}
			const res = await updateClient(options)
		} catch (error) {}
	}
	const deleteSelectedClients = async () => {
		const clientIds = dataSelected?.map((client) => {
			return client?.clientId
		})

		try {
			const res = await deleteMultipleClient(clientIds)

			setDataSelected([])
			setIsModalOpen(false)
			getClients()
			message.success('Client deleted succcessfully.')
		} catch (error) {
			setIsModalOpen(false)
			message.error('Unable to delete client.')
		}
	}

	const getStyles = (value) => {
		switch (value) {
			case 'Active':
				return {
					border: '1px solid #C9EBFF',
					backgroundColor: '#E6F7FF',
					color: '#1890FF',
					borderRadius: '2px'
				}
			case 'Inactive':
				return {
					border: '1px solid #FFCAE2',
					backgroundColor: '#FFF0F6',
					color: '#EB2F96',
					borderRadius: '2px'
				}
			default:
				return {}
		}
	}

	const columns = [
		{
			title: 'Client Name',
			dataIndex: 'clientDetails',
			render: (client) => {
				return (
					<div className="d-flex align-items-center">
						<AvatarGroupComponent
							name={client?.clientName || ''}
							imageUrl={client?.imageUrl || ''}
						/>
						<div className="ms-2">
							<span className="my-clients-data font-roboto fs-12 fw-500 d-block ">
								{client?.clientName}
							</span>
							{client?.startDate && client?.startDate !== '' ? (
								<span className="my-clients-data font-roboto fs-12 fw-500 d-block">
									Start Date: {client?.startDate}
								</span>
							) : null}
						</div>
					</div>
				)
			}
		},
		{
			title: 'Contact Person',
			dataIndex: 'contactPersonDetails',
			render: (contactPersonDetails) => {
				return (
					<div className="d-flex align-items-center">
						<AvatarGroupComponent
							name={
								contactPersonDetails?.contactName ||
								contactPersonDetails?.contactEmail ||
								''
							}
							imageUrl={contactPersonDetails?.imageUrl || ''}
						/>
						<div className=" ms-2 ">
							<span className="my-clients-data font-roboto fs-12 fw-500 d-block">
								{contactPersonDetails?.contactName || ''}
							</span>
							<Button
								href={`mailto:${contactPersonDetails?.contactEmail}`}
								className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none border-0 bg-transparent p-0 d-flex align-items-center gap-2"
								onClick={(e) => {
									e.stopPropagation()
								}}
								style={{
									boxShadow: 'none',
									height: '100%'
								}}
							>
								<span className="font-roboto">
									{contactPersonDetails?.contactEmail}
								</span>
								<Launch
									sx={{
										fontSize: 'inherit',
										color: 'inherit'
									}}
								/>
							</Button>
						</div>
					</div>
				)
			}
		},
		{
			title: 'Phone number',
			dataIndex: 'phoneNumber',
			render: (phoneNumber) => {
				if (phoneNumber && phoneNumber !== '') {
					return (
						<Button
							href={`tel:${phoneNumber}`}
							className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none border-0 bg-transparent p-0 d-flex align-items-center gap-2"
							onClick={(e) => {
								e.stopPropagation()
							}}
							style={{
								boxShadow: 'none',
								height: '100%'
							}}
						>
							<span className="font-roboto">+{phoneNumber}</span>
							<Launch
								sx={{
									fontSize: 'inherit',
									color: 'inherit'
								}}
							/>
						</Button>
					)
				} else {
					return <span className="fs-12 fw-500 font-roboto">----</span>
				}
			}
		},
		{
			title: 'Industry',
			dataIndex: 'industry',
			render: (industry) => {
				return (
					<span className="my-clients-data font-roboto fs-12 fw-500">
						{industry}
					</span>
				)
			}
		},
		{
			title: 'Projects',
			dataIndex: 'projects',
			render: (text, record) => {
				const { projects, clientId } = record
				if (projects && projects.length !== 0) {
					const items = getRemainProjects(projects, clientId)

					return (
						<AvatarGroupComponent
							data={[...projects]}
							maxCount={3}
							isAddBtn={
								isAddBtn || projects?.length !== 0 || items.length !== 0
							}
							items={items}
						/>
					)
				} else {
					const items = getRemainProjects([], clientId)
					return (
						<Dropdown
							trigger={['hover']}
							menu={{ items }}
							placement="bottomRight"
							overlayStyle={{
								maxHeight: '250px',
								overflow: 'auto'
							}}
							className="avatar-add-dropdown"
							overlayClassName="avatar-add-dropdown-overlay"
						>
							<Avatar
								style={{
									backgroundColor: '#EDF5FC',
									color: '#434343',
									marginRight: 0,
									cursor: 'pointer'
								}}
								className={`font-roboto generic-avatar fs-12`}
								size
							>
								<Add
									sx={{
										fontSize: '1.25rem',
										color: 'inherit'
									}}
								/>
							</Avatar>
						</Dropdown>
					)
				}
			}
		},
		{
			title: 'Members',
			dataIndex: 'clientMembers',
			render: (clientMembers) => {
				// const {clientMembers, clientId} = record
				// let mbr = clientMembers && clientMembers.length !== 0 ? clientMembers[0] : []
				// if (mbr.length !== 0) {
				// 	const items = getRemainMembers(clientMembers, clientId)
				// 	return <AvatarGroupComponent data={clientMembers[0]} maxCount={3} isAddBtn={isAddBtn || clientMembers?.length !== 0 || items.length !== 0} items={items} />
				// } else {
				// 	const items = getRemainMembers([], clientId)
				// 	return (
				// 		<Dropdown trigger={['hover']} menu={{ items }} placement='bottomRight' overlayStyle={{
				// 				maxHeight: '250px',
				// 				overflow: 'auto'
				// 			}} className='avatar-add-dropdown' overlayClassName='avatar-add-dropdown-overlay'>
				// 				<Avatar
				// 					style={{
				// 						backgroundColor: '#EDF5FC',
				// 						color: '#434343',
				// 						marginRight: 0,
				// 						cursor: 'pointer',
				// 					}}
				// 					className={`font-roboto generic-avatar fs-12`}
				// 					size
				// 				>
				// 					<Add sx={{
				// 						fontSize: '1.25rem',
				// 						color: 'inherit'
				// 					}} />
				// 				</Avatar>
				// 			</Dropdown>
				// 	)
				// }

				if (clientMembers && clientMembers?.length !== 0) {
					return <AvatarGroupComponent data={clientMembers} />
				} else {
					return <span className="fs-12 fw-500 font-roboto">----</span>
				}
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			render: (text, record) => {
				return (
					<div onClick={(e) => e.stopPropagation()}>
						<Status
							value={record.status}
							statusOptions={statusOptions}
							select={(newStatus) => {
								handleStatusChange(record, newStatus)
							}}
							getStyles={(status) => getStyles(status)}
						/>
					</div>
				)
			}
		},
		// {
		// 	title: ' ',
		// 	dataIndex: 'contactLead',
		// 	key: 'contactLead',
		// 	render: (text, record) => (
		// 		<a
		// 			href="/"
		// 			className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none"
		// 		>
		// 			Message Project Lead
		// 		</a>
		// 	),
		// 	width: '15%'
		// },
		{
			title: '',
			render: (record) => {
				const options = [
					{
						key: '1',
						label: (
							<div
								className="context-menu-option"
								onClick={() => {
									setDataSelected([record])
									setDeleteClient(true)
									//setIsUpdate(false)
								}}
							>
								<div className="d-flex align-items-center">
									<Delete className="context-menu-icon" />
									<span className="fs-14 fw-semibold txt-color-primary font-roboto">
										Delete
									</span>
								</div>
							</div>
						)
					},
					{
						key: '2',
						label: (
							<div
								className="context-menu-option"
								onClick={() => {
									// setDataSelected([record])
									setIsUpdate(true)
									setIsModalOpen(true)
									setUpdateData({ ...record?.data })
								}}
							>
								<div className="d-flex align-items-center">
									<Edit className="context-menu-icon" />

									<span className="fs-14 fw-semibold txt-color-primary font-roboto">
										Edit
									</span>
								</div>
							</div>
						)
					}
				]

				return (
					<div
						onClick={(e) => {
							e.stopPropagation()
						}}
						style={{
							width: 'fit-content',
							height: 'fit-content',
							borderRadius: '50%'
						}}
						className="d-flex justify-content-center align-items-center"
					>
						<ContextMenu items={options} />
					</div>
				)
			}
		}
	]

	const dataSource =
		data &&
		data?.length !== 0 &&
		data?.map((client, index) => {
			return {
				key: index.toString(),
				clientId: client?.id,
				contactPersonDetails: {
					contactEmail: client?.email || '',
					contactName: client?.contactName || '',
					imageUrl: client?.contactImageUrl || ''
				},
				phoneNumber: client?.phoneNumber,
				industry: client?.industry,
				projects: client?.projects?.map((pr, i) => {
					return {
						name: pr?.project?.name,
						imageUrl: pr?.project?.imageUrl,
						deletedAt: pr?.project?.deletedAt,
						id: pr?.projectId
					}
				}),
				balanceAmount: '',
				status: client?.status,
				data: { ...client },
				clientDetails: {
					clientName: client?.name,
					imageUrl: client?.imageUrl,
					startDate:
						client?.startDate && client?.startDate !== ''
							? dayjs(client?.startDate).format('Do MMMM, YYYY')
							: ''
				},
				clientMembers: client?.clientMembers.map((mbr) => ({
					name: mbr?.member?.name,
					imageUrl: mbr?.member?.imageUrl,
					id: mbr?.memberId,
					email: mbr?.member?.email
				}))
			}
		})

	const onChange = (record) => {
		console.log('onChange record', record)
	}

	return (
		<>
			{contextHolder}
			<div className="container-fluid px-0">
				{/* <div className="mt-1 mb-3 d-flex flex-row justify-content-between">
					<IndividualViewFilters
						activeFilter={activeFilter}
						filtersData={filtersData}
						onchange={onFilterChange}
					/>
				</div> */}

				<div
					style={{
						marginTop: '-58px',
						float: 'right',
						zIndex: '1',
						position: 'relative'
					}}
				>
					{dataSelected?.length === 0 ? (
						<>
							{/* <button className="ms-4 operations-on-table">
									<img src={searchIcon} alt="search-icon" className="px-1" />
									<span className="ms-1 fs-14 font-roboto">Search</span>
								</button>
								<button
									className="ms-4 operations-on-table"
									onClick={filterApplied}
								>
									<img src={filterIcon} alt="filter-icon" className="px-1" />
									<span className="ms-1 fs-14 font-roboto">Filter</span>
								</button> */}
						</>
					) : (
						<>
							{/* <button className="ms-4 operations-on-table">
									<img src={reminderIcon} alt="search-icon" className="px-1" />
									<span className="ms-1 fs-14 font-roboto">Add Reminder</span>
								</button> */}
							<button
								className="ms-4 operations-on-table d-flex align-items-center"
								onClick={() => {
									setDeleteClient(true)
								}}
							>
								<Delete
									sx={{
										fontSize: '1rem'
									}}
								/>
								<span className="ms-1 fs-14 font-roboto">Delete</span>
							</button>
						</>
					)}
				</div>

				<div className="d-flex align-items-center justify-content-between mb-4">
					<IndividualViewFilters
						filtersData={filtersData}
						activeFilter={activeFilter}
						onchange={onFilterChange}
					/>

					{activeFilter !== '' ? (
						<button
							type="button"
							className="p-0 border-0 font-roboto fw-500 fs-14 bg-transparent txt-color-blue-1 ms-3"
							onClick={() => {
								setActiveFilter('')
							}}
						>
							Clear Filter
						</button>
					) : null}
				</div>

				<TableComponent
					dataSource={dataSource}
					columns={columns}
					onChange={onChange}
					loading={isLoading}
					onRow={(record) => {
						return {
							onClick: (e) => {
								navigate(
									`/dashboard/manage-clients/client-details/${record?.clientId}`
								)
							}
						}
					}}
					setDataSelected={setDataSelected}
				/>

				<DeleteClient
					isModalOpen={deleteClient}
					setIsModalOpen={setDeleteClient}
					dataSelected={dataSelected}
					deletClients={deleteSelectedClients}
				/>

				{isModalOpen ? (
					<ClientModal
						open={isModalOpen} //false
						onClose={() => {
							setIsModalOpen(false)
							setUpdateData({})
							setIsUpdate(false)
						}}
						getData={() => getClients()} // () => console.log("Called")
						isUpdate={isUpdate} // false
						updateData={updateData} // {}
					></ClientModal>
				) : null}
			</div>
		</>
	)
}

export default MyClients
