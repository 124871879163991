import Cookies from 'js-cookie'
import dayjs from 'dayjs'

import { issueBreakdown, projectFinances, totalEmpoloyee } from '../API/JiraAPI'
import { getIntegrations } from '../API/UserAPI'

export const getIssueBreakdown = async (payload) => {
	try {
		const res = await issueBreakdown(payload)
		const data = res.data
		return { data }
	} catch (err) {
		console.log(err)
	}
}

export const getEmployeeCount = async () => {
	try {
		const res = await totalEmpoloyee()
		const data = res.data
		return { data }
	} catch (err) {
		console.log(err)
	}
}

export const getProjectFinance = async () => {
	try {
		const res = await projectFinances()
		const data = res.data
		return data
	} catch (err) {
		console.log(err)
	}
}

export const checkIntegration = async (navigate, messageApi) => {
	try {
		const res = await getIntegrations()
		const { data } = res

		let integApps = Object.keys(data)
		let flag = true
		integApps.forEach((inte) => {
			if (!data[inte]) {
				flag = false
			}
		})

		if (flag) {
			navigate('/dashboard/main')
			Cookies.set('isIntegrate', true, {
				expires: 12 / 24
			})
		} else {
			navigate('/dashboard/main')
			Cookies.set('isIntegrate', false, {
				expires: 12 / 24
			})
		}
	} catch (error) {
		Cookies.set('isIntegrate', false, {
			expires: 12 / 24
		})
	}
}

export const makeDurationFromSeconds = (seconds) => {
	const hours = Math.floor(seconds / 3600)
	const minutes = Math.floor((seconds % 3600) / 60)
	const remainingSeconds = seconds % 60

	return dayjs().set({
		hour: hours,
		minute: minutes,
		second: remainingSeconds,
		millisecond: 0
	})
}

export const splitName = (username) => {
	if (username !== null) {
		let regexPattern = /\s+/g
		let ans = username.replace(regexPattern, ' ')
		const [firstName, ...rest] = ans?.split(' ')
		const lastName = rest?.join(' ')

		return { firstName, lastName }
	}
}

export const formatDate = (inputDate) => {
	const addDaySuffix = (day) => {
		if (day >= 11 && day <= 13) {
			return day + 'th'
		}
		const lastDigit = day % 10
		if (lastDigit === 1) {
			return day + 'st'
		} else if (lastDigit === 2) {
			return day + 'nd'
		} else if (lastDigit === 3) {
			return day + 'rd'
		} else {
			return day + 'th'
		}
	}

	const date = dayjs(inputDate) // Replace '2023-08-28' with your date

	const formattedDate = date.format('MMMM YYYY') // 'D' for day, 'MMMM' for full month name, 'YYYY' for year

	// Add the 'th' suffix to the day
	const dayWithSuffix = addDaySuffix(date.format('D'))

	return `${dayWithSuffix} ${formattedDate}`
}

export function findSprintEndDate(date, days) {
	const millisToAdd = days * 24 * 60 * 60 * 1000
	const newDate = new Date(date.getTime() + millisToAdd)
	const day = String(newDate.getDate()).padStart(2, '0')
	const month = String(newDate.getMonth() + 1).padStart(2, '0')
	const year = newDate.getFullYear()
	return `${year}-${month}-${day}`
}

export function formatSprintDate(inputDateString) {
	const inputDate = new Date(inputDateString)
	const month = String(inputDate.getMonth() + 1).padStart(2, '0') // months are zero-based
	const day = String(inputDate.getDate()).padStart(2, '0')
	const year = inputDate.getFullYear()
	return `${month}/${day}/${year}`
}

export function getMonthNames(monthNumbers) {
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	]

	const result = monthNumbers.map((num) => monthNames[num - 1])
	return result
}

export function transformData(inputData) {
	const tasksAssignedData = new Array(12).fill(0)
	const tasksCompletedData = new Array(12).fill(0)

	inputData.forEach((item) => {
		const monthIndex = item.month - 1
		tasksAssignedData[monthIndex] = item.tasksAssigned
		tasksCompletedData[monthIndex] = item.tasksCompleted
	})

	const categories = [
		{
			name: 'Tasks Assigned',
			data: tasksAssignedData,
			color: '#4796DF'
		},
		{
			name: 'Tasks Completed',
			data: tasksCompletedData,
			color: '#204464'
		}
	]

	return categories
}
