import React, { useEffect, useMemo, useState } from 'react'
import { Skeleton, Tabs } from 'antd'
import moment from 'moment-timezone'
import 'moment-timezone/data/packed/latest.json'
import ProjectManagement from '../components/views/Dashboard/ProjectManagement'
import Finance from '../components/views/Dashboard/Finance'
import HRPayroll from '../components/views/Dashboard/HR&Payroll'
import DropdownFilter from '../components/shared/DropdownFilter'
import { getPaticularUserData, getUser } from '../API/UserAPI'
import Cookies from 'js-cookie'
import WelcomePage from '../components/views/WelcomePage'
import { useNavigate } from 'react-router'

const Dashboard = () => {
	const [selectedOption, setSelectedOption] = useState('Last 1 month')
	const [selectedTab, setSelectedTab] = useState('1')
	const [userData, setUserData] = useState(null)
	const [isLoading, setIsLoading] = useState(true)

	const [companyDetails, setCompanyDetails] = useState(null)

	useEffect(() => {
		setCompanyDetails(
			Cookies.get('companyDetails')
				? JSON.parse(Cookies.get('companyDetails'))
				: null
		)
	}, [])

	// const country = 'United States';
	const countryTimezone = moment.tz.guess()

	require('moment-timezone/data/packed/latest.json')

	// Get the current time in the country's timezone
	const currentTimeInCountry = moment.tz(countryTimezone)

	const morningStart = moment.tz('06:00', 'HH:mm', countryTimezone)
	const afternoonStart = moment.tz('12:00', 'HH:mm', countryTimezone)
	const eveningStart = moment.tz('16:00', 'HH:mm', countryTimezone)

	const getTimeOfDayInCountry = (
		currentTime,
		morningStart,
		afternoonStart,
		eveningStart
	) => {
		if (
			currentTime.isAfter(morningStart) &&
			currentTime.isBefore(afternoonStart)
		) {
			return 'morning'
		} else if (
			currentTime.isBefore(eveningStart) &&
			currentTime.isAfter(morningStart)
		) {
			return 'afternoon'
		} else {
			return 'evening'
		}
	}

	const timeOfDayInCountry = getTimeOfDayInCountry(
		currentTimeInCountry,
		morningStart,
		afternoonStart,
		eveningStart
	)

	const getUserData = async () => {
		try {
			const res = await getUser()

			const { data } = res
			setUserData({ ...data })
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
		}
	}

	const items = [
		{
			key: '1',
			label: 'Project Management',
			children: (
				<ProjectManagement
					selectedOption={selectedOption}
					selectedTab={selectedTab}
					isIntegrate={true}
					proceed={companyDetails && companyDetails?.firstName ? true : false}
				/>
			)
		}
		// {
		// 	key: '2',
		// 	label: 'Finance',
		// 	children: (
		// 		<Finance
		// 			selectedOption={selectedOption}
		// 			selectedTab={selectedTab}
		// 			isIntegrate={isIntegrate}
		// 		/>
		// 	)
		// }
		// {
		// 	key: '3',
		// 	label: 'HR & Payroll',
		// 	children: <HRPayroll />
		// }
	]

	const onChange = (key) => {
		setSelectedTab(key)
	}

	const handleSelect = (key) => {
		setSelectedOption(key)
	}

	const dropDownMenuItems = [
		{
			label: 'Last 1 month',
			key: '1'
		},
		{
			label: 'Last 3 months',
			key: '3'
		},
		{
			label: 'Last 6 months',
			key: '6'
		}
	]

	return (
		<div className="container-fluid mx-0">
			<h5 className="ms-4 mb-0 font-roboto txt-color-primary fw-semibold">
				Hi {companyDetails?.firstName || ''}, Welcome to{' '}
				{companyDetails?.companyDetails?.name}
			</h5>
			{/* {isIntegrate ? ( */}
			<>
				{/* <div className="pt-1 mt-4 float-end"> */}
				{/* {isLoading ? (
							<Skeleton.Button width={'10rem'} />
						) : userData && userData?.name ? (
							<h1 className="fs-3 mb-4 dashboard-primary-header">
								Hi {userData?.name}, Good {timeOfDayInCountry}
							</h1>
						) : (
							<h1 className="fs-3 mb-4 dashboard-primary-header">
								Hi, Good {timeOfDayInCountry}
							</h1>
						)} */}

				{/* <DropdownFilter
							title={`${selectedOption}`}
							extraclassName="primary-dropdown"
							menuItems={dropDownMenuItems}
							onSelect={handleSelect}
							defaultActiveKey={selectedOption}
						/>
					</div> */}
				<div className="mt-4">
					<Tabs
						defaultActiveKey={'1'}
						items={items}
						onChange={onChange}
						className="dashboard-tabs"
					/>
				</div>
			</>
			{/* ) : (
				<WelcomePage />
			)} */}
		</div>
	)
}

export default Dashboard
