import { Space, message } from 'antd'
import { useEffect, useState } from 'react'
import TableComponent from '../../shared/TableComponent'
import AvatarGroupComponent from '../../shared/AvatarGroupComponent'
import { handleTaskStatus, handleTaskPriority } from '../../../API/TasksAPI'
import dayjs from 'dayjs'
import IndividualTaskModal from '../../shared/Modals/IndividualTaskModal'
import KanbanView from '../Projects/IssueDetails/kanbanView/KanbanView'
import IndividualViewFilters from '../../shared/TableComponents/IndividualViewFilters'
import ContextMenu from '../../shared/ContextMenu'
import { getColoumn } from '../../../API/taskAPI'
import { Delete, Edit } from '@mui/icons-material'
import { getAllTasksClient } from '../../../API/clientAPI'
import Status from '../Projects/ProjectsDetails/Status'
import Priority from '../Projects/IssueDetails/Priority'

const ClientTasks = ({ clientId, addTask }) => {
	const [messageApi, contextHolder] = message.useMessage()

	const [data, setData] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [isKanbanview, setIsKanbanview] = useState(false)
	const [statusOptions, setStatusOptions] = useState([])

	const [showIndividualTask, setShowIndividualTask] = useState(false)
	const [taskId, setTaskId] = useState()

	const addDaySuffix = (day) => {
		if (day >= 11 && day <= 13) {
			return day + 'th'
		}
		const lastDigit = day % 10
		if (lastDigit === 1) {
			return day + 'st'
		} else if (lastDigit === 2) {
			return day + 'nd'
		} else if (lastDigit === 3) {
			return day + 'rd'
		} else {
			return day + 'th'
		}
	}

	const formatDate = (inputDate) => {
		const date = dayjs(inputDate)

		const formattedDate = date.format('MMMM YYYY')

		// Add the 'th' suffix to the day
		const dayWithSuffix = addDaySuffix(date.format('D'))

		return `${dayWithSuffix} ${formattedDate}`
	}

	const priorityOptions = [
		{
			value: 'Low',
			label: 'Low'
		},
		{
			value: 'Medium',
			label: 'Medium'
		},
		{
			value: 'High',
			label: 'High'
		},
		{
			value: 'Urgent',
			label: 'Urgent'
		}
	]

	const getStatusStyles = (value) => {
		switch (value) {
			case 'ToDo':
				return {
					border: '1px solid #E6E6E6',
					backgroundColor: '#E6E6E6',
					color: '#5F5F5F',
					borderRadius: '2px'
				}
			case 'To Do':
				return {
					border: '1px solid #E6E6E6',
					backgroundColor: '#E6E6E6',
					color: '#5F5F5F',
					borderRadius: '2px'
				}
			case 'Completed':
				return {
					border: '1px solid #CFEBBA',
					backgroundColor: '#F6FFED',
					color: '#52C41A',
					borderRadius: '2px'
				}
			case 'InProgress':
				return {
					border: '1px solid #C9EBFF',
					backgroundColor: '#E6F7FF',
					color: '#1890FF',
					borderRadius: '2px'
				}
			case 'In Progress':
				return {
					border: '1px solid #C9EBFF',
					backgroundColor: '#E6F7FF',
					color: '#1890FF',
					borderRadius: '2px'
				}
			default:
				return {
					border: '1px solid #D9D9D9',
					backgroundColor: 'rgba(250, 250, 250, 0.11)',
					color: '#555',
					borderRadius: '2px'
				}
		}
	}

	const getColoumnData = async () => {
		try {
			const { data } = await getColoumn()
			setStatusOptions(
				data?.map((item) => ({ value: item?.id, label: item?.name }))
			)
		} catch (error) {
			console.log(error)
		}
	}

	const getTasks = async () => {
		setIsLoading(true)
		try {
			const res = await getAllTasksClient(clientId)
			const { data = [] } = res
			setData([...data])
			setIsLoading(false)
		} catch (error) {
			messageApi.error('Unable to fetch issues.')
			setIsLoading(false)
			setData([])
		}
	}

	const handleStatusChange = async (data, newStatus) => {
		const statusName = statusOptions?.filter((el) => el.value === newStatus)
		try {
			const res = await handleTaskStatus({
				id: data?.taskDetail?.taskId,
				statusId: newStatus,
				status: statusName[0]?.label
			})
			getTasks()
		} catch (error) {
			message.error('Unable to update task.')
		}
	}

	const handlePriorityChange = async (data, newPriority) => {
		try {
			const res = await handleTaskPriority({
				id: data?.taskDetail?.taskId,
				priority: newPriority
			})
		} catch (error) {
			message.error('Unable to update task.')
		}
	}

	useEffect(() => {
		if (!addTask) {
			getTasks()
			getColoumnData()
		}
	}, [addTask])

	const columns = [
		{
			title: 'Tasks',
			dataIndex: 'taskDetail',
			key: 'taskDetail',
			render: (text, record) => (
				<div
					className="d-flex justify-content-equal align-items-center"
					style={{ columnGap: '10px' }}
				>
					<div className="">
						<p
							className="font-roboto fw-500 fs-12 mb-1"
							style={{ color: '#434343' }}
						>
							{record?.taskDetail?.taskName}
						</p>
						<p className="font-roboto fw-400 fs-12 mb-1 tasksData">
							{record?.taskDetail?.taskDescription
								? record?.taskDetail?.taskDescription
								: ''}
						</p>
						<p className="font-roboto fw-500 fs-10 mb-0 tasksData">
							Started on {formatDate(record?.startDate)}
						</p>
					</div>
				</div>
			)
		},
		{
			title: 'Priority',
			dataIndex: 'priority',
			key: 'priority',
			render: (text, record) => {
				return (
					<Space size="middle">
						<div className="" onClick={(e) => e.stopPropagation()}>
							<Priority
								value={record?.priority || ''}
								defaultValue={record?.priority}
								//issueId={record.key}
								priorityOptions={priorityOptions}
								select={(newPriority) => {
									handlePriorityChange(record, newPriority)
								}}
								//getStyles={(newPriority)=>getPriorityStyles(newPriority)}
							/>
						</div>
					</Space>
				)
			}
		},
		// {
		// 	title: 'Sprint Name',
		// 	dataIndex: 'sprintName',
		// 	key: 'sprintName',
		// 	render: (text, record) => (
		// 		<span className="font-roboto fw-500 fs-12 mb-1">
		// 			{record?.sprintName}
		// 		</span>
		// 	),
		// 	width: '10%'
		// },
		{
			title: 'Due Date',
			dataIndex: 'dueDate',
			key: 'dueDate',
			render: (dueDate) => (
				<span className="fs-12 fw-500 font-roboto tasksData">
					{formatDate(dueDate)}
				</span>
			)
		},
		{
			title: 'Project name',
			dataIndex: 'projectName',
			key: 'projectName',
			render: (projectName) => (
				<span className="font-roboto fw-500 fs-12 mb-1 tasksData text-capitalize">
					{projectName}
				</span>
			)
		},
		{
			title: 'Members',
			dataIndex: 'members',
			key: 'members',
			render: (members) => {
				if (members && members.length !== 0) {
					return <AvatarGroupComponent maxCount={3} data={members} />
				} else {
					return <span className="fs-12 fw-500 font-roboto">----</span>
				}
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text, record) => (
				<div onClick={(e) => e.stopPropagation()}>
					<Status
						value={record.statusDetail?.name}
						statusOptions={statusOptions}
						select={(newStatus) => {
							handleStatusChange(record, newStatus)
						}}
						getStyles={getStatusStyles}
					/>
				</div>
			)
		}
		// {
		// 	title: 'Estimate Time',
		// 	dataIndex: 'taskEstimate',
		// 	key: 'taskEstimate',
		// 	render: (taskEstimate) => (
		// 		<span className="font-roboto fw-500 fs-12 mb-1">
		// 			{taskEstimate && `In ${taskEstimate} hrs`}
		// 		</span>
		// 	),
		// 	width: '10%'
		// },
		// {
		// 	title: ' ',
		// 	dataIndex: 'contactLead',
		// 	key: 'contactLead',
		// 	render: (text, record) => (
		// 		<a
		// 			href="/"
		// 			className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none"
		// 		>
		// 			Contact Assignee
		// 		</a>
		// 	),
		// 	width: '10%'
		// },
		// {
		// 	title: ' ',
		// 	render: (text, record) => {
		// 		const options = [
		// 			{
		// 				key: '1',
		// 				label: (
		// 					<div
		// 						className=""
		// 						onClick={() => {
		// 							setSelectedTask(record)
		// 							setDataSelected([record])
		// 							setDeleteTask(true)
		// 						}}
		// 					>
		// 						<div className="context-menu-option">
		// 							<div className="d-flex align-items-center">
		// 								<Delete className="context-menu-icon" />

		// 								<span className="fs-14 fw-semibold txt-color-primary font-roboto">
		// 									Delete
		// 								</span>
		// 							</div>
		// 						</div>
		// 					</div>
		// 				)
		// 			},
		// 			{
		// 				key: '2',
		// 				label: (
		// 					<div
		// 						className=""
		// 						onClick={() => {
		// 							setSelectedTask(record)
		// 							createNew()
		// 							setDataSelected([record])
		// 							setEditNewTask(true)
		// 						}}
		// 					>
		// 						<div className="context-menu-option">
		// 							<div className="d-flex align-items-center">
		// 								<Edit className="context-menu-icon" />

		// 								<span className="fs-14 fw-semibold txt-color-primary font-roboto">
		// 									Edit
		// 								</span>
		// 							</div>
		// 						</div>
		// 					</div>
		// 				)
		// 			}
		// 		]
		// 		return (
		// 			<>
		// 				<div
		// 					onClick={(e) => {
		// 						e.stopPropagation()
		// 					}}
		// 					style={{
		// 						width: 'fit-content',
		// 						height: 'fit-content',
		// 						borderRadius: '50%'
		// 					}}
		// 					className="d-flex justify-content-center align-items-center"
		// 				>
		// 					<ContextMenu items={options} />
		// 				</div>
		// 			</>
		// 		)
		// 	},
		// 	width: '5%'
		// }
	]

	const dataSource =
		data &&
		data.length !== 0 &&
		data.map((task, index) => {
			return {
				key: index.toString(),
				clientName: task?.clientName || '----',
				id: task?.id,
				name: task?.name,
				description: task?.description,
				taskDetail: {
					taskId: task?.id,
					taskName: task?.name,
					taskDescription: task?.description
				},
				startDate: task?.startDate,
				dueDate: task?.dueDate,
				statusDetail: {
					id: task?.statusDetail?.id,
					name: task?.statusDetail?.name,
					userId: task?.statusDetail?.userId,
					color: task?.statusDetail?.color
				},
				members:
					task?.members && task?.members.length !== 0
						? task?.members.map((mbr) => {
								return {
									name: mbr?.name || '',
									email: mbr?.email || '',
									imageUrl: mbr?.imageUrl || ''
								}
						  })
						: [],
				priority: task?.priority,
				taskEstimate: task?.taskEstimate,
				projectName: task?.project?.name,
				projectId: task?.projectId,
				project: task?.project
			}
		})

	const onChange = (pagination, filters, sorter, extra) => {}

	return (
		<>
			{contextHolder}
			<div className="container-fluid px-0">
				{showIndividualTask && (
					<IndividualTaskModal
						isOpen={showIndividualTask}
						setIsOpen={setShowIndividualTask}
						taskId={taskId}
					/>
				)}
				{/* <div className="mt-1 mb-3 d-flex flex-row justify-content-between">
					<div className="">
						<IndividualViewFilters
							activeFilter={selectedHeaderTag}
							filtersData={priorityList}
							onchange={handleTag}
						/>
						<TaskHeaderTags setSelectedHeaderTag={setSelectedHeaderTag} selectedHeaderTag={selectedHeaderTag} />
					</div>

					<div className="">
						<SwitchScreen
							setIsKanbanview={setIsKanbanview}
							isKanbanview={isKanbanview}
						/>
					</div>
				</div> */}
				{isKanbanview ? (
					<KanbanView />
				) : (
					<TableComponent
						dataSource={dataSource}
						columns={columns}
						onChange={onChange}
						loading={isLoading}
						//setDataSelected={setDataSelected}
						// onRow={(record) => {
						// 	return {
						// 		onClick: (e) => {
						// 			e.stopPropagation()
						// 			navigate(
						// 				`/dashboard/projects/issue-details/${record?.issueId}`,
						// 				{
						// 					state: {
						// 						data: {
						// 							...record
						// 						}
						// 					}
						// 				}
						// 			)
						// 		}
						// 	}
						// }}
					/>
				)}
				{showIndividualTask && (
					<IndividualTaskModal
						open={showIndividualTask}
						setIsOpen={setShowIndividualTask}
						taskId={taskId}
					/>
				)}
			</div>
		</>
	)
}

export default ClientTasks
