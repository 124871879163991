import React, { useEffect, useState } from 'react'
import { Layout, Menu } from 'antd'
import { Outlet, useLocation } from 'react-router'

import { cardHolderIcon } from '../../assets/svg'
import DashboardHeader from './DashboardHeader'
import {
	Dashboard,
	PeopleAlt,
	Star,
	FileCopy,
	Apps,
	Settings,
	DateRange
} from '@mui/icons-material'
import { useNavigate } from 'react-router'
import Cookies from 'js-cookie'

const { Sider, Content } = Layout

const DashboardLayout = () => {
	const location = useLocation()

	const getSelectedKey = () => {
		const path = location.pathname

		// logic to map URLs to corresponding keys.
		if (path.includes('/main')) {
			return '1'
		} else if (path.includes('/integrations')) {
			return '11'
		} else if (path.includes('/projects')) {
			return '3'
		} else if (path.includes('/call-logs')) {
			return '12'
		} else if (path.includes('clients')) {
			return '2'
		}
		// Return a default key if none of the conditions match.
		return ''
	}

	const [collapsed, setCollapsed] = useState(true)
	const [openKeys, setOpenKeys] = useState([])
	const [selectedKey, setSelectedKey] = useState(getSelectedKey())

	const navigate = useNavigate()

	useEffect(() => {
		setSelectedKey(getSelectedKey())
	}, [location])

	const getItem = (label, key, icon, children) => {
		return {
			key,
			icon,
			children,
			label
		}
	}

	const topItems = [
		getItem('Dashboard', '1', <Dashboard />),
		getItem('Clients', '2', <PeopleAlt />),
		getItem('Projects', '3', <FileCopy />),
		// getItem('Invoices', 'sub2', cardHolderIcon, [
		// 	getItem('Invoice 1', '4'),
		// 	getItem('Invoice 2', '5')
		// ]),
		// getItem('Favourites', '6', <Star />),
		// getItem(
		// 	'Integrations',
		// 	'11',
		// 	<svg
		// 		xmlns="http://www.w3.org/2000/svg"
		// 		width="20"
		// 		height="20"
		// 		viewBox="0 0 20 20"
		// 		fill="none"
		// 		className="menu-item-svg-icon integrations-menu"
		// 	>
		// 		<path
		// 			d="M19.5941 9.9999C19.5937 7.56243 17.61 5.57877 15.1726 5.57833L11.6568 5.57878C12.5506 6.19473 13.2456 7.07466 13.6356 8.10512L15.1717 8.10557C16.2169 8.10557 17.0669 8.95558 17.0664 10.0003C17.0673 11.0446 16.2169 11.8951 15.1717 11.8951L13.636 11.8951L12.6073 11.8956L11.3821 11.8951C10.8363 11.8951 10.3468 11.6593 10.0006 11.2881C9.74733 11.017 9.57626 10.6717 9.51596 10.2889C9.50166 10.1942 9.48692 10.0991 9.48737 10.0003C9.48737 9.90118 9.50122 9.80693 9.51596 9.71179C9.39357 9.33123 9.03981 9.05251 8.6186 9.05295L7.06553 9.05251C6.99809 9.35848 6.96056 9.67472 6.96056 9.9999C6.96012 10.3255 6.99853 10.6418 7.06598 10.9477C7.13834 11.28 7.25001 11.5963 7.39428 11.8951C7.90974 12.9778 8.84997 13.818 10.001 14.1981C10.4356 14.3415 10.9002 14.4219 11.383 14.4215L11.6591 14.4215L15.1739 14.4219C17.61 14.4228 19.5941 12.4387 19.5941 9.9999Z"
		// 			fill={selectedKey === '11' ? '#204464' : '#7B7B7B'}
		// 		/>
		// 		<path
		// 			d="M6.36465 11.8954L4.82856 11.895C3.78336 11.895 2.93335 11.045 2.93335 9.99977C2.93335 8.95456 3.78292 8.105 4.82812 8.105L6.36511 8.10455L7.39289 8.105L8.6181 8.10544C9.16393 8.10544 9.65437 8.34039 9.99965 8.71246C10.2529 8.98359 10.4249 9.32798 10.4843 9.71167C10.4986 9.80636 10.5133 9.9015 10.5129 10.0002C10.5138 10.0985 10.499 10.1936 10.4843 10.2888C10.6067 10.6693 10.9613 10.9471 11.3821 10.9471L12.9347 10.9471C13.0022 10.6412 13.0401 10.3254 13.0397 9.99976C13.0406 9.67458 13.003 9.35745 12.9347 9.05238C12.8628 8.7205 12.7507 8.40383 12.6073 8.10411C12.0909 7.02228 11.1512 6.18164 9.99965 5.80197C9.56459 5.65815 9.10051 5.57819 8.61721 5.57819L8.34251 5.57775L4.82678 5.5782C2.38886 5.5782 0.405203 7.56186 0.405649 9.99932C0.405203 12.4377 2.38886 14.4213 4.82722 14.4209L8.34206 14.4213C7.44917 13.8054 6.75549 12.925 6.36465 11.8954Z"
		// 			fill={selectedKey === '11' ? '#204464' : '#7B7B7B'}
		// 		/>
		// 	</svg>
		// ),
		getItem(
			'Call Logs',
			'12',
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
			>
				<mask
					id="mask0_1653_12028"
					style={{
						maskType: 'alpha'
					}}
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="20"
					height="20"
				>
					<rect
						width="20"
						height="20"
						fill={selectedKey === '12' ? '#204464' : '#7B7B7B'}
					/>
				</mask>
				<g mask="url(#mask0_1653_12028)">
					<path
						d="M10.0001 3.33334V1.66667H18.3334V3.33334H10.0001ZM10.0001 6.66667V5.00001H18.3334V6.66667H10.0001ZM10.0001 10V8.33334H18.3334V10H10.0001ZM15.7917 18.3333C14.0556 18.3333 12.3404 17.9549 10.6459 17.1979C8.95147 16.441 7.4098 15.3681 6.02091 13.9792C4.63203 12.5903 3.55911 11.0486 2.80216 9.35417C2.04522 7.65973 1.66675 5.94445 1.66675 4.20834C1.66675 3.95834 1.75008 3.75001 1.91675 3.58334C2.08341 3.41667 2.29175 3.33334 2.54175 3.33334H5.91675C6.11119 3.33334 6.2848 3.39931 6.43758 3.53126C6.59036 3.6632 6.68064 3.81945 6.70841 4.00001L7.25008 6.91667C7.27786 7.13889 7.27091 7.32639 7.22925 7.47917C7.18758 7.63195 7.11119 7.76389 7.00008 7.87501L4.97925 9.91667C5.25703 10.4306 5.58689 10.9271 5.96883 11.4063C6.35078 11.8854 6.77091 12.3472 7.22925 12.7917C7.6598 13.2222 8.11119 13.6215 8.58341 13.9896C9.05564 14.3576 9.55564 14.6944 10.0834 15L12.0417 13.0417C12.1667 12.9167 12.3299 12.8229 12.5313 12.7604C12.7327 12.6979 12.9306 12.6806 13.1251 12.7083L16.0001 13.2917C16.1945 13.3472 16.3542 13.4479 16.4792 13.5938C16.6042 13.7396 16.6667 13.9028 16.6667 14.0833V17.4583C16.6667 17.7083 16.5834 17.9167 16.4167 18.0833C16.2501 18.25 16.0417 18.3333 15.7917 18.3333Z"
						fill={selectedKey === '12' ? '#204464' : '#7B7B7B'}
					/>
				</g>
			</svg>
		),
		getItem('Calender', '13', <DateRange />)
	]

	// if (companyDetails?.id !== companyDetails?.companyDetails?.adminId) {
	// 	topItems?.splice(0, 1)
	// }

	const rootSubmenuKeys = ['sub1', 'sub2']

	// const bottomItems = [
	// 	getItem('Apps', '8', <Apps />),
	// 	getItem('Settings', '9', <Settings />)
	// ]

	const onOpenChange = (keys) => {
		const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
		if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
			setOpenKeys(keys)
		} else {
			setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
		}
	}

	const handleMenuClick = (e) => {
		setSelectedKey(e.key)
		switch (e.key) {
			case '1':
				navigate('/dashboard/main')
				break
			case '3':
				navigate('/dashboard/projects')
				break
			case '11':
				navigate('/dashboard/integrations')
				break
			case '12':
				navigate('/dashboard/call-logs')
				break
			case '2':
				navigate('/dashboard/manage-clients')
				break
			case '13':
				// window.open('https://calendar.google.com/calendar/u/0/r', '_blank')
				navigate('/dashboard/calendar')
				break
			default:
				break
		}
	}

	return (
		<Layout className="app-main-container">
			<DashboardHeader />

			<Layout className="app-inner-layout position-relative">
				<div className={`sider-container ${collapsed ? '' : 'show'}`}>
					<Sider
						trigger={null}
						collapsible
						collapsed={collapsed}
						onMouseOver={() => setCollapsed(false)}
						onMouseLeave={() => setCollapsed(true)}
						className="app-sider bg-white"
					>
						<div className="sidebar-menu">
							<div className="sidebar-menu-top">
								<Menu
									className="sidebar-menu-top-item"
									defaultSelectedKeys={[selectedKey]}
									mode="inline"
									items={topItems}
									subMenuOpenDelay={10}
									openKeys={openKeys}
									onOpenChange={onOpenChange}
									onClick={handleMenuClick}
									selectedKeys={[selectedKey]}
								/>
							</div>

							{/* <div className="sidebar-menu-bottom">
								<Menu
									className="sidebar-menu-bottom-item"
									mode="inline"
									items={bottomItems}
									openKeys={openKeys}
									onOpenChange={onOpenChange}
								/>
							</div> */}
						</div>
					</Sider>
				</div>

				<Content className="app-content-container">
					<Outlet />
				</Content>
			</Layout>
		</Layout>
	)
}

export default DashboardLayout
