import { useState, useEffect } from 'react'
import TableComponent from '../../../shared/TableComponent'
import Priority from '../IssueDetails/Priority'
import dayjs from 'dayjs'
import { handleTaskPriority } from '../../../../API/TasksAPI'
import AvatarGroupComponent from '../../../shared/AvatarGroupComponent'
import { handleTaskStatus } from '../../../../API/TasksAPI'
import Status from './Status'
import bellIcon from '../../../../assets/images/bell-icon.svg'
import deleteFilledIcon from '../../../../assets/images/delete-filled-icon.svg'
import editIcon from '../../../../assets/images/edit-icon.svg'
import { getProjectTasks } from '../../../../API/TasksAPI'
import { getColoumn } from '../../../../API/taskAPI'
import useMessage from 'antd/es/message/useMessage'

const Tasks = ({
	projectId,
	newProjectTaskAdded,
	getData,
	setNewTaskAdded,
	isLoading,
	setIsLoading,
	activeKey
}) => {
	const [data, setData] = useState([])
	const [statusOptions, setStatusOptions] = useState([])
	const [message, contextHolder] = useMessage()

	const getColoumnData = async () => {
		try {
			const { data } = await getColoumn()
			setStatusOptions(
				data?.map((item) => ({ value: item?.id, label: item?.name }))
			)
		} catch (error) {
			console.log(error)
		}
	}

	const addDaySuffix = (day) => {
		if (day >= 11 && day <= 13) {
			return day + 'th'
		}
		const lastDigit = day % 10
		if (lastDigit === 1) {
			return day + 'st'
		} else if (lastDigit === 2) {
			return day + 'nd'
		} else if (lastDigit === 3) {
			return day + 'rd'
		} else {
			return day + 'th'
		}
	}

	const formatDate = (inputDate) => {
		const date = dayjs(inputDate) // Replace '2023-08-28' with your date

		const formattedDate = date.format('MMMM YYYY') // 'D' for day, 'MMMM' for full month name, 'YYYY' for year

		// Add the 'th' suffix to the day
		const dayWithSuffix = addDaySuffix(date.format('D'))

		return `${dayWithSuffix} ${formattedDate}`
	}

	const priorityOptions = [
		{
			value: 'Low',
			label: 'Low'
		},
		{
			value: 'Medium',
			label: 'Medium'
		},
		{
			value: 'High',
			label: 'High'
		},
		{
			value: 'Urgent',
			label: 'Urgent'
		}
	]

	const getPriorityStyles = (value) => {
		switch (value) {
			case 'Low':
				return {
					border: '1px solid #D9D9D9',
					backgroundColor: '#FAFAFA',
					color: '#555555',
					borderRadius: '100px'
				}
			case 'Medium':
				return {
					border: '1px solid #C9DAFF',
					backgroundColor: '#F0F5FF',
					color: '#2F54EB',
					borderRadius: '100px'
				}
			case 'High':
				return {
					border: '1px solid #FFCAE2',
					backgroundColor: '#FFF0F6',
					color: '#EB2F96',
					borderRadius: '100px'
				}
			case 'Urgent':
				return {
					border: '1px solid #FFCCC9',
					backgroundColor: '#FFF1F0',
					color: '#F5222D',
					borderRadius: '100px'
				}
			default:
				return {}
		}
	}

	const deleteTask = (e) => {
		console.log('record data ', e.target.value)
	}

	const options = [
		{
			key: '1',
			label: (
				<div
					className="p-2"
					style={{ borderBottom: '1px solid #EBEBEB' }}
					onClick={(e) => deleteTask(e)}
				>
					<img src={deleteFilledIcon} alt="icon" />
					<span className="font-roboto fs-12 fw-semibold ms-2">Delete</span>
				</div>
			)
		},
		{
			key: '2',
			label: (
				<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
					<img src={editIcon} alt="icon" />
					<span className="font-roboto fs-12 fw-semibold ms-2">Edit</span>
				</div>
			)
		},
		{
			key: '3',
			label: (
				<div className="p-2">
					<img src={bellIcon} alt="icon" />
					<span className="font-roboto fs-12 fw-semibold ms-2">
						Add Reminder
					</span>
				</div>
			)
		}
	]

	const projectTasks = async () => {
		setIsLoading(true)
		try {
			const res = await getProjectTasks(projectId)
			const { data = [] } = res
			setData([...data])
			setIsLoading(false)
		} catch (error) {
			setData([])
			setIsLoading(false)
		}
	}

	useEffect(() => {
		projectTasks()
		getColoumnData()
	}, [activeKey])

	useEffect(() => {
		if (newProjectTaskAdded) {
			projectTasks()
			setNewTaskAdded(false)
		}
	}, [newProjectTaskAdded])

	const handlePriorityChange = async (data, newPriority) => {
		setIsLoading(true)
		try {
			const res = await handleTaskPriority({
				id: data?.taskDetail?.taskId,
				priority: newPriority
			})
			projectTasks()
		} catch (error) {
			setIsLoading(false)
			message.error('Unable to update priority of task.')
		}
	}

	const getStatusStyles = (value) => {
		switch (value) {
			case 'ToDo':
				return {
					border: '1px solid #E6E6E6',
					backgroundColor: '#E6E6E6',
					color: '#5F5F5F'
				}
			case 'Completed':
				return {
					border: '1px solid #CFEBBA',
					backgroundColor: '#F6FFED',
					color: '#52C41A'
				}
			case 'InProgress':
				return {
					border: '1px solid #C9EBFF',
					backgroundColor: '#E6F7FF',
					color: '#1890FF'
				}
			default:
				return {}
		}
	}

	const handleStatusChange = async (data, newStatus) => {
		try {
			const res = await handleTaskStatus({
				id: data?.taskDetail?.taskId,
				statusId: newStatus
			})
			getData()
			message.success('Status updated.')
		} catch (error) {
			console.log('Unable to update status.')
			message.error('Unable to change status.')
		}
	}

	const columns = [
		{
			title: 'Tasks',
			dataIndex: 'taskDetail',
			key: 'taskDetail',
			render: (text, record) => (
				<div
					className="d-flex justify-content-equal align-items-center"
					style={{ columnGap: '10px' }}
				>
					<div className="">
						<p className="font-roboto fw-500 fs-12 mb-1">
							{record?.taskDetail?.taskName}
						</p>
						<p className="font-roboto fw-400 fs-12 mb-1">
							{record?.taskDescription ? record?.taskDescription : ''}
						</p>
						<p className="font-roboto fw-500 fs-10 mb-0">
							Started on {formatDate(record?.startDate)}
						</p>
					</div>
				</div>
			)
		},
		{
			title: 'Priority',
			dataIndex: 'priority',
			key: 'priority',
			render: (text, record) => (
				<div onClick={(e) => e.stopPropagation()}>
					<Priority
						value={record.priority}
						issueId={record.key}
						priorityOptions={priorityOptions}
						select={(newPriority) => {
							handlePriorityChange(record, newPriority)
						}}
						getStyles={() => getPriorityStyles}
					/>
				</div>
			)
		},
		{
			title: 'Sprint Name',
			dataIndex: 'sprint',
			key: 'sprint',
			render: (sprint) => (
				<span className="font-roboto fw-500 fs-12 mb-1">
					{sprint?.sprintName}
				</span>
			)
		},
		{
			title: 'Start Date',
			dataIndex: 'startDate',
			key: 'startDate',
			render: (startDate) => (
				<span className="fs-12 fw-500 font-roboto">
					{formatDate(startDate)}
				</span>
			)
		},
		{
			title: 'Due Date',
			dataIndex: 'dueDate',
			key: 'dueDate',
			render: (dueDate) => (
				<span className="fs-12 fw-500 font-roboto">{formatDate(dueDate)}</span>
			)
		},
		// {
		// 	title: 'Estimate Time',
		// 	dataIndex: 'taskEstimate',
		// 	key: 'taskEstimate',
		// 	render: (taskEstimate) => (
		// 		<span className="font-roboto fw-500 fs-12 mb-1 ms-4">
		// 			{taskEstimate ? `In ${taskEstimate} hrs` : '----'}
		// 		</span>
		// 	)
		// },
		{
			title: 'Members',
			dataIndex: 'members',
			key: 'members',
			render: (members) => {
				if (members && members?.length !== 0) {
					return <AvatarGroupComponent data={members} />
				} else {
					return <span className="fs-12 fw-500 font-roboto">----</span>
				}
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text, record) => {
				return (
					<div onClick={(e) => e.stopPropagation()}>
						<Status
							value={record.status}
							statusOptions={statusOptions}
							select={(newStatus) => {
								handleStatusChange(record, newStatus)
							}}
							getStyles={(newStatus) => getStatusStyles(newStatus)}
						/>
					</div>
				)
			}
		}
	]

	const dataSource =
		data &&
		data.length !== 0 &&
		data.map((task, index) => {
			return {
				key: index.toString(),
				taskDetail: {
					taskId: task.id,
					taskName: task.name
				},
				startDate: task.startDate,
				dueDate: task.dueDate,
				status: parseInt(task.statusId),
				taskDescription: task.description,
				members:
					task?.members && task?.members.length !== 0
						? task?.members.map((mbr) => {
								return {
									name: mbr?.name || '',
									imageUrl: mbr?.imageUrl || '',
									email: mbr?.email || ''
								}
						  })
						: [],
				projectId: task.projectId,
				priority: task.priority,
				taskEstimate: task.taskEstimate,
				taskLabel: task.label,
				sprint: {
					sprintName: task?.sprint?.name || '----',
					sprintId: task?.sprint?.id || null
				}
			}
		})

	return (
		<>
			{contextHolder}
			<TableComponent
				dataSource={dataSource}
				columns={columns}
				loading={isLoading}
			/>
		</>
	)
}

export default Tasks
