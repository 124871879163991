import Cookies from 'js-cookie'
import { deleteRequest, getRequest, postRequest, putRequest } from '.'

const getAllProjects = async (clientIsNull = '') => {
	const memberEmail = Cookies.get('user-email') ?? ''
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await getRequest(
		`/v1/project?companyDetailsId=${companyDetailsId}&memberEmail=${memberEmail}&clientIsNull=${clientIsNull}`
	)
	return res
}

const getProfitLoss = async (params) => {
	const { startDate, endDate, customerId } = params
	const res = await getRequest(
		`/quickbooks/incomeAndExpensesByDetails?startDate=${startDate}&endDate=${endDate}&customer=${customerId}`
	)
	return res
}

const getProjectSprints = async (projectId) => {
	const res = await getRequest(`/jira/allSprints/${projectId}`)
	return res
}

const getProjectIssues = async (projectId, sprintId) => {
	const res = await getRequest(`/jira/issues/${projectId}/${sprintId}`)
	return res
}

const getProjectEmployees = async (projectId) => {
	const res = await getRequest(`/jira/employees/${projectId}`)
	return res
}

const handleProjectStatus = async (options) => {
	const res = await putRequest(`/v1/project`, options)
	return res
}

const deleteProjects = async (ids) => {
	const res = await deleteRequest(`/v1/project/multiple?ids=${ids}`)
	return res
}

const addProject = async (body) => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await postRequest(`/v1/project`, {
		...body,
		companyDetailsId: companyDetailsId
	})
	return res
}

const updateProject = async (options) => {
	const res = await putRequest(`/v1/project`, options)
	return res
}

export const blukEditProject = async (options) => {
	const res = await putRequest(`/v1/project/client`, options)
	return res
}

const getProject = async (id) => {
	const res = await getRequest(`/v1/project/${parseInt(id)}`)
	return res
}

const updateBulkProject = async (projectIds, clientId) => {
	const res = await putRequest(
		`/v1/project/client?projectIds=${projectIds}&clientId=${clientId}`
	)
	return res
}

export {
	getAllProjects,
	getProjectSprints,
	getProfitLoss,
	getProjectIssues,
	getProjectEmployees,
	handleProjectStatus,
	deleteProjects,
	addProject,
	updateProject,
	getProject,
	updateBulkProject
}
