import { useEffect, useState } from 'react'
import { Button, Checkbox, Dropdown, message } from 'antd'

import TableComponent from '../../shared/TableComponent'
import AvatarGroupComponent from '../../shared/AvatarGroupComponent'
import {
	Add,
	Check,
	Clear,
	Delete,
	DoneAll,
	Edit,
	ExpandMore,
	Launch,
	NorthEastOutlined,
	SouthEastOutlined
} from '@mui/icons-material'
import { getAllProjects, updateProject } from '../../../API/projectsAPI'
import { useNavigate } from 'react-router'
import { Icon } from '@mui/material'
import Status from './ProjectsDetails/Status'
import EditProjectModal from '../../shared/Modals/EditProjectModal'
import dayjs from 'dayjs'
import { handleProjectStatus } from '../../../API/projectsAPI'
import ContextMenu from '../../shared/ContextMenu'
import { getAllMembers } from '../../../API/MembersAPI'
import ButtonComponent from '../../shared/ButtonComponent'
import { getAllClients } from '../../../API/clientAPI'

const AllProjects = ({
	activeKey,
	setDataSelected,
	newProjectAdded = false,
	setNewProjectAdded = { setNewProjectAdded },
	newTaskAdded = { newTaskAdded },
	setNewTaskAdded = { setNewTaskAdded },
	setDeleteProject,
	deleteProject
}) => {
	const navigate = useNavigate()

	const [data, setData] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	const [editProject, setEditProject] = useState(false)
	const [selectedProject, setSelectedProject] = useState()

	const [projectEdited, setProjectEdited] = useState(false)
	const [isAddBtn, setIsAddBtn] = useState(false)

	const [members, setMembers] = useState([])
	const [clients, setClients] = useState([])

	const [selectedMembers, setSelectedmembers] = useState([])
	const [initialMembers, setInitialMembers] = useState([])

	const [selectedClients, setSelectedClients] = useState([])
	const [initialClients, setInitialClients] = useState([])

	const [messageApi, contextHolder] = message.useMessage()

	const getProjects = async () => {
		setIsLoading(true)
		try {
			const res = await getAllProjects()
			const { data = [] } = res
			setData([...data])
			let tempMembers = data?.map((pr) => {
				return {
					projectId: pr.id,
					members:
						pr?.members && pr?.members.length !== 0
							? pr?.members.map((mbr) => {
									return mbr.id
							  })
							: []
				}
			})
			setSelectedmembers([...tempMembers])
			setInitialMembers([...tempMembers])

			let tempClients = data?.map((pr) => ({
				projectId: pr?.id,
				clientId: pr?.clientId || ''
			}))
			setSelectedClients([...tempClients])
			setInitialClients([...tempClients])

			getMembers()
			getClients()
		} catch (error) {
			setIsLoading(false)
			setData([])
		}
	}

	const getClients = async () => {
		try {
			const res = await getAllClients()
			const { data = [] } = res
			let temp =
				data &&
				data?.map((client) => ({
					clientId: client.id,
					clientName: client?.name || '',
					projects:
						client?.projects && client.projects.map((pr) => pr.projectId)
				}))
			setClients([...temp])
		} catch (error) {
			debugger
		}
	}

	const getMembers = async () => {
		try {
			const res = await getAllMembers()
			const { data } = res

			setMembers([...data])
			setIsLoading(false)
			setIsAddBtn(true)
		} catch (error) {
			setIsLoading(false)
		}
	}

	const addMemberToProject = async (projectId) => {
		let members = selectedMembers.find(
			(mbr) => mbr?.projectId === projectId
		).members

		try {
			const res = await updateProject({
				id: projectId,
				members: members.map((mbr) => ({ id: mbr }))
			})
			getProjects()
			messageApi.success('Member updated to project.')
		} catch (error) {
			debugger
			messageApi.error('Unable to update member.')
		}
	}

	const handleStatusChange = async (record, status) => {
		try {
			const options = { id: record.projectId, status: status }
			const res = await handleProjectStatus(options)
		} catch (error) {
			console.log('error from put is ', error)
		}
	}

	const addDaySuffix = (day) => {
		if (day >= 11 && day <= 13) {
			return day + 'th'
		}
		const lastDigit = day % 10
		if (lastDigit === 1) {
			return day + 'st'
		} else if (lastDigit === 2) {
			return day + 'nd'
		} else if (lastDigit === 3) {
			return day + 'rd'
		} else {
			return day + 'th'
		}
	}

	const formatDate = (inputDate) => {
		const date = dayjs(inputDate) // Replace '2023-08-28' with your date

		const formattedDate = date.format('MMMM YYYY') // 'D' for day, 'MMMM' for full month name, 'YYYY' for year

		// Add the 'th' suffix to the day
		const dayWithSuffix = addDaySuffix(date.format('D'))

		return `${dayWithSuffix} ${formattedDate}`
	}

	const dataSource = (Array.isArray(data) ? data : []).map((project, index) => {
		return {
			key: index.toString(),
			projectDetail: {
				projectName: project.name,
				projectAvatarUrl: project.imageUrl
			},
			projectLeadDetail: {
				projectLead: project?.projectLead?.name || project?.leadName || '',
				projectLeadAvatarUrl: project?.projectLead?.imageUrl || '',
				projectLeadEmail:
					project?.projectLead?.email || project?.leadEmail || ''
			},
			profitOrLoss: project.profitOrLoss || 980767,
			client: {
				clientName: project?.clientName,
				clientId: project?.clientId
			},
			//projectProgress: project.projectProgress,
			projectProgress: 58,
			activeEmployees: project?.members || [],
			projectId: project?.id,
			customerId: project?.customerId,
			startDate: project?.startDate,
			status: project?.status,
			dueDate: project?.dueDate,
			projectDescription: project?.description || '',
			members: project?.members || '',
			contactLead: project?.leadEmail || ''
		}
	})

	const statusOptions = [
		{
			value: 'On Hold',
			label: 'On hold'
		},
		{
			value: 'Active',
			label: 'Active'
		},
		{
			value: 'Completed',
			label: 'Completed'
		},
		{
			value: 'Upcoming',
			label: 'Upcoming'
		}
	]

	const getStyles = (value) => {
		switch (value) {
			case 'On Hold':
				return {
					border: '1px solid #C9EBFF',
					backgroundColor: '#E6F7FF',
					color: '#1890FF',
					borderRadius: '2px'
				}
			case 'Active':
				return {
					border: '1px solid #DEC3F7',
					backgroundColor: '#F9F0FF',
					color: '#722ED1',
					borderRadius: '2px'
				}
			case 'Completed':
				return {
					border: '1px solid #FFEBC9',
					backgroundColor: '#FFF7E6',
					color: '#E19A0C',
					borderRadius: '2px'
				}
			case 'Upcoming':
				return {
					border: '1px solid #CFEBBA',
					backgroundColor: '#F6FFED',
					color: '#52C41A',
					borderRadius: '2px'
				}
			default:
				return {}
		}
	}

	const handleMemberSelection = (memberId, projectId) => {
		let temp = [...selectedMembers]
		let members = selectedMembers.find(
			(mbr) => mbr.projectId === projectId
		).members
		if (members.includes(memberId)) {
			let index = members.findIndex((mbr) => mbr === memberId)
			if (index !== -1) {
				members.splice(index, 1)
			}
		} else {
			members.push(memberId)
		}

		let objIndex = temp.findIndex((obj) => obj?.projectId === projectId)
		temp[objIndex].members = [...members]
		setSelectedmembers([...temp])
	}

	const getRemainMembers = (includedMembers, projectId) => {
		const ids = includedMembers.map((member) => member.id)

		let temp = selectedMembers.find((mbr) => mbr.projectId === projectId)

		const remainingMembers = members.filter(
			(member) => !ids.includes(member.id)
		)

		const itemsArr = [...includedMembers, ...remainingMembers].map(
			(member, index) => {
				return {
					key: index,
					label: (
						<div
							className="d-flex align-items-center px-2 py-2"
							onClick={(e) => {
								handleMemberSelection(member?.id, projectId)
								e.stopPropagation()
							}}
						>
							<Checkbox
								checked={temp.members.includes(member.id)}
								value={member?.id}
								className="me-2"
								onChange={(e, value) => {
									handleMemberSelection(e.target.value, projectId)
								}}
							/>
							<AvatarGroupComponent
								name={member?.name || member?.email || ''}
								imageUrl={member?.imageUrl || ''}
								size={'small'}
							/>
							<p className="mb-0 font-roboto fs-12 txt-color-primary ms-2">
								{member?.name || member?.email}
							</p>
						</div>
					)
				}
			}
		)

		itemsArr.push({
			key: [...includedMembers, ...remainingMembers].length,
			label: (
				<div
					className="d-flex align-items-center justify-content-end py-2"
					onClick={(e) => {
						e.stopPropagation()
					}}
				>
					<ButtonComponent
						text=""
						icon={
							<Clear
								sx={{
									fontSize: '1rem',
									margin: '0'
								}}
							/>
						}
						variant="light"
						extraclassName="me-2 px-2 py-1 d-flex align-items-center avatar-dropdown-btn"
						click={() => {
							setSelectedmembers([...initialMembers])
						}}
					/>
					<ButtonComponent
						text=""
						icon={
							<Check
								sx={{
									fontSize: '1rem',
									margin: '0'
								}}
							/>
						}
						variant="dark"
						extraclassName="px-2 py-1 d-flex align-items-center avatar-dropdown-btn"
						click={(e) => {
							addMemberToProject(projectId)
							e.stopPropagation()
						}}
					/>
				</div>
			)
		})

		return itemsArr
	}

	const handleClientSelection = () => {}

	// const getRemainClients = (includedClient) => {
	// 	const projectIds = clients.find((client) => client.clientId === includedClient)

	// 	let temp = selectedMembers.find((mbr) => mbr.projectId === projectId)

	// 	const remainingMembers = members.filter(
	// 		(member) => !ids.includes(member.id)
	// 	)

	// 	const itemsArr = clients.map(
	// 		(member, index) => {
	// 			return {
	// 				key: index,
	// 				label: (
	// 					<div
	// 						className="d-flex align-items-center px-2 py-2"
	// 						onClick={(e) => {
	// 							handleMemberSelection(member?.id, projectId)
	// 							e.stopPropagation()
	// 						}}
	// 					>
	// 						<Checkbox
	// 							checked={temp.members.includes(member.id)}
	// 							value={member?.id}
	// 							className="me-2"
	// 							onChange={(e, value) => {
	// 								handleMemberSelection(e.target.value, projectId)
	// 							}}
	// 						/>
	// 						<AvatarGroupComponent
	// 							name={member?.name || member?.email || ''}
	// 							imageUrl={member?.imageUrl || ''}
	// 							size={'small'}
	// 						/>
	// 						<p className="mb-0 font-roboto fs-12 txt-color-primary ms-2">
	// 							{member?.name || member?.email}
	// 						</p>
	// 					</div>
	// 				)
	// 			}
	// 		}
	// 	)

	// 	itemsArr.push({
	// 		key: clients.length,
	// 		label: (
	// 			<div
	// 				className="d-flex align-items-center justify-content-end py-2"
	// 				onClick={(e) => {
	// 					e.stopPropagation()
	// 				}}
	// 			>
	// 				<ButtonComponent
	// 					text=""
	// 					icon={
	// 						<Clear
	// 							sx={{
	// 								fontSize: '1rem',
	// 								margin: '0'
	// 							}}
	// 						/>
	// 					}
	// 					variant="light"
	// 					extraclassName="me-2 px-2 py-1 d-flex align-items-center avatar-dropdown-btn"
	// 					click={() => {
	// 						setSelectedmembers([...initialMembers])
	// 					}}
	// 				/>
	// 				<ButtonComponent
	// 					text=""
	// 					icon={
	// 						<Check
	// 							sx={{
	// 								fontSize: '1rem',
	// 								margin: '0'
	// 							}}
	// 						/>
	// 					}
	// 					variant="dark"
	// 					extraclassName="px-2 py-1 d-flex align-items-center avatar-dropdown-btn"
	// 					click={(e) => {
	// 						addMemberToProject(projectId)
	// 						e.stopPropagation()
	// 					}}
	// 				/>
	// 			</div>
	// 		)
	// 	})

	// 	return itemsArr
	// }

	useEffect(() => {
		if (activeKey === '1') {
			getProjects()
		}
	}, [activeKey])

	useEffect(() => {
		// currently leave this condition to renrender on each state uptaded
		// only calling one extra time project api
		if (newProjectAdded) {
			setNewProjectAdded(false)
		}
		if (projectEdited) {
			setProjectEdited(false)
		}
		if (newTaskAdded) {
			setNewTaskAdded(false)
		}
		getProjects()
	}, [newProjectAdded, projectEdited, newTaskAdded, deleteProject])

	const columns = [
		{
			title: 'Project name',
			dataIndex: 'projectDetail',
			key: 'projectDetail',
			render: (text, record) => (
				<div className="d-flex justify-content-start align-items-center">
					<span className="fw-bold font-roboto">
						<AvatarGroupComponent
							size={'large'}
							imageUrl={record?.projectDetail?.projectAvatarUrl}
							name={record?.projectDetail?.projectName}
						/>{' '}
					</span>
					<div className="d-flex flex-column ms-2">
						<span className="fs-12 font-roboto fw-semibold projectData text-capitalize">
							{record.projectDetail.projectName}
						</span>
						<span className="fs-11 font-roboto fw-normal projectData">
							Start Date : {formatDate(record?.startDate)}
						</span>
					</div>
				</div>
			)
		},
		{
			title: 'Project Lead',
			dataIndex: 'projectLeadDetail',
			key: 'projectLeadDetail',
			render: (projectLeadDetail) => {
				if (
					projectLeadDetail?.projectLead !== '' ||
					projectLeadDetail?.projectLeadAvatarUrl !== ''
				) {
					return (
						<div className="d-flex align-items-center">
							<AvatarGroupComponent
								size={'large'}
								imageUrl={projectLeadDetail.projectLeadAvatarUrl}
								name={projectLeadDetail?.projectLead}
							/>{' '}
							<span className="ms-2 font-roboto fs-12 txt-color-primary">
								{projectLeadDetail.projectLead}
							</span>
						</div>
					)
				} else {
					return (
						<span className="fw-semibold font-roboto fs-12 ms-2 projectData">
							{projectLeadDetail?.projectLeadEmail?.split('@')?.[0] || '----'}
						</span>
					)
				}
			}
		},
		{
			title: 'Client Name',
			dataIndex: 'client',
			key: 'client',
			render: (text, record) => {
				// 	if (record?.client?.clientName && record?.client?.clientName !== '') {
				// 		const items = []
				// 		return (
				// 			<Dropdown
				// 					trigger={['hover']}
				// 					menu={{ items }}
				// 					placement="bottom"
				// 					overlayStyle={{
				// 						maxHeight: '250px',
				// 						overflow: 'auto'
				// 					}}
				// 					className="avatar-add-dropdown"
				// 					overlayClassName="avatar-add-dropdown-overlay"
				// 					onOpenChange={(open) => {
				// 						// if (!open) {
				// 						// 	cancelFunc()
				// 						// }
				// 					}}
				// 			>
				// 				<div className='d-flex align-items-center'>

				// 					<span className='font-roboto fw-semibold fs-12 mb-0' style={{ color: " #3571A7" }}>{record?.client?.clientName}</span>
				// 					<ExpandMore
				// 							sx={{
				// 								fontSize: '1rem',
				// 							color: '#3571A7',
				// 								marginLeft: '7px'
				// 							}}
				// 						/>
				// 					</div>
				// 			</Dropdown>

				// )
				// 	} else {
				// 		const items = []

				// 		return (
				// 			<Dropdown
				// 					trigger={['hover']}
				// 					menu={{ items }}
				// 					placement="bottom"
				// 					overlayStyle={{
				// 						maxHeight: '250px',
				// 						overflow: 'auto'
				// 					}}
				// 					className="avatar-add-dropdown"
				// 					overlayClassName="avatar-add-dropdown-overlay"
				// 					onOpenChange={(open) => {
				// 						// if (!open) {
				// 						// 	cancelFunc()
				// 						// }
				// 					}}
				// 			>
				// 				<div className='d-flex align-items-center'>
				// 					<Add
				// 							sx={{
				// 								fontSize: '1rem',
				// 							color: '#3571A7',
				// 								marginRight: '7px'
				// 							}}
				// 						/>
				// 					<span className='font-roboto fw-semibold fs-12 mb-0' style={{color:" #3571A7"}}>Add a client</span>
				// 				</div>
				// 				</Dropdown>
				// 		)
				// 	}
				return (
					<span className="fw-semibold font-roboto fs-12 projectData">
						{record?.client?.clientName ?? '---'}
					</span>
				)
			}
		},
		{
			title: 'Due Date',
			dataIndex: 'dueDate',
			sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
			render: (dueDate) => (
				<span className="fw-semibold font-roboto fs-12 projectData">
					{formatDate(dueDate)}
				</span>
			),
			sorter: (a, b) => {
				return new Date(a.dueDate) - new Date(b.dueDate)
			}
		},
		{
			title: 'Members',
			dataIndex: 'activeEmployees',
			key: 'activeEmployees',
			render: (text, record) => {
				const { activeEmployees, projectId } = record
				if (activeEmployees && activeEmployees.length > 0) {
					const items = getRemainMembers(activeEmployees, projectId)
					return (
						<AvatarGroupComponent
							data={[...activeEmployees]}
							isAddBtn={isAddBtn || members?.length !== 0 || items.length !== 0}
							items={items}
							cancelFunc={() => {
								setSelectedmembers([...initialMembers])
							}}
						/>
					)
				} else {
					return (
						<span className="fw-semibold font-roboto fs-12 projectData ms-2">
							----
						</span>
					)
				}
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			render: (text, record) => {
				return (
					<div onClick={(e) => e.stopPropagation()}>
						<Status
							value={record.status}
							statusOptions={statusOptions}
							select={(newStatus) => {
								handleStatusChange(record, newStatus)
							}}
							getStyles={(status) => getStyles(status)}
						/>
					</div>
				)
			}
		},
		{
			title: ' ',
			dataIndex: 'contactLead',
			key: 'contactLead',
			render: (contactLead) => {
				if (contactLead && contactLead !== '') {
					return (
						<Button
							href={`mailto:${contactLead}`}
							className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none border-0 bg-transparent p-0 d-flex align-items-center gap-2"
							onClick={(e) => {
								e.stopPropagation()
							}}
							style={{
								boxShadow: 'none',
								height: '100%'
							}}
						>
							<span className="font-roboto">Contact Project Lead </span>
							<Launch
								sx={{
									fontSize: 'inherit',
									color: 'inherit'
								}}
							/>
						</Button>
					)
				} else {
					return <span className="fs-12 fw-500 font-roboto">----</span>
				}
			}
		},
		{
			title: ' ',
			render: (record) => {
				const options = [
					{
						key: '1',
						label: (
							<div
								className=""
								onClick={() => {
									setSelectedProject(record)
									setDataSelected([record])
									setDeleteProject(true)
								}}
							>
								<div className="context-menu-option">
									<div className="d-flex align-items-center">
										<Delete className="context-menu-icon" />

										<span className="fs-14 fw-semibold txt-color-primary font-roboto">
											Delete
										</span>
									</div>
								</div>
							</div>
						)
					},
					{
						key: '2',
						label: (
							<div
								className=""
								onClick={() => {
									setSelectedProject(record)
									//setDataSelected([record])
									setEditProject(true)
								}}
							>
								<div className="context-menu-option">
									<div className="d-flex align-items-center">
										<Edit className="context-menu-icon" />

										<span className="fs-14 fw-semibold txt-color-primary font-roboto">
											Edit
										</span>
									</div>
								</div>
							</div>
						)
					}
				]

				return (
					<div
						onClick={(e) => {
							e.stopPropagation()
						}}
						style={{ width: '25px', height: '25px', borderRadius: '50%' }}
						className="d-flex justify-content-center align-items-center"
					>
						<ContextMenu items={options} />
					</div>
				)
			}
		}
	]

	const onChange = (pagination, filters, sorter, extra) => {}

	return (
		<>
			{contextHolder}
			<div className="container-fluid font-roboto px-0">
				<TableComponent
					dataSource={dataSource}
					columns={columns}
					onChange={onChange}
					onRow={(record) => {
						return {
							onClick: (e) => {
								navigate(`/dashboard/projects/${record?.projectId}`)
							}
						}
					}}
					loading={isLoading}
					setDataSelected={setDataSelected}
				/>
			</div>
			{editProject && (
				<EditProjectModal
					open={editProject}
					setOpen={setEditProject}
					onClose={() => setEditProject(false)}
					selectedProject={selectedProject}
					setProjectEdited={setProjectEdited}
					dataSource={dataSource}
				/>
			)}
		</>
	)
}

export default AllProjects
