import { Select } from 'antd'
import { useState } from 'react'
import './priority.css'
import { ExpandMore } from '@mui/icons-material'

const Priority = ({ value, issueId, priorityOptions, select, ...rest }) => {
	const [priority, setPriority] = useState(value)

	const getStyles = (value) => {
		switch (value) {
			case 'Low':
				return {
					border: '1px solid #D9D9D9',
					backgroundColor: '#FAFAFA',
					color: '#555555'
				}
			case 'Medium':
				return {
					border: '1px solid #C9DAFF',
					backgroundColor: '#F0F5FF',
					color: '#2F54EB'
				}
			case 'High':
				return {
					border: '1px solid #FFCAE2',
					backgroundColor: '#FFF0F6',
					color: '#EB2F96'
				}
			case 'Urgent':
				return {
					border: '1px solid #FFCCC9',
					backgroundColor: '#FFF1F0',
					color: '#F5222D'
				}
			default:
				return {
					border: '1px solid #d9d9d9',
					backgroundColor: '#fff',
					color: '#F5222D'
				}
		}
	}

	// }, [])

	return (
		<Select
			defaultValue={value}
			value={value}
			onChange={(newValue) => {
				setPriority(newValue)
				select(newValue)
			}}
			options={priorityOptions}
			style={{
				...getStyles(priority)
			}}
			placeholder="Select Priority"
			suffixIcon={
				<ExpandMore
					sx={{ color: getStyles(priority).color, fontSize: '1rem' }}
				/>
			}
			className="priority-select"
			popupClassName="generic-select-dropdown"
			{...rest}
		/>
	)
}

export default Priority
