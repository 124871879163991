import axios from 'axios'
import Cookies from 'js-cookie'

let instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	timeout: 100000
})

instance.interceptors.request.use((config) => {
	let accessToken = Cookies.get('pms-access-token') ?? ''
	let refreshToken = Cookies.get('pms-refresh-token') ?? ''

	if (
		accessToken &&
		refreshToken &&
		accessToken !== '' &&
		refreshToken !== ''
	) {
		if (config?.url.includes('refresh-token')) {
			config.headers.Authorization = `Bearer ${refreshToken}`
		} else {
			// woirkign on it disabling bearer tokken for paticular urls
			if (
				!config?.url.includes('signup') &&
				!config?.url.includes('search') &&
				!config?.url.includes('signin')
			) {
				config.headers.Authorization = `Bearer ${accessToken}`
			}
		}
	}

	return config
})

const getRequest = (API, body) =>
	instance.get(API, {
		data: body
	})

const postRequest = (API, body) => instance.post(API, body)

const putRequest = (API, body) => instance.put(API, body)

const deleteRequest = (API, body) => instance.delete(API, body)

export { getRequest, postRequest, instance, putRequest, deleteRequest }
