import axios from 'axios'
import { getRequest, putRequest, postRequest, deleteRequest } from '.'
import Cookies from 'js-cookie'

const updatePassword = async (body) => {
	const res = await postRequest('/central-auth/auth/update-password', body)
	return res
}

const resetPassword = async (body) => {
	const res = await postRequest('/central-auth/auth/reset-password')
	return res
}

const sendOTP = async (body) => {
	const res = await postRequest('/central-auth/verify/email/send-otp', body)
	return res
}

const verifyOTP = async (body) => {
	const res = await postRequest('/central-auth/verify/email/verify-otp', body)
	return res
}

const getUser = async () => {
	const email = Cookies.get('user-email') ?? ''

	const res = await getRequest(`/v1/user?userEmail=${email}`)
	return res
}
// changed beacuse need to handle if some one not registered with organization id
export const getPaticularUserData = async () => {
	const email = Cookies.get('user-email') ?? ''

	const res = await getRequest(`/v1/user/search?email=${email}`)

	if (!res?.data?.companyDetailsId) {
		return null
	} else {
		Cookies.set('companyDetailsId', res?.data?.companyDetailsId, {
			expires: 12 / 24
		})
		let companyDetails = JSON.stringify(res?.data || {})
		Cookies.set('companyDetails', companyDetails, {
			expires: 12 / 24
		})

		return res
	}
}

export const updateUserData = async (options) => {
	const res = await putRequest(`/v1/user`, options)
	return res
}

const getIntegrations = async () => {
	const res = await getRequest('/users/integrations')
	return res
}

const sendCompanyDetails = async (body) => {
	const res = await postRequest('/v1/company-details', body)
	return res
}

export {
	updatePassword,
	resetPassword,
	sendOTP,
	verifyOTP,
	getUser,
	getIntegrations,
	sendCompanyDetails
}
