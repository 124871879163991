import React, { useState, useEffect } from 'react'
import { Tabs, message } from 'antd'
import { useNavigate } from 'react-router'
import { AddCircleOutlineOutlined } from '@mui/icons-material'

import searchIcon from '../assets/images/search-icon.svg'
import filterIcon from '../assets/images/filter-icon.svg'
import reminderIcon from '../assets/images/reminder-icon.svg'
import deleteIcon from '../assets/images/delete-icon.svg'
import ProjectsFilter from '../components/shared/Drawers/ProjectsFilter'
import ButtonComponent from '../components/shared/ButtonComponent'
import DeleteProjectModal from '../components/shared/Modals/DeleteProjectModal'
import DeleteTaskModal from '../components/shared/Modals/DeleteTaskModal'
import DeleteMemberModal from '../components/shared/Modals/DeleteMemberModal'
import MyClients from '../components/views/ManageClients/MyClients'
import ClientForm from '../components/views/Forms/Client/ClientForm'

const ManageClients = () => {
	const [messageApi, contextHolder] = message.useMessage()
	const [activeKey, setActiveKey] = useState('1')

	const [dataSelected, setDataSelected] = useState([])

	const [deleteProject, setDeleteProject] = useState(false)
	const [deleteTask, setDeleteTask] = useState(false)
	const [deleteMember, setDeleteMember] = useState(false)

	const [applyFilter, setApplyFilter] = useState(false)

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalProps, setModalProps] = useState({})

	const [isUpdate, setIsUpdate] = useState(false)

	const [disableCta, setDisableCta] = useState(false)

	const deletion = () => {
		switch (activeKey) {
			case '1':
				setDeleteProject(true)
				break
			case '2':
				setDeleteTask(true)
				break
			case '3':
				setDeleteMember(true)
				break
			default:
				break
		}
	}

	const filterApplied = () => {
		setApplyFilter(true)
	}

	const items = [
		{
			key: '1',
			label: 'My Clients',
			children: (
				<MyClients
					setIsModalOpen={setIsModalOpen}
					isModalOpen={isModalOpen}
					setDisableCta={setDisableCta}
				/>
			)
		}
	]

	const getModalProps = (key) => {
		switch (key) {
			case '1':
				setModalProps({
					title: isUpdate ? 'Update Client' : 'Create a Client',
					children: (
						<ClientForm
							isUpdate={isUpdate}
							setIsModalOpen={setIsModalOpen}
							updateData={isModalOpen}
						/>
					)
				})
				break

			default:
				return false
		}
	}

	useEffect(() => {
		getModalProps(activeKey)
	}, [activeKey])

	return (
		<>
			{contextHolder}
			<div className="container-fluid p-4">
				<div className="d-flex justify-content-between align-items-center mb-4">
					<h1 className="fs-3 dashboard-primary-header">Clients</h1>
					<ButtonComponent
						variant="dark"
						disabled={disableCta}
						text={`Create a ${
							activeKey === '1'
								? 'client'
								: activeKey === '2'
								? 'task'
								: 'member'
						}`}
						icon={
							<AddCircleOutlineOutlined
								sx={{
									fontSize: '1.25rem'
								}}
							/>
						}
						click={() => {
							setIsModalOpen(true)
						}}
					/>
				</div>
				<div>
					<div
						style={{
							marginBottom: '-35px',
							float: 'right',
							zIndex: '1',
							position: 'relative'
						}}
					>
						{dataSelected.length === 0 ? (
							<>
								{/* <button className="ms-4 operations-on-table">
									<img src={searchIcon} alt="search-icon" className="px-1" />
									<span className="ms-1 fs-14 font-roboto">Search</span>
								</button>
								<button
									className="ms-4 operations-on-table"
									onClick={filterApplied}
								>
									<img src={filterIcon} alt="filter-icon" className="px-1" />
									<span className="ms-1 fs-14 font-roboto">Filter</span>
								</button> */}
							</>
						) : (
							<>
								<button className="ms-4 operations-on-table">
									<img src={reminderIcon} alt="search-icon" className="px-1" />
									<span className="ms-1 fs-14 font-roboto">Add Reminder</span>
								</button>
								<button className="ms-4 operations-on-table" onClick={deletion}>
									<img src={deleteIcon} alt="filter-icon" className="px-1" />
									<span className="ms-1 fs-14 font-roboto">Delete</span>
								</button>
							</>
						)}
					</div>
					<Tabs
						defaultActiveKey={activeKey}
						items={items}
						className="dashboard-tabs"
						onChange={(key) => {
							setActiveKey(key)
						}}
					/>
					{deleteProject && (
						<DeleteProjectModal
							isModalOpen={deleteProject}
							setIsModalOpen={setDeleteProject}
							dataSelected={dataSelected}
						/>
					)}
					{deleteTask && (
						<DeleteTaskModal
							isModalOpen={deleteTask}
							setIsModalOpen={setDeleteTask}
							dataSelected={dataSelected}
						/>
					)}
					{deleteMember && (
						<DeleteMemberModal
							isModalOpen={deleteMember}
							setIsModalOpen={setDeleteMember}
							dataSelected={dataSelected}
						/>
					)}
					{applyFilter && (
						<ProjectsFilter
							applyFilter={applyFilter}
							setApplyFilter={setApplyFilter}
						/>
					)}
				</div>
			</div>
		</>
	)
}

export default ManageClients
