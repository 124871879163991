import Cookies from 'js-cookie'
import { deleteRequest, getRequest, postRequest, putRequest } from '.'

const createSprint = async (body) => {
	const res = await postRequest('/v1/sprint', body)
	return res
}

const updateSprint = async (body) => {
	const res = await putRequest('/v1/sprint', body)
	return res
}

const getSprints = async (projectId = '', sprint = '') => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await getRequest(`/v1/sprint?projectId=${projectId}`)
	return res
}

const deleteSprint = async (ids) => {
	const res = await deleteRequest(`/v1/sprint/multiple?ids=${ids}`)
	return res
}

export { createSprint, deleteSprint, getSprints, updateSprint }
