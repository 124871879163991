import React from 'react'
import ProjectCardComponent from '../views/Projects/ProjectCardComponent'

const ProjectEventModal = ({
	modalPosition,
	selectedEvent,
	cardRef,
	cardActionRef
}) => {
	return (
		<div
			className="custom-modal"
			style={{
				top: `${modalPosition.top}px`,
				left: `${modalPosition.left}px`
			}}
			ref={cardRef}
		>
			<ProjectCardComponent
				project={selectedEvent}
				cardActionRef={cardActionRef}
			/>
		</div>
	)
}

export default ProjectEventModal
