// import { Dropdown } from 'antd'
// import { ExpandMoreRounded } from '@mui/icons-material'

// const items = [
// 	{
// 		label: <a href="https://www.antgroup.com">1st menu item</a>,
// 		key: '0'
// 	},
// 	{
// 		label: <a href="https://www.aliyun.com">2nd menu item</a>,
// 		key: '1'
// 	},
// 	{
// 		type: 'divider'
// 	},
// 	{
// 		label: '3rd menu item',
// 		key: '3'
// 	}
// ]

// const DropdownFilter = ({ title, extraclass = '' }) => {
// 	return (
// 		<Dropdown
// 			menu={{ items }}
// 			trigger={['click']}
// 			className={`d-flex justify-content-center rounded-1 ms-3 ${extraclass}`}
// 		>
// 			<a
// 				href="void"
// 				onClick={(e) => e.preventDefault()}
// 				className="filter-dropdown text-decoration-none align-items-center fs-12"
// 			>
// 				<span className="dropdownTitle fw-semibold p-auto">{title}</span>
// 				<ExpandMoreRounded
// 					color="red"
// 					sx={{
// 						color: '#4796df'
// 					}}
// 				/>
// 			</a>
// 		</Dropdown>
// 	)
// }

// export default DropdownFilter

import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Menu } from 'antd'
import { ExpandMoreRounded } from '@mui/icons-material'

const DropdownFilter = ({
	title,
	extraclass,
	menuItems = [],
	onSelect,
	iconColor = '#4796df',
	icon = <ExpandMoreRounded sx={{ color: `${iconColor}` }} />
}) => {
	const handleMenuClick = ({ key }) => {
		if (onSelect) {
			menuItems?.map((item) => {
				if (item.key === key) {
					onSelect(item.label)
				}
			})
		}
	}

	return (
		<Dropdown
			menu={{ items: menuItems, onClick: handleMenuClick }}
			trigger={['click']}
			className={`d-flex justify-content-center rounded-1 ${extraclass}`}
			overlayClassName="dropdown-overlay"
		>
			<span className="filter-dropdown text-decoration-none align-items-center fs-12">
				{title}
				{icon}
			</span>
		</Dropdown>
	)
}

DropdownFilter.defaultProps = {
	extraclass: ''
}

DropdownFilter.propTypes = {
	title: PropTypes.string.isRequired,
	extraclass: PropTypes.string,
	menuItems: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			key: PropTypes.string.isRequired
		})
	),
	onSelect: PropTypes.func
}

export default DropdownFilter
