import {
	AddCircleOutline,
	ChevronLeftOutlined,
	Delete,
	Edit,
	ExpandMore
} from '@mui/icons-material'
import useMessage from 'antd/es/message/useMessage'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import AvatarGroupComponent from '../components/shared/AvatarGroupComponent'
import Status from '../components/views/Projects/ProjectsDetails/Status'
import searchIcon from '../assets/images/search-icon.svg'
import filterIcon from '../assets/images/filter-icon.svg'
import deleteFilledIcon from '../assets/images/delete-filled-icon.svg'
import editIcon from '../assets/images/edit-icon.svg'
import { Tabs, Spin, Skeleton, message } from 'antd'
import { deleteMultipleClient, getClient, updateClient } from '../API/clientAPI'
import DeleteClient from '../components/shared/Modals/DeleteClient'
import ClientProjects from '../components/views/ManageClients/ClientProjects'
import ContextMenu from '../components/shared/ContextMenu'
import dropdownArrow from '../assets/images/dropdown-arrow.svg'
import addCircleBlue from '../assets/images/add-circle-blue.svg'
import personIcon from '../assets/images/person-icon.svg'
import { IconButton } from '@mui/material'
import dayjs from 'dayjs'
import ClientTasks from '../components/views/ManageClients/ClientTasks'
import DropdownFilter from '../components/shared/DropdownFilter'
import AddClientProject from '../components/shared/Modals/ClientActionsModals/AddProject'
import AddClientTask from '../components/shared/Modals/ClientActionsModals/AddTask'

const ClientDetails = () => {
	const [messageApi, contextHolder] = useMessage()
	const navigate = useNavigate()

	const params = useParams()
	const { clientId } = params

	const [clientData, setClientData] = useState({})
	const [clientStatus, setClientStatus] = useState()
	const [isLoading, setIsLoading] = useState(true)

	const [deleteClient, setDeleteClient] = useState(false)

	const [clientDeleted, setClientDeleted] = useState(false)
	const [dataSelected, setDataSelected] = useState([])

	const [addProject, setAddProject] = useState(false)
	const [addTask, setAddTask] = useState(false)

	const items = [
		{
			key: '1',
			label: 'Projects',
			children: <ClientProjects clientId={clientId} addProject={addProject} />
		},
		{
			key: '2',
			label: 'Tasks',
			children: <ClientTasks clientId={clientId} addTask={addTask} />
		}
	]

	const statusOptions = [
		{
			value: 'Active',
			label: 'Active'
		},
		{
			value: 'Inactive',
			label: 'Inactive'
		}
	]

	const options = [
		{
			key: '1',
			label: (
				<div
					className="context-menu-option"
					onClick={() => {
						setDataSelected([{ clientId: clientId }])
						setDeleteClient(true)
					}}
				>
					<div className="d-flex align-items-center">
						<Delete className="context-menu-icon" />

						<span className="fs-14 fw-semibold txt-color-primary font-roboto">
							Delete
						</span>
					</div>
				</div>
			)
		}
		// {
		// 	key: '2',
		// 	label: (
		// 		// <div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 		// 	<img src={editIcon} alt="icon" />
		// 		// 	<span className="font-roboto fs-12 fw-semibold ms-2">Edit</span>
		// 		// </div>
		// 		<div
		// 			className="context-menu-option"
		// 			//onClick={() => setEditProject(true)}
		// 		>
		// 			<div className="d-flex align-items-center">
		// 				<Edit className="context-menu-icon" />

		// 				<span className="fs-14 fw-semibold txt-color-primary font-roboto">
		// 					Edit
		// 				</span>
		// 			</div>
		// 		</div>
		// 	)
		// }
		////// ToDo:- Edit Client
		// {
		// 	key: '2',
		// 	label: (
		// 		<div className="context-menu-option">
		// 			<div className="d-flex align-items-center">
		// 				<Edit className="context-menu-icon" />

		// 				<span className="fs-10 fw-semibold txt-color-primary font-roboto">
		// 					Edit
		// 				</span>
		// 			</div>
		// 		</div>
		// 	)
		// }
	]

	const clientOperations = [
		{
			key: '1',
			label: (
				<div className="p-2" onClick={() => setAddProject(true)}>
					<AddCircleOutline />
					<span className="font-roboto fs-12 fw-semibold ms-2">
						Add Project
					</span>
				</div>
			)
		},
		{
			key: '2',
			label: (
				<div className="p-2" onClick={() => setAddTask(true)}>
					<AddCircleOutline />
					<span className="font-roboto fs-12 fw-semibold ms-2">Add Task</span>
				</div>
			)
		}
		// {
		// 	key: '2',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<AddCircleOutline />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">Add Member</span>
		// 		</div>
		// 	)
		// },
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<AddCircleOutline />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">
		// 				Add Invoice
		// 			</span>
		// 		</div>
		// 	)
		// },
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<img src={recieptIcon} alt="icon" />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">
		// 				Add Proposal
		// 			</span>
		// 		</div>
		// 	)
		// },
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<img src={callLogIcon} alt="icon" />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">
		// 				Add Call Logs
		// 			</span>
		// 		</div>
		// 	)
		// },
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<img src={pinIcon} alt="icon" />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">Add Files</span>
		// 		</div>
		// 	)
		// },
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<img src={bellIcon} alt="icon" />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">
		// 				Add Reminder
		// 			</span>
		// 		</div>
		// 	)
		// }
	]

	const getStyles = (value) => {
		switch (value) {
			case 'Active':
				return {
					border: '1px solid #C9EBFF',
					backgroundColor: '#E6F7FF',
					color: '#1890FF',
					borderRadius: '2px'
				}
			case 'Inactive':
				return {
					border: '1px solid #FFCAE2',
					backgroundColor: '#FFF0F6',
					color: '#EB2F96',
					borderRadius: '2px'
				}
			default:
				return {}
		}
	}

	const getClientData = async () => {
		setIsLoading(true)
		try {
			const res = await getClient(clientId)
			const { data = {} } = res
			setClientData(data)
			setClientStatus(data?.status || 'Inactive')
			setIsLoading(false)
		} catch (error) {
			debugger
			messageApi.error('Unable to fetch client details.')
			setClientData([])
			setIsLoading(false)
		}
	}

	const handleStatusChange = async (status) => {
		try {
			const options = {
				id: clientData?.id,
				status: status,
				email: clientData?.email
			}
			const res = await updateClient(options)
		} catch (error) {
			console.log('error from put is ', error)
		}
		//console.log("New Status is ",status)
	}

	const deleteSelectedClients = async () => {
		const clientIds = dataSelected?.map((client) => {
			return client?.clientId
		})

		try {
			const res = await deleteMultipleClient(clientIds)
			setDataSelected([])
			messageApi.success('Client deleted succcessfully.')
			navigate('/dashboard/manage-clients')
		} catch (error) {
			messageApi.error('Unable to delete client.')
		}
	}

	useEffect(() => {
		getClientData()
	}, [])

	const formatPhoneNumber = (phoneNumber = '') => {
		// Format the phone number
		return `(${phoneNumber?.substring(0, 3)}) ${phoneNumber?.substring(
			3,
			6
		)}-${phoneNumber?.substring(6, 10)}`
	}

	let clientProjects = clientData?.projects
		?.filter((pr) => !pr?.project?.deletedAt)
		.map((pr1) => ({
			imageUrl: pr1?.project?.imageUrl,
			name: pr1?.project?.name
		}))

	return (
		<>
			{contextHolder}
			<div className="container-fluid p-4">
				<div className="d-flex justify-content-between row">
					<div className="col-12 col-md-5">
						<div className="d-flex align-items-start mb-2">
							<IconButton
								className="me-2"
								onClick={() => {
									navigate('/dashboard/manage-clients')
								}}
							>
								<ChevronLeftOutlined
									sx={{
										color: '#323232',
										fontSize: '1.5rem'
									}}
								/>
							</IconButton>
							<div>
								<h1 className="fs-3 mb-0 dashboard-primary-header d-flex mt-1">
									{isLoading ? (
										<Skeleton.Button
											className=""
											style={{
												width: '10rem',
												height: '2rem'
											}}
										/>
									) : (
										clientData?.name || ''
									)}
								</h1>
								<p className="font-roboto fs-12 txt-color-primary mb-0">
									{clientData?.description || ''}
								</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-7">
						<div className="d-flex align-items-center w-100 justify-content-end h-100">
							{/* <Link className="font-roboto txt-color-blue-1 fs-14 text-decoration-none fw-500">
								Manage Issue
							</Link> */}
							{/* <ButtonComponent
								text={
									<div className="d-flex justify-content-center align-items-center">
										<span className="font-roboto me-2">Add</span>
										<DropdownFilter
											title=""
											menuItems={clientOperations}
											onSelect={() => {
												console.log('option selected')
											}}
											icon={<img src={dropdownArrow} alt="icon" />}
											className="ms-2"
										/>
									</div>
								}
								variant="light"
								icon={<img src={addCircleBlue} alt="blue-add-circle" />}
							/> */}
							{/* <div
								style={{ width: '25px', height: '25px', borderRadius: '50%' }}
								className="d-flex justify-content-center align-items-center ms-3"
							>
								
								<ContextMenu items={options} />
							</div> */}
							<div className="d-flex justify-content-center align-items-center gap-2">
								<DropdownFilter
									title={
										<span
											className="fs-14 font-roboto fw-semibold d-flex align-items-center gap-2"
											style={{
												color: '#204464'
											}}
										>
											<AddCircleOutline
												sx={{
													color: '#204464',
													fontSize: '1.25rem'
												}}
											/>{' '}
											Add
										</span>
									}
									menuItems={clientOperations}
									icon={
										<ExpandMore
											sx={{
												color: '#204464',
												fontSize: '1.25rem',
												marginLeft: '8px'
											}}
										/>
									}
									extraclass="add-operation"
								></DropdownFilter>
								<ContextMenu items={options} />
							</div>
						</div>
					</div>
				</div>
				<div className="bg-white px-4 py-3 mb-4 mt-4">
					<div className="row p-0 m-0">
						<div className="row d-flex align-items-center">
							<div className="col-12 col-md-2">
								<h6 className="font-roboto txt-color-secondary fs-12">
									Start date
								</h6>
								<p className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
									{isLoading ? (
										<Skeleton.Button
											className=""
											style={{
												width: '2rem',
												height: '1rem'
											}}
										/>
									) : clientData?.startDate && clientData?.startDate !== '' ? (
										dayjs(clientData?.startDate).format('Do MMMM, YYYY')
									) : (
										'----'
									)}
								</p>
							</div>

							<div className="col-12 col-md-2">
								<h6 className="font-roboto txt-color-secondary fs-12">
									Contact Person
								</h6>
								<p className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
									{isLoading ? (
										<Skeleton.Button
											className=""
											style={{
												width: '2rem',
												height: '1rem'
											}}
										/>
									) : (
										clientData?.email || '----'
									)}
								</p>
							</div>
							<div className="col-12 col-md-2">
								<h6 className="font-roboto txt-color-secondary fs-12">
									Phone Number
								</h6>
								<p className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
									{isLoading ? (
										<Skeleton.Button
											className=""
											style={{
												width: '2rem',
												height: '1rem'
											}}
										/>
									) : clientData?.phoneNumber &&
									  clientData?.phoneNumber !== '' ? (
										`+${clientData?.phoneNumber}`
									) : (
										'----'
									)}
								</p>
							</div>
							<div className="col-12 col-md-2">
								<h6 className="font-roboto txt-color-secondary fs-12">
									Industry
								</h6>
								<p className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
									{isLoading ? (
										<Skeleton.Button
											className=""
											style={{
												width: '2rem',
												height: '1rem'
											}}
										/>
									) : (
										clientData?.industry || '----'
									)}
								</p>
							</div>
							<div className="col-12 col-md-2">
								<h6 className="font-roboto txt-color-secondary fs-12">
									Projects
								</h6>

								{isLoading ? (
									<Skeleton.Button
										className=""
										style={{
											width: '2rem',
											height: '1rem'
										}}
									/>
								) : // clientData?.projects && (
								// 	<AvatarGroupComponent
								// 		data={clientData?.projects
								// 			?.filter((pr) => !pr?.project?.deletedAt)
								// 			.map((pr1) => ({
								// 				imageUrl: pr1?.project?.imageUrl,
								// 				name: pr1?.project?.name
								// 			}))}
								// 	/>
								// )
								clientProjects.length > 0 ? (
									<AvatarGroupComponent data={clientProjects} />
								) : (
									<span>---</span>
								)}
							</div>
							<div className="col-12 col-md-2">
								<h6 className="font-roboto txt-color-secondary fs-12">
									Status
								</h6>
								{isLoading ? (
									<Skeleton.Button
										className=""
										style={{
											width: '2rem',
											height: '1rem'
										}}
									/>
								) : (
									<div onClick={(e) => e.stopPropagation()}>
										<Status
											value={clientStatus}
											statusOptions={statusOptions}
											select={(newStatus) => {
												setClientStatus(newStatus)
												handleStatusChange(newStatus)
											}}
											getStyles={getStyles}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div
					style={{
						marginBottom: '-35px',
						float: 'right',
						zIndex: '1',
						position: 'relative'
					}}
				>
					{/* <button className="ms-4 operations-on-table">
						<img src={searchIcon} alt="search-icon" className="px-1" />
						<span className="ms-1 fs-14 font-roboto">Search</span>
					</button>
					<button className="ms-4 operations-on-table">
						<img src={filterIcon} alt="filter-icon" className="px-1" />
						<span className="ms-1 fs-14 font-roboto">Filter</span>
					</button> */}
				</div>
				<Tabs defaultActiveKey={'1'} items={items} className="dashboard-tabs" />
				{deleteClient && (
					<DeleteClient
						isModalOpen={deleteClient}
						setIsModalOpen={setDeleteClient}
						dataSelected={dataSelected}
						setClientDeleted={setClientDeleted}
						deletClients={deleteSelectedClients}
					/>
				)}
				<AddClientProject
					onClose={() => setAddProject(false)}
					open={addProject}
					getData={getClientData}
					clientId={clientData?.id}
					clientProjects={
						clientData?.projects && clientData.projects.length !== 0
							? clientData.projects.map((pr) => pr.projectId)
							: []
					}
				/>
				<AddClientTask
					onClose={() => setAddTask(false)}
					open={addTask}
					getData={getClientData}
					clientId={clientData?.id}
					clientProjects={
						clientData?.projects && clientData.projects.length !== 0
							? clientData.projects.map((pr) => ({
									value: pr?.projectId,
									label: pr?.project?.name
							  }))
							: []
					}
				/>
			</div>
		</>
	)
}

export default ClientDetails
