import { useState } from 'react'
import ModalComponent from '../ModalComponent'
import ProjectInformation from '../../views/Projects/EditProject/ProjectInformation'
import AddMembers from '../../views/Projects/EditProject/AddMembers'
import { Tabs } from 'antd'

const EditProjectModal = ({
	open,
	setOpen,
	onClose,
	selectedProject,
	setProjectEdited,
	dataSource
}) => {
	const [activeKey, setActiveKey] = useState('1')

	const [editProjectFormData, setEditProjectFormData] = useState({})

	const handleTabChange = (key) => {
		setActiveKey(key)
	}
	const items = [
		{
			key: '1',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '1' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">1</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '1' ? '#262626' : '#9D9D9D' }}
					>
						Project Information
					</span>
				</div>
			),
			children: (
				<ProjectInformation
					selectedProject={selectedProject}
					setEditProjectFormData={setEditProjectFormData}
					handleTabChange={handleTabChange}
				/>
			)
		},
		{
			key: '2',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '2' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">2</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '2' ? '#262626' : '#9D9D9D' }}
					>
						Add Members
					</span>
				</div>
			),

			children: (
				<AddMembers
					formData={editProjectFormData}
					selectedProject={selectedProject}
					setProjectEdited={setProjectEdited}
					setOpen={setOpen}
					dataSource={dataSource}
				/>
			)
		}
	]
	return (
		<ModalComponent title="Edit Project" open={open} onClose={onClose}>
			<div className="container-fluid">
				<Tabs
					defaultActiveKey={'1'}
					items={items}
					activeKey={activeKey}
					className="create-project-tabs mt-4"
					onChange={handleTabChange}
				/>
			</div>
		</ModalComponent>
	)
}

export default EditProjectModal
