import { Dropdown } from 'antd'
import { IconButton } from '@mui/material'
import { MoreVert } from '@mui/icons-material'

const ContextMenu = ({ items }) => {
	return (
		<Dropdown
			menu={{ items }}
			trigger={['click']}
			className="generic-context-menu"
			placement="bottomRight"
			overlayClassName="generic-context-menu-overlay"
			overlayStyle={{
				marginTop: '10px',
				marginLeft: '-20px'
			}}
		>
			<a
				onClick={(e) => e.stopPropagation()}
				className="context-menu-controller"
			>
				<IconButton>
					<MoreVert
						sx={{
							color: '#555',
							width: '20px',
							height: '20px'
						}}
					/>
				</IconButton>
			</a>
		</Dropdown>
	)
}

export default ContextMenu
