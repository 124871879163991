import React, { useEffect, useState } from 'react'
import ButtonComponent from '../components/shared/ButtonComponent'
import {
	AddCircleOutline,
	AddCircleOutlineOutlined,
	Delete,
	ExpandMore
} from '@mui/icons-material'
import { Tabs } from 'antd'
import CalendarComp from '../components/calendar'
import { getAllProjects } from '../API/projectsAPI'
import { getAllTasks } from '../API/TasksAPI'
import DropdownFilter from '../components/shared/DropdownFilter'
import ContextMenu from '../components/shared/ContextMenu'
import TaskModalCalendar from '../components/calendar/TaskModalCalendar'
import ClientProjects from '../components/views/ManageClients/ClientProjects'
import AddClientProject from '../components/shared/Modals/ClientActionsModals/AddProject'
import QuickCTA from '../components/quickCTA'

const Calendar = () => {
	const [activeKey, setActiveKey] = useState('1')
	const [projectData, setProjectData] = useState([])
	const [taskData, setTaskData] = useState([])
	const [isReload, setIsReload] = useState(false)
	const [addProject, setAddProject] = useState(false)
	const [projectOptions, setProjectOptions] = useState([])
	const [addTask, setAddTask] = useState(false)
	const [dataSelected, setDataSelected] = useState([])
	const [deleteClient, setDeleteClient] = useState(false)

	const getProjectData = async () => {
		try {
			const res = await getAllProjects()

			setProjectData(res?.data)

			setProjectOptions(
				res?.data?.map((project) => {
					return {
						value: project?.id,
						label: project?.name,
						clientId: project?.clientId || ''
					}
				})
			)
		} catch (error) {
			console.log(error)
		}
	}
	const getTaskData = async () => {
		try {
			const res = await getAllTasks()
			setTaskData(res?.data)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getProjectData()
		getTaskData()
	}, [isReload])

	const items = [
		{
			key: '1',
			label: 'All events',
			children: (
				<CalendarComp
					taskData={taskData}
					projectData={projectData}
					key={'1'}
					setIsReload={setIsReload}
					isReload={isReload}
				/>
			)
		},
		{
			key: '2',
			label: 'Assigned to you',
			children: (
				<CalendarComp
					taskData={taskData}
					projectData={projectData}
					setIsReload={setIsReload}
					key={'2'}
					isReload={isReload}
				/>
			)
		}
	]

	const options = [
		{
			key: '1',
			label: (
				<div
					className="context-menu-option"
					onClick={() => {
						setDataSelected([{ clientId: '' }])
						setDeleteClient(true)
					}}
				>
					<div className="d-flex align-items-center">
						{/* <Delete className="context-menu-icon" /> */}

						<span className="fs-14 fw-semibold txt-color-primary font-roboto">
							Delete
						</span>
					</div>
				</div>
			)
		}
		// {
		// 	key: '2',
		// 	label: (
		// 		// <div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 		// 	<img src={editIcon} alt="icon" />
		// 		// 	<span className="font-roboto fs-12 fw-semibold ms-2">Edit</span>
		// 		// </div>
		// 		<div
		// 			className="context-menu-option"
		// 			//onClick={() => setEditProject(true)}
		// 		>
		// 			<div className="d-flex align-items-center">
		// 				<Edit className="context-menu-icon" />

		// 				<span className="fs-14 fw-semibold txt-color-primary font-roboto">
		// 					Edit
		// 				</span>
		// 			</div>
		// 		</div>
		// 	)
		// }
		////// ToDo:- Edit Client
		// {
		// 	key: '2',
		// 	label: (
		// 		<div className="context-menu-option">
		// 			<div className="d-flex align-items-center">
		// 				<Edit className="context-menu-icon" />

		// 				<span className="fs-10 fw-semibold txt-color-primary font-roboto">
		// 					Edit
		// 				</span>
		// 			</div>
		// 		</div>
		// 	)
		// }
	]

	const clientOperations = [
		{
			key: '1',
			label: (
				<div className="p-2" onClick={() => setAddProject(true)}>
					<AddCircleOutline />
					<span className="font-roboto fs-12 fw-semibold ms-2">
						Add Project
					</span>
				</div>
			)
		},
		{
			key: '2',
			label: (
				<div className="p-2" onClick={() => setAddTask(true)}>
					<AddCircleOutline />
					<span className="font-roboto fs-12 fw-semibold ms-2">Add Task</span>
				</div>
			)
		}
		// {
		// 	key: '2',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<AddCircleOutline />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">Add Member</span>
		// 		</div>
		// 	)
		// },
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<AddCircleOutline />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">
		// 				Add Invoice
		// 			</span>
		// 		</div>
		// 	)
		// },
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<img src={recieptIcon} alt="icon" />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">
		// 				Add Proposal
		// 			</span>
		// 		</div>
		// 	)
		// },
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<img src={callLogIcon} alt="icon" />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">
		// 				Add Call Logs
		// 			</span>
		// 		</div>
		// 	)
		// },
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<img src={pinIcon} alt="icon" />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">Add Files</span>
		// 		</div>
		// 	)
		// },
		// {
		// 	key: '3',
		// 	label: (
		// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
		// 			<img src={bellIcon} alt="icon" />
		// 			<span className="font-roboto fs-12 fw-semibold ms-2">
		// 				Add Reminder
		// 			</span>
		// 		</div>
		// 	)
		// }
	]

	return (
		<>
			<div className="container-fluid p-4">
				<div className="d-flex justify-content-between align-items-center mb-4">
					<h1 className="fs-3 dashboard-primary-header">Calendar</h1>

					<div className="d-flex justify-content-center align-items-center gap-2">
						<QuickCTA
							originComp="calendar"
							clientOperations={clientOperations}
						/>

						{/* <ContextMenu items={options} /> */}
					</div>
				</div>

				<Tabs
					defaultActiveKey={activeKey}
					items={items}
					className="dashboard-tabs"
					onChange={(key) => {
						setActiveKey(key)
					}}
				/>
			</div>

			{addTask && (
				<div className="">
					<TaskModalCalendar
						open={addTask}
						onClose={() => {
							setAddTask(false)
							setIsReload(true)
						}}
						editNewTask={false}
						dataSelected={{}}
						clientId={''}
						clientProjects={[]}
					/>
				</div>
			)}
			<AddClientProject
				onClose={() => {
					setAddProject(false)
					setIsReload(true)
				}}
				open={addProject}
				getData={() => console.log('clicked from add projecy from calendar')}
				clientId={''}
				clientProjects={projectOptions}
				control="calendar"
			/>
		</>
	)
}

export default Calendar
