import React from 'react'
import InputComponent from './InputComponent'
import PhoneNumberInput from './PhoneNumberInput'
import SelectComponent from './SelectComponent'
import GenericSelect from './GenericSelect'
import DatePickerComponent from './DatePickerComponent'
import TimePickerComponent from './Timepickers/TimePickerComponent'
import DurationPicker from './Timepickers/DurationPicker'
import ImageUpload from './Uploads/ImageUpload'
import MemberSelect from './MemberSelect'

// type FormComponentProps = {
// 	control: String
// 	rest: any
// }

const FormComponent = (props) => {
	const { control, ...rest } = props

	switch (control) {
		case 'input':
			return <InputComponent {...rest} />
		case 'phone-input':
			return <PhoneNumberInput {...rest} />
		case 'select':
			return <SelectComponent {...rest} />
		case 'generic-select':
			return <GenericSelect {...rest} />
		case 'date-picker':
			return <DatePickerComponent {...rest} />
		case 'time-picker':
			return <TimePickerComponent {...rest} />
		case 'duration-picker':
			return <DurationPicker {...rest} />
		case 'input-number':
			return <InputComponent {...rest} />
		case 'upload-image':
			return <ImageUpload {...rest} />
		case 'member-select':
			return <MemberSelect {...rest} />
		default:
			return 'Something went wrong'
	}
}

export default FormComponent
