import Cookies from 'js-cookie'
import { Outlet, Navigate } from 'react-router-dom'

const AuthRoute = () => {
	const isLoggedin = Cookies.get('user-status') ? true : false

	return !isLoggedin ? <Outlet /> : <Navigate to="/dashboard/main" />
}

export default AuthRoute
