import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { TimePicker } from 'antd'

import TextError from '../../../../hoc/TextError'

const TimePickerComponent = (props) => {
	const { label, name, formfieldClass = '', ...rest } = props
	return (
		<div className={`formField ${formfieldClass}`}>
			<label htmlFor={`${name}`}>{label}</label>
			<Field name={name} id={name}>
				{({ field, form }) => {
					const { setFieldValue } = form
					return (
						<TimePicker
							className="d-block generic-time-picker"
							allowClear
							onChange={(e) => {
								setFieldValue(name, e)
							}}
							value={field?.value}
							{...rest}
						/>
					)
				}}
			</Field>
			<ErrorMessage name={name} component={TextError} />
		</div>
	)
}

export default TimePickerComponent
