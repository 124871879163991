import React, { useEffect } from 'react'

const Terms = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div className="container mt-5">
			<div className="container-fluid">
				{/* <h6 className="fw-semibold fs-6 text-uppercase text-center highlight-subheder">
          Terms
        </h6> */}
				<h1 className="large-header text-center fw-bold">Terms of Use</h1>
				<div className="inner-content pb-5">
					<p className="terms-content txt fst-italic">
						Please read these Terms and Conditions ("Terms", "Terms and
						Conditions") carefully before using the CheckWriting Application
						("Application", "Service") operated by Synccos Inc. ("us", "we", or
						"our"). Your access to and use of the Application is conditioned
						upon your acceptance of and compliance with these Terms. These Terms
						apply to all users of the Application.
					</p>
					<p className="terms-content point">1. License</p>
					<p className="terms-content txt">
						By accessing or using the Application, you agree to be bound by
						these Terms. If you disagree with any part of the terms, then you do
						not have permission to access or use the Application. Subject to
						these Terms, we grant you a limited, non-exclusive,
						non-transferable, and revocable license to use the Application for
						your personal, non-commercial purposes.
					</p>
					<p className="terms-content point">2. Accounts and User conduct</p>
					<p className="terms-content txt">
						To access some features of the Application, you must create an
						account ("Account"). You agree to provide accurate, current, and
						complete information during the registration process and to update
						such information as necessary. You are responsible for maintaining
						the confidentiality of your Account and password, and you agree to
						accept responsibility for all activities that occur under your
						Account.
					</p>
					<p className="terms-content txt">
						You agree not to use the Application for any illegal or unauthorized
						purposes, including but not limited to fraud, forgery, or identity
						theft. You also agree not to impersonate another person or entity or
						to use another person's Account without their permission.
					</p>
					<p className="terms-content point">3. Fees and Payments</p>
					<p className="terms-content txt">
						Some features of the Application may require payment of fees. All
						fees are stated in U.S. dollars and are non-refundable. By using the
						Application, you agree to pay any applicable fees and authorize us
						to charge your chosen payment method.
					</p>
					<p className="terms-content point">4. Intellectual Property</p>
					<p className="terms-content txt">
						The Application and its original content, features, and
						functionality are and will remain the exclusive property of Synccos
						Inc. and its licensors. The Application is protected by copyright,
						trademark, and other laws of both the United States and foreign
						countries.
					</p>
					<p className="terms-content point">
						5. Disclaimers and limitation of liability
					</p>
					<p className="terms-content txt">
						The Application is provided on an "as is" and "as available" basis.
						We make no warranties, expressed or implied, and hereby disclaim and
						negate all other warranties, including without limitation, implied
						warranties or conditions of merchantability, fitness for a
						particular purpose, or non-infringement of intellectual property or
						other violation of rights.
					</p>
					<p className="terms-content txt">
						In no event shall Synccos Inc., its directors, employees, or agents
						be liable for any direct, indirect, incidental, special,
						consequential, or punitive damages, including but not limited to
						loss of profits, data, use, or goodwill, arising out of or in
						connection with your access to or use of the Application.
					</p>
					<p className="terms-content point">6. Indemnification</p>
					<p className="terms-content txt">
						You agree to defend, indemnify, and hold harmless Synccos Inc. and
						its licensee and licensors, and their employees, contractors,
						agents, officers, and directors, from and against any and all
						claims, damages, obligations, losses, liabilities, costs or debt,
						and expenses (including but not limited to attorney's fees),
						resulting from or arising out of your use and access of the
						Application or your breach of these Terms.
					</p>
					<p className="terms-content point">7. Governing Law</p>
					<p className="terms-content txt">
						These Terms shall be governed and construed in accordance with the
						laws of the United States and the state in which Synccos Inc. is
						incorporated, without regard to its conflict of law provisions.
					</p>
					<p className="terms-content point">8. Changes to terms</p>
					<p className="terms-content txt">
						We reserve the right, at our sole discretion, to modify or replace
						these Terms at any time. If a revision is material, we will provide
						at least 30 days' notice prior to any new terms taking effect. By
						continuing to access or use the Application after any revisions
						become effective, you agree to be bound by the revised terms.
					</p>
					<p className="terms-content point">9. Termination</p>
					<p className="terms-content txt">
						We may terminate or suspend your access to the Application
						immediately, without prior notice or liability, if you breach these
						Terms. Upon termination, your right to use the Application will
						immediately cease. All provisions of the Terms which by their nature
						should survive termination shall survive termination, including,
						without limitation, ownership provisions, warranty disclaimers,
						indemnity, and limitations of liability.
					</p>
					<p className="terms-content point">10. Privacy</p>
					<p className="terms-content txt">
						Your privacy is important to us. Please review our Privacy Policy,
						which informs you of our practices regarding the collection, use,
						and disclosure of personal information we receive from users of the
						Application. By using the Application, you agree to the collection
						and use of information in accordance with our Privacy Policy.
					</p>
					<p className="terms-content point">11. Contact us</p>
					<p className="terms-content txt">
						If you have any questions about these Terms, please contact us at{' '}
						<a
							href="mailto:support@synccos.com "
							className="text-decoration-none"
						>
							support@synccos.com
						</a>
						, by phone at{' '}
						<a href="tel:+1 833-279-6226" className="text-decoration-none">
							+1 833-279-6226
						</a>
						, or by mail at:
					</p>
					<p className="terms-content txt mb-0">Synccos Inc.</p>
					<p className="terms-content txt mb-0">1021 State Rt.</p>
					<p className="terms-content txt">32 Highland Mills, NY 10930</p>

					<p className="terms-content point">12. Third-party services</p>
					<p className="terms-content txt">
						The Application may contain links to third-party websites or
						services that are not owned or controlled by Synccos Inc. We have no
						control over and assume no responsibility for the content, privacy
						policies, or practices of any third-party websites or services. You
						acknowledge and agree that Synccos Inc. shall not be responsible or
						liable, directly or indirectly, for any damage or loss caused or
						alleged to be caused by or in connection with the use of or reliance
						on any such content, goods, or services available on or through any
						such websites or services.
					</p>
					<p className="terms-content point">13. Updated and maintenance</p>
					<p className="terms-content txt">
						We may, from time to time, provide updates or perform maintenance on
						the Application. This may result in temporary interruptions or
						downtime. We will make reasonable efforts to minimize any disruption
						to your access and use of the Application during such times.
					</p>
					<p className="terms-content point">14. Security</p>
					<p className="terms-content txt">
						We are committed to ensuring the security of the Application and the
						information it processes. While we strive to use commercially
						acceptable means to protect your personal information, we cannot
						guarantee its absolute security. By using the Application, you
						acknowledge that you understand the risks associated with
						transmitting information over the internet and that Synccos Inc. is
						not responsible for any unauthorized access to or use of your
						personal information.
					</p>
					<p className="terms-content point">15. Force Majeure</p>
					<p className="terms-content txt">
						Synccos Inc. shall not be held liable for any failure or delay in
						the performance of its obligations under these Terms due to causes
						beyond its reasonable control, including but not limited to acts of
						God, war, terrorism, labor disputes, embargoes, government orders or
						actions, technical failures, or any other unforeseen events or
						circumstances.
					</p>
					<p className="terms-content txt">
						By accessing or using the CheckWriting Application, you agree to be
						bound by these Terms and Conditions. If you do not agree with any
						part of these Terms and Conditions, you are not authorized to access
						or use the Application.
					</p>
					<p className="terms-content point">16. Severability</p>
					<p className="terms-content txt">
						If any provision of these Terms is found to be invalid or
						unenforceable by a court of competent jurisdiction, the remaining
						provisions shall remain in full force and effect, and the invalid or
						unenforceable provision shall be deemed replaced by a valid and
						enforceable provision that most closely reflects the intent of the
						original provision.
					</p>
					<p className="terms-content point">17. Waiver</p>
					<p className="terms-content txt">
						No waiver of any provision of these Terms by Synccos Inc. shall be
						deemed a further or continuing waiver of such provision or any other
						provision, and any failure by Synccos Inc. to assert a right or
						provision under these Terms shall not constitute a waiver of such
						right or provision.
					</p>
					<p className="terms-content point">18. Entire Agreement</p>
					<p className="terms-content txt">
						These Terms, along with our Privacy Policy, constitute the entire
						agreement between you and Synccos Inc. regarding your use of the
						Application and supersede all prior agreements, understandings, and
						communications, whether written or oral, with respect to the subject
						matter hereof.
					</p>
					<p className="terms-content point">19. Assignment</p>
					<p className="terms-content txt">
						You may not assign or transfer your rights or obligations under
						these Terms without the prior written consent of Synccos Inc. Any
						attempted assignment or transfer without such consent will be null
						and void. Synccos Inc. may freely assign or transfer its rights and
						obligations under these Terms without restriction.
					</p>
					<p className="terms-content point">20. Notices</p>
					<p className="terms-content txt">
						Any notices or other communications required or permitted hereunder
						shall be in writing and shall be deemed given when delivered (a)
						personally, (b) by registered or certified mail, postage prepaid,
						return receipt requested, (c) by a nationally recognized overnight
						courier, or (d) by email, provided that a copy is also sent by
						another means specified in this section.
					</p>
					<p className="terms-content point">21. No agency</p>
					<p className="terms-content txt">
						No agency, partnership, joint venture, employee-employer, or
						franchisor-franchisee relationship is intended or created by these
						Terms. Neither party shall have any authority to bind the other
						party in any manner whatsoever.
					</p>
					<p className="terms-content point">22. Compliance with laws</p>
					<p className="terms-content txt">
						You agree to comply with all applicable laws, regulations, and rules
						in connection with your access to and use of the Application. You
						shall not use the Application in any manner that would violate any
						applicable laws, regulations, or rules, including but not limited to
						any export control laws or regulations.
					</p>
					<p className="terms-content point">23. Dispute resolution</p>
					<p className="terms-content txt">
						Any dispute, controversy, or claim arising out of or in connection
						with these Terms, including any question regarding its existence,
						validity, or termination, shall be resolved by arbitration in
						accordance with the rules of the American Arbitration Association.
						The seat of arbitration shall be the city in which Synccos Inc. is
						incorporated, and the language of the arbitration shall be English.
						The decision of the arbitrator shall be final and binding on the
						parties, and judgment upon the award rendered by the arbitrator may
						be entered in any court having jurisdiction thereof.
					</p>
					<p className="terms-content txt">
						By accessing or using the CheckWriting Application, you agree to be
						bound by these Terms and Conditions. If you do not agree with any
						part of these Terms and Conditions, you are not authorized to access
						or use the Application.
					</p>
					<p className="terms-content point">24. Custom support</p>
					<p className="terms-content txt">
						If you encounter any issues, have questions, or require assistance
						in using the Application, please contact our customer support team
						at checkwriter@synccos.com. We will make reasonable efforts to
						respond to your inquiries and address any concerns you may have.
					</p>
					<p className="terms-content point">25. Headings</p>
					<p className="terms-content txt">
						The headings used in these Terms are for convenience only and shall
						not be considered in the interpretation or construction of these
						Terms.
					</p>
					<p className="terms-content point">26. Updated to the application</p>
					<p className="terms-content txt">
						We reserve the right, at our sole discretion, to update, modify, or
						discontinue the Application or any features, functionality, or
						content thereof at any time, with or without prior notice. We will
						make reasonable efforts to notify you of any significant changes to
						the Application.
					</p>
					<p className="terms-content point">27. Export restrictions</p>
					<p className="terms-content txt">
						You acknowledge that the Application may be subject to United States
						export control laws and regulations, including the Export
						Administration Regulations (EAR), and that you will comply with
						these laws and regulations. You represent and warrant that you are
						not located in, under the control of, or a national or resident of
						any country to which the United States has embargoed goods and
						services.
					</p>
					<p className="terms-content point">28. Age restrictions</p>
					<p className="terms-content txt">
						You affirm that you are at least 18 years of age or the age of
						majority in your jurisdiction, whichever is greater, and are fully
						able and competent to enter into and comply with these Terms. If you
						are under 18 or the age of majority, you may only use the
						Application under the supervision of a parent or legal guardian who
						agrees to be bound by these Terms.
					</p>
					<p className="terms-content point">29. International Use</p>
					<p className="terms-content txt">
						The Application is controlled and operated by Synccos Inc. from its
						facilities in the United States. We make no representation that the
						Application is appropriate or available for use in other locations.
						Those who access or use the Application from other jurisdictions do
						so at their own volition and are responsible for compliance with
						local law.
					</p>
					<p className="terms-content point">30. Acknowledgement</p>
					<p className="terms-content txt">
						By using the Application, you acknowledge that you have read and
						understood these Terms and Conditions, and you agree to be bound by
						them. If you do not agree to these Terms and Conditions, you are not
						authorized to access or use the Application.
					</p>
					<p className="terms-content txt">
						These Terms and Conditions, together with our Privacy Policy,
						constitute the entire agreement between you and Synccos Inc.
						concerning your use of the CheckWriting Application. If any
						provision of these Terms and Conditions is deemed invalid by a court
						of competent jurisdiction, the invalidity of such provision shall
						not affect the validity of the remaining provisions of these Terms
						and Conditions, which shall remain in full force and effect.
					</p>
				</div>
				{/* <div className="contact-us">
					<div className="row m-0">
						<div className="col-12 col-md-6"></div>
						<div className="col-12 col-md-6"></div>
					</div>
				</div> */}
			</div>
		</div>
	)
}

export default Terms
