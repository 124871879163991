import React, { useEffect, useState } from 'react'
import ModalComponent from '../ModalComponent'
import { Select, Avatar, Upload, message } from 'antd'
import EditIcon from '@mui/icons-material/Edit'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AvatarGroupComponent from '../AvatarGroupComponent'
import '../../../styles/Forms.css'
import { Tabs, Checkbox } from 'antd'
import uploadFileIcon from '../../../assets/images/upload-file-icon.svg'
import Priority from '../../views/Projects/IssueDetails/Priority'
import AddIcon from '@mui/icons-material/Add'
import { getTask } from '../../../API/TasksAPI'
import dayjs from 'dayjs'
import Chatbox from '../../views/ChatsAndActivity/Chatbox'
const { Dragger } = Upload

// const priorityOptions = [
// 	{
// 		key: '1',
// 		label: 'Low',
// 		bgColor: '#FAFAFA',
// 		textColor: '#555555',
// 		borderColor: '#D9D9D9'
// 	},
// 	{
// 		key: '2',
// 		label: 'Medium',
// 		bgColor: '#F0F5FF',
// 		textColor: '#2F54EB',
// 		borderColor: '#C9DAFF'
// 	},
// 	{
// 		key: '3',
// 		label: 'High',
// 		bgColor: '#FFF0F6',
// 		textColor: '#EB2F96',
// 		borderColor: '#FFCAE2'
// 	},
// 	{
// 		key: '4',
// 		label: 'Urgent',
// 		bgColor: '#FFF1F0',
// 		textColor: '#F5222D',
// 		borderColor: '#FFCCC9'
// 	}
// ]

// const priorityOpt = [
// 	{
// 		key: '1',
// 		label: 'Low',
// 		bgColor: '#FAFAFA',
// 		textColor: '#555555',
// 		borderColor: '#D9D9D9'
// 	},
// 	{
// 		key: '2',
// 		label: 'Medium',
// 		bgColor: '#F0F5FF',
// 		textColor: '#2F54EB',
// 		borderColor: '#C9DAFF'
// 	},
// 	{
// 		key: '3',
// 		label: 'High',
// 		bgColor: '#FFF0F6',
// 		textColor: '#EB2F96',
// 		borderColor: '#FFCAE2'
// 	},
// 	{
// 		key: '4',
// 		label: 'Urgent',
// 		bgColor: '#FFF1F0',
// 		textColor: '#F5222D',
// 		borderColor: '#FFCCC9'
// 	}
// ]

const priorityOptions = [
	{
		value: 'Low',
		label: 'Low'
	},
	{
		value: 'Medium',
		label: 'Medium'
	},
	{
		value: 'High',
		label: 'High'
	},
	{
		value: 'Urgent',
		label: 'Urgent'
	}
]

const handleTaskPriorityChange = async (newPriority) => {
	console.log('Task Priority changed to ', newPriority)
}

const handleSubtaskPriorityChange = async (newPriority) => {
	console.log('Subtask Priority changed to ', newPriority)
}

const IndividualTaskModal = ({ isOpen, setIsOpen, taskId }) => {
	//const [isOpen, setIsOpen] = useState(false)
	const [activeKey, setActiveKey] = useState('1')
	const [taskData, setTaskData] = useState([])

	const handleTabChange = (key) => {
		setActiveKey(key)
	}

	const addDaySuffix = (day) => {
		if (day >= 11 && day <= 13) {
			return day + 'th'
		}
		const lastDigit = day % 10
		if (lastDigit === 1) {
			return day + 'st'
		} else if (lastDigit === 2) {
			return day + 'nd'
		} else if (lastDigit === 3) {
			return day + 'rd'
		} else {
			return day + 'th'
		}
	}

	const formatDate = (inputDate) => {
		const date = dayjs(inputDate)

		const formattedDate = date.format('MMMM YYYY')

		// Add the 'th' suffix to the day
		const dayWithSuffix = addDaySuffix(date.format('D'))

		return `${dayWithSuffix} ${formattedDate}`
	}

	const getTaskData = async () => {
		try {
			//const res = await getTask(taskId)
			//const { data = {} } = res
			const data = {
				//id:'',
				name: 'Dummy Task',
				description: 'Task contains dummy data',
				priority: 'Urgent',
				sprintName: 'SYN-200',
				startDate: dayjs(),
				dueDate: dayjs(),
				taskEstimate: '10',
				label: 'High Priority Task',
				members: [],
				statusDetail: 'In Progress'
			}
			setTaskData({
				taskId: data?.id,
				taskName: data?.name || 'Dummy task',
				taskDescription: data?.description,
				taskPriority: data?.priority,
				sprintName: data?.sprintName || '-',
				startDate: data?.startDate,
				dueDate: data?.dueDate,
				estimateTime: data?.taskEstimate,
				taskLabel: data?.label,
				members: data?.members?.map((member) => {
					return {
						...member
					}
				}),
				taskStatus: data?.statusDetail
			})
		} catch (error) {
			console.log('Error while fetching task data ', error)
		}
	}

	useEffect(() => {
		getTaskData()
	}, [])

	const [currentStatus, setCurrentStatus] = useState(taskData?.taskStatus?.name)
	const [currentPriority, setCurrentPriority] = useState(taskData?.taskPriority)

	const statusOptions = [
		{
			value: 'ToDo',
			label: 'To do'
		},
		{
			value: 'Done',
			label: 'Done'
		},
		{
			value: 'InProgress',
			label: 'In progress'
		}
	]

	const items = [
		{
			key: '1',
			label: (
				<div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '1' ? '#262626' : '#9D9D9D' }}
					>
						Sub Tasks
					</span>
				</div>
			),
			children: <SubTasks />
		},
		{
			key: '2',
			label: (
				<div className="d-flex">
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '2' ? '#262626' : '#9D9D9D' }}
					>
						Linked Tasks
					</span>
				</div>
			),
			children: <SubTasks />
		}
	]

	const chatItems = [
		{
			key: '1',
			label: (
				<div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '1' ? '#262626' : '#9D9D9D' }}
					>
						Chat
					</span>
				</div>
			),
			children: <Chatbox />
		},
		{
			key: '2',
			label: (
				<div className="d-flex">
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '2' ? '#262626' : '#9D9D9D' }}
					>
						Activity
					</span>
				</div>
			)
		}
	]

	return (
		<ModalComponent
			title={
				<div className="d-flex flex-column">
					<span
						className="font-roboto fw-bolder fs-5"
						style={{ color: '#434343' }}
					>
						{taskData?.taskName}
					</span>
					<span
						className="fs-12 font-roboto fw-normal"
						style={{ color: '#7B7B7B' }}
					>
						{taskData?.taskDescription}
					</span>
				</div>
			}
			width="95%"
			open={isOpen}
			onClose={() => setIsOpen(false)}
		>
			<div className="row mt-4">
				<div className="col-12 col-md-1">
					<h6 className="fs-12 font-roboto" style={{ color: '#9D9D9D' }}>
						Priority
					</h6>
					<Priority
						value={taskData?.taskPriority}
						priorityOptions={priorityOptions}
						select={(newPriority) => {
							handleTaskPriorityChange(newPriority)
						}}
					/>
				</div>
				<div className="col-12 col-md-2 text-center">
					<h6 className="fs-12 font-roboto" style={{ color: '#9D9D9D' }}>
						Sprint Name
					</h6>
					<div className="d-flex justify-content-center align-items-center">
						<span className=" font-roboto fs-16 fw-bold">
							{taskData?.sprintName}
						</span>
						<span
							role="button"
							className="ms-2"
							style={{
								color: '#C4C4C4'
							}}
						>
							<EditIcon fontSize="16px" />
						</span>
					</div>
				</div>
				<div className="col-12 col-md-2">
					<h6 className="fs-12" style={{ color: '#9D9D9D' }}>
						Start Date
					</h6>
					<div className="d-flex align-items-center">
						<span className="font-roboto fs-16 fw-bold">
							{formatDate(taskData?.startDate)}
						</span>

						<span
							role="button"
							className="ms-2"
							style={{
								color: '#C4C4C4'
							}}
						>
							<CalendarMonthIcon fontSize="16px" />
						</span>
					</div>
				</div>
				<div className="col-12 col-md-2">
					<h6 className="fs-12" style={{ color: '#9D9D9D' }}>
						Due Date
					</h6>
					<div className="d-flex align-items-center">
						<span className="font-roboto fs-16 fw-bold">
							{formatDate(taskData?.dueDate)}
						</span>

						<span
							role="button"
							className="ms-2"
							style={{
								color: '#C4C4C4'
							}}
						>
							<CalendarMonthIcon fontSize="16px" />
						</span>
					</div>
				</div>
				<div className="col-12 col-md-1">
					<h6 className="fs-12" style={{ color: '#9D9D9D' }}>
						Estimate time
					</h6>
					<div className="d-flex align-items-center">
						<span className="font-roboto fs-16 fw-bold">
							{taskData?.estimateTime ? taskData?.estimateTime : '-'}
						</span>

						<span
							role="button"
							className="ms-2"
							style={{
								color: '#C4C4C4'
							}}
						>
							<EditIcon fontSize="16px" />
						</span>
					</div>
				</div>
				<div className="col-12 col-md-1">
					<h6 className="fs-12" style={{ color: '#9D9D9D' }}>
						Label
					</h6>
					<Select
						status="error "
						bordered={false}
						style={{
							width: 80
						}}
						className="text-danger task-status-selector p-0 mb-0"
						value={1}
						options={[
							{
								value: 1,
								label: 'Scratch'
							}
						]}
					/>
				</div>
				<div className="col-12 col-md-1">
					<h6 className="fs-12" style={{ color: '#9D9D9D' }}>
						Members
					</h6>
					{/* <AvatarGroupComponent maxCount={2} data={[
                        { employeeName: "John", id: 1 },
                        { employeeName: "Sara", id: 2 },
                        { employeeName: "Peter", id: 3 },
                    ]} /> */}
					<AvatarGroupComponent
						maxCount={2}
						data={[
							taskData?.members?.map((member) => {
								return {
									employeeName: member?.name,
									id: member?.id
								}
							})
						]}
					/>
				</div>
				<div className="col-12 col-md-2">
					<h6 className="fs-12" style={{ color: '#9D9D9D' }}>
						Status
					</h6>
					<Select
						className="p-0"
						width={80}
						value={currentStatus}
						options={statusOptions}
						onSelect={(newStatus) => setCurrentStatus(newStatus)}
					/>
				</div>
			</div>

			<div className="mt-3 border-top">
				<div className="row">
					<div className="pt-4 col-md-7">
						<Tabs
							defaultActiveKey={'1'}
							items={items}
							tabBarGutter={0}
							onChange={handleTabChange}
							className="individual-task-tab dashboard-tabs"
						/>
					</div>

					<div className="pt-4 ps-4 col-md-5 border-start">
						<Tabs
							defaultActiveKey={'1'}
							items={chatItems}
							tabBarGutter={0}
							onChange={handleTabChange}
							className="individual-task-tab dashboard-tabs"
						/>
					</div>
				</div>
			</div>
		</ModalComponent>
	)
}

export default IndividualTaskModal

const SubTasks = () => {
	const props = {
		name: 'file',
		multiple: true,
		showUploadList: false,
		action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
		onChange(info) {
			const { status } = info.file
			if (status !== 'uploading') {
				console.log(info.file, info.fileList)
			}
			if (status === 'done') {
				message.success(`${info.file.name} file uploaded successfully.`)
			} else if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`)
			}
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files)
		}
	}
	return (
		<>
			<div className="list-holder pe-3">
				{Array.from({ length: 8 }).map((_, index) => {
					return (
						<div
							className="d-flex  mb-3 align-items-center justify-content-between p-3"
							key={index}
							style={{
								border: '1px solid #EAEAEA'
							}}
						>
							<div className="d-flex align-items-center">
								<Checkbox />
								<span
									className="ms-3 font-roboto fw-500"
									style={{ color: '#555555' }}
								>
									SYNC- 986
								</span>
							</div>

							<div className="d-flex justify-content-between  align-items-center">
								<Priority
									value={'Urgent'}
									priorityOptions={priorityOptions}
									select={(newPriority) => {
										handleSubtaskPriorityChange(newPriority)
									}}
								/>

								<div className="d-flex align-items-center mx-3">
									<span
										style={{
											color: '#C4C4C4'
										}}
									>
										<CalendarMonthIcon fontSize="16px" />
									</span>
									<span
										style={{
											lineHeight: 1.9,
											color: '#555555'
										}}
										className="ms-1 font-roboto fw-500"
									>
										Due: 28th August, 2023
									</span>
								</div>

								<AvatarGroupComponent
									maxCount={2}
									data={[
										{ employeeName: 'John', id: 1 },
										{ employeeName: 'Sara', id: 2 },
										{ employeeName: 'Peter', id: 3 }
									]}
								/>
							</div>
						</div>
					)
				})}

				<div
					style={{
						backgroundColor: '#FAFAFA'
					}}
					className="d-flex  mb-3  align-items-center justify-content-between p-3"
				>
					<div className="d-flex align-items-center">
						<Checkbox />
						<span
							style={{
								color: '#9D9D9D'
							}}
							className="ms-3 font-roboto fw-medium"
						>
							Click to add a subtask
						</span>
					</div>

					<div className="d-flex justify-content-between  align-items-center">
						<Priority value={'Urgent'} priorityOptions={priorityOptions} />

						<div className=" mx-3">
							<span
								style={{
									color: '#C4C4C4'
								}}
							>
								<CalendarMonthIcon fontSize="16px" />
							</span>
						</div>

						<Avatar
							style={{
								backgroundColor: '#EDF5FC'
							}}
							size={32}
						>
							<AddIcon className="fs-16" htmlColor="#434343" />
						</Avatar>
					</div>
				</div>
			</div>

			<div className="mt-3">
				<Dragger {...props}>
					<div className="d-flex flex-column">
						<div className="d-flex justify-content-center align-items-center">
							<img src={uploadFileIcon} alt="upload-file-icon" />
							<span className="font-roboto fs-14 fw-semibold create-project-headings ms-2">
								Drop your files or browse
							</span>
						</div>
					</div>
				</Dragger>
			</div>
		</>
	)
}
