import React, { useState, useEffect } from 'react'
import { useMember } from '../../../../../utils/componentUtils/memberUtils/useAddMember'
import useInviteInput from '../../../../../hooks/useInviteInput'
import { getAllMembers, inviteMembers } from '../../../../../API/MembersAPI'
import { getRoles } from '../../../../../API/rolesApi'
import Cookies from 'js-cookie'
import { addProject, updateBulkProject } from '../../../../../API/projectsAPI'
import ButtonComponent from '../../../ButtonComponent'
import AvatarGroupComponent from '../../../AvatarGroupComponent'
import { Select } from 'antd'
import { Delete, ExpandMore } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import useMessage from 'antd/es/message/useMessage'
import { updateClient } from '../../../../../API/clientAPI'
import { useNavigate } from 'react-router-dom'

const MemberForm = ({
	formData,
	clientId,
	handleTabChange,
	getData,
	onClose,
	projects,
	setFormData,
	control,
	originComp = ''
}) => {
	const [allMembers, setAllMembers] = useState([])
	const [allRoles, setAllRoles] = useState([])
	const [selectedRoles, setSelectedRoles] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	const [messageApi, contextHolder] = useMessage()

	const { memberSelect, selectedValues, setSelectedValues } = useMember()

	const { invited, getInviteInput } = useInviteInput()

	useEffect(() => {
		getAllMembersData()
		getAllRolesData()
	}, [])

	const navigate = useNavigate()

	useEffect(() => {
		if (
			selectedValues &&
			selectedValues.length !== 0 &&
			selectedValues?.length !== selectedRoles?.length
		) {
			let roles = selectedValues.map((id) => {
				return {
					id: id,
					roleId: 3
				}
			})
			setSelectedRoles([...roles])
		}
	}, [selectedValues])

	const getAllMembersData = async () => {
		try {
			const res = await getAllMembers()
			setAllMembers(res?.data)
		} catch (error) {}
	}

	const getAllRolesData = async () => {
		try {
			const res = await getRoles()
			const membersList = res?.data
			let arr = []
			for (let i = 0; i < membersList?.length; i++) {
				let obj = {}
				obj['value'] = membersList[i]?.id
				obj['label'] = membersList[i]?.name
				arr.push(obj)
			}
			setAllRoles(arr)
		} catch (error) {}
	}

	const addNewProject = async () => {
		const userEmail = Cookies.get('user-email')
		const companyDetails = JSON.parse(Cookies.get('companyDetails') || {})

		const body = {
			name: formData?.projectName,
			status: formData?.projectStatus,
			description: formData?.projectDescription,
			startDate: formData?.startDate,
			dueDate: formData?.dueDate,
			members: selectedRoles,
			imageUrl: formData?.projectImage,
			memberEmail: userEmail,
			clientId: clientId
		}

		let invitedArr
		if (invited?.length > 0) {
			invitedArr = [...invited]
		}
		if (companyDetails?.hasOwnProperty('firstName')) {
			body[
				'leadName'
			] = `${companyDetails?.firstName} ${companyDetails?.lastName}`
			body['leadEmail'] = `${companyDetails?.email}`
			body['leadImageUrl'] = companyDetails?.imageUrl || ''
		}

		try {
			const res = await addProject(body)
			const { data } = res
			const { id } = data

			if (res?.data && invitedArr?.length > 0) {
				invitedArr[0]['projectId'] = res?.data?.id
				const resInvite = await inviteMembers(invitedArr)
			}
			messageApi.success('Project added.')

			if (control !== 'calendar') {
				addProjectToClient(id, clientId)
				setIsLoading(true)
			} else {
				setTimeout(() => {
					setFormData({})
					onClose()
					handleTabChange('1')
				}, [100])
				setSelectedRoles([])
				setSelectedValues([])
				// for managing the naviagtion from teh Header
				if (originComp === 'Header') {
					navigate(`/dashboard/projects/${id}`)
				}
			}
		} catch (error) {
			setIsLoading(false)
			messageApi.error('Unable to add project.')
			console.log('Error while adding new project ', error)
		}
	}

	const addProjectToClient = async (projectId, clientId) => {
		try {
			const res = await updateClient({
				projects: [
					...projects.map((pr) => ({ projectId: pr })),
					{ projectId: projectId }
				],
				id: clientId
			})

			updateProjectsData(projectId, clientId)

			messageApi.success('Project updated to client.')
		} catch (error) {
			messageApi.error('Unable to update project.')
		}
	}

	const updateProjectsData = async (projectId, clientId) => {
		try {
			const res = await updateBulkProject(projectId, clientId)
			messageApi.success('Project updated in client.')
			setFormData({})
			handleTabChange('1')
			getData()
			onClose()
		} catch (error) {
			messageApi.error('Unable to update project in client.')
			console.log(
				'Error in updating project while creating a new client ',
				error
			)
		}
	}

	const removeMember = (id) => {
		let temp = [...selectedValues]
		let index = selectedValues.findIndex((mbr) => mbr === id)

		if (index !== -1) {
			temp.splice(index, 1)
		}
		setSelectedValues([...temp])

		let tempRoles = [...selectedRoles]
		let roleIndex = selectedRoles.findIndex((rl) => rl?.id === id)
		if (roleIndex !== -1) {
			tempRoles.splice(roleIndex, 1)
		}
		setSelectedRoles([...tempRoles])
	}

	const handleSelect = (targetRoles, memberID) => {
		// for edit of project
		let arr = [...selectedRoles]

		if (arr.find((el) => el?.id === memberID)) {
			let changedPreviousRole = arr.map((item) => {
				if (item?.id === memberID) {
					return { id: memberID, roleId: targetRoles }
				}
				return item
			})
			setSelectedRoles([...changedPreviousRole])
		} else {
			// let arr = [...selectedRoles]
			setSelectedRoles([...arr, { id: memberID, roleId: targetRoles }])
		}
	}

	return (
		<>
			{contextHolder}
			<div className="">
				<h6
					style={{ color: '#555' }}
					className="fw-semibold font-roboto txt-color-primary fs-14"
				>
					Add Members
				</h6>
				{/*Member Component Render */}
				{memberSelect()}

				{/* <InviteMember /> */}
			</div>
			<div className="mt-4">
				{selectedValues && selectedValues?.length > 0 && (
					<>
						<h6
							style={{ color: '#555' }}
							className="fw-semibold font-roboto txt-color-primary fs-14"
						>
							Add members as
						</h6>
						<p className="font-roboto fs-12 ">
							Define role for each members. You can also customise or add new
							roles in{' '}
							<span role="button" style={{ color: '#204464' }}>
								Settings
							</span>
						</p>
					</>
				)}
				{allMembers &&
					allMembers?.length !== 0 &&
					allMembers?.map((member) => {
						if (selectedValues.includes(member?.id)) {
							return (
								<div
									className="d-flex align-items-center  justify-content-between mb-4 "
									key={member.id}
								>
									<div className="d-flex  align-items-center ">
										<AvatarGroupComponent
											name={member?.name || member?.email || ''}
											imageUrl={member?.imageUrl}
										/>
										<div className="ms-2">
											<h6 className="fs-12 mb-0 font-roboto leading-1 ">
												{member.name}
											</h6>
											<span className="font-roboto fs-11 fw-normal">
												{member.email}
											</span>
										</div>
									</div>

									<div className="d-flex align-items-center">
										<Select
											className="generic-select mb-0 fs-10"
											placeholder="Select a Designation"
											style={{
												maxWidth: '7rem'
											}}
											options={allRoles}
											onChange={(value) => handleSelect(value, member.id)}
											suffixIcon={
												<ExpandMore
													sx={{
														color: '#d9d9d9',
														fontSize: '1.5rem'
													}}
												/>
											}
											defaultValue={
												member?.roleId && member?.roleId !== null
													? member?.roleId
													: 3
											}
											popupClassName="generic-select-dropdown"
											value={
												selectedRoles?.find((rl) => rl?.id === member?.id)
													?.roleId
											}
										/>
										<IconButton
											className="ms-2"
											onClick={() => removeMember(member.id)}
										>
											<Delete
												sx={{
													fontSize: '1rem'
												}}
											/>
										</IconButton>
									</div>
								</div>
							)
						} else {
							return null
						}
					})}

				<div className="mt-4">
					<h6
						style={{ color: '#555' }}
						className="fw-semibold font-roboto txt-color-primary fs-14"
					>
						Invite new members
					</h6>
					{getInviteInput()}
				</div>

				<div className="d-flex align-items-center justify-content-end mt-3">
					<ButtonComponent
						variant="light"
						text="Back"
						click={() => handleTabChange('1')}
					/>
					<ButtonComponent
						disabled={isLoading}
						variant="dark"
						extraclassName="ms-2"
						text="Save"
						click={addNewProject}
					/>
				</div>
			</div>
		</>
	)
}

export default MemberForm
