import React, { useEffect } from 'react'

const Privacy = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div className="container mt-5">
			<div className="container-fluid">
				{/* <h6 className="fw-semibold fs-6 text-uppercase text-center highlight-subheder">
					Privacy
				</h6> */}
				<h1 className="large-header text-center fw-bold">Privacy Policy</h1>
				<div className="inner-content pb-5">
					<p className="terms-content txt fst-italic">
						Thank you for choosing Synccos Inc. (“Synccos,” “we,” “us,” or
						“our”). This Privacy Policy describes how we collect, use, and
						disclose information that we obtain about visitors to our website,
						synccos.com, and users of our services.
					</p>
					<p className="terms-content point">1. Collection of Information</p>
					<p className="terms-content txt">
						We collect personal information about you in various ways, such as
						when you provide it to us through our website or through our
						customer support channels. The types of personal information we may
						collect include your name, email address, telephone number, payment
						information, device type, operating system, browser type, IP
						address, location, and usage data. We may also collect information
						about your interactions with our website and advertisements, such as
						the pages you visit and the links you click.
					</p>
					<p className="terms-content point">2. Use of Information</p>
					<p className="terms-content txt">
						We use the personal information we collect to provide, maintain, and
						improve our services, to personalize your experience, to communicate
						with you, to conduct research and analysis, to develop and improve
						our products and services, and to respond to your inquiries and
						requests. We may also use your personal information for marketing
						purposes, such as sending you promotional emails, unless you opt-out
						of receiving such communications.
					</p>

					<p className="terms-content point">3. Sharing of Information</p>
					<p className="terms-content txt">
						We may share your personal information with third-party service
						providers who assist us in providing our services, such as payment
						processors, cloud storage providers, and marketing and analytics
						providers. We require these service providers to comply with
						applicable data protection laws and to use the information only for
						the purposes for which it was provided. We may also share your
						personal information with our affiliates, partners, and advisors for
						business purposes, such as to provide support and improve our
						products and services. We may also share your personal information
						if we are required to do so by law or in connection with legal
						proceedings. In the event of a merger, acquisition, or sale of all
						or a portion of our business, we may transfer your personal
						information to the relevant third party.
					</p>
					<p className="terms-content point">4. Security</p>
					<p className="terms-content txt">
						We take reasonable measures to protect your personal information
						from unauthorized access, use, and disclosure. We use encryption and
						other security technologies to protect your personal information,
						and we conduct periodic security assessments and audits to ensure
						the ongoing effectiveness of our security measures. However, no
						method of transmission over the Internet or electronic storage is
						completely secure, and we cannot guarantee the absolute security of
						your personal information.
					</p>
					<p className="terms-content point">5. Data Retention</p>
					<p className="terms-content txt">
						Synccos Inc. will retain your personal information for as long as
						necessary to provide our services and to comply with legal
						obligations. We may also retain your personal information for a
						longer period if necessary to resolve disputes or enforce our
						agreements.
					</p>

					<p className="terms-content point">6. International Transfers</p>
					<p className="terms-content txt">
						Synccos Inc. is based in the United States, and your personal
						information may be transferred to and processed in other countries
						where our service providers are located. These countries may have
						different data protection laws than your country of residence, but
						we will take appropriate measures to ensure that your personal
						information is protected following applicable law.
					</p>
					<p className="terms-content point">7. Children’s Privacy</p>
					<p className="terms-content txt">
						Our services are not intended for use by children under the age of
						18, and we do not knowingly collect personal information from
						children under the age of 18.
					</p>
					<p className="terms-content point">8. Additional Rights</p>
					<p className="terms-content txt">
						Depending on your location, you may have additional rights regarding
						your personal information, such as the right to access, correct, and
						delete your personal information, as well as the right to object to
						certain processing activities. Please contact us at the address
						provided in the policy if you would like to exercise these rights.
					</p>
					<p className="terms-content point">9. Links to Other Websites</p>
					<p className="terms-content txt">
						Our website may contain links to other websites not operated by us.
						We are not responsible for the privacy practices of these websites
						and encourage you to review their privacy policies.
					</p>
					<p className="terms-content point">
						10. Changes to this Privacy Policy
					</p>
					<p className="terms-content txt">
						We may update this Privacy Policy from time to time. If we make
						material changes, we will be sure to give notice on our website and
						by other means as appropriate.
					</p>
					<p className="terms-content point">11. Contact us</p>
					<p className="terms-content txt">
						If you have any questions or concerns about this Privacy Policy,
						don't hesitate to contact us at{' '}
						<a
							href="mailto:support@synccos.com "
							className="text-decoration-none"
						>
							support@synccos.com
						</a>
						, by phone at{' '}
						<a href="tel:+1 833-279-6226" className="text-decoration-none">
							+1 833-279-6226
						</a>
						, or by mail at:
					</p>
				</div>
				{/* <div className="contact-us">
					<div className="row m-0">
						<div className="col-12 col-md-6"></div>
						<div className="col-12 col-md-6"></div>
					</div>
				</div> */}
			</div>
		</div>
	)
}

export default Privacy
