export const checkwriterLogo = (
	<svg
		width="204"
		height="252"
		viewBox="0 0 204 252"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_2263_24182)">
			<path
				d="M173.097 6.677C159.689 6.677 148.796 17.5704 148.796 31.0974V224.306C148.796 236.157 139.219 245.734 127.368 245.734H27.4121C15.561 245.734 5.98438 236.157 5.98438 224.306V28.1047C5.98438 16.2536 15.561 6.677 27.4121 6.677H173.097Z"
				fill="url(#paint0_linear_2263_24182)"
			></path>
			<path
				d="M197.519 31.0974V102.084C197.519 115.491 186.626 126.385 173.219 126.385H148.918V31.0974C148.918 17.5704 159.811 6.677 173.219 6.677C179.922 6.677 186.027 9.43028 190.457 13.8595C194.886 18.2887 197.519 24.2741 197.519 31.0974Z"
				fill="url(#paint1_linear_2263_24182)"
			></path>
			<path
				d="M60.8125 35.407V122.075"
				stroke="white"
				stroke-width="8.95295"
				stroke-linecap="round"
				stroke-linejoin="round"
			></path>
			<path
				d="M79.7267 51.2085H51.3559C44.0538 51.2085 38.0684 57.4333 38.0684 64.9749C38.0684 72.5165 44.0538 78.7413 51.3559 78.7413H70.2698C77.572 78.7413 83.5573 84.9661 83.5573 92.5077C83.5573 100.049 77.572 106.274 70.2698 106.274H38.0684"
				stroke="white"
				stroke-width="8.95295"
				stroke-linecap="round"
				stroke-linejoin="round"
			></path>
			<path
				d="M38.0684 171.275H101.992"
				stroke="white"
				stroke-width="8.95295"
				stroke-linecap="round"
				stroke-linejoin="round"
			></path>
			<path
				d="M38.0684 200.005H60.8128"
				stroke="white"
				stroke-width="8.95295"
				stroke-linecap="round"
				stroke-linejoin="round"
			></path>
			<path
				d="M84.1543 200.005H121.264"
				stroke="white"
				stroke-width="8.95295"
				stroke-linecap="round"
				stroke-linejoin="round"
			></path>
			<path
				d="M173.097 6.677C159.689 6.677 148.796 17.5704 148.796 31.0974V224.306C148.796 236.157 139.219 245.734 127.368 245.734H27.4121C15.561 245.734 5.98438 236.157 5.98438 224.306V28.1047C5.98438 16.2536 15.561 6.677 27.4121 6.677H173.097Z"
				stroke="white"
				stroke-width="11.9373"
				stroke-miterlimit="10"
			></path>
			<path
				d="M197.519 31.0974V102.084C197.519 115.491 186.626 126.385 173.219 126.385H148.918V31.0974C148.918 17.5704 159.811 6.677 173.219 6.677C179.922 6.677 186.027 9.43028 190.457 13.8595C194.886 18.2887 197.519 24.2741 197.519 31.0974Z"
				stroke="white"
				stroke-width="11.9373"
				stroke-miterlimit="10"
			></path>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_2263_24182"
				x1="35.2683"
				y1="15.9882"
				x2="154.016"
				y2="239.44"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#00BF6F"></stop>
				<stop offset="1" stop-color="#009361"></stop>
			</linearGradient>
			<linearGradient
				id="paint1_linear_2263_24182"
				x1="151.007"
				y1="21.728"
				x2="193.96"
				y2="127.675"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#00AF67"></stop>
				<stop offset="0.2325" stop-color="#00A763"></stop>
				<stop offset="0.5991" stop-color="#00905A"></stop>
				<stop offset="1" stop-color="#00704C"></stop>
			</linearGradient>
			<clipPath id="clip0_2263_24182">
				<rect
					width="203.503"
					height="251.027"
					fill="white"
					transform="translate(0 0.691406)"
				></rect>
			</clipPath>
		</defs>
	</svg>
)
