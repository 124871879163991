import { LoadingOutlined } from '@ant-design/icons'
import { Button, Upload } from 'antd'
import React, { useEffect, useState } from 'react'

import addPhotoAlternate from '../../../../assets/images/add-photo-alternate.svg'
import useMessage from 'antd/es/message/useMessage'
import { uploadSingleFile } from '../../../../API/filesAPI'
import { AddPhotoAlternateOutlined } from '@mui/icons-material'

const ImageUpload = ({
	label,
	placeholder,
	setFieldValue,
	name,
	imageUrl = ''
}) => {
	const [image, setImage] = useState('')
	const [loading, setLoading] = useState(false)
	const [message, contextHolder] = useMessage()

	const uploadButton = (
		<>
			{loading ? (
				<LoadingOutlined />
			) : (
				<div className="w-100 d-flex align-items-center justify-content-center">
					<AddPhotoAlternateOutlined className="txt-color-secondary" />
				</div>
			)}
		</>
	)

	useEffect(() => {
		setImage(imageUrl)
	}, [imageUrl])

	const handleUploadAction = async (fileData) => {
		setLoading(true)
		const { file } = fileData
		const body = new FormData()
		body.append('multipartFile', file)
		try {
			const res = await uploadSingleFile(body)
			const { data } = res
			setImage(data?.url)
			setFieldValue(name, data?.url)
			setLoading(false)
			setFieldValue(name, data?.url)
		} catch (error) {
			debugger
			setLoading(false)
			message.error('Upload Failed.')
		}
	}

	const beforeUpload = (file, fileList) => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file types!')
			return false
		}

		const isLt2M = file.size / 1024 / 1024 < 2
		if (!isLt2M) {
			message.error('Image must be smaller than 2MB!')
			return false
		}

		if (fileList.length > 1) {
			message.error('You can only upload one image!')
			return false
		}

		return true
	}

	return (
		<>
			{contextHolder}
			<div className="mt-2 mb-4 d-flex flex-column generic-image-upload">
				<span
					className="font-roboto fs-14 fw-semibold create-project-headings"
					style={{ marginBottom: '10px' }}
				>
					{label}
				</span>
				<div className="d-flex justify-content-start align-items-center">
					<Upload
						name={name}
						listType="picture-card"
						className="avatar-uploader upload-image-div position-relative"
						showUploadList={false}
						beforeUpload={beforeUpload}
						customRequest={handleUploadAction}
						accept="image/png, image/jpeg, image/jpg"
					>
						{image ? (
							<>
								<img
									src={image}
									alt="avatar"
									style={{ width: '100%', objectFit: 'cover' }}
								/>

								{loading ? (
									<div className="d-flex align-items-center position-absolute top-0 bottom-0 right-0 left-0">
										<LoadingOutlined />
									</div>
								) : null}
							</>
						) : (
							uploadButton
						)}
					</Upload>
					<div className="d-flex flex-column ms-2">
						<span className="fs-12 font-roboto fw-normal mb-2 txt-color-fourth">
							{placeholder}
						</span>
						<div className="d-flex align-items-center">
							<Upload
								name={name}
								showUploadList={false}
								beforeUpload={beforeUpload}
								customRequest={handleUploadAction}
								className="upload-image-btn"
								accept="image/png, image/jpeg, image/jpg"
							>
								<Button
									className="fs-10 font-roboto fw-semibold txt-color-primary mb-2 create-project-headings"
									style={{
										borderRadius: '2px',
										border: '1px solid #D9D9D9',
										backgroundColor: 'rgba(250, 250, 250, 0.11)',
										padding: '6px 8px',
										height: 'auto'
									}}
								>
									Upload Image
								</Button>
							</Upload>
							{image && (
								<Button
									className="fs-10 font-roboto fw-semibold txt-color-primary mb-2 create-project-headings border-0"
									style={{
										borderRadius: '2px',
										border: '1px solid #D9D9D9',
										backgroundColor: 'rgba(250, 250, 250, 0.11)',
										padding: '6px 8px',
										height: 'auto'
									}}
									onClick={() => {
										setFieldValue(name, '')
										setImage(null)
									}}
								>
									Delete
								</Button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ImageUpload
