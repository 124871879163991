import Cookies from 'js-cookie'
import { getRequest, postRequest } from '.'

const issueBreakdown = async (payload) => {
	const { startDate, endDate } = payload
	const res = await getRequest(
		`/jira/issuesBreakdown?startDate=${startDate}&endDate=${endDate}`
	)
	return res
}
export const taskBreakdown = async () => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await getRequest(
		`v1/dashboard/task-breakdown?companyDetailsId=${companyDetailsId}`
	)
	return res
}

const totalEmpoloyee = async () => {
	const res = await getRequest(`/jira/employeeCount`)
	return res
}
export const totalMember = async () => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await getRequest(
		`v1/dashboard/members-count?companyDetailsId=${companyDetailsId}`
	)
	return res
}
export const bestPerformer = async () => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await getRequest(
		`v1/dashboard/best-performers?companyDetailsId=${companyDetailsId}`
	)
	return res
}
export const defaulterApi = async () => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await getRequest(
		`v1/dashboard/defaulters?companyDetailsId=${companyDetailsId}`
	)
	return res
}
export const pendingIssuesApi = async () => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await getRequest(
		`v1/dashboard/pending-issues?companyDetailsId=${companyDetailsId}`
	)
	return res
}
export const workLoadApi = async () => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await getRequest(
		`v1/dashboard/work-load?companyDetailsId=${companyDetailsId}`
	)
	return res
}

const dashboardInfo = async (body) => {
	const res = await postRequest(`/jira/dashboardInfo`, body)
	return res
}

const projectFinances = async () => {
	const res = await getRequest(`/quickbooks/allProjectsFinance`)
	return res
}

export { issueBreakdown, totalEmpoloyee, dashboardInfo, projectFinances }
