import React from 'react'
import ReactEcharts from 'echarts-for-react'
import { chunk } from 'lodash'
import { Spin } from 'antd'

const FinanceBarChart = ({ data, isLoading = false }) => {
	// data = ['', '', '5600', '', '', '-4500', '', '', '', '3400', '', '']
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	]

	let newData = []

	data.forEach((val, i) => {
		newData.push({ [months[i]]: val })
	})

	const quarters = ['Q1', 'Q2', 'Q3', 'Q4']

	const quarterData = chunk(newData, 3)

	function reverse2DArrayAlongColumns(arr) {
		const numRows = arr.length
		const numCols = arr[0].length
		const reversedArray = []

		for (let col = 0; col < numCols; col++) {
			const reversedColumn = []
			for (let row = 0; row < numRows; row++) {
				reversedColumn.push(arr[row][col])
			}
			reversedArray.push(reversedColumn)
		}

		return reversedArray
	}
	const originalArray = quarterData

	const reversedArray = reverse2DArrayAlongColumns(originalArray)

	const option = {
		// title: {
		//   text: 'Quarterly Profit/Loss',
		//   subtext: 'Values in $K',
		//   left: 'center',
		// },
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow'
			},

			formatter: function (params) {
				let tooltip = params[0].axisValue + '<br/>'
				for (let i = 0; i < params.length; i++) {
					tooltip += `${params[i].name}: ${params[i].value}<br/>`
				}
				return tooltip
			}
		},
		grid: {
			left: '7%',
			top: '10%',
			bottom: '10%'
		},

		xAxis: {
			axisLine: {
				show: true
			},
			type: 'category',
			data: quarters
		},
		yAxis: {
			type: 'value',
			min: Math.min(...data),
			max: Math.max(...data),
			interval: parseInt(Math.max(...data) / 10),
			axisLabel: {
				formatter: (value) => `$${value / 1000}K`
			},
			splitLine: {
				show: false
			},
			axisLine: {
				show: true
			}
		},

		series: [
			{
				type: 'bar',

				data: reversedArray[0].map((value) => {
					return {
						name: Object.keys(value)[0],
						value: Object.values(value)[0] ? Object.values(value)[0] : '',
						itemStyle: {
							color: Object.values(value)[0] < 0 ? '#EA6F6F' : '#5EBF5B'
						}
					}
				})
			},
			{
				type: 'bar',

				data: reversedArray[1].map((value) => {
					return {
						name: Object.keys(value)[0],
						value: Object.values(value)[0] ? Object.values(value)[0] : '',
						itemStyle: {
							color: Object.values(value)[0] < 0 ? '#EA6F6F' : '#5EBF5B'
						}
					}
				})
			},
			{
				type: 'bar',

				data: reversedArray[2].map((value) => {
					return {
						name: Object.keys(value)[0],
						value: Object.values(value)[0] ? Object.values(value)[0] : '',
						itemStyle: {
							color: Object.values(value)[0] < 0 ? '#EA6F6F' : '#5EBF5B'
						}
					}
				})
			}
		]
	}

	return (
		<div
			style={{
				width: '650px',
				height: '330px'
			}}
		>
			{isLoading ? (
				<div
					className="d-flex align-items-center justify-content-center"
					style={{
						height: '70%'
					}}
				>
					<Spin size="medium" />
				</div>
			) : data &&
			  data.some((el) => el !== null && el !== undefined && el !== '') ? (
				<ReactEcharts option={option} />
			) : (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ minHeight: '70%' }}
				>
					No Data Found
				</div>
			)}
		</div>
	)
}

export default FinanceBarChart
