import React, { useEffect, useState } from 'react'
import ButtonComponent from '../../../shared/ButtonComponent'
import { useMember } from '../../../../utils/componentUtils/memberUtils/useAddMember'
import useMessage from 'antd/es/message/useMessage'
import AvatarGroupComponent from '../../../shared/AvatarGroupComponent'
import { getAllMembers } from '../../../../API/MembersAPI'
import { IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { updateBulkProject } from '../../../../API/projectsAPI'
import { createClient, updateClient } from '../../../../API/clientAPI'
import useInviteInput from '../../../../hooks/useInviteInput'
import { useNavigate } from 'react-router-dom'

const MemberForm = ({
	handleTabChange,
	formData,
	isUpdate,
	onClose,
	getData,
	originComp = ''
}) => {
	const { memberSelect, selectedValues, setSelectedValues } = useMember()
	const [messageApi, contextHolder] = useMessage()
	const { getInviteInput, invited } = useInviteInput()

	const [members, setMembers] = useState([])

	const navigate = useNavigate()

	const removeMember = (id) => {
		setMembers((prev) => prev.filter((member) => member.id !== id))
		setSelectedValues((prev) => prev.filter((member) => member !== id))
	}

	const [allMembers, setAllMembers] = useState([])

	useEffect(() => {
		// if (!editNewTask) {
		let addedNewMember = allMembers?.filter((item) => {
			if (selectedValues?.includes(item?.id)) {
				return item
			}
		})

		setMembers(addedNewMember)
		// }
	}, [selectedValues, allMembers])

	useEffect(() => {
		if (isUpdate) {
			setSelectedValues(
				formData?.clientMembers?.map((member) => member?.memberId)
			)
		}
	}, [isUpdate])

	const getMembers = async () => {
		try {
			const res = await getAllMembers()
			const { data = [] } = res
			setAllMembers(
				data?.map((member) => {
					return {
						id: member?.id,
						name: member?.name,
						avatar: member?.imageUrl,
						subTitle: 'Project Lead',
						email: member?.email
					}
				})
			)
		} catch (error) {
			console.log('Error while fetching members ', error)
		}
	}

	useEffect(() => {
		getMembers()
	}, [])

	const updateProjectsData = async (projectIds, clientId) => {
		try {
			const res = await updateBulkProject(projectIds, clientId)
		} catch (error) {
			console.log(
				'Error in updating project while creating a new client ',
				error
			)
		}
	}

	const createNewClient = async () => {
		const body = {
			email: formData?.contactEmail,
			name: formData?.name,
			status: formData?.status,
			description: formData?.description,
			phoneNumber: formData?.phoneNumber,
			billingAddress: formData?.billingAddress,
			industry: formData?.industry,
			services: formData?.services.join(),
			projects: formData?.projects.map((pr) => {
				return {
					projectId: pr
				}
			}),
			imageUrl: formData?.imageUrl,
			contactName: formData?.contactName,
			startDate:
				formData?.startDate && formData?.startDate !== ''
					? formData?.startDate?.format('YYYY-MM-DD')
					: '',
			clientMembers: selectedValues.map((member) => ({ memberId: member }))
		}

		let invitedArr
		// for inviting member in project
		if (invited?.length > 0) {
			invitedArr = [...invited]
		}

		try {
			let res
			if (isUpdate) {
				body.id = formData?.id

				res = await updateClient(body)
				//for adding hte memeber
				// if (res?.data && invitedArr?.length > 0) {
				// 	invitedArr[0]['projectId'] = res?.data?.id
				// 	const resInvite = await inviteMembers(invitedArr)
				// }
				messageApi.success('Client updated successfully.')
			} else {
				res = await createClient(body)

				//for adding hte memeber
				// if (res?.data && invitedArr?.length > 0) {
				// 	invitedArr[0]['projectId'] = res?.data?.id
				// 	const resInvite = await inviteMembers(invitedArr)
				// }
				messageApi.success('Client added successfully.')
			}

			const { data = {} } = res

			const { id } = data

			if (
				formData.projects &&
				formData?.projects !== '' &&
				formData?.projects?.length > 0
			) {
				updateProjectsData(formData?.projects, id)
			}
			//resetForm()
			onClose()
			getData()

			if (originComp === 'Header') {
				navigate(`/dashboard/manage-clients/client-details/${id}`)
			}
		} catch (error) {
			messageApi.error('Unable to update client.')
		}
	}

	return (
		<>
			{contextHolder}
			<div className="">
				<label className="fs-14 fw-bold mb-2 font-roboto integration-content">
					{' '}
					Add Members
				</label>
				{memberSelect()}
			</div>
			<div className="mt-4">
				<h6
					style={{ color: '#555' }}
					className="fw-semibold font-roboto txt-color-primary fs-14"
				>
					Add members as
				</h6>
				<p className="font-roboto fs-12 ">
					Define role for each members. You can also customise or add new roles
					in{' '}
					<span role="button" style={{ color: '#204464' }}>
						Settings
					</span>
				</p>
				{members?.map((member) => {
					return (
						<div
							className="d-flex align-items-center  justify-content-between mb-4 "
							key={member.id}
						>
							<div className="d-flex  align-items-center ">
								<AvatarGroupComponent
									name={member?.name || member?.email || ''}
									size={40}
									imageUrl={member?.imageUrl}
								/>

								<div className="ms-2">
									<h6 className="fs-12 mb-0 font-roboto leading-1 ">
										{member.name}
									</h6>
									<span className="font-roboto fs-11 fw-normal">
										{member?.email}
									</span>
								</div>
							</div>
							<div
								className="d-flex align-items-center"
								onClick={() => removeMember(member.id)}
							>
								<IconButton>
									<Delete
										sx={{
											fontSize: '1.25rem',
											color: '#555'
										}}
									/>
								</IconButton>
							</div>
						</div>
					)
				})}

				<div className="mt-4">
					<h6
						style={{ color: '#555' }}
						className="fw-semibold font-roboto txt-color-primary fs-14"
					>
						Invite new members
					</h6>
					{getInviteInput()}
				</div>

				<div className="d-flex align-items-center justify-content-end mt-3">
					<ButtonComponent
						variant="light"
						text="Back"
						click={() => handleTabChange('1')}
					/>
					<ButtonComponent
						variant="dark"
						extraclassName="ms-2"
						text="Save"
						click={createNewClient}
					/>
				</div>
			</div>
		</>
	)
}

export default MemberForm
