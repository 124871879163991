import React from 'react'
import { checkwriterLogo } from '../../assets/appsLogsSvg'
import Cookies from 'js-cookie'

const ProjectApps = () => {
	let refreshtoken = Cookies.get('check-refresh-token')
		? Cookies.get('check-refresh-token')
		: ''
	let accesstoken = Cookies.get('check-access-token')
		? Cookies.get('check-access-token')
		: ''
	let pmsrefreshtoken = Cookies.get('pms-refresh-token')
		? Cookies.get('pms-refresh-token')
		: ''
	let pmsaccesstoken = Cookies.get('pms-access-token')
		? Cookies.get('pms-access-token')
		: ''
	let companyId = Cookies.get('companyDetailsId')
		? Cookies.get('companyDetailsId')
		: ''
	let email = Cookies.get('user-email') ? Cookies.get('user-email') : ''
	return (
		<div>
			<div className="row">
				<div className="col-12 col-md-6">
					<div
						className="app-div d-flex flex-column justify-content-center align-items-center"
						onClick={() => {
							window.open(
								`https://checkwriter.synccos.com?tkn=${accesstoken}&cmpId=${companyId}&mail=${email}&rfTkn=${refreshtoken}&pmsTkn=${pmsaccesstoken}&pmsRfTkn=${pmsrefreshtoken}`,
								'_blank'
							)
							// window.open(
							// 	`http://localhost:3000?tkn=${accesstoken}&cmpId=${companyId}&mail=${email}&rfTkn=${refreshtoken}&pmsTkn=${pmsaccesstoken}&pmsRfTkn=${pmsrefreshtoken}`,
							// 	'_blank'
							// )
						}}
					>
						<div className="app-icon">{checkwriterLogo}</div>
						<p className="fs-14 txt-color-secondary font-roboto text-center fw-500 mb-0 mt-1">
							Checkwriter
						</p>
					</div>
				</div>
				<div className="col-12 col-md-6"></div>
				<div className="col-12 col-md-6"></div>
			</div>
		</div>
	)
}

export default ProjectApps
