import { AddCircleOutline, ExpandMore } from '@mui/icons-material'
import DropdownFilter from '../shared/DropdownFilter'
import { useEffect, useState } from 'react'
import AddClientProject from '../shared/Modals/ClientActionsModals/AddProject'
import { getAllProjects } from '../../API/projectsAPI'
import TaskModalCalendar from '../calendar/TaskModalCalendar'
import MyClients from '../views/ManageClients/MyClients'
import ClientModal from '../views/Forms/Client'

const QuickCTA = ({
	originComp = '',
	clientOperations: clientOperationsDefault
}) => {
	const [clientOperations, setClientOperations] = useState([])

	const [addProject, setAddProject] = useState(false)
	const [projectOptions, setProjectOptions] = useState([false])
	const [addTask, setAddTask] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)

	useEffect(() => {
		let uickResult = quickFilter(originComp)
		setClientOperations(uickResult)
	}, [])

	const getProjectData = async () => {
		try {
			const res = await getAllProjects()

			// setProjectData(res?.data)

			setProjectOptions(
				res?.data?.map((project) => {
					return {
						value: project?.id,
						label: project?.name,
						clientId: project?.clientId || ''
					}
				})
			)
		} catch (error) {
			console.log(error)
		}
	}
	// const getTaskData = async () => {
	// 	try {
	// 		const res = await getAllTasks()
	// 		setTaskData(res?.data)
	// 	} catch (error) {
	// 		console.log(error)
	// 	}
	// }

	useEffect(() => {
		getProjectData()
		// getTaskData()
	}, [])

	const quickFilter = (type) => {
		let quickOptions = []
		switch (type) {
			case 'Header':
				quickOptions = [
					{
						key: '1',
						label: (
							<div className="p-2" onClick={() => setAddProject(true)}>
								<AddCircleOutline />
								<span className="font-roboto fs-12 fw-semibold ms-2">
									Add Project
								</span>
							</div>
						)
					},
					{
						key: '2',
						label: (
							<div className="p-2" onClick={() => setAddTask(true)}>
								<AddCircleOutline />
								<span className="font-roboto fs-12 fw-semibold ms-2">
									Add Task
								</span>
							</div>
						)
					},
					{
						key: '3',
						label: (
							<div className="p-2" onClick={() => setIsModalOpen(true)}>
								<AddCircleOutline />
								<span className="font-roboto fs-12 fw-semibold ms-2">
									Add Client
								</span>
							</div>
						)
					}
					// {
					// 	key: '3',
					// 	label: (
					// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
					// 			<AddCircleOutline />
					// 			<span className="font-roboto fs-12 fw-semibold ms-2">
					// 				Add Invoice
					// 			</span>
					// 		</div>
					// 	)
					// },
					// {
					// 	key: '3',
					// 	label: (
					// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
					// 			<img src={recieptIcon} alt="icon" />
					// 			<span className="font-roboto fs-12 fw-semibold ms-2">
					// 				Add Proposal
					// 			</span>
					// 		</div>
					// 	)
					// },
					// {
					// 	key: '3',
					// 	label: (
					// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
					// 			<img src={callLogIcon} alt="icon" />
					// 			<span className="font-roboto fs-12 fw-semibold ms-2">
					// 				Add Call Logs
					// 			</span>
					// 		</div>
					// 	)
					// },
					// {
					// 	key: '3',
					// 	label: (
					// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
					// 			<img src={pinIcon} alt="icon" />
					// 			<span className="font-roboto fs-12 fw-semibold ms-2">Add Files</span>
					// 		</div>
					// 	)
					// },
					// {
					// 	key: '3',
					// 	label: (
					// 		<div className="p-2" style={{ borderBottom: '1px solid #EBEBEB' }}>
					// 			<img src={bellIcon} alt="icon" />
					// 			<span className="font-roboto fs-12 fw-semibold ms-2">
					// 				Add Reminder
					// 			</span>
					// 		</div>
					// 	)
					// }
				]
				return quickOptions

			default:
				return clientOperationsDefault
		}
	}

	return (
		<>
			<DropdownFilter
				title={
					<span
						className="fs-14 font-roboto fw-semibold d-flex align-items-center gap-2"
						style={{
							color: '#fff'
						}}
					>
						<AddCircleOutline
							sx={{
								color: '#fff',
								fontSize: '1.25rem'
							}}
						/>{' '}
						Create a new
					</span>
				}
				menuItems={clientOperations}
				icon={
					<ExpandMore
						sx={{
							color: '#fff',
							fontSize: '1.25rem',
							marginLeft: '8px'
						}}
					/>
				}
				extraclass="add-operation dark"
			></DropdownFilter>

			<AddClientProject
				onClose={() => {
					setAddProject(false)
				}}
				open={addProject}
				getData={() => console.log('clicked from add projecy from calendar')}
				clientId={''}
				clientProjects={projectOptions}
				control="calendar"
				originComp={originComp}
			/>

			{addTask && (
				<div className="">
					<TaskModalCalendar
						open={addTask}
						onClose={() => {
							setAddTask(false)
							// setIsReload(true)
						}}
						editNewTask={false}
						dataSelected={{}}
						clientId={''}
						clientProjects={[]}
						originComp={originComp}
					/>
				</div>
			)}

			{isModalOpen ? (
				<ClientModal
					open={isModalOpen} //false
					onClose={() => {
						setIsModalOpen(false)
						// setUpdateData({})
						// setIsUpdate(false)
					}}
					getData={() => console.log('Called')} // () => console.log("Called")
					originComp={originComp}
					// isUpdate={isUpdate} // false
					// updateData={updateData} // {}
				></ClientModal>
			) : null}
		</>
	)
}

export default QuickCTA
