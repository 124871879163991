import React from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'

import appLogoLight from '../../assets/images/synccos-logo-white.png'
import authImg from '../../assets/images/auth-page-img.png'

import synccosVid from '../../assets/videos/syncc-vid.mp4'

import appLogo from '../../assets/images/app-logo-light.png'

const AuthLayout = () => {
	const navigate = useNavigate()

	return (
		<div className="container-fluid p-0">
			<div className="auth-container">
				<div className="row m-0">
					<div className="col-7 col-md-6 col-lg-7 p-0">
						<div className="auth-banner">
							<img
								src={appLogo}
								alt=""
								className="auth-app-logo"
								style={{
									cursor: 'pointer',
									zIndex: '1'
								}}
								onClick={() => {
									navigate('/')
								}}
							/>

							<video
								autoPlay
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover'
								}}
								playsInline
								muted
							>
								<source src={synccosVid} type="video/mp4" />
							</video>
						</div>
					</div>
					<div className="col-5 col-md-6 col-lg-5">
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	)
}

export default AuthLayout
