import { Button, Checkbox, Progress, Tooltip, message } from 'antd'
import React, { useEffect, useState, forwardRef } from 'react'
import TableComponent from '../../shared/TableComponent'
import { Check, Clear, Error, Launch, Stars } from '@mui/icons-material'
import Roles from './MembersDetails/Roles'
import MemberAnalysis from '../../shared/Modals/MemberAnalysis'
import {
	getAllMembers,
	editMember,
	getMembersPerformance,
	resendInvite,
	bulkCreateMember
} from '../../../API/MembersAPI'

import { getAllProjects } from '../../../API/projectsAPI'
import { getRoles } from '../../../API/rolesApi'
import AvatarGroupComponent from '../../shared/AvatarGroupComponent'
import ButtonComponent from '../../shared/ButtonComponent'

const Employees = forwardRef(
	({ activeKey, setDataSelected, createNewMember }, ref) => {
		const [messageApi, contextHolder] = message.useMessage()

		const [data, setData] = useState(null)
		const [performanceData, setPerformanceData] = useState([])

		const [isLoading, setIsLoading] = useState(true)

		const [showMemberAnalysis, setShowMemberAnalysis] = useState(false)
		const [employeeData, setEmployeeData] = useState({})

		const [selectedProjects, setSelectedProjects] = useState([])
		const [initialProjects, setInitialProjects] = useState([])

		const getMembers = async () => {
			setIsLoading(true)
			try {
				const res = await getAllMembers()
				const { data } = res
				setData(data || [])
				let temp = data.map((pr) => {
					return {
						memberId: pr.id,
						projects:
							pr?.projects && pr?.projects.length !== 0
								? pr?.projects.map((mbr) => {
										return mbr.id
								  })
								: []
					}
				})
				setSelectedProjects([...temp])
				setInitialProjects([...temp])
				setIsLoading(false)
			} catch (error) {
				messageApi.error('Unable to get employees.')
				setIsLoading(false)
				setData([])
			}
		}

		const getMemberPerformance = async (id) => {
			try {
				const res = await getMembersPerformance(id)
				const { data } = res
				setPerformanceData(data || [])
				setIsLoading(false)
			} catch (error) {
				messageApi.error('Unable to get data.')
				setIsLoading(false)
				setPerformanceData([])
			}
		}
		const [allProjects, setAllProjects] = useState([])
		const [allRoles, setAllRoles] = useState([])

		const getProjectData = async () => {
			try {
				const res = await getAllProjects()
				const { data = [] } = res
				setAllProjects([...data])
			} catch (error) {
				debugger
			}
		}

		const getAllRoles = async () => {
			try {
				const res = await getRoles()
				const { data = [] } = res
				setAllRoles([...data])
			} catch (error) {}
		}

		const addProjectToMember = async (memberId) => {
			let projects = selectedProjects.find(
				(mbr) => mbr?.memberId === memberId
			).projects
			try {
				const res = await bulkCreateMember([
					{
						id: memberId,
						projects: projects.map((pr) => ({ projectId: pr }))
					}
				])
				messageApi.success('Project updated to member.')
				getMembers()
			} catch (error) {
				messageApi.error('Unable to update member.')
			}
		}

		const handleProjectSelection = (projectId, memberId) => {
			let temp = [...selectedProjects]
			let projects = selectedProjects.find(
				(mbr) => mbr.memberId === memberId
			).projects
			if (projects.includes(projectId)) {
				let index = projects.findIndex((mbr) => mbr === memberId)
				if (index !== -1) {
					projects.splice(index, 1)
				}
			} else {
				projects.push(projectId)
			}

			let objIndex = temp.findIndex((obj) => obj?.memberId === memberId)
			temp[objIndex].projects = [...projects]
			setSelectedProjects([...temp])
		}

		const getRemainProjects = (includedProjects, memberId) => {
			const ids = includedProjects.map((project) => project.id)

			let temp = selectedProjects.find((mbr) => mbr.memberId === memberId)

			const remainingMembers = allProjects.filter(
				(project) => !ids.includes(project.id)
			)
			const itemsArr = [...includedProjects, ...remainingMembers].map(
				(project, index) => {
					return {
						key: index,
						label: (
							<div
								className="d-flex align-items-center px-2 py-2"
								onClick={(e) => {
									handleProjectSelection(project.id, memberId)
									e.stopPropagation()
								}}
							>
								<Checkbox
									checked={temp.projects.includes(project.id)}
									value={project?.id}
									className="me-2"
									onChange={(e) => {
										handleProjectSelection(e.target.value, memberId)
										e.stopPropagation()
									}}
								/>
								<AvatarGroupComponent
									name={project?.name || ''}
									imageUrl={project?.imageUrl || ''}
									size={'small'}
								/>
								<p className="mb-0 font-roboto fs-12 txt-color-primary ms-2">
									{project?.name || project?.email}
								</p>
							</div>
						)
					}
				}
			)

			itemsArr.push({
				key: [...includedProjects, ...remainingMembers].length,
				label: (
					<div
						className="d-flex align-items-center justify-content-end py-2"
						onClick={(e) => {
							e.stopPropagation()
						}}
					>
						<ButtonComponent
							text=""
							icon={
								<Clear
									sx={{
										fontSize: '1rem',
										margin: '0'
									}}
								/>
							}
							variant="light"
							extraclassName="me-2 px-2 py-1 d-flex align-items-center avatar-dropdown-btn"
							click={() => {
								setSelectedProjects([...initialProjects])
							}}
						/>
						<ButtonComponent
							text=""
							icon={
								<Check
									sx={{
										fontSize: '1rem',
										margin: '0'
									}}
								/>
							}
							variant="dark"
							extraclassName="px-2 py-1 d-flex align-items-center avatar-dropdown-btn"
							click={(e) => {
								addProjectToMember(memberId)
								e.stopPropagation()
							}}
						/>
					</div>
				)
			})

			return itemsArr
		}

		useEffect(() => {
			getProjectData()
			getAllRoles()
		}, [])

		const CloseMemberAnalysis = () => {
			setShowMemberAnalysis(false)
			getMembers()
		}

		useEffect(() => {
			getMembers()
		}, [activeKey, createNewMember])

		const reinviteMember = async (email) => {
			try {
				const res = await resendInvite(email)
				messageApi.success('Invite send successfully.')
				getMembers()
			} catch (error) {
				messageApi('Unable to send invite.')
			}
		}

		const dataSource =
			data &&
			data.length !== 0 &&
			data.map((member, index) => {
				return {
					key: index.toString(),
					employeeDetails: {
						assigneeName: member?.name,
						assigneeId: member?.id,
						assigneeEmail: member?.email,
						assigneeImageUrl: member?.imageUrl || '',
						performanceStatus: member?.performanceStatus || ''
					},
					role: {
						memberProjects: member?.projects || [],
						memberId: member?.id
					},
					projects: member?.projects || [],
					workload: {
						workloadRate: member?.workloadRate || 0,
						workloadStatus: member?.workloadStatus || ''
					},
					workingHours: member?.workingHours,
					tasksCompleted: member?.tasksCompletedPercent || 0,
					status: member?.status || '',
					data: { ...member },
					memberId: member.id
				}
			})

		const columns = [
			{
				title: 'Employee Name',
				dataIndex: 'employeeDetails',
				key: 'employeeDetails',
				render: (employeeDetails) => (
					<div className="d-flex align-items-center">
						<span
							className={`${
								employeeDetails?.performanceStatus === '' ? 'me-4' : 'me-2'
							}`}
						>
							{employeeDetails?.performanceStatus === 'DEFAULTER' ? (
								<Error
									sx={{
										color: '#DE5959',
										fontSize: '1.25rem'
									}}
								/>
							) : employeeDetails?.performanceStatus === 'BEST_PERFORMER' ? (
								<Stars
									sx={{
										color: '#49BA46',
										fontSize: '1.25rem'
									}}
								/>
							) : (
								''
							)}
						</span>
						<div className="d-flex align-items-center gap-2">
							<AvatarGroupComponent
								name={
									employeeDetails?.assigneeName ||
									employeeDetails?.assigneeEmail ||
									''
								}
								imageUrl={employeeDetails?.assigneeImageUrl}
								showAddAvatar={true}
							/>
							<div className="d-flex flex-column" style={{ color: '#555555' }}>
								<span className="font-roboto fw-500 fs-12">
									{employeeDetails.assigneeName}
								</span>
								<span className="font-roboto fs-12 fw-normal">
									{employeeDetails.assigneeEmail}
								</span>
							</div>
						</div>
					</div>
				)
			},
			{
				title: 'Projects',
				dataIndex: 'projects',
				key: 'projects',
				render: (text, record) => {
					const { projects, memberId } = record
					if (projects && projects?.length !== 0) {
						const items = getRemainProjects(projects, memberId)
						return (
							<AvatarGroupComponent
								data={projects}
								maxCount={3}
								isAddBtn={allProjects?.length !== 0 || items.length !== 0}
								items={items}
								cancelFunc={() => {
									setSelectedProjects([...initialProjects])
								}}
							/>
						)
					} else {
						return <span className="fs-12 fw-500 font-roboto">----</span>
					}
				}
			},
			{
				title: 'Workload rate',
				dataIndex: 'workload',
				key: 'workload',
				render: (workload) => (
					<div className="d-flex gap-2 align-items-center fs-10">
						{' '}
						<span className="font-roboto fw-500 fs-12">
							{workload?.workloadRate ? workload?.workloadRate?.toFixed(0) : 0}%
						</span>
						{workload?.workloadStatus &&
						workload?.workloadStatus === 'OVERLOADED' ? (
							<span
								className="rounded-pill"
								style={{
									padding: '4px 6px',
									background: '#FFFFFF',
									color: '#EC9534',
									border: '1px solid #EC9534'
								}}
							>
								overload
							</span>
						) : null}
					</div>
				)
			},
			// {
			// 	title: 'Roles',
			// 	dataIndex: 'role',
			// 	render: (role) => {
			// 		console.log('m,ember record', role)
			// 		return (
			// 		<div>
			// 			{/* <Roles
			// 				value={record?.role}
			// 				memberId={record.key}
			// 				roleOptions={allRoles && allRoles.length !== 0 && allRoles.map((role) => {
			// 					return ({
			// 						label: role?.name,
			// 						value: role?.id
			// 					})
			// 				})}
			// 				projectOptions={allProjects && allProjects?.length !== 0 && allProjects?.map((project) => {
			// 					return ({
			// 						label: project?.name,
			// 						value: project?.id
			// 					})
			// 				})}
			// 			/> */}
			// 			<div
			// 		className="d-flex justify-content-center align-items-center p-2 role-container"
			// 		style={{
			// 			maxWidth: '8rem'
			// 		}}
			// 	>
			// 		<span
			// 			className="fw-bold fs-10 font-roboto"
			// 			style={{ color: '#555555' }}
			// 		>
			// 			{role?.memberProjects[0]?.role?.name || ''}{' '}
			// 		</span>
			// 		<span
			// 			className="mx-1 fw-bold fs-10 font-roboto"
			// 			style={{ color: '#555555' }}
			// 		>
			// 			+{role?.memberProjects.length - 1}
			// 		</span>
			// 		{/* <DownOutlined style={{ fontSize: '8px', marginLeft: '8px' }} /> */}
			// 	</div>
			// 		</div>
			// 	)
			// 	}
			// },
			{
				title: 'Tasks Completed',
				dataIndex: 'tasksCompleted',
				key: 'tasksCompleted',
				render: (tasksCompleted) => (
					<Progress
						percent={tasksCompleted && tasksCompleted?.toFixed(0)}
						strokeColor={'#3571A7'}
						className="table-progress"
					/>
				)
			},
			{
				title: 'Status',
				dataIndex: 'status',
				key: 'status',
				render: (status) => {
					return (
						<div
							className="d-flex justify-content-center py-1 px-2 fs-10 font-roboto text-capitalize rounded-1 fw-semibold align-items-center"
							style={{
								color: `${status}` === 'INACTIVE' ? '#4242DF' : '#11A40D',
								backgroundColor:
									`${status}` === 'INACTIVE'
										? 'rgba(181, 181, 255, 0.15)'
										: 'rgba(218, 255, 181, 0.15)',
								border:
									`${status}` === 'INACTIVE'
										? '1px solid #6F96FC'
										: '1px solid #96EB55',
								borderRadius: '2px',
								maxWidth: 'fit-content'
							}}
						>
							{status}
						</div>
					)
				}
			},
			{
				title: ' ',
				dataIndex: 'contactMember',
				key: 'contactMember',
				render: (text, record) => {
					if (
						record?.employeeDetails?.assigneeEmail &&
						record?.employeeDetails?.assigneeEmail !== ''
					) {
						return (
							<Button
								href={`mailto:${record?.employeeDetails.assigneeEmail}`}
								className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none border-0 bg-transparent p-0 d-flex align-items-center gap-2"
								onClick={(e) => {
									e.stopPropagation()
								}}
								style={{
									boxShadow: 'none',
									height: '100%'
								}}
							>
								<span className="font-roboto">Contact Member </span>
								<Launch
									sx={{
										fontSize: 'inherit',
										color: 'inherit'
									}}
								/>
							</Button>
						)
					} else {
						return <span className="font-roboto fw-500 fs-12">----</span>
					}
				}
			},
			{
				title: ' ',
				dataIndex: 'resendInvite',
				key: 'resendInvite',
				render: (text, record) => {
					if (
						(record?.status &&
							record?.employeeDetails.assigneeEmail &&
							record?.employeeDetails.assigneeEmail !== '' &&
							record?.status === 'EXPIRED') ||
						record?.status === 'PENDING'
					) {
						return (
							<Button
								className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none border-0 bg-transparent p-0 d-flex align-items-center gap-2"
								onClick={(e) => {
									reinviteMember(record?.employeeDetails.assigneeEmail)
									e.stopPropagation()
								}}
								style={{
									boxShadow: 'none',
									height: '100%'
								}}
							>
								<span className="font-roboto">Resend Invite</span>
								<Launch
									sx={{
										fontSize: 'inherit',
										color: 'inherit'
									}}
								/>
							</Button>
						)
					} else {
						return null
					}
				}
			}
		]

		const onChange = (pagination, filters, sorter, extra) => {}

		return (
			<>
				{contextHolder}
				<div className="container-fluid px-0">
					<TableComponent
						columns={columns}
						onChange={onChange}
						dataSource={dataSource}
						onRow={(record) => {
							return {
								onClick: () => {
									setEmployeeData({ ...record.data })
									setShowMemberAnalysis(true)
									getMemberPerformance(record?.data?.id)
								}
							}
						}}
						loading={isLoading}
						setDataSelected={setDataSelected}
					/>
					{showMemberAnalysis && (
						<MemberAnalysis
							open={showMemberAnalysis}
							onClose={CloseMemberAnalysis}
							employeeData={employeeData}
							performanceData={performanceData}
						/>
					)}
				</div>
			</>
		)
	}
)

export default Employees
