import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import AvatarGroupComponent from '../../shared/AvatarGroupComponent'
import Status from '../Projects/ProjectsDetails/Status'
import TableComponent from '../../shared/TableComponent'
import ContextMenu from '../../shared/ContextMenu'
import { Button, message } from 'antd'
import { getClient } from '../../../API/clientAPI'
import { handleProjectStatus } from '../../../API/projectsAPI'
import { Delete, Edit, Launch } from '@mui/icons-material'
import { useNavigate } from 'react-router'

const ClientProjects = ({ clientId, addProject }) => {
	const [isLoading, setIsLoading] = useState(true)

	const [data, setData] = useState([])

	const navigate = useNavigate()

	useEffect(() => {
		if (!addProject) {
			getProjects()
		}
	}, [addProject])

	const addDaySuffix = (day) => {
		if (day >= 11 && day <= 13) {
			return day + 'th'
		}
		const lastDigit = day % 10
		if (lastDigit === 1) {
			return day + 'st'
		} else if (lastDigit === 2) {
			return day + 'nd'
		} else if (lastDigit === 3) {
			return day + 'rd'
		} else {
			return day + 'th'
		}
	}

	const formatDate = (inputDate) => {
		const date = dayjs(inputDate) // Replace '2023-08-28' with your date

		const formattedDate = date.format('MMMM YYYY') // 'D' for day, 'MMMM' for full month name, 'YYYY' for year

		// Add the 'th' suffix to the day
		const dayWithSuffix = addDaySuffix(date.format('D'))

		return `${dayWithSuffix} ${formattedDate}`
	}

	const statusOptions = [
		{
			value: 'On Hold',
			label: 'On hold'
		},
		{
			value: 'Active',
			label: 'Active'
		},
		{
			value: 'Completed',
			label: 'Completed'
		},
		{
			value: 'Upcoming',
			label: 'Upcoming'
		}
	]

	const getStyles = (value) => {
		switch (value) {
			case 'On Hold':
				return {
					border: '1px solid #C9EBFF',
					backgroundColor: '#E6F7FF',
					color: '#1890FF',
					borderRadius: '2px'
				}
			case 'Active':
				return {
					border: '1px solid #DEC3F7',
					backgroundColor: '#F9F0FF',
					color: '#722ED1',
					borderRadius: '2px'
				}
			case 'Completed':
				return {
					border: '1px solid #FFEBC9',
					backgroundColor: '#FFF7E6',
					color: '#E19A0C',
					borderRadius: '2px'
				}
			case 'Upcoming':
				return {
					border: '1px solid #CFEBBA',
					backgroundColor: '#F6FFED',
					color: '#52C41A',
					borderRadius: '2px'
				}
			default:
				return {}
		}
	}

	const getProjects = async () => {
		setIsLoading(true)
		try {
			const res = await getClient(clientId)
			const { data = [] } = res

			setData(
				data?.projects
					?.filter((currProject) => !currProject.project?.deletedAt)
					.map((pr) => {
						return pr?.project
					})
			)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			setData([])
		}
	}

	const handleStatusChange = async (record, status) => {
		try {
			const options = { id: record.projectId, status: status }
			const res = await handleProjectStatus(options)
		} catch (error) {
			message.api('Cannot update project status')
		}
	}

	const columns = [
		{
			title: 'Project name',
			dataIndex: 'projectDetail',
			key: 'projectDetail',
			render: (text, record) => (
				<div
					className="d-flex justify-content-start align-items-center"
					style={{
						width: 'fit-content',
						cursor: 'pointer'
					}}
					onClick={(e) => {
						navigate(`/dashboard/projects/${record?.projectId}`)
					}}
				>
					<span className="fw-bold font-roboto">
						<AvatarGroupComponent
							size={'large'}
							imageUrl={record?.projectDetail?.projectAvatarUrl}
							name={record?.projectDetail?.projectName}
						/>{' '}
					</span>
					<div className="d-flex flex-column ms-2">
						<Button
							className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none border-0 bg-transparent p-0 d-flex align-items-center gap-2"
							onClick={(e) => {
								navigate(`/dashboard/projects/${record?.projectId}`)
							}}
							style={{
								boxShadow: 'none',
								height: '100%'
							}}
						>
							<span className="font-roboto">
								{record.projectDetail.projectName}
							</span>
							<Launch
								sx={{
									fontSize: 'inherit',
									color: 'inherit'
								}}
							/>
						</Button>

						<span className="fs-11 font-roboto fw-normal projectData">
							Start Date : {formatDate(record?.startDate)}
						</span>
					</div>
				</div>
			)
		},
		{
			title: 'Project Lead',
			dataIndex: 'projectLeadDetail',
			key: 'projectLeadDetail',
			render: (projectLeadDetail) => {
				return (
					<span className="fw-semibold font-roboto fs-12 projectData">
						<AvatarGroupComponent
							size={'large'}
							imageUrl={projectLeadDetail?.projectLeadAvatarUrl || ''}
							name={
								projectLeadDetail?.projectLead ||
								projectLeadDetail?.projectLeadEmail ||
								''
							}
						/>{' '}
						<span className="ms-2 font-roboto fw-semibold fs-12 projectData">
							{projectLeadDetail.projectLead}
						</span>
					</span>
				)
			}
		},
		{
			title: 'Due Date',
			dataIndex: 'dueDate',
			width: '20%',
			render: (dueDate) => (
				<span className="fw-semibold font-roboto fs-12 projectData">
					{formatDate(dueDate)}
				</span>
			)
		},
		{
			title: 'Members',
			dataIndex: 'activeEmployees',
			key: 'activeEmployees',
			render: (activeEmployees) => {
				return <AvatarGroupComponent data={[...activeEmployees]} />
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			render: (text, record) => {
				return (
					<div onClick={(e) => e.stopPropagation()}>
						<Status
							value={record.status}
							statusOptions={statusOptions}
							select={(newStatus) => {
								handleStatusChange(record, newStatus)
							}}
							getStyles={(status) => getStyles(status)}
						/>
					</div>
				)
			}
		},
		{
			title: ' ',
			dataIndex: 'contactLead',
			key: 'contactLead',
			render: (contactLead) => {
				if (contactLead && contactLead !== '') {
					return (
						<Button
							href={`mailto:${contactLead}`}
							className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none border-0 bg-transparent p-0 d-flex align-items-center gap-2"
							onClick={(e) => {
								e.stopPropagation()
							}}
							style={{
								boxShadow: 'none',
								height: '100%'
							}}
						>
							<span className="font-roboto">Contact Project Lead </span>
							<Launch
								sx={{
									fontSize: 'inherit',
									color: 'inherit'
								}}
							/>
						</Button>
					)
				} else {
					return <span className="fs-12 fw-500 font-roboto">----</span>
				}
			}
		}
		// {
		// 	title: ' ',
		// 	render: (record) => {
		// 		const options = [
		// 			{
		// 				key: '1',
		// 				label: (
		// 					<div
		// 						className="context-menu-option"
		// 						onClick={() => {
		// 							//setDeleteProject(true)
		// 						}}
		// 					>
		// 						<div className="d-flex align-items-center">
		// 							<Delete className="context-menu-icon" />

		// 							<span className="fs-10 fw-semibold txt-color-primary font-roboto">
		// 								Delete
		// 							</span>
		// 						</div>
		// 					</div>
		// 				)
		// 			},
		// 			{
		// 				key: '2',
		// 				label: (
		// 					<div
		// 						className="context-menu-option"
		// 						//onClick={() => setEditProject(true)}
		// 					>
		// 						<div className="d-flex align-items-center">
		// 							<Edit className="context-menu-icon" />

		// 							<span className="fs-10 fw-semibold txt-color-primary font-roboto">
		// 								Edit
		// 							</span>
		// 						</div>
		// 					</div>
		// 				)
		// 			}
		// 		]

		// 		return (
		// 			<div
		// 				style={{ width: '25px', height: '25px', borderRadius: '50%' }}
		// 				className="d-flex justify-content-center align-items-center"
		// 			>
		// 				<ContextMenu items={options} />
		// 			</div>
		// 		)
		// 	}
		//}
	]

	const dataSource = (Array.isArray(data) ? data : []).map((project, index) => {
		console.log('project', project)
		return {
			key: index.toString(),
			projectDetail: {
				projectName: project?.name || '',
				projectAvatarUrl: project?.imageUrl || ''
			},
			projectLeadDetail: {
				projectLead: project?.leadName || '',
				projectLeadAvatarUrl: project?.leadImageUrl || '',
				projectLeadEmail: project?.leadEmail || ''
			},
			profitOrLoss: project?.profitOrLoss || '',
			clientName: project?.clientName || '',
			//projectProgress: project.projectProgress,
			projectProgress: 58,
			activeEmployees: project?.members || [],
			// .map((employee) => employee.employeeName)
			// .join(', '),
			contactLead: project?.leadEmail,
			projectId: project?.id,
			customerId: project?.customerId,
			startDate: project?.startDate,
			status: project?.status,
			dueDate: project?.dueDate,
			projectDescription: project?.description || ''
		}
	})

	return (
		<>
			<div className="container-fluid font-roboto px-0">
				<TableComponent
					dataSource={dataSource}
					columns={columns}
					loading={isLoading}
				/>
			</div>
		</>
	)
}

export default ClientProjects
