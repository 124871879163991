import Cookies from 'js-cookie'
import { deleteRequest, getRequest, postRequest, putRequest } from '.'

const createClient = async (body) => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await postRequest('/v1/client', {
		...body,
		companyDetailsId: companyDetailsId
	})
	return res
}

const updateClient = async (body) => {
	// const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await putRequest('/v1/client', {
		...body
		// companyDetailsId: companyDetailsId
	})
	return res
}

const deleteMultipleClient = async (clientIds) => {
	const res = await deleteRequest(`/v1/client?clientIds=${clientIds}`)
	return res
}

const getAllClients = async (filter = '') => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await getRequest(
		`/v1/client?companyDetailsId=${companyDetailsId}&status=${filter}`
	)
	return res
}

const getClient = async (id) => {
	//const email = Cookies.get('user-email') ?? ''
	const res = await getRequest(`/v1/client/${id}`)
	return res
}

const getAllTasksClient = async (clientId) => {
	const res = await getRequest(`/v1/task?clientId=${clientId}`)
	return res
}

export {
	createClient,
	getAllClients,
	deleteMultipleClient,
	updateClient,
	getClient,
	getAllTasksClient
}
