import React, { useEffect, useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import { Input } from 'antd'

import TextError from '../../../../hoc/TextError'

const DurationPicker = (props) => {
	const { label, name, type, formfieldClass = '', value, ...rest } = props
	const [formatedTime, setFormateTime] = useState('0h 0m 0s')
	const [displayFormat, setDisplayFormat] = useState(false)

	useEffect(() => {
		changeFormatToTimeString(value)
	}, [])

	const changeFormatToTimeString = (time) => {
		let inputValue = time

		if (inputValue && inputValue !== '') {
			let strParts = inputValue.split(':')
			if (strParts.length > 1) {
				if (strParts.length === 2) {
					setFormateTime(`${strParts[0]} mins ${strParts[1]} sec`)
				} else if (strParts.length === 3) {
					setFormateTime(
						`${strParts[0]} hrs ${strParts[1]} mins ${strParts[2]} sec`
					)
				}
			}
			setDisplayFormat(true)
		} else {
			setDisplayFormat(false)
		}
	}

	return (
		<div className={`formField ${formfieldClass} position-relative`}>
			<label htmlFor={`${name}`}>{label}</label>
			<Field
				type={'text'}
				id={name}
				name={name}
				label={label}
				className="generic-duration-picker generic-input"
				as={Input}
				{...rest}
				onFocus={() => setDisplayFormat(false)}
				onBlur={(e) => {
					changeFormatToTimeString(e.target.value)
				}}
				style={displayFormat ? { color: 'transparent' } : { color: '#555' }}
			/>
			<p
				className={`duration-picker-format fs-12 fw-nomral font-roboto mb-0 txt-color-primary position-absolute ${
					displayFormat ? 'd-block' : 'd-none'
				}`}
			>
				{formatedTime}
			</p>
			<ErrorMessage name={name} component={TextError} />
		</div>
	)
}

export default DurationPicker
