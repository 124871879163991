import React from 'react'
import { Card } from 'antd'

const CardComponent = ({ title, children, ...rest }) => {
	return (
		<Card
			title={title}
			{...rest}
			className="app-card border-0 rounded-0 dashboard-card"
		>
			{children}
		</Card>
	)
}

export default CardComponent
