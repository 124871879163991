import React from 'react'
import appLogo from '../../assets/images/app-logo-light.png'
import { Link, useNavigate } from 'react-router-dom'
import { campaignIcon } from '../../assets/svg'

import './Layout.css'
import { useSelector } from 'react-redux'

const Header = () => {
	const navigate = useNavigate()

	let isLoggedIn = useSelector((state: any) => state.loginLogout.isLoggedIn)

	return (
		<header className="app-header">
			<div className="container">
				<div className="d-flex align-items-center justify-content-between py-5">
					<Link to="/" className="app-logo">
						<img src={appLogo} alt="app-logo" />
					</Link>
					<div className="header-nav">
						<nav className="d-flex align-items-center">
							<a
								href="#why-choose-us"
								className="mx-4 fs-14 font-poppins fw-normal position-relative d-block header-nav-links"
							>
								Why choose us?
							</a>
							<a
								href="#our-features"
								className="mx-4 fs-14 font-poppins fw-normal position-relative d-block header-nav-links"
							>
								Our features
							</a>

							<Link
								to="/blog"
								className="mx-4 fs-14 font-poppins fw-normal position-relative d-block header-nav-links"
							>
								Roadmap
							</Link>
						</nav>
					</div>
					<div className="functional-section d-flex align-items-center justify-content-center">
						{/* <div className="header-icon me-5 position-relative notification-icon">
							{campaignIcon}
							<span className="indicator">
								<span className="indicate">&nbsp;</span>
								<span className="indicate-border">&nbsp;</span>
								<span className="indicate-border indicate-border-1">
									&nbsp;
								</span>
								<span className="indicate-border indicate-border-2">
									&nbsp;
								</span>
								<span className="indicate-border indicate-border-3">
									&nbsp;
								</span>
								<span className="indicate-border indicate-border-4">
									&nbsp;
								</span>
							</span>
						</div> */}
						{isLoggedIn ? (
							<>
								;
								<button
									className="common-btn blue-btn animation-right text-uppercase fw-bold fs-6"
									onClick={() => {
										navigate('/dashboard/main')
									}}
								>
									To dashboard
									<span className="icon ms-3">
										<i className="fa-solid fa-arrow-right-to-bracket"></i>
									</span>
								</button>
							</>
						) : (
							<>
								<button
									className="common-btn white-btn me-3 fs-14 font-poppins"
									onClick={() => {
										navigate('/auth/login')
									}}
								>
									Log in
								</button>
								<button
									className="common-btn blue-btn animation-right text-uppercase fw-bold fs-6"
									onClick={() => {
										navigate('/auth/signup')
									}}
								>
									Launch Synccos Pms
									<span className="icon ms-3">
										<i className="fa-solid fa-arrow-right-to-bracket"></i>
									</span>
								</button>
							</>
						)}
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header
