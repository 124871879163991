import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import dayjs from 'dayjs'
import FormComponent from '../../../shared/forms'
import { message, Upload, Input } from 'antd'
import uploadFileIcon from '../../../../assets/images/upload-file-icon.svg'
import ButtonComponent from '../../../shared/ButtonComponent'
import { LoadingOutlined } from '@ant-design/icons'
import addPhotoAlternate from '../../../../assets/images/add-photo-alternate.svg'
import ComponentsStyles from '../../../../styles/ComponentsStyles.css'
import SelectComponent from '../../../shared/forms/SelectComponent'
import { getColoumn } from '../../../../API/taskAPI'
import { getAllProjects } from '../../../../API/projectsAPI'
import { getSprints } from '../../../../API/sprintAPI'

const { Dragger } = Upload

const NewTaskInformation = ({
	handleTabChange,
	setFormData,
	onClose,
	projectId,
	projectName
}) => {
	const [statusOptions, setStatusOptions] = useState([])
	const [projectOptions, setProjectOptions] = useState([])
	const [sprintOptions, setSprints] = useState([])

	const formInitialValues = {
		taskName: '',
		statusId: '',
		taskDescription: '',
		projectName: projectName,
		projectId: projectId,
		sprintId: '',
		startDate: dayjs(),
		dueDate: dayjs()
	}

	const formValidationSchema = Yup.object({
		taskName: Yup.string().required('Task name is required'),
		statusId: Yup.string().required('Task status is required'),
		taskDescription: Yup.string(),
		projectName: Yup.string(),
		projectId: Yup.string(),
		sprintId: Yup.string(),
		startDate: Yup.date().required('Start date is required'),
		dueDate: Yup.date()
			.required('End date is required')
			.min(Yup.ref('startDate'), 'Due date should not be before start date.')
	})

	const getProjects = async () => {
		try {
			const res = await getAllProjects()
			const { data = [] } = res

			setProjectOptions(
				data?.map((project) => {
					return {
						value: project?.id,
						label: project?.name,
						clientId: project?.clientId || ''
					}
				})
			)
		} catch (error) {
			console.log('Error while fetching all projects ', error)
		}
	}
	const getAllColoumn = async () => {
		try {
			const res = await getColoumn()
			const { data = [] } = res

			setStatusOptions(
				data?.map((status) => {
					return {
						value: status?.id,
						label: status?.name
					}
				})
			)
		} catch (error) {
			console.log('Error while fetching all projects ', error)
		}
	}

	const getSprintData = async () => {
		try {
			const res = await getSprints(projectId, projectName)
			const { data = [] } = res

			setSprints(
				data && data.length !== 0
					? data.map((spr) => {
							return {
								value: spr.id,
								label: spr?.name
							}
					  })
					: []
			)
		} catch (error) {}
	}

	useEffect(() => {
		getProjects()
		getAllColoumn()
		getSprintData()
	}, [])

	const priorityOptions = [
		{
			label: 'High',
			value: 'High'
		},
		{
			label: 'Low',
			value: 'Low'
		},
		{
			label: 'Medium',
			value: 'Medium'
		},
		{
			label: 'Urgent',
			value: 'Urgent'
		}
	]

	return (
		<>
			<Formik
				initialValues={formInitialValues}
				validationSchema={formValidationSchema}
				enableReinitialize={true}
				onSubmit={(values) => {
					setFormData({
						...values,
						status:
							statusOptions.find((status) => status.value === values.statusId)
								?.label || ''
					})

					handleTabChange('2')
				}}
			>
				{({ handleSubmit, errors }) => {
					return (
						<Form>
							<div className="d-flex flex-column">
								<div className="row">
									<div className="col-12 col-md-6">
										<FormComponent
											name="taskName"
											placeholder="Enter task name"
											control="input"
											label="Task Name"
										/>
									</div>
									<div className="col-12 col-md-6">
										<FormComponent
											name="statusId"
											placeholder="Select Status"
											control="generic-select"
											options={statusOptions}
											multiple={false}
											label="Task Status"
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-12">
										<FormComponent
											name="taskDescription"
											placeholder="Type your summary"
											control="input"
											label="Task Description (optional)"
											labelClass="create-project-headings"
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-12 col-md-6">
										<FormComponent
											name="projectName"
											placeholder="Select a project"
											control="generic-select"
											label="Select Project"
											options={projectOptions}
										/>
									</div>
									<div className="col-12 col-md-6">
										<FormComponent
											name="sprintId"
											placeholder="Select a sprint"
											control="generic-select"
											label="Add to sprint (optional)"
											multiple={false}
											options={sprintOptions}
										/>
									</div>
									<div className="col-12 col-md-6">
										<FormComponent
											name="priority"
											placeholder="Select a priority"
											control="generic-select"
											label="Add Priority"
											multiple={false}
											options={priorityOptions}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-12 col-md-6">
										<FormComponent
											name="startDate"
											placeholder="Enter start date"
											control="date-picker"
											label="Start Date"
										/>
									</div>
									<div className="col-12 col-md-6">
										<FormComponent
											name="dueDate"
											placeholder="Select due date"
											control="date-picker"
											label="Due Date"
										/>
									</div>
								</div>
							</div>
							<div className="d-flex flex-column">
								{/* <span
									className="font-roboto fs-14 fw-semibold"
									style={{ color: '#555555', marginBottom: '10px' }}
								>
									Add Files
								</span>
								<Dragger {...props}>
									<div className="d-flex flex-column">
										<div className="d-flex justify-content-center align-items-center">
											<img src={uploadFileIcon} alt="upload-file-icon" />
											<span className="font-roboto fs-14 fw-semibold create-project-headings ms-2">
												Drop your files or browse
											</span>
										</div>
										<span
											className="font-roboto fs-10 fw-normal ms-2"
											style={{ color: '#7B7B7B' }}
										>
											Max file size is 2mb
										</span>
									</div>
								</Dragger> */}
								<div className="d-flex align-items-center justify-content-end mt-4">
									<ButtonComponent
										variant="light"
										text="Cancel"
										click={onClose}
									/>
									<ButtonComponent
										type="button"
										variant="dark"
										extraclassName="ms-2"
										text="Add a task"
										click={() => {
											console.log(errors)
											handleSubmit()
										}}
									/>
								</div>
							</div>
						</Form>
					)
				}}
			</Formik>
		</>
	)
}

export default NewTaskInformation
