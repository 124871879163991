import DropdownFilter from '../../shared/DropdownFilter'
import CardComponent from '../../shared/CardComponent'
import BarGraph from '../../shared/Graphs/BarGraph'
import PieChart from '../../shared/Graphs/PieChart'
import AnalysisLineChart from '../../shared/Graphs/LineCharts/AnalysisLineChart'
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined'
import SouthEastIcon from '@mui/icons-material/SouthEast'
import { Avatar, Button, Skeleton, Spin } from 'antd'
import {
	ArrowForwardIosRounded,
	DragHandle,
	Error,
	KeyboardArrowDown,
	KeyboardDoubleArrowUp,
	Launch,
	Stars
} from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { getEmployeeCount, getProjectFinance } from '../../../utils/helper'
import {
	bestPerformer,
	dashboardInfo,
	defaulterApi,
	pendingIssuesApi,
	taskBreakdown,
	totalMember,
	workLoadApi
} from '../../../API/JiraAPI'
import Cookies from 'js-cookie'
import AvatarGroupComponent from '../../shared/AvatarGroupComponent'
import { getAllProjects } from '../../../API/projectsAPI'
import { useNavigate } from 'react-router'

const ProjectManagement = ({ selectedOption, isIntegrate, proceed }) => {
	const navigate = useNavigate()

	const [issueBreakdownData, setIssueBreakdownData] = useState({})
	const [employeeCountData, setEmployeeCountData] = useState(0)
	const [projectFinanceData, setProjectFinanceData] = useState([])
	const [dashBoardInfoData, setDashboardInfoData] = useState([])
	const [bestPromerData, setBestPromerData] = useState({})
	const [defaultersData, setDefaultersData] = useState({})
	const [pendingIssuesData, setPendingIssuesData] = useState({})
	const [workLoadData, setWorkLoadData] = useState({})

	const [memberProjects, setMemberProjects] = useState([])

	const [isLoading, setisLoading] = useState(false)

	const currentDate = new Date()
	const selectedStartDate = new Date(currentDate)
	let selectedEndDate

	let compareWith = 'last month'

	// Determine the date range based on the selectedOption
	if (selectedOption === 'Last 1 month') {
		// Last 1 month
		selectedStartDate.setMonth(currentDate.getMonth() - 1)
		compareWith = 'last month'
	} else if (selectedOption === 'Last 3 months') {
		// Last 3 months
		selectedStartDate.setMonth(currentDate.getMonth() - 3)
		compareWith = 'last 3 months'
	} else if (selectedOption === 'Last 6 months') {
		// Last 6 months
		selectedStartDate.setMonth(currentDate.getMonth() - 6)
		compareWith = 'last 6 months'
	}

	// Set the end date to the current date
	selectedEndDate = currentDate

	const payload = {
		startDate: selectedStartDate.toISOString().split('T')[0],
		endDate: selectedEndDate.toISOString().split('T')[0]
	}

	const legendItems = [
		{ colors: '#4796DF', name: 'Income' },
		{ colors: '#92A2BB', name: 'Expense' },
		{ colors: ['#5EBF5B', '#EA6F6F'], name: 'Profit/Loss' }
	]

	const defaulterApiData = async () => {
		try {
			const res = await defaulterApi()
			const { data } = res
			setDefaultersData(data)
		} catch (error) {
			console.log(error, 'error in the best performer')
		}
	}
	const pendingIssuesApiData = async () => {
		try {
			const res = await pendingIssuesApi()
			const { data } = res
			setPendingIssuesData(data)
		} catch (error) {
			console.log(error, 'error in the best performer')
		}
	}
	const bestPerformerApi = async () => {
		try {
			const res = await bestPerformer()
			const { data } = res

			setBestPromerData(data)
			// setDashboardInfoData([data])
		} catch (error) {
			console.log(error, 'error in the best performer')
		}
	}
	const workLoadApiData = async () => {
		try {
			const res = await workLoadApi()
			const { data } = res

			setWorkLoadData(data)
			// setDashboardInfoData([data])
		} catch (error) {
			console.log(error, 'error in the best performer')
		}
	}

	console.log('bestPermormer', bestPerformer)

	// const getDashBoardInfo = async () => {
	// 	setisLoading(true)
	// 	function transformAPIToAnalysisData(dashboardInfo) {
	// 		const transformedData = []

	// 		// Order of categories
	// 		const categoryOrder = [
	// 			'bestPerformers',
	// 			'defaulters',
	// 			'pendingIssues',
	// 			'workingTimes',
	// 			'employeeWorkload'
	// 		]

	// 		const categoryDisplayNames = {
	// 			bestPerformers: 'Best Performers',
	// 			defaulters: 'Defaulters',
	// 			pendingIssues: 'Pending Issues',
	// 			workingTimes: 'Average Work Time',
	// 			employeeWorkload: 'Work Load Rate'
	// 		}

	// 		categoryOrder.forEach((categoryKey) => {
	// 			const category = dashboardInfo[categoryKey]

	// 			if (category) {
	// 				const vsLastMonth = category.vsLastMonth
	// 				const status = vsLastMonth > 0 ? 'profit' : 'loss'

	// 				const arrayPropertyName = Object.keys(category).find((key) =>
	// 					Array.isArray(category[key])
	// 				)

	// 				if (arrayPropertyName) {
	// 					const categoryObj = {
	// 						category: categoryDisplayNames[categoryKey],
	// 						// value:
	// 						// 	category?.avgThisMonth?.toFixed(0) || category?.totalThisMonth,
	// 						percentChange: `${(vsLastMonth ?? 0)?.toFixed(0)} %`,
	// 						status,
	// 						data: category[arrayPropertyName].map((ele, index) => ({
	// 							person: ele?.name,
	// 							value:
	// 								ele?.projectName || ele?.pendingIssues !== undefined
	// 									? ele.pendingIssues
	// 									: typeof ele.avgTime === 'number'
	// 									? `${ele.avgTime.toFixed(2)} hrs`
	// 									: `${ele.workloadPercentage?.toFixed(2)} %`,
	// 							analysisDataImage: ele?.image
	// 						})),
	// 						thisMonth:
	// 							categoryKey === 'workingTimes' ||
	// 							categoryKey === 'employeeWorkload'
	// 								? category?.avgThisMonth
	// 								: category?.totalThisMonth,
	// 						lastMonth:
	// 							categoryKey === 'workingTimes' ||
	// 							categoryKey === 'employeeWorkload'
	// 								? category?.avgLastMonth
	// 								: category?.totalLastMonth
	// 					}

	// 					// Check the category and format the value property accordingly
	// 					if (categoryKey === 'workingTimes') {
	// 						categoryObj.value = `${category?.avgThisMonth?.toFixed(2)} hrs`
	// 					} else if (categoryKey === 'employeeWorkload') {
	// 						categoryObj.value = `${category?.avgThisMonth?.toFixed(2)} %`
	// 					} else {
	// 						categoryObj.value = category?.totalThisMonth
	// 					}

	// 					transformedData.push(categoryObj)
	// 				}
	// 			}
	// 		})
	// 		return transformedData
	// 	}

	// 	try {
	// 		const res = await dashboardInfo(payload)
	// 		const data = res.data
	// 		const udpatedData = transformAPIToAnalysisData(data)
	// 		setDashboardInfoData(udpatedData)
	// 		setisLoading(false)
	// 	} catch (err) {
	// 		setisLoading(false)
	// 	}
	// }

	const getIssueBreakdownFn = async () => {
		setisLoading(true)
		try {
			const res = await taskBreakdown()
			setIssueBreakdownData(res?.data)
			setisLoading(false)
		} catch (err) {
			setisLoading(false)
		}
	}

	const getEmployeeCountFn = async () => {
		setisLoading(true)
		try {
			const data = await totalMember()
			setEmployeeCountData(data?.data || 0)
			setisLoading(false)
		} catch (err) {
			setisLoading(false)
		}
	}

	const getProjectFinanceFn = async () => {
		setisLoading(true)
		try {
			const data = await getProjectFinance()
			setProjectFinanceData(data)
			setisLoading(false)
		} catch (err) {
			setisLoading(false)
		}
	}

	const makeLineGraphData = (curr, prev) => {
		const currentDate = new Date()
		const previousDate = new Date(currentDate)
		previousDate.setMonth(currentDate.getMonth() - 1)
		const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0')
		const previousMonth = String(previousDate.getMonth() + 1).padStart(2, '0')
		const currentFormattedDate = `${currentDate.getFullYear()}-${currentMonth}`
		const previousFormattedDate = `${previousDate.getFullYear()}-${previousMonth}`
		const graphData = [
			{ [currentFormattedDate]: curr },
			{ [previousFormattedDate]: prev }
		]
		return graphData
	}

	const getMemberProjects = async () => {
		try {
			const res = await getAllProjects()
			const { data } = res
			setMemberProjects([...data])
		} catch (error) {
			debugger
		}
	}

	useEffect(() => {
		if (proceed) {
			getIssueBreakdownFn()
			getEmployeeCountFn()
			getProjectFinanceFn()
			bestPerformerApi()
			defaulterApiData()
			pendingIssuesApiData()
			workLoadApiData()
			getMemberProjects()
			// getDashBoardInfo()
		}
	}, [selectedOption, proceed])
	return (
		<>
			<div className="row mb-4 mx-0 px-0">
				{/* <div className="col-12 col-md-12 col-lg-6">
					<CardComponent
						title={
							<div className="d-flex justify-content-between">
								<h3 className="cardTitle font-roboto fs-6 fw-bold">
									Projects Finance
								</h3>
								<DropdownFilter title="Ongoing" />
							</div>
						}
					>
						<div className="text-center" style={{ height: '300px' }}>

							<BarGraph
								projectFinanceData={projectFinanceData}
								legendItems={legendItems}
								isLoading={isLoading}
							/>
						</div>
					</CardComponent>
				</div> */}
				<div className="col-12 col-md-12 col-lg-12 ps-0">
					<div className="row d-flex align-items-center m-0 p-0">
						<div className="col-12 col-md-7 col-lg-8 px-0">
							<CardComponent
								title={
									<div className="d-flex justify-content-between">
										<h3 className="cardTitle font-roboto fs-6 fw-bold">
											Issues Breakdown
										</h3>
										{/* <div className="d-flex justify-content-center">
									<DropdownFilter title="Clients" />
									<DropdownFilter title="Projects" />
									<DropdownFilter title="Sprints" />
								</div> */}
									</div>
								}
							>
								<div className="row" style={{ height: '315px' }}>
									<div className="col-4 chart-right-border">
										<PieChart
											priority="Low"
											value={issueBreakdownData?.LOW?.total || 0}
											progressValue={issueBreakdownData?.LOW?.inProgress}
											toDoValue={issueBreakdownData?.LOW?.todo}
											isLoading={isLoading}
											icon={
												<KeyboardArrowDown
													sx={{
														color: '#0159FF',
														fontSize: '1.25rem'
													}}
												/>
											}
										/>
									</div>
									<div className="col-4 chart-right-border">
										<PieChart
											priority="Medium"
											value={issueBreakdownData?.MEDIUM?.total || 0}
											progressValue={issueBreakdownData?.MEDIUM?.inProgress}
											toDoValue={issueBreakdownData?.MEDIUM?.todo}
											isLoading={isLoading}
											icon={
												<DragHandle
													sx={{
														fontSize: '1.25rem',
														color: '#FC9F01'
													}}
												/>
											}
										/>
									</div>
									<div className="col-4">
										<PieChart
											priority="High"
											value={issueBreakdownData?.HIGH?.total || 0}
											progressValue={issueBreakdownData?.HIGH?.inProgress}
											toDoValue={issueBreakdownData?.HIGH?.todo}
											isLoading={isLoading}
											icon={
												<KeyboardDoubleArrowUp
													sx={{
														color: '#FF3C00',
														fontSize: '1.25rem'
													}}
												/>
											}
										/>
									</div>
								</div>
							</CardComponent>
						</div>
						<div className="col-12 col-md-5 col-lg-4">
							<CardComponent
								title={
									<div className="d-flex justify-content-between">
										<h3 className="cardTitle font-roboto fs-6 fw-bold">
											Projects
										</h3>
									</div>
								}
							>
								<div
									className="row dashboard-projects-info"
									style={{ height: '315px', overflow: 'auto' }}
								>
									{isLoading ? (
										<div className="d-flex align-items-center justify-content-center">
											<Spin size="small" />
										</div>
									) : (
										<div>
											<div className="row m-0 mb-2">
												<div
													className="col-12 col-md-4"
													style={{
														borderBottom: '1px dashed rgba(0,0,0,0.3)'
													}}
												>
													<p
														className="font-roboto fw-semibold fs-10 txt-color-primary mb-0 pb-1"
														style={{
															borderRight: '1px dashed rgba(0,0,0,0.3)'
														}}
													>
														Project
													</p>
												</div>
												<div
													className="col-12 col-md-4"
													style={{
														borderBottom: '1px dashed rgba(0,0,0,0.3)'
													}}
												>
													<p
														className="font-roboto fw-semibold fs-10 txt-color-primary mb-0 pb-1"
														style={{
															borderRight: '1px dashed rgba(0,0,0,0.3)'
														}}
													>
														Lead
													</p>
												</div>
												<div
													className="col-12 col-md-4"
													style={{
														borderBottom: '1px dashed rgba(0,0,0,0.3)'
													}}
												>
													<p className="font-roboto fw-semibold fs-10 txt-color-primary mb-0 pb-1">
														Contact Lead
													</p>
												</div>
											</div>
											{memberProjects && memberProjects.length !== 0 ? (
												memberProjects.map((project) => {
													return (
														<div className="row m-0 mb-3">
															<div className="col-12 col-md-4">
																<div
																	className="d-flex align-items-center gap-2"
																	style={{
																		borderRight: '1px dashed rgba(0,0,0,0.3)'
																	}}
																>
																	<AvatarGroupComponent
																		name={project?.name}
																		imageUrl={project?.imageUrl}
																	/>
																	{/* <p className="font-roboto fs-12 txt-color-tertiary mb-0 ms-2 fw-500 text-capitalize">
																		{project?.name}
																	</p> */}
																	<Button
																		className="txt-color-blue-1 font-roboto text-capitalize fs-12 fw-500 text-decoration-none border-0 bg-transparent p-0 d-flex align-items-center gap-1"
																		onClick={(e) => {
																			e.stopPropagation()
																			navigate(
																				`/dashboard/projects/${project?.id}`
																			)
																		}}
																		style={{
																			boxShadow: 'none',
																			height: '100%'
																		}}
																	>
																		<span className="font-roboto">
																			{project?.name}
																		</span>
																		<Launch
																			sx={{
																				fontSize: 'inherit',
																				color: 'inherit'
																			}}
																		/>
																	</Button>
																</div>
															</div>
															<div className="col-12 col-md-4">
																<div
																	className="d-flex align-items-center"
																	style={{
																		borderRight: '1px dashed rgba(0,0,0,0.3)'
																	}}
																>
																	<AvatarGroupComponent
																		name={project?.leadName}
																		imageUrl={project?.leadImageUrl || ''}
																	/>
																	<p className="font-roboto fs-12 txt-color-tertiary mb-0 ms-2 fw-500 text-capitalize">
																		{project?.leadName || '---'}
																	</p>
																</div>
															</div>
															<div className="col-12 col-md-4">
																{project?.leadEmail &&
																project?.leadEmail !== '' ? (
																	<Button
																		href={`mailto:${project?.leadEmail}`}
																		className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none border-0 bg-transparent p-0 d-flex align-items-center gap-2"
																		onClick={(e) => {
																			e.stopPropagation()
																		}}
																		style={{
																			boxShadow: 'none',
																			height: '100%'
																		}}
																	>
																		<span className="font-roboto">
																			Contact Member{' '}
																		</span>
																		<Launch
																			sx={{
																				fontSize: 'inherit',
																				color: 'inherit'
																			}}
																		/>
																	</Button>
																) : (
																	<p>---</p>
																)}
															</div>
														</div>
													)
												})
											) : (
												<p className="font-roboto fs-14 txt-color-primary mb-0 text-center mt-3">
													No projects found
												</p>
											)}
										</div>
									)}
								</div>
							</CardComponent>
						</div>
					</div>
				</div>
			</div>

			<div className="" style={{ background: '#ffffff' }}>
				<div className="py-4 ps-4">
					{isLoading ? (
						<Skeleton.Button
							active
							size="small"
							style={{
								width: '15rem'
							}}
						/>
					) : (
						<h3 className="cardTitle font-roboto fs-6 fw-bold mb-0">
							{employeeCountData || 0} Employee Analysis
						</h3>
					)}
				</div>
				<div className="row">
					<div className="col-3">
						<CardComponent>
							<div className="d-flex flex-column  justify-content-start mt-0">
								<div className={` mb-3  `}>
									<span className="fs-14 fw-bolder font-roboto linechart-title d-flex align-items-center">
										<Stars
											className="me-1"
											sx={{
												color: '#49BA46',
												fontSize: '1.25rem'
											}}
										/>{' '}
										Best Performers
									</span>
								</div>
								{isLoading ? (
									<div
										className="d-flex align-items-center justify-content-center w-100"
										style={{
											height: '200px'
										}}
									>
										<Spin size="middle" />
									</div>
								) : bestPromerData?.count > 0 ? (
									<>
										<div
											className="  row "
											style={{ width: '100%', height: '200px' }}
										>
											<div className="d-flex flex-row justify-content-between ">
												<div className="d-flex flex-column align-items-start  ">
													<div className="">
														<h3 className="font-roboto fw-bolder mb-2 fs-4 m-0 p-0">
															{bestPromerData?.count}
														</h3>
													</div>

													<div
														style={{
															marginLeft: '-10px'
														}}
													>
														{bestPromerData?.percentageChange > 0 ? (
															<span
																className={`font-roboto fs-12 fw-semibold mb-1  arrow-outward-profit`}
															>
																<ArrowOutwardOutlinedIcon />
															</span>
														) : (
															<span
																className={`font-roboto fs-12 fw-semibold mb-1  arrow-outward-loss`}
															>
																<SouthEastIcon />
															</span>
														)}
														<span className="ps-2 ">
															{bestPromerData?.percentageChange}{' '}
															<span
																className={`${
																	bestPromerData?.percentageChange > 0
																		? 'arrow-outward-profit'
																		: 'arrow-outward-loss'
																}`}
															>
																%
															</span>{' '}
														</span>
													</div>
												</div>

												{/* <div className="col-12 col-md-5 col-lg-5 p-0">
													<div
														className=""
														style={{
															marginTop: '-8px'
														}}
													>
														<AnalysisLineChart
															myData={makeLineGraphData(
																2,
																6
															)}
															areaColor={
																bestPromerData?.percentageChange > 0
																	? '#5EBF5B'
																	: '#EA6F6F'
															}
														/>
													</div>
												</div> */}
											</div>

											<div className="mt-4">
												{bestPromerData?.members
													?.slice(0, 3)
													.map((dataElement) => (
														<div
															className={`d-flex justify-content-between align-items-center mb-3`}
														>
															<div
																className="d-flex justify-content-center align-items-center"
																style={{}}
															>
																<AvatarGroupComponent
																	name={dataElement?.name}
																/>
																<span className="ps-2 font-roboto fs-12 fw-normal analysisData-color">
																	{dataElement.name}
																</span>
															</div>
															<div className="fw-bolder fs-10 analysisData-color font-roboto">
																{dataElement.value}
															</div>
														</div>
													))}
												{/* <div className="d-flex justify-content-start align-items-center analysisData-color fw-bolder mt-1 ms-1 view-all-info">
													<span className="me-1 fs-10 font-nunito">
														View all
													</span>
													<ArrowForwardIosRounded
														sx={{
															color: '#4796DF',
															fontSize: '10px'
														}}
														className="icon"
													/>
												</div> */}
											</div>
										</div>
									</>
								) : (
									<div
										className="d-flex align-items-center justify-content-center w-100"
										style={{ minHeight: '200px' }}
									>
										No Data Found
									</div>
								)}
							</div>
						</CardComponent>
					</div>
					<div className="col-3">
						<CardComponent>
							<div className="d-flex flex-column  justify-content-start mt-0">
								<div className={` mb-3  `}>
									<span className="fs-14 fw-bolder font-roboto linechart-title d-flex align-items-center">
										<Error
											className="me-1"
											sx={{
												color: '#DE5959',
												fontSize: '1.25rem'
											}}
										/>{' '}
										Defaulters
									</span>
								</div>
								{isLoading ? (
									<div
										className="d-flex align-items-center justify-content-center w-100"
										style={{
											height: '200px'
										}}
									>
										<Spin size="middle" />
									</div>
								) : defaultersData?.count > 0 ? (
									<>
										<div
											className="  row "
											style={{ width: '100%', height: '200px' }}
										>
											<div className="d-flex flex-row justify-content-between ">
												<div className="d-flex flex-column align-items-start  ">
													<div className="">
														<h3 className="font-roboto fw-bolder mb-2 fs-4 m-0 p-0">
															{defaultersData?.count}
														</h3>
													</div>

													<div
														style={{
															marginLeft: '-10px'
														}}
													>
														{defaultersData?.percentageChange > 0 ? (
															<span
																className={`font-roboto fs-12 fw-semibold mb-1  arrow-outward-profit`}
															>
																<ArrowOutwardOutlinedIcon />
															</span>
														) : (
															<span
																className={`font-roboto fs-12 fw-semibold mb-1  arrow-outward-loss`}
															>
																<SouthEastIcon />
															</span>
														)}
														<span className="ps-2 ">
															{defaultersData?.percentageChange}{' '}
															<span
																className={`${
																	defaultersData?.percentageChange > 0
																		? 'arrow-outward-profit'
																		: 'arrow-outward-loss'
																}`}
															>
																%
															</span>{' '}
														</span>
													</div>
												</div>

												{/* <div className="col-12 col-md-5 col-lg-5 p-0">
													<div
														className=""
														style={{
															marginTop: '-8px'
														}}
													>
														<AnalysisLineChart
															myData={makeLineGraphData(
																2,
																6
															)}
															areaColor={
																defaultersData?.percentageChange > 0
																	? '#5EBF5B'
																	: '#EA6F6F'
															}
														/>
													</div>
												</div> */}
											</div>

											<div className="mt-4">
												{defaultersData?.members
													?.slice(0, 3)
													.map((dataElement) => (
														<div
															className={`d-flex justify-content-between align-items-center mb-3`}
														>
															<div
																className="d-flex justify-content-center align-items-center"
																style={{}}
															>
																<AvatarGroupComponent
																	name={dataElement?.name}
																/>
																<span className="ps-2 font-roboto fs-12 fw-normal analysisData-color">
																	{dataElement.name}
																</span>
															</div>
															<div className="fw-bolder fs-10 analysisData-color font-roboto">
																{dataElement.value}
															</div>
														</div>
													))}
												{/* <div className="d-flex justify-content-start align-items-center analysisData-color fw-bolder mt-1 ms-1 view-all-info">
													<span className="me-1 fs-10 font-nunito">
														View all
													</span>
													<ArrowForwardIosRounded
														sx={{
															color: '#4796DF',
															fontSize: '10px'
														}}
														className="icon"
													/>
												</div> */}
											</div>
										</div>
									</>
								) : (
									<div
										className="d-flex align-items-center justify-content-center w-100"
										style={{ minHeight: '200px' }}
									>
										No Data Found
									</div>
								)}
							</div>
						</CardComponent>
					</div>
					<div className="col-3">
						<CardComponent>
							<div className="d-flex flex-column  justify-content-start mt-0">
								<div className={` mb-3  `}>
									<span className="fs-14 fw-bolder font-roboto linechart-title">
										Pending Issues
									</span>
								</div>
								{isLoading ? (
									<div
										className="d-flex align-items-center justify-content-center w-100"
										style={{
											height: '200px'
										}}
									>
										<Spin size="middle" />
									</div>
								) : pendingIssuesData?.count > 0 ? (
									<>
										<div
											className="  row "
											style={{ width: '100%', height: '200px' }}
										>
											<div className="d-flex flex-row justify-content-between ">
												<div className="d-flex flex-column align-items-start  ">
													<div className="">
														<h3 className="font-roboto fw-bolder mb-2 fs-4 m-0 p-0">
															{pendingIssuesData?.count}
														</h3>
													</div>

													<div
														style={{
															marginLeft: '-10px'
														}}
													>
														{pendingIssuesData?.percentageChange > 0 ? (
															<span
																className={`font-roboto fs-12 fw-semibold mb-1  arrow-outward-profit`}
															>
																<ArrowOutwardOutlinedIcon />
															</span>
														) : (
															<span
																className={`font-roboto fs-12 fw-semibold mb-1  arrow-outward-loss`}
															>
																<SouthEastIcon />
															</span>
														)}
														<span className="ps-2 ">
															{pendingIssuesData?.percentageChange}{' '}
															<span
																className={`${
																	pendingIssuesData?.percentageChange > 0
																		? 'arrow-outward-profit'
																		: 'arrow-outward-loss'
																}`}
															>
																%
															</span>{' '}
														</span>
													</div>
												</div>

												{/* <div className="col-12 col-md-5 col-lg-5 p-0">
													<div
														className=""
														style={{
															marginTop: '-8px'
														}}
													>
														<AnalysisLineChart
															myData={makeLineGraphData(
																2,
																6
															)}
															areaColor={
																pendingIssuesData?.percentageChange > 0
																	? '#5EBF5B'
																	: '#EA6F6F'
															}
														/>
													</div>
												</div> */}
											</div>

											<div className="mt-4">
												{pendingIssuesData?.memberPendingIssueDTO
													?.slice(0, 3)
													.map((dataElement) => (
														<div
															className={`d-flex justify-content-between align-items-center mb-3`}
														>
															<div
																className="d-flex justify-content-center align-items-center"
																style={{}}
															>
																<AvatarGroupComponent
																	name={dataElement?.name}
																/>
																<span className="ps-2 font-roboto fs-12 fw-normal analysisData-color">
																	{dataElement.name}
																</span>
															</div>
															<div className="fw-bolder fs-10 analysisData-color font-roboto">
																{dataElement.issueCount}
															</div>
														</div>
													))}
												{/* <div className="d-flex justify-content-start align-items-center analysisData-color fw-bolder mt-1 ms-1 view-all-info">
													<span className="me-1 fs-10 font-nunito">
														View all
													</span>
													<ArrowForwardIosRounded
														sx={{
															color: '#4796DF',
															fontSize: '10px'
														}}
														className="icon"
													/>
												</div> */}
											</div>
										</div>
									</>
								) : (
									<div
										className="d-flex align-items-center justify-content-center w-100"
										style={{ minHeight: '200px' }}
									>
										No Data Found
									</div>
								)}
							</div>
						</CardComponent>
					</div>
					<div className="col-3">
						<CardComponent>
							<div className="d-flex flex-column  justify-content-start mt-0">
								{isLoading ? (
									<div
										className="d-flex align-items-center justify-content-center w-100"
										style={{
											height: '200px'
										}}
									>
										<Spin size="middle" />
									</div>
								) : (
									<>
										<div className={` mb-3  `}>
											<span className="fs-14 fw-bolder font-roboto linechart-title">
												Work Load Rate
											</span>
										</div>
										{workLoadData?.averageWorkloadThisPeriod > 0 ? (
											<div className="">
												<>
													<div
														className="  row "
														style={{ width: '100%', height: '200px' }}
													>
														<div className="d-flex flex-row justify-content-between ">
															<div className="d-flex flex-column align-items-start  ">
																<div className="">
																	<h3 className="font-roboto fw-bolder mb-2 fs-4 m-0 p-0">
																		{workLoadData?.averageWorkloadThisPeriod ||
																			0}
																	</h3>
																</div>

																<div
																	style={{
																		marginLeft: '-10px'
																	}}
																>
																	{workLoadData?.percentageChange > 0 ? (
																		<span
																			className={`font-roboto fs-12 fw-semibold mb-1  arrow-outward-profit`}
																		>
																			<ArrowOutwardOutlinedIcon />
																		</span>
																	) : (
																		<span
																			className={`font-roboto fs-12 fw-semibold mb-1  arrow-outward-loss`}
																		>
																			<SouthEastIcon />
																		</span>
																	)}
																	<span className="ps-2 ">
																		{workLoadData?.percentageChange}{' '}
																		<span
																			className={`${
																				workLoadData?.percentageChange > 0
																					? 'arrow-outward-profit'
																					: 'arrow-outward-loss'
																			}`}
																		>
																			%
																		</span>{' '}
																	</span>
																</div>
															</div>
															{/* 
																<div className="col-12 col-md-5 col-lg-5 p-0">
																	<div
																		className=""
																		style={{
																			marginTop: '-8px'
																		}}
																	>
																		<AnalysisLineChart
																			myData={makeLineGraphData(
																				2,
																				6
																			)}
																			areaColor={
																				workLoadData?.percentageChange > 0
																					? '#5EBF5B'
																					: '#EA6F6F'
																			}
																		/>
																	</div>
																</div> */}
														</div>

														<div className="mt-4">
															{workLoadData?.memberWorkloadDTOS
																?.slice(0, 3)
																.map((dataElement) => (
																	<div
																		className={`d-flex justify-content-between align-items-center mb-3`}
																	>
																		<div
																			className="d-flex justify-content-center align-items-center"
																			style={{}}
																		>
																			<AvatarGroupComponent
																				name={dataElement?.name}
																			/>
																			<span className="ps-2 font-roboto fs-12 fw-normal analysisData-color">
																				{dataElement.name}
																			</span>
																		</div>
																		<div className="fw-bolder fs-10 analysisData-color font-roboto">
																			{dataElement.workloadPercentage}%
																		</div>
																	</div>
																))}
															{/* <div className="d-flex justify-content-start align-items-center analysisData-color fw-bolder mt-1 ms-1 view-all-info">
																	<span className="me-1 fs-10 font-nunito">
																		View all
																	</span>
																	<ArrowForwardIosRounded
																		sx={{
																			color: '#4796DF',
																			fontSize: '10px'
																		}}
																		className="icon"
																	/>
																</div> */}
														</div>
													</div>
												</>
											</div>
										) : (
											<div
												className="d-flex align-items-center justify-content-center w-100"
												style={{ minHeight: '200px' }}
											>
												No Data Found
											</div>
										)}
									</>
								)}
							</div>
						</CardComponent>
					</div>
				</div>
			</div>
		</>
	)
}

export default ProjectManagement
