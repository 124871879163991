import React, { useEffect } from 'react'
import ModalComponent from '../ModalComponent'
import { useState } from 'react'
import '../../../styles/ComponentsStyles.css'
import DropdownFilter from '../DropdownFilter'
import { Button, Progress, Select, Spin, Tabs } from 'antd'
import MemberPerformance from '../../views/Projects/MembersDetails/MemberPerformance'
import MemberChats from '../../views/Projects/MembersDetails/MemberChats'
import MemberSharedFiles from '../../views/Projects/MembersDetails/MemberSharedFiles'
import bestPerformerIcon from '../../../assets/images/best-performer-icon.svg'
import defaulterIcon from '../../../assets/images/defaulter-icon.svg'
import AvatarGroupComponent from '../AvatarGroupComponent'
import { useProject } from '../../../utils/componentUtils/memberUtils/useAddProject'
import { getAllProjects } from '../../../API/projectsAPI'
import { IconButton } from '@mui/material'
import { Delete, Error, ExpandMore, Launch, Stars } from '@mui/icons-material'
import {
	editMember,
	removeMember,
	bulkCreateMember
} from '../../../API/MembersAPI'
import ButtonComponent from '../ButtonComponent'
import useMessage from 'antd/es/message/useMessage'
import { getRoles } from '../../../API/rolesApi'
import { getMonthNames, transformData } from '../../../utils/helper'
import dayjs from 'dayjs'

const MemberAnalysis = ({ open, onClose, employeeData, performanceData }) => {
	const { projectSelect, selectedProjects, setSelectedProjects } = useProject()

	const memberData = employeeData ?? []

	const [allProjects, setAllProjects] = useState([])
	const [roleOptions, setRoleOptions] = useState([])
	const [initialProjects, setInitialProjects] = useState([])
	const [newProjects, setNewProjects] = useState([])
	const [initialProjectIds, setInitialProjectIds] = useState([])
	const [isTouched, setIsTouched] = useState(false)
	const [message, contextHolder] = useMessage()
	const [performanceMonths, setPerfomanceMonths] = useState([])

	const [isLoading, setIsLoading] = useState(true)

	const items = [
		{
			key: '1',
			title: 'Performance Analytics',
			children: <MemberAnalysis />
		}
	]

	const getAllProjectsData = async () => {
		try {
			const res = await getAllProjects()
			const { data = [] } = res
			setAllProjects(
				data?.map((project) => {
					return {
						id: project?.id,
						name: project?.name
					}
				})
			)

			setSelectedProjects(employeeData?.projects?.map((project) => project?.id))
			setInitialProjectIds(
				employeeData?.projects?.map((project) => project?.id)
			)
			setInitialProjects(
				employeeData?.projects?.map((project) => ({
					id: project?.id,
					roleId: project?.role?.id || null
				}))
			)
			setIsLoading(false)
		} catch (error) {
			setAllProjects([])
		}
	}

	const getAllRoles = async () => {
		try {
			const res = await getRoles()
			const { data = [] } = res
			let temp =
				data &&
				data?.length !== 0 &&
				data?.map((rl) => ({
					value: rl?.id,
					label: rl?.name
				}))
			setRoleOptions([...temp])
		} catch (error) {
			debugger
		}
	}

	useEffect(() => {
		getAllProjectsData()
		getAllRoles()
	}, [])

	useEffect(() => {
		if (allProjects && allProjects.length !== 0) {
			if (
				selectedProjects.length > [...initialProjects, ...newProjects].length
			) {
				let arr = [...initialProjects, ...newProjects]

				let newlyAddedIds = selectedProjects.find(
					(id) => !arr.some((obj) => obj.id === id)
				)

				let temp = [...newProjects]
				temp.push({
					id: newlyAddedIds,
					roleId: 3
				})
				setNewProjects([...temp])
			}
		}
	}, [selectedProjects])

	// useEffect(() => {
	// 	if (performanceData) {
	// 		let months = performanceData?.map((value) => value.month)
	// 		setPerfomanceMonths(getMonthNames(months))
	// 	}
	// }, [performanceData])

	///To Do add member to project after manipulation
	const addMemberToProject = async () => {
		let projects = newProjects.map((pr) => ({
			projectId: pr.id,
			roleId: pr.roleId
		}))
		let body = [
			{
				id: employeeData?.id,
				projects: [...projects]
			}
		]
		try {
			const res = await bulkCreateMember(body)
			message.success('Projects added to member.')
			onClose()
		} catch (error) {
			message.error('Unable to add projects to members.')
		}
	}

	const updateMembersData = async (data, projectId) => {
		try {
			const res = await removeMember(data)

			let iniIndex = initialProjects?.findIndex((pr) => pr.id === projectId)
			let newIndex = newProjects.findIndex((pr) => pr.id === projectId)

			if (iniIndex !== -1) {
				let temp = [...initialProjects]
				temp.splice(iniIndex, 1)
				setInitialProjects([...temp])
			}

			if (newIndex !== -1) {
				let temp = [...newProjects]

				temp.splice(newIndex, 1)
				setNewProjects([...temp])
			}

			message.success('Project removed successfully.')
		} catch (error) {
			message.error('Unable to remove project.')
			let temp = [...selectedProjects]
			temp.push(projectId)
			setSelectedProjects([...temp])
		}
	}

	const deleteProject = (id, member) => {
		let index = selectedProjects?.findIndex((pr) => pr === id)

		let tempSelPr = [...selectedProjects]

		if (index !== -1) {
			tempSelPr.splice(index, 1)
		}
		setSelectedProjects([...tempSelPr])

		if ([...initialProjectIds]?.includes(id)) {
			updateMembersData([{ id: member?.id, projects: [{ projectId: id }] }], id)
		}
	}

	const addRoleToProject = (roleId, projectId) => {
		if (initialProjects.find((pr) => pr?.id === projectId)) {
			let temp = initialProjects.filter((pr) => pr.id !== projectId)

			setNewProjects([...newProjects, { id: projectId, roleId: roleId }])
			setInitialProjects([...temp])
		} else if (newProjects.find((pr) => pr?.id === projectId)) {
			let temp = newProjects.map((pr) => {
				if (pr?.id === projectId) {
					return {
						id: projectId,
						roleId: roleId
					}
				} else {
					return pr
				}
			})

			setNewProjects([...temp])
		}
	}

	const getInitials = (fullName) => {
		const names = fullName ? fullName?.split(' ') : []
		const firstNameInitial = names[0]?.charAt(0).toUpperCase() || ''
		const lastNameInitial =
			names[names.length - 1]?.charAt(0).toUpperCase() || ''
		return `${firstNameInitial}${lastNameInitial}`
	}

	const formatDate = (dateString) => {
		const date = dayjs(dateString)
		const dayWithSuffix = addOrdinalSuffix(date.date())
		const formattedDate = date.locale('en').format(`Do MMMM, YYYY`)

		return formattedDate.replace(/(\d)(st|nd|rd|th)/, '$1')
	}

	const addOrdinalSuffix = (number) => {
		const suffixes = ['th', 'st', 'nd', 'rd']
		const v = number % 100
		return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
	}

	return (
		<>
			{contextHolder}
			<ModalComponent
				title={memberData?.employeeDetails?.assigneeName}
				open={open}
				onClose={onClose}
				width="65%"
			>
				<div className="d-flex flex-column">
					<h3 className="font-roboto fs-3 fw-bold mb-2 txt-color-tertiary">
						{employeeData?.name}
					</h3>
					<div className="d-flex justify-content-start align-items-center">
						{employeeData?.imageUrl && employeeData?.imageUrl !== '' ? (
							<img
								src={employeeData?.imageUrl}
								alt="employee-avatar"
								id="avatar"
								width={110}
								height={96}
							/>
						) : (
							<div
								className="employee-avatar mt-2 d-flex align-items-center justify-content-center me-2"
								style={{
									backgroundColor: '#AC5B72',
									borderRadius: '2px',
									width: '6.875rem',
									height: '6rem'
								}}
							>
								<span className="text-white fs-1 fw-bold font-roboto">
									{getInitials(employeeData?.name || employeeData?.email)}
								</span>
							</div>
						)}

						<div className="d-flex flex-column ms-2">
							<a
								href={`mailto:${employeeData?.email}`}
								className="font-roboto fw-normal fs-14 text-decoration-none txt-color-primary d-flex align-items-center gap-1"
							>
								{employeeData?.email}
								<Launch
									sx={{
										color: 'inherit',
										fontSize: 'inherit'
									}}
								/>
							</a>
							<span className="font-roboto fw-500 fs-10 txt-color-primary">
								{employeeData?.role?.name ||
									employeeData?.role ||
									'No Role Assigned'}
							</span>
							<span className="font-roboto fw-semibold fs-12 txt-color-secondary mt-2">
								Added on{' '}
								<span className="font-roboto fw-semibold txt-color-tertiary">
									{employeeData?.createdAt && employeeData?.createdAt !== ''
										? formatDate(employeeData?.createdAt)
										: '---'}
								</span>
							</span>
						</div>
					</div>

					<div className="row mt-4 py-4 px-2 member-details-container">
						<div className="col-12 col-md-2">
							<div className="d-flex flex-column">
								<span className="font-roboto txt-color-secondary fs-12">
									Projects
								</span>
								<span className="font-roboto fw-500 fs-6 txt-color-primary">
									{employeeData?.projects?.length > 0
										? employeeData?.projects?.length
										: '---'}
								</span>
							</div>
						</div>
						<div className="col-12 col-md-2">
							<div className="d-flex flex-column">
								<span className="font-roboto txt-color-secondary fs-12">
									Workload rate
								</span>
								<div className="d-flex gap-2 align-items-center fs-10">
									{employeeData?.workloadRate &&
									employeeData?.workloadRate !== '' ? (
										<>
											<span className="font-roboto fw-500 fs-14 txt-color-primary">
												{memberData?.workloadRate?.toFixed(0)}%
											</span>
											{employeeData?.workloadStatus &&
											employeeData?.workloadStatus === 'OVERLOADED' ? (
												<span
													className="rounded-pill"
													style={{
														padding: '4px 6px',
														background: '#FFFFFF',
														color: '#EC9534',
														border: '1px solid #EC9534'
													}}
												>
													overload
												</span>
											) : null}
										</>
									) : (
										'---'
									)}
								</div>
							</div>
						</div>
						<div className="col-12 col-md-2">
							<div className="d-flex flex-column">
								<span className="font-roboto txt-color-secondary fs-12">
									Working hours
								</span>
								{employeeData?.workingHours &&
								employeeData?.workingHours !== '' ? (
									<>
										<span className="font-roboto fw-500 fs-14 txt-color-primary">
											{memberData?.workingHours} hrs
										</span>
									</>
								) : (
									'---'
								)}
							</div>
						</div>
						<div className="col-12 col-md-3">
							<div className="d-flex flex-column">
								<span className="font-roboto txt-color-secondary fs-12">
									Defaulter/Best Performer
								</span>
								<div className="d-flex justify-content-start align-items-center">
									<span className="font-roboto fw-500 fs-14 d-flex justify-content-center align-items-center txt-color-primary">
										<Error
											className="me-1"
											sx={{
												color: '#DE5959',
												fontSize: '1.25rem'
											}}
										/>
										{employeeData?.defaulterCount || 0}
									</span>
									<span className="font-roboto fw-500 fs-14 ms-3 d-flex justify-content-center align-items-center txt-color-primary">
										<Stars
											className="me-1"
											sx={{
												color: '#49BA46',
												fontSize: '1.25rem'
											}}
										/>
										{employeeData?.bestPerformerCount || 0}
									</span>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-3">
							<div className="d-flex flex-column">
								<span className="font-roboto txt-color-secondary fs-12">
									Tasks Completed ( {employeeData?.tasksCompleted || 0} /{' '}
									{employeeData?.totalTasks || 0} )
								</span>
								<span className="d-flex justify-content-start align-items-center">
									<Progress
										percent={memberData?.tasksCompletedPercent?.toFixed(0)}
										strokeColor="#3571A7"
										className="font-roboto fw-500 fs-14 txt-color-primary"
									/>
								</span>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-5 mt-4">
						<label
							className="mb-2 font-roboto fs-12 fw-bold"
							style={{ color: '#555555' }}
						>
							Add to projects
						</label>
						{projectSelect()}
					</div>

					<div className="d-flex flex-column mb-4">
						{selectedProjects?.length > 0 && (
							<label
								className="mb-2 font-roboto fs-12 fw-bold"
								style={{ color: '#555555' }}
							>
								Projects involved in
							</label>
						)}

						<div className="row">
							{isLoading ? (
								<div
									className="loader-div d-flex align-items-center w-100 justify-content-center"
									style={{
										height: '5rem'
									}}
								>
									<Spin size="large" />
								</div>
							) : (
								allProjects?.map((project, index) => {
									if (selectedProjects.includes(project?.id)) {
										return (
											<div className="col-12 col-md-4 col-lg-3" key={index}>
												<div className="d-flex align-items-center justify-content-between projects-involved-container mb-3 ">
													<div className="d-flex align-items-center">
														<AvatarGroupComponent
															size={36}
															name={project?.name}
															imageUrl={project?.imageUrl}
															type="square"
														/>
														<div className="ms-3">
															<span
																className="d-block fs-12 font-roboto fw-500"
																style={{
																	whiteSpace: 'nowrap',
																	overflow: 'hidden',
																	textOverflow: 'ellipsis',
																	width: '6rem'
																}}
															>
																{project?.name}
															</span>
															<Select
																className="role-select border-0 rounded-0 bg-white p-0"
																suffixIcon={
																	<ExpandMore
																		sx={{
																			fontSize: '1rem',
																			color: '#555'
																		}}
																	/>
																}
																options={roleOptions}
																placeholder="Select Role"
																defaultValue={3}
																popupClassName="generic-select-dropdown"
																value={
																	[...initialProjects, ...newProjects]?.find(
																		(pr) => pr?.id === project?.id
																	)?.roleId
																}
																onChange={(e) => {
																	addRoleToProject(e, project?.id)
																}}
															/>
														</div>
													</div>
													<IconButton
														onClick={() =>
															deleteProject(project?.id, memberData)
														}
													>
														<Delete
															sx={{
																color: '#555',
																fontSize: '1rem'
															}}
														/>
													</IconButton>
												</div>
											</div>
										)
									} else {
										return null
									}
								})
							)}
						</div>
					</div>
					{/* <Tabs defaultActiveKey={'1'} items={items} className="dashboard-tabs" /> */}
					{/* <Button
						className="mt-3 font-roboto fs-14 fw-bolder border-0"
						style={{
							color: '#555555',
							width: 'fit-content',
							boxShadow: 'none'
						}}
					>
						Remove Member
					</Button> */}
					{isTouched || newProjects.length !== 0 ? (
						<div className="d-flex align-items-center justify-content-end">
							<ButtonComponent
								text="Save"
								variant="dark"
								click={() => {
									addMemberToProject()
								}}
							/>
						</div>
					) : null}
					{/* <MemberPerformance
					months={performanceMonths}
					categories={transformData(performanceData)}
					/> */}
				</div>
			</ModalComponent>
		</>
	)
}

export default MemberAnalysis
