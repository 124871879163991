import React, { useEffect, useState } from 'react'
import TaskCardComponent from './TaskCardComponent'
import { getTask } from '../../API/TasksAPI'

const TaskEventModal = ({
	modalPosition,
	selectedEvent,
	cardRef,
	cardActionRef,
	setIsReload,
	setSelectedEvent,
	isReload
}) => {
	const [taskData, setTaskData] = useState({})

	const getPaticularTaskData = async (id) => {
		try {
			const res = await getTask(id)
			setTaskData(res?.data)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (selectedEvent?.id) {
			getPaticularTaskData(selectedEvent?.id)
		}
	}, [selectedEvent?.id])

	return (
		<div
			className="custom-modal"
			style={{
				top: `${modalPosition.top}px`,
				left: `${modalPosition.left}px`
			}}
			ref={cardRef}
		>
			<TaskCardComponent
				task={taskData}
				cardActionRef={cardActionRef}
				cardRef={cardRef}
				setIsReload={setIsReload}
				setSelectedEvent={setSelectedEvent}
				isReload={isReload}
			/>
		</div>
	)
}

export default TaskEventModal
