import React, { useEffect, useState } from 'react'
import { getAllMembers } from '../../../API/MembersAPI'
import { Button, Select } from 'antd'
import { Delete, ExpandMore } from '@mui/icons-material'
import AvatarGroupComponent from '../AvatarGroupComponent'
import { IconButton } from '@mui/material'

const { Option } = Select

const MemberSelect = () => {
	const [data, setData] = useState([])
	const [isFail, setIsFail] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

	const [selectedValues, setSelectedValues] = useState([])

	const getData = async () => {
		try {
			const res = await getAllMembers()
			const { data } = res

			setIsLoading(false)
			if (data.length < 1) {
				setIsFail(true)
			} else {
				setData([...data])
			}
		} catch (error) {
			debugger
			setIsFail(true)
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getData()
	}, [])

	return (
		<div className="formField">
			{
				<Select
					mode="multiple"
					value={selectedValues}
					className="member-select"
					menuItemSelectedIcon={false}
					onChange={(value) => {
						setSelectedValues([...value])
					}}
					suffixIcon={<ExpandMore />}
					optionFilterProp="label"
					popupClassName="member-select-popup"
					loading={isLoading}
					placeholder="Select Members"
				>
					{data &&
						data.map((member) => {
							return (
								<Option
									key={member?.id}
									value={member?.id}
									label={`${member?.name} ${member?.email}`}
								>
									<div className="d-flex align-items-center justify-content-between px-2 py-2">
										<div className="d-flex align-items-center">
											<AvatarGroupComponent
												size={40}
												imageUrl={member?.profileImg || ''}
												name={member?.name || ''}
											/>
											<div className="ms-2">
												<p className="fs-12 fw-500 font-roboto txt-color-primary mb-0">
													{member?.name}
												</p>
												<p
													className="fw-400 font-roboto mb-0 txt-color-primary"
													style={{
														fontSize: '11px'
													}}
												>
													{member?.role || 'Member Role'}
												</p>
											</div>
										</div>
										{selectedValues.includes(member?.id) ? (
											<IconButton>
												<Delete
													sx={{
														fontSize: '16px',
														color: '#555'
													}}
												/>
											</IconButton>
										) : (
											<Button className="add-member-btn border-0 rounded-0 px-2 py-1 font-roboto fs-10 txt-color-primary">
												Add
											</Button>
										)}
									</div>
								</Option>
							)
						})}
				</Select>
			}
		</div>
	)
}

export default MemberSelect
