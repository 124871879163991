import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useState } from 'react'
import DrawerComponent from '../DrawerComponent'
import FormComponentProps from '../forms'
import ComponentStyles from '../../../styles/ComponentsStyles.css'
import forms from '../forms/forms.css'
import ButtonComponent from '../ButtonComponent'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import FormComponent from '../forms'

const ProjectsFilter = ({ applyFilter, setApplyFilter }) => {
	const statusOptions = [
		{
			key: '1',
			value: 'Upcoming'
		},
		{
			key: '2',
			value: 'Active'
		},
		{
			key: '3',
			value: 'On Hold'
		},
		{
			key: '4',
			value: 'Completed'
		},
		{
			key: '5',
			value: 'Archived'
		}
	]

	const startedOnOptions = [
		{
			key: '1',
			value: 'This month'
		},
		{
			key: '2',
			value: 'This quarter'
		},
		{
			key: '3',
			value: 'This year'
		},
		{
			key: '4',
			value: 'Last year'
		}
	]

	const initialValues = {
		minAmount: 0,
		maxAmount: 1000,
		startDate: dayjs(),
		endDate: dayjs()
	}

	const validationSchema = Yup.object().shape({
		minAmount: Yup.number().required('Required!'),
		maxAmount: Yup.number().required('Required!'),
		startDate: Yup.date().required('Required'),
		endDate: Yup.date().required('Required')
	})

	const [formInitialValues, setFormInitialValues] = useState(initialValues)

	return (
		<DrawerComponent
			drawerTitle="Filters"
			drawerWidth={372}
			isDrawerOpen={applyFilter}
			setIsDrawerOpen={setApplyFilter}
		>
			<Formik
				initialValues={formInitialValues}
				validationSchema={validationSchema}
				enableReinitialize={true}
				onSubmit={(values) => {
					console.log('submitting!')
				}}
			>
				{({ handleSubmit, values, resetForm, isValid, dirty }) => {
					return (
						<Form>
							<div className="d-flex flex-column mt-4">
								<span className="filter-headings font-roboto fw-normal fs-14 mb-2">
									Status
								</span>
								<div className="mb-4">
									{statusOptions.map((element) => (
										<div
											className="status-border px-2 py-2 me-2 mb-2"
											style={{ width: 'fit-content', float: 'left' }}
										>
											<span className="filter-values font-roboto fs-12 fw-normal">
												{element.value}
											</span>
										</div>
									))}
								</div>
							</div>

							<span className="filter-headings font-roboto fw-normal">
								Profit/Loss Amount
							</span>
							<div className="d-flex justify-content-between align-items-center mt-2">
								<div style={{ width: '30%' }}>
									<FormComponentProps
										name="minAmount"
										type="number"
										control="input-number"
										prefix={true}
										showButtons={false}
										className="mt-2"
									/>
								</div>
								<span
									className="filter-values font-roboto fw-normal"
									style={{ marginTop: '-24px' }}
								>
									to
								</span>
								<div style={{ width: '30%' }}>
									<FormComponentProps
										name="maxAmount"
										type="number"
										control="input-number"
										prefix={true}
										showButtons={false}
										className="mt-2"
									/>
								</div>
							</div>
							<div className="d-flex justify-content-between align-items-center mb-3 mt-2">
								<div style={{ width: '48%' }}>
									<span className="filter-headings font-roboto fs-14 fw-normal">
										Start date
									</span>
									<FormComponent
										name="startDate"
										placeholder="Start date"
										control="date"
										className="mt-2"
										style={{ width: '100%' }}
									/>
								</div>
								<div style={{ width: '48%' }}>
									<span className="filter-headings font-roboto fs-14 fw-normal">
										Due date
									</span>
									<FormComponent
										name="endDate"
										placeholder="End date"
										control="date"
										className="mt-2"
										style={{ width: '100%' }}
									/>
								</div>
							</div>

							<div className="d-flex flex-column">
								<span className="filter-headings font-roboto fs-14 fw-normal mb-2">
									Started On
								</span>
								<div className="mb-5">
									{startedOnOptions.map((element) => (
										<div
											className="status-border px-2 py-2"
											style={{
												width: 'fit-content',
												float: 'left',
												marginRight: '6px'
											}}
										>
											<span className="filter-values font-roboto fs-12 fw-normal">
												{element.value}
											</span>
										</div>
									))}
								</div>
								<div className="d-flex justify-content-end align-items-center mt-5">
									<ButtonComponent
										variant="light"
										text="Clear all"
										extraclassName="ms-2"
									/>
									<ButtonComponent
										variant="dark"
										text="Apply Filters"
										extraclassName="ms-2"
									/>
								</div>
							</div>
						</Form>
					)
				}}
			</Formik>
		</DrawerComponent>
	)
}

export default ProjectsFilter
