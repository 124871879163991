import { useEffect, useState } from 'react'
import ModalComponent from '../ModalComponent'
import { Tabs } from 'antd'
import ComponentsStyles from '../../../styles/ComponentsStyles.css'
import AddMembers from '../../views/Projects/NewTask/AddMembers'
import NewTaskInformation from '../../views/Projects/ProjectsDetails/NewTaskInformation'
import AddNewTaskMembers from '../../views/Projects/ProjectsDetails/AddNewTaskMembers'
import SprintForm from '../../views/Forms/SprintForm'
import { getAllProjects } from '../../../API/projectsAPI'
import AddSprintMembers from '../../views/Projects/ProjectsDetails/AddNewSprintMembers'

const CreateNewSprint = ({
	open,
	onClose,
	setOpen,
	projectId,
	projectName,
	setNewSprintAdded,
	editData,
	setEditData,
	getData
}) => {
	const [activeKey, setActiveKey] = useState('1')

	const handleTabChange = (key) => {
		setActiveKey(key)
	}

	const [formData, setFormData] = useState({})
	const [dataSelected, setDataSelected] = useState([])
	const [editNewSprint, setEditNewSprint] = useState(false)
	const [projectOptions, setProjectOptions] = useState([])
	const [membersData, setMembersData] = useState([])

	const getProjects = async () => {
		try {
			const res = await getAllProjects()
			const { data = [] } = res
			console.log(data)
			setProjectOptions(
				data?.map((project) => {
					return {
						value: project?.id,
						label: project?.name,
						clientId: project?.clientId || ''
					}
				})
			)
		} catch (error) {
			console.log('Error while fetching all projects ', error)
		}
	}

	useEffect(() => {
		getProjects()
	}, [])

	//const [membersData,setMembersData]=useState({})
	//const [formSubmitted,setFormSubmitted]=useState()

	const items = [
		{
			key: '1',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '1' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">1</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '1' ? '#262626' : '#9D9D9D' }}
					>
						Sprint Information
					</span>
				</div>
			),
			children: (
				<SprintForm
					isUpdate={false}
					handleTabChange={handleTabChange}
					projectOptions={projectOptions}
					membersData={membersData}
					formData={formData}
					setFormData={setFormData}
					editData={editData}
					setMembersData={setMembersData}
					projectId={projectId}
				/>
			)
		},
		{
			key: '2',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '2' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">2</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '2' ? '#262626' : '#9D9D9D' }}
					>
						Add Members
					</span>
				</div>
			),
			children: (
				<AddSprintMembers
					handleTabChange={handleTabChange}
					setMembersData={setMembersData}
					formData={formData}
					onClose={onClose}
					dataSelected={dataSelected}
					editNewTask={editNewSprint}
					setEditNewTask={setEditNewSprint}
					setFormData={setFormData}
					role={''}
					projectOptions={projectOptions}
					membersData={membersData}
					editData={editData}
					setEditData={setEditData}
					getData={getData}
				/>
			)
		}
	]
	return (
		<ModalComponent title="Create a Sprint" open={open} onClose={onClose}>
			<div className="container-fluid">
				<Tabs
					defaultActiveKey={'1'}
					items={items}
					activeKey={activeKey}
					className="create-project-tabs mt-4"
					onChange={handleTabChange}
				/>
			</div>
		</ModalComponent>
	)
}

export default CreateNewSprint
