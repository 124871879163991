import { useState, useEffect } from 'react'
import * as Yup from 'yup'
import '../../../../styles/SelectListStyle.css'
import SelectDropDown from '../../../shared/forms/SelectDropDown'
import profile from '../../../../assets/images/Imageprofile.png'
import { Avatar, Select } from 'antd'
import deletedIcon from '../../../../assets/images/delete_outline.svg'
import ButtonComponent from '../../../shared/ButtonComponent'
import { getAllMembers } from '../../../../API/MembersAPI'
import { createTask, handleTaskStatus } from '../../../../API/TasksAPI'
import { useMember } from '../../../../utils/componentUtils/memberUtils/useAddMember'
import useInviteInput from '../../../../hooks/useInviteInput'
import AvatarGroupComponent from '../../../shared/AvatarGroupComponent'
import {
	createSprint,
	getSprints,
	updateSprint
} from '../../../../API/sprintAPI'

const AddSprintMembers = ({
	handleTabChange,
	formData,
	onClose,
	getData,
	dataSelected,
	editNewTask = false,
	setEditNewTask,
	projectOptions,
	setMembersData,
	setFormData,
	membersData,
	editData,
	setEditData
}) => {
	const [members, setMembers] = useState([])
	const { memberSelect, selectedValues, setSelectedValues } = useMember()
	const { invited, getInviteInput } = useInviteInput()

	const removeMember = (id) => {
		setMembers((prev) => prev.filter((member) => member.id !== id))
		setSelectedValues((prev) => prev.filter((member) => member !== id))
	}

	const [allMembers, setAllMembers] = useState([])

	useEffect(() => {
		if (!Object?.keys(editData)?.length) {
			let addedNewMember = allMembers?.filter((item) => {
				if (selectedValues?.includes(item?.id)) {
					return item
				}
			})

			setMembers(addedNewMember)
			// setMembersData(addedNewMember)
		}
	}, [selectedValues, allMembers])

	useEffect(() => {
		if (membersData) {
			setMembers(membersData)
		}
	}, [membersData])

	const getMembers = async () => {
		try {
			const res = await getAllMembers()
			const { data = [] } = res
			setAllMembers(
				data?.map((member) => {
					return {
						id: member?.id,
						name: member?.name,
						avatar: member?.imageUrl,
						subTitle: 'Project Lead',
						email: member?.email
					}
				})
			)
		} catch (error) {
			console.log('Error while fetching members ', error)
		}
	}

	useEffect(() => {
		getMembers()
	}, [])

	useEffect(() => {
		if (editNewTask) {
			setSelectedValues(dataSelected?.[0]?.members?.map((member) => member?.id))
		}
	}, [editNewTask])

	const addNewSprint = async () => {
		let body = { ...formData }
		console.log(body)
		body.members = members.map((value) => ({
			id: value.id
		}))

		debugger

		try {
			let res
			if (Object?.keys(editData)?.length) {
				body.id = editData.id
				res = await updateSprint(body)
			} else {
				res = await createSprint(body)
			}
			if (res?.status === 201 || res?.status === 200) {
				onClose()
				setEditData({})
			}
			getData()
		} catch (error) {
			console.log('Error while creating a new task is ', error)
		}
	}

	return (
		<>
			<div className="">
				<label className="fs-14 fw-bold mb-2 font-roboto integration-content">
					{' '}
					Add Members
				</label>
				{memberSelect()}
			</div>
			<div className="mt-4">
				<h6
					style={{ color: '#555' }}
					className="fw-semibold font-roboto txt-color-primary fs-14"
				>
					Add members as
				</h6>
				<p className="font-roboto fs-12 ">
					Define role for each members. You can also customise or add new roles
					in{' '}
					<span role="button" style={{ color: '#204464' }}>
						Settings
					</span>
				</p>
				{members &&
					members.length !== 0 &&
					members?.map((member) => {
						return (
							<div
								className="d-flex align-items-center  justify-content-between mb-4 "
								key={member.id}
							>
								<div className="d-flex  align-items-center ">
									<AvatarGroupComponent
										name={member?.name || member?.email || ''}
										size={40}
										imageUrl={member?.imageUrl}
									/>

									<div className="ms-2">
										<h6 className="fs-12 mb-0 font-roboto leading-1 ">
											{member.name}
										</h6>
										<span className="font-roboto fs-11 fw-normal">
											{member?.email}
										</span>
									</div>
								</div>
								<div className="d-flex align-items-center">
									<img
										src={deletedIcon}
										alt=""
										className="cursor-pointer ms-3"
										onClick={() => removeMember(member.id)}
									/>
								</div>
							</div>
						)
					})}

				<div className="mt-4">
					<h6
						style={{ color: '#555' }}
						className="fw-semibold font-roboto txt-color-primary fs-14"
					>
						Invite new members
					</h6>
					{getInviteInput()}
				</div>

				<div className="d-flex align-items-center justify-content-end mt-3">
					<ButtonComponent
						variant="light"
						text="Back"
						click={() => handleTabChange('1')}
					/>
					<ButtonComponent
						variant="dark"
						extraclassName="ms-2"
						text="Save"
						click={addNewSprint}
					/>
				</div>
			</div>
		</>
	)
}

export default AddSprintMembers
