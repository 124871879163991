import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import FormComponent from '../../../shared/forms'
import ButtonComponent from '../../../shared/ButtonComponent'
import { getAllClients } from '../../../../API/clientAPI'

const AddProjectInformation = ({ handleTabChange, setFormData, formData }) => {
	const [clients, setClients] = useState([])

	const formInitialValues = {
		projectName: formData?.projectName || '',
		projectStatus: formData?.projectStatus || null,
		projectDescription: formData?.projectDescription || '',
		startDate: formData?.startDate || null,
		dueDate: formData?.dueDate || null,
		// api implement left
		parojectImage: formData?.projectImage || '',
		clientId: formData?.clientId || ''
	}

	const formValidationSchema = Yup.object({
		projectName: Yup.string().required('Project name is required'),
		projectStatus: Yup.string().required('Project status is required'),
		projectDescription: Yup.string(),
		startDate: Yup.date().required('Start date is required'),
		dueDate: Yup.date()
			.required('End date is required')
			.min(Yup.ref('startDate'), 'Due date should not be before start date.')
		// clientId: Yup.string().required('Required')
	})

	const statusOptions = [
		{
			value: 'On Hold',
			label: 'On hold'
		},
		{
			value: 'Active',
			label: 'Active'
		},
		{
			value: 'Completed',
			label: 'Completed'
		},
		{
			value: 'Upcoming',
			label: 'Upcoming'
		}
	]

	const getClientsData = async () => {
		try {
			const res = await getAllClients()
			const { data } = res

			setClients(
				data && data?.length !== 0
					? data?.map((client) => ({
							value: client?.id,
							label: client?.name
					  }))
					: []
			)
		} catch (error) {}
	}

	useEffect(() => {
		getClientsData()
	}, [])

	return (
		<>
			<Formik
				initialValues={formInitialValues}
				validationSchema={formValidationSchema}
				enableReinitialize={true}
				onSubmit={(values) => {
					handleTabChange('2')
					setFormData(values)
				}}
			>
				{({ handleSubmit, values, setFieldValue }) => {
					return (
						<Form>
							<div className="row">
								<div className="col-12">
									<FormComponent
										control="upload-image"
										placeholder="Select a image for your project"
										label="Project Image"
										name="projectImage"
										setFieldValue={setFieldValue}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										name="projectName"
										placeholder="Enter project name"
										control="input"
										label="Project Name"
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponent
										name="projectStatus"
										placeholder="Select Status"
										control="generic-select"
										options={statusOptions}
										label="Project Status"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										name="clientId"
										placeholder="Select Client"
										control="generic-select"
										options={clients}
										label="Select client (optional)"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-12">
									<FormComponent
										name="projectDescription"
										placeholder="Enter project description e.g Design Revamp for xyz client"
										control="input"
										label="Project Description (optional)"
										labelClass="create-project-headings"
									/>
								</div>
							</div>

							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										name="startDate"
										placeholder="Enter start date"
										control="date-picker"
										label="Start Date"
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponent
										name="dueDate"
										placeholder="Select due date"
										control="date-picker"
										label="Due Date"
									/>
								</div>
							</div>
							<div className="d-flex justify-content-end align-items-center mt-4">
								<ButtonComponent
									variant="dark"
									text="Next"
									click={handleSubmit}
								/>
							</div>
						</Form>
					)
				}}
			</Formik>
		</>
	)
}

export default AddProjectInformation
