import { SortableContext, useSortable } from '@dnd-kit/sortable'
import TrashIcon from '@mui/icons-material/DeleteOutline'
import { CSS } from '@dnd-kit/utilities'
import { useMemo, useState } from 'react'
import PlusIcon from '@mui/icons-material/Add'

import TaskCard from './TaskCard'
import { Checkbox, Input } from 'antd'
import { MoreVert } from '@mui/icons-material'
import ContextMenu from '../../../../shared/ContextMenu'
import { KanbanContextList } from '../../../../../utils/staticData'
import GenericDelete from '../../../../shared/Modals/GenericDelete'
import CreateNewTaskModal from '../../../../shared/Modals/CreateNewTaskModal'
import ModalComponent from '../../../../shared/ModalComponent'

function ColumnContainer({
	column,
	deleteColumn,
	updateColumn,
	createTask,
	tasks,
	deleteTask,
	updateTask,
	setIsOpen,
	isOpen,
	updateColumnApi,
	setIsCreateTask,
	isCreateTask,
	dataSelected,
	setEditNewTask,
	editNewTask,
	getTaskData
}) {
	const [editMode, setEditMode] = useState(false)

	const tasksIds = useMemo(() => {
		return tasks.map((task) => task.id)
	}, [tasks])

	const {
		setNodeRef,
		attributes,
		listeners,
		transform,
		transition,
		isDragging
	} = useSortable({
		id: column.id,
		data: {
			type: 'Column',
			column
		},
		disabled: editMode
	})

	const style = {
		transition,
		transform: CSS.Transform.toString(transform)
	}

	if (isDragging) {
		return (
			<div
				ref={setNodeRef}
				style={{
					width: '24rem',
					backgroundColor: '#fff'
				}}
				className=" opacity-40 border border-pink-500 w-350 h-500 max-h-500 rounded-md d-flex flex-column"
			></div>
		)
	}

	const KanbanContextList = [
		{
			key: '1',
			label: (
				<div className="p-2" onClick={() => setIsOpen(true)}>
					Delete
				</div>
			)
		},
		{ key: '2', label: <div className="p-2">coming soon..</div> }
	]

	return (
		<div
			ref={setNodeRef}
			// style={style}
		>
			{/* Column title */}
			<div
				{...attributes}
				{...listeners}
				className={`  bg-white px-2 fw-semibold border-top-0 border-start-0 border-end-0 d-flex align-items-center justify-content-between kanban-column-head`}
				style={{
					border: `2px solid ${column?.color}`,
					width: '24rem'
				}}
			>
				<div className="d-flex bg-white">
					{!editMode && (
						<>
							<div className=" d-flex justify-content-center align-items-center px-2  text-sm rounded-circle">
								<div className="d-flex gap-2 align-items-center ">
									<button
										className="d-flex add-task-btn-shrt border-0 bg-transparent"
										onClick={() => {
											createTask(column.id)
										}}
									>
										<PlusIcon
											sx={{
												fontSize: '16px',
												color: '#555'
											}}
										/>
									</button>

									<div
										className="fs-14 txt-color-tertiary fw-semibold"
										onClick={() => {
											setEditMode(true)
										}}
									>
										<span className="font-roboto">{column?.name}</span>
										<span className="ms-2 font-roboto">{`(${tasks?.length})`}</span>
									</div>
								</div>
							</div>
						</>
					)}

					{editMode && (
						<Input
							placeholder="Basic usage"
							className="bg-white form-control kanban-input"
							value={column?.name}
							onChange={(e) => {
								updateColumn(column?.id, e.target.value)
							}}
							autoFocus
							onBlur={(e) => {
								setEditMode(false)
								updateColumnApi(column, e.target.value)
							}}
							onKeyDown={(e) => {
								if (e.key !== 'Enter') return
								setEditMode(false)
								updateColumnApi(column, e.target.value)
							}}
						/>
					)}
				</div>
				<div className="d-flex align-items-center gap-3 ">
					<div>
						<ContextMenu items={KanbanContextList} />
					</div>
					<div>
						{/* <Checkbox className="kanban-checkbox generic-checkbox" /> */}
					</div>
				</div>
			</div>

			{/* Column task container */}
			<div
				className="d-flex flex-grow-1 flex-column gap-4 py-2 "
				style={
					{
						// overflowX: 'hidden',
						// overflowY: 'scroll',
					}
				}
			>
				<SortableContext items={tasksIds}>
					{tasks.map((task) => (
						<TaskCard
							key={task.id}
							task={task}
							deleteTask={deleteTask}
							updateTask={updateTask}
							coloumnId={column?.id}
							editNewTask={editNewTask}
							setEditNewTask={setEditNewTask}
						/>
					))}
				</SortableContext>
			</div>
			{/* Column footer */}
			<button
				className="d-flex align-items-center bg-white justify-content-center border txt-color-primary fs-14 py-3 mb-2 font-roboto fw-semibold"
				style={{
					'&:hover': {
						backgroundColor: 'yourMainBackgroundColor', // Set your hover background color here
						color: 'yourRoseColor' // Set your hover text color here
					},
					'&:active': {
						backgroundColor: 'black' // Set your active background color here
					},
					width: '24rem'
				}}
				onClick={() => {
					createTask(column?.id)
				}}
			>
				<PlusIcon
					className="me-2"
					sx={{
						color: '#555',
						fontSize: '16px'
					}}
				/>
				Add task
			</button>

			{isCreateTask && (
				<CreateNewTaskModal
					open={column?.id === isCreateTask}
					onClose={() => setIsCreateTask(false)}
					createTask={createTask}
					role="kanban"
					// targetStatus = {column}
					getData={getTaskData}
					// open={createNewTask}
					// onClose={() => setCreateNewTask(false)}
					dataSelected={dataSelected}
					editNewTask={editNewTask}
					setEditNewTask={setEditNewTask}

					// setOpen={setIsCreateTask}
				/>
			)}
		</div>
	)
}

export default ColumnContainer
