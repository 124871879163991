import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Dragger from 'antd/es/upload/Dragger'

import FormComponent from '../../shared/forms'
import ButtonComponent from '../../shared/ButtonComponent'
import uploadFileIcon from '../../../assets/images/upload-file-icon.svg'
import { findSprintEndDate, formatSprintDate } from '../../../utils/helper'
import dayjs from 'dayjs'

const SprintForm = ({
	isUpdate,
	handleTabChange,
	projectOptions,
	membersData,
	formData,
	setFormData,
	editData,
	setMembersData,
	projectId
}) => {
	const [initialValues, setInitialValues] = useState({
		name: '',
		description: '',
		projectId: projectId ?? '',
		duration: '',
		startDate: '',
		endDate: '',
		startTime: ''
	})

	const validationSchema = Yup.object({})
	const [timeLine, setTimeLine] = useState('')
	const [endDate, setEndDate] = useState(null)
	const [isCustom, setIsCustom] = useState(false)

	const onSubmit = (values) => {
		let body = {
			name: values?.name,
			description: values?.description,
			projectId: values?.projectId,
			startDate: dayjs(values.startDate).format('YYYY-MM-DD'),
			endDate: isCustom ? dayjs(values?.endDate).format('YYYY-MM-DD') : endDate,
			duration: values?.duration,
			startTime: dayjs(values.startTime).format('YYYY-MM-DDTHH:mm:ss')
		}
		setFormData(body)
		handleTabChange('2')
	}

	const sprintDuration = [
		{
			key: '7',
			value: '1 week'
		},
		{
			key: '14',
			value: '2 weeks'
		},
		{
			key: '21',
			value: '3 weeks'
		},
		{
			key: '15',
			value: '15 days'
		},
		{
			key: 'Custom',
			value: 'Custom'
		}
	]

	const findEndDate = (e) => {
		if (!e) return
		let endDate = findSprintEndDate(e['$d'], Number(timeLine))
		setEndDate(endDate)
	}
	useEffect(() => {
		if (Object?.keys(editData)?.length) {
			setInitialValues({
				name: editData?.name,
				description: editData?.description,
				projectId: editData?.projectId,
				startDate: dayjs(editData?.startDate),
				endDate: dayjs(editData?.endDate),
				duration: editData?.duration,
				startTime: dayjs(editData?.startTime)
			})
			setEndDate(dayjs(editData?.endDate))
			setMembersData(editData?.members)
			setFormData({
				name: editData?.name,
				description: editData?.description,
				projectId: editData?.projectId,
				startDate: dayjs(editData?.startDate).format('YYYY-MM-DD'),
				endDate: dayjs(editData?.endDate).format('YYYY-MM-DD'),
				duration: editData?.duration,
				startTime: dayjs(editData?.startTime).format('YYYY-MM-DDTHH:mm:ss')
			})
		}
	}, [editData])

	return (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				enableReinitialize={true}
			>
				{({
					values,
					setFieldValue,
					resetForm,
					handleSubmit,
					isValid,
					dirty
				}) => {
					return (
						<Form>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										control="input"
										name="name"
										label={<>Sprint Name</>}
										placeholder="Enter client Name"
										type="text"
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponent
										control="generic-select"
										name="projectId"
										label={<>Add To project</>}
										placeholder="Select Project"
										options={projectOptions}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-12">
									<FormComponent
										control="input"
										name="description"
										label={<>Sprint Description</>}
										placeholder="Enter sprint description"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										control="generic-select"
										name="duration"
										label={<>Sprint Duration</>}
										options={sprintDuration}
										placeholder="Select sprint duration"
										onChange={(e) => {
											setFieldValue('duration', e)
											setTimeLine(
												sprintDuration?.filter(
													(value) => value?.value === e
												)?.[0]?.key
											)
											setFieldValue('startDate', '')
											setFieldValue('endDate', '')
											setEndDate('')
											if (e === 'Custom') {
												setIsCustom(true)
											} else setIsCustom(false)
										}}
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponent
										control="time-picker"
										name="startTime"
										label={<>Sprint start time</>}
										placeholder="Enter start time"
										format={'hh:mm:ss A'}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										control="date-picker"
										name="startDate"
										label={<>Start Date</>}
										placeholder="Enter start date"
										onDateChange={(e) => {
											findEndDate(e)
										}}
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponent
										control="date-picker"
										name="endDate"
										label={<>End Date</>}
										placeholder="Enter end date"
										disabled={values?.duration === 'Custom' ? false : true}
										value={
											isCustom
												? values.endDate
												: endDate
												? dayjs(endDate, 'YYYY-MM-DD')
												: null
										}
									/>
								</div>
							</div>

							{/* <div className="row">
								<div className="col-12">
									<div className="d-flex flex-column">
										<span
											className="font-roboto fs-14 fw-semibold"
											style={{ color: '#555555', marginBottom: '10px' }}
										>
											Add Files
										</span>
										<Dragger {...draggerProps}>
											<div className="d-flex flex-column">
												<div className="d-flex justify-content-center align-items-center">
													<img src={uploadFileIcon} alt="upload-file-icon" />
													<span className="font-roboto fs-14 fw-semibold create-project-headings ms-2">
														Drop your files or browse
													</span>
												</div>
												<span
													className="font-roboto fs-10 fw-normal ms-2"
													style={{ color: '#7B7B7B' }}
												>
													Max file size is 2mb
												</span>
											</div>
										</Dragger>
									</div>
								</div>
							</div> */}
							<div className="d-flex align-items-center justify-content-end">
								<ButtonComponent
									type="button"
									text={isUpdate ? 'Edit' : 'Add'}
									variant="dark"
									click={handleSubmit}
								/>
							</div>
						</Form>
					)
				}}
			</Formik>
		</>
	)
}

export default SprintForm
