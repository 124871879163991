import { Button, Tabs, message } from 'antd'
import React from 'react'
import ChildIssues from '../components/views/Projects/IssueDetails/ChildIssues'
import LinkedIssues from '../components/views/Projects/IssueDetails/LinkedIssues'
import Comments from '../components/views/Projects/IssueDetails/Comments'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ButtonComponent from '../components/shared/ButtonComponent'
import AvatarGroupComponent from '../components/shared/AvatarGroupComponent'
import moment from 'moment'
import { ChevronLeftOutlined } from '@mui/icons-material'

const IssuesDetails = () => {
	const [messageApi, contextHolder] = message.useMessage()
	const location = useLocation()
	const navigate = useNavigate()

	const { state } = location
	const { data = {} } = state

	const items = [
		{
			key: '1',
			label: 'Child Issues (89)',
			children: <ChildIssues />
		},
		{
			key: '2',
			label: 'Linked Issues (89)',
			children: <LinkedIssues />
		}
		// {
		// 	key: '3',
		// 	label: 'Comments',
		// 	children: <Comments />
		// }
	]

	return (
		<>
			{contextHolder}
			<div className="container-fluid p-4">
				<div className="d-flex justify-content-between row">
					<div className="col-12 col-md-5">
						<div className="d-flex align-items-center mb-2">
							<button
								className="p-0 border-0 bg-white"
								onClick={() => {
									navigate('/dashboard/projects')
								}}
							>
								<ChevronLeftOutlined
									sx={{
										color: '#323232'
									}}
								/>
							</button>
							<img
								className="me-2"
								src={data?.issueDetail?.priorityIcon}
								alt="error"
								height={16}
								width={16}
							/>
							<h1 className="fs-3 mb-0 dashboard-primary-header">
								{data?.issueId}
							</h1>
						</div>

						<p className="txt-color-primary font-roboto mb-0 ms-4">
							{data?.issueDetail?.issueDescription || ''}
						</p>
					</div>
					<div className="col-12 col-md-7">
						<div className="d-flex align-items-center w-100 justify-content-end h-100">
							<Link className="font-roboto txt-color-blue-1 fs-14 text-decoration-none fw-500">
								Manage Issue
							</Link>
						</div>
					</div>
				</div>
				<div className="bg-white px-4 py-3 mb-4 mt-4">
					<div className="row p-0 m-0">
						<div className="col-12 col-md-8 col-lg-11">
							<div className="row d-flex align-items-center">
								<div className="col-12 col-md-2">
									<h6 className="font-roboto txt-color-secondary fs-12">
										Client Name
									</h6>
									<p className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
										{data?.clientName || 'N/A'}
									</p>
								</div>
								<div className="col-12 col-md-2">
									<h6 className="font-roboto txt-color-secondary fs-12">
										Sprint Name
									</h6>
									<p className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
										{data?.sprintName || 'N/A'}
									</p>
								</div>
								<div className="col-12 col-md-2">
									<h6 className="font-roboto txt-color-secondary fs-12">
										Due Date
									</h6>
									<p className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
										{data?.dueDate
											? moment(data?.dueDate).format('Do MMMM, YYYY')
											: 'N/A'}
									</p>
								</div>
								<div className="col-12 col-md-2">
									<h6 className="font-roboto txt-color-secondary fs-12">
										Created On
									</h6>
									<p className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
										{data?.issueDetail?.createdDate
											? moment(
													data?.issueDetail?.createdDate,
													'Do MMMM YYYY [at] h:mm A'
											  ).format('Do MMM [at] h:mm A')
											: 'N/A'}
									</p>
								</div>
								<div className="col-12 col-md-2">
									<h6 className="font-roboto txt-color-secondary fs-12">
										Project Name
									</h6>
									<p className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
										{data?.projectName || ''}
									</p>
								</div>
								<div className="col-12 col-md-2">
									<h6 className="font-roboto txt-color-secondary fs-12">
										Assignee
									</h6>
									<div className="d-flex align-items-center">
										<AvatarGroupComponent
											size={'small'}
											imageUrl={data?.assigneeDetails?.assigneeAvatarUrl || ''}
										/>
										<p className="txt-color-tertiary fw-500 font-roboto mb-0 ms-2">
											{data?.assigneeDetails?.assigneeName || ''}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-4 col-lg-1">
							<div className="d-flex align-items-center justify-content-end h-100">
								<div
									className="secondary font-roboto py-2 px-3 fs-6 txt-color-primary fw-500"
									style={{
										border: '1px solid #D9D9D9',
										borderRadius: '2px'
									}}
								>
									{data?.status}
								</div>
							</div>
						</div>
					</div>
				</div>
				<Tabs defaultActiveKey={'1'} items={items} className="dashboard-tabs" />
			</div>
		</>
	)
}

export default IssuesDetails
