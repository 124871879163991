import { useState } from 'react'
import ModalComponent from '../../../shared/ModalComponent'
import { Tabs } from 'antd'
import MemberForm from './MemberForm'
import ClientForm from './ClientForm'
import dayjs from 'dayjs'

const ClientModal = ({
	updateData = {},
	getData = () => console.log('getData called.'),
	onClose,
	isUpdate,
	open,
	originComp = ''
}) => {
	const [activeKey, setActiveKey] = useState('1')

	const handleTabChange = (key) => {
		setActiveKey(key)
	}

	const [formData, setFormData] = useState({
		name: updateData?.name || '',
		status: updateData?.status || null,
		description: updateData?.description || '',
		phoneNumber: updateData?.phoneNumber || '',
		billingAddress: updateData?.billingAddress || '',
		industry: updateData?.industry || '',
		services:
			updateData?.services &&
			updateData.services !== '' &&
			!Array.isArray(updateData?.services)
				? updateData.services.split(',')
				: [],
		projects:
			updateData?.projects && updateData?.projects.length !== 0
				? updateData?.projects.map((project) => project?.project?.id)
				: [],
		contactEmail: updateData?.email || '',
		imageUrl: updateData?.imageUrl || '',
		contactName: updateData?.contactName || '',
		startDate:
			updateData?.startDate && updateData?.startDate !== null
				? dayjs(updateData?.startDate)
				: '',
		clientMembers: updateData?.clientMembers || [],
		id: updateData?.id || ''
	})

	const items = [
		{
			key: '1',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '1' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">1</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '1' ? '#262626' : '#9D9D9D' }}
					>
						Add Project Information
					</span>
				</div>
			),
			children: (
				<ClientForm
					handleTabChange={handleTabChange}
					setFormData={setFormData}
					onClose={onClose}
					formData={formData}
					isUpdate={isUpdate}
				/>
			)
		},
		{
			key: '2',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '2' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">2</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '2' ? '#262626' : '#9D9D9D' }}
					>
						Add Members
					</span>
				</div>
			),
			children: (
				<MemberForm
					handleTabChange={handleTabChange}
					formData={formData}
					getData={getData}
					onClose={onClose}
					isUpdate={isUpdate}
					originComp={originComp}
				/>
			)
		}
	]

	return (
		<ModalComponent
			title={isUpdate ? 'Edit Client' : 'Create a Client'}
			open={open}
			onClose={onClose}
		>
			<div className="container-fluid px-0">
				<Tabs
					defaultActiveKey={'1'}
					items={items}
					activeKey={activeKey}
					className="create-project-tabs mt-4"
					onChange={handleTabChange}
				/>
			</div>
		</ModalComponent>
	)
}

export default ClientModal
