import Cookies from 'js-cookie'
import { getRequest, postRequest, putRequest } from '.'

export const getRoles = async () => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const email = Cookies.get('user-email')
	const res = await getRequest(
		`/v1/role?email=${email}&companyDetailsId=${companyDetailsId}`
	)
	return res
}

export const editRole = async (body) => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await putRequest(`/v1/role`, body)
	return res
}

export const createRole = async (body) => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await postRequest(`/v1/role`, {
		...body,
		companyDetailsId: companyDetailsId
	})
	return res
}
