import ReactEChart from 'echarts-for-react'
import { ArrowForwardIosRounded } from '@mui/icons-material'
import { Skeleton, Spin } from 'antd'
import { IconButton } from '@mui/material'
import { useNavigate } from 'react-router'

const PieChart = ({
	priority,
	value,
	progressValue,
	toDoValue,
	isLoading = false,
	icon = ''
}) => {
	const navigate = useNavigate()

	const eChartOptions = {
		tooltip: {
			trigger: 'item'
		},
		legend: {
			show: false
		},
		series: [
			{
				type: 'pie',
				radius: ['35%', '80%'],
				label: {
					show: false,
					position: 'center'
				},
				labelLine: {
					show: false
				},
				data: [
					{
						value: progressValue,
						name: 'In progress',
						itemStyle: { color: '#4796DF' }
					},
					{ value: toDoValue, name: 'To do', itemStyle: { color: '#C6DEF5' } }
				]
			}
		]
	}

	return (
		<>
			<div className="d-flex justify-content-between align-items-center pb-2 mb-1 piechart-title">
				<span className="fs-14 fw-bolder font-roboto">
					{icon} {priority} priority
				</span>
				<IconButton
					onClick={() => {
						navigate('/dashboard/projects', {
							state: {
								priority,
								activeTab: '2'
							}
						})
					}}
				>
					<ArrowForwardIosRounded
						sx={{
							color: '#204464',
							fontSize: '1rem'
						}}
					/>
				</IconButton>
			</div>
			<h4 className="pb-2 fw-bolder piechart-title-border font-roboto">
				{isLoading ? <Skeleton.Button active size="small" /> : value}
			</h4>
			{eChartOptions?.series[0]?.data.map((element) => (
				<div className="d-flex justify-content-between ps-1 pe-2 py-1">
					<div>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill={element?.itemStyle?.color}
							className="bi bi-circle-fill"
							viewBox="0 0 16 16"
						>
							<circle cx="8" cy="8" r="8" />
						</svg>
						<span
							key={element.name}
							className="legend-name font-roboto ms-2 fs-12 fw-normal"
						>
							{element.name}
						</span>
					</div>
					<div className="legend-value fw-bolder font-roboto">
						{element.value}
					</div>
				</div>
			))}

			{isLoading ? (
				<div
					className="d-flex align-items-center justify-content-center"
					style={{
						height: '50%'
					}}
				>
					<Spin size="small" />
				</div>
			) : value === 0 ? (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ minHeight: '150px' }}
				>
					No Data Found
				</div>
			) : (
				<div className="p-0 pieChartContainer">
					<ReactEChart option={eChartOptions} />
				</div>
			)}
		</>
	)
}

export default PieChart
