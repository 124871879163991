import leftArrow from '../assets/images/left-arrow.svg'
import ButtonComponent from '../components/shared/ButtonComponent'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import TableComponent from '../components/shared/TableComponent'
import editIconDark from '../assets/images/edit-icon-dark.svg'
import AvatarGroupComponent from '../components/shared/AvatarGroupComponent'
import dayjs from 'dayjs'
import lock from '../assets/images/lock.svg'
import { useEffect, useState } from 'react'
import CreateOrEditRoleModal from '../components/shared/Modals/CreateOrEditRoleModal'
import { useNavigate } from 'react-router'
import '../styles/ComponentsStyles.css'
import { IconButton } from '@mui/material'
import { ChevronLeft, Edit } from '@mui/icons-material'
import { getRoles } from '../API/rolesApi'

const RolesAndPermissions = () => {
	const navigate = useNavigate()

	const [editRole, setEditRole] = useState(false)
	const [editRoleId, setEditRoleId] = useState(null)

	const [createRole, setCreateRole] = useState(false)

	const [data, setData] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	const getData = async () => {
		try {
			const res = await getRoles()
			const { data } = res
			setData(data ?? [])
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getData()
	}, [])

	const editingRole = (roleId) => {
		setEditRole(true)
		setEditRoleId(roleId)
	}

	const creatingRole = (roleId) => {
		setCreateRole(true)
	}

	const addDaySuffix = (day) => {
		if (day >= 11 && day <= 13) {
			return day + 'th'
		}
		const lastDigit = day % 10
		if (lastDigit === 1) {
			return day + 'st'
		} else if (lastDigit === 2) {
			return day + 'nd'
		} else if (lastDigit === 3) {
			return day + 'rd'
		} else {
			return day + 'th'
		}
	}

	const formatDate = (inputDate) => {
		const date = dayjs(inputDate) // Replace '2023-08-28' with your date

		const formattedDate = date.format('MMMM YYYY') // 'D' for day, 'MMMM' for full month name, 'YYYY' for year

		// Add the 'th' suffix to the day
		const dayWithSuffix = addDaySuffix(date.format('D'))

		return `${dayWithSuffix} ${formattedDate}`
	}

	const columns = [
		{
			title: 'Roles',
			dataIndex: 'name',
			render: (record) => {
				return (
					<div className="d-flex justify-content-start align-items-center">
						{/* {record.roleId === '1' ? (
							<img src={lock} alt="lick-icon" className="me-3" />
						) : (
							<></>
						)} */}
						<div className="d-flex flex-column">
							<span
								className="font-roboto fw-500 fs-12"
								style={{ color: '#555555' }}
							>
								{record}
							</span>
							<span
								className="fs-11 font-roboto fw-normal"
								style={{ color: '#555555' }}
							>
								{record?.createDate
									? `Created on ${formatDate(record.createDate)}`
									: null}
							</span>
						</div>
					</div>
				)
			}
		},
		{
			title: 'Members',
			dataIndex: 'members',
			render: (members) => {
				if (members && members.length > 0) {
					return <AvatarGroupComponent maxCount={2} data={members ?? []} />
				} else {
					return (
						<span
							className="font-roboto fw-500 fs-12"
							style={{ color: '#555555' }}
						>
							----
						</span>
					)
				}
			}
		}
		// {
		// 	title: '',
		// 	dataIndex: 'options',
		// 	render: (text, record) => {
		// 		return (
		// 			<IconButton onClick={() => editingRole(record?.id)}>
		// 				<Edit
		// 					sx={{
		// 						fontSize: '1rem'
		// 					}}
		// 				/>
		// 			</IconButton>
		// 		)
		// 	},
		// 	width: '20%'
		// }
	]

	const dataSource =
		data &&
		data.length !== 0 &&
		data.map((role) => {
			return {
				...role
			}
		})

	return (
		<div className="container-fluid p-4">
			<div className="d-flex justify-content-between align-items-center mb-4">
				<div className="d-flex justify-content-start align-items-center mb-4">
					<IconButton onClick={() => navigate('/dashboard/manage-accounts')}>
						<ChevronLeft />
					</IconButton>

					<h1 className="fs-3 dashboard-primary-header ms-2 mb-0">
						Roles & Permissions
					</h1>
				</div>
				<ButtonComponent
					variant="dark"
					text="Create a role"
					icon={<AddCircleOutlineIcon />}
					click={() => creatingRole()}
				/>
			</div>
			<TableComponent
				columns={columns}
				dataSource={dataSource}
				loading={isLoading}
				needRowSelection={false}
			/>
			{editRole && (
				<CreateOrEditRoleModal
					open={editRole}
					onClose={() => setEditRole(false)}
					operation="Edit"
					roleId={editRoleId}
					editRoleId={editRoleId}
					getData={getData}
				/>
			)}
			{createRole && (
				<CreateOrEditRoleModal
					open={createRole}
					onClose={() => setCreateRole(false)}
					operation="Create"
					getData={getData}
				/>
			)}
		</div>
	)
}

export default RolesAndPermissions
