import { Input } from 'antd'
import { ErrorMessage, Field } from 'formik'
import TextError from '../../../hoc/TextError'

// type InputProps = {
// 	label: String
// 	name: String
// 	type: String
// 	formfieldClass: String
// 	rest: any
// }

const InputComponent = (props) => {
	const { label, name, type, formfieldClass = '', labelClass, ...rest } = props

	return (
		<div className={`formField ${formfieldClass}`}>
			<label htmlFor={`${name}`}>{label}</label>
			<Field
				type={type}
				id={name}
				name={name}
				label={label}
				className="generic-input"
				as={type === 'password' ? Input.Password : Input}
				{...rest}
			/>
			<ErrorMessage name={name} component={TextError} />
		</div>
	)
}

export default InputComponent
