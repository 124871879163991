import { getRequest, putRequest } from '.'

const getAllIssues = async () => {
	const res = await getRequest('/jira/allIssuesDetails')
	return res
}

const getAllTasks = async () => {
	const res = await getRequest('https://pms.synccos.com/v1/task')
	return res
}

const getLinkedIssues = async (issueKey) => {
	const res = await getRequest(`/jira/linkedIssues/${issueKey}`)
	return res
}

const getChildIssues = async (issueKey) => {
	const res = await getRequest(`/jira/childIssues/${issueKey}`)
	return res
}

const getIssuesComments = async (issueKey) => {
	const res = await getRequest(`/jira/issueComments/${issueKey}`)
	return res
}

const handleTaskStatus = async (options) => {
	const res = await putRequest(`https://pms.synccos.com/v1/task`, options)
	return res
}

const handleTaskPriority = async (options) => {
	const res = await putRequest(`https://pms.synccos.com/v1/task`, options)
	return res
}

export {
	getLinkedIssues,
	getChildIssues,
	getIssuesComments,
	getAllIssues,
	handleTaskStatus,
	handleTaskPriority,
	getAllTasks
}
