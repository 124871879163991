import React from 'react'

const Tags = ({ name }) => {
	if (name) {
		return (
			<div
				className="d-inline-flex align-items-center mb-2 me-2"
				style={{
					padding: '3px 16px',
					border: '1px solid rgba(217, 217, 217, 1)',
					background: 'rgba(250, 250, 250, 1)',
					borderRadius: '100px'
				}}
			>
				<span className=" card-tag-color">{name}</span>
			</div>
		)
	} else return null
}

export default Tags
