import { useNavigate } from 'react-router'
import leftArrow from '../assets/images/left-arrow.svg'
import rightArrow from '../assets/images/right-arrow.svg'
import { IconButton } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

const ManageAccounts = () => {
	const navigate = useNavigate()

	return (
		<div className="container-fluid p-4">
			<div className="d-flex justify-content-start align-items-center mb-4">
				<IconButton onClick={() => navigate('/dashboard/projects')}>
					<ChevronLeft
						sx={{
							color: '#555'
						}}
					/>
				</IconButton>

				<h1 className="fs-3 dashboard-primary-header ms-2 mb-0">
					Manage your account
				</h1>
			</div>
			<div className="row ms-3">
				{/* <div className="col-md-3 col-12 bg-white py-4 px-3">
					<div className="d-flex flex-column">
						<div className="d-flex justify-content-between align-items-center">
							<h5
								className="font-roboto"
								style={{ color: '#434343', marginBottom: '0px' }}
							>
								My Profile
							</h5>
							<img src={rightArrow} alt="right-arrow" />
						</div>
						<span
							className="fs-14 font-roboto mt-3"
							style={{ color: '#555555' }}
						>
							Manage, Edit, Delete your profile
						</span>
					</div>
				</div> */}

				<div
					className="col-md-3 col-12 ms-3 bg-white py-4 px-3"
					onClick={() => navigate('/dashboard/roles-and-permissions')}
					style={{ cursor: 'pointer' }}
				>
					<div className="d-flex flex-column">
						<div className="d-flex justify-content-between align-items-center">
							<h5
								className="font-roboto"
								style={{ color: '#434343', marginBottom: '0px' }}
							>
								Roles & Permissions
							</h5>
							<IconButton>
								<ChevronRight
									sx={{
										fontSize: '1rem',
										color: '#555'
									}}
								/>
							</IconButton>
						</div>
						<span
							className="fs-14 font-roboto mt-3"
							style={{ color: '#555555' }}
						>
							See current available roles
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ManageAccounts
