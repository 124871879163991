import ModalComponent from '../ModalComponent'
import FormComponent from '../forms'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useEffect, useState } from 'react'
import { Avatar } from 'antd'
import ButtonComponent from '../ButtonComponent'
import { useMember } from '../../../utils/componentUtils/memberUtils/useAddMember'
import deletedIcon from '../../../assets/images/delete_outline.svg'
import { getAllMembers } from '../../../API/MembersAPI'
import { getRoles, editRole, createRole } from '../../../API/rolesApi'

const CreateOrEditRoleModal = ({
	open,
	onClose,
	operation,
	roleId,
	editRoleId,
	getData
}) => {
	const initialValues = {
		role: ''
	}

	const validationSchema = Yup.object().shape({
		role: Yup.string().required('Role name is required')
	})

	// const { memberSelect, selectedValues } = useMember()

	// const [allMembers, setAllMembers] = useState([])
	// const getAllMembersData = async () => {
	// 	try {
	// 		const res = await getAllMembers()
	// 		setAllMembers(res?.data)
	// 	} catch (error) {}
	// }

	// const [allRoles, setAllRoles] = useState([])
	// const getAllRolesData = async () => {
	// 	try {
	// 		const res = await getRoles()
	// 		const membersList = res?.data
	// 		let arr = []
	// 		for (let i = 0; i < membersList?.length; i++) {
	// 			let obj = {}
	// 			obj['value'] = membersList[i]?.id
	// 			obj['label'] = membersList[i]?.name
	// 			arr.push(obj)
	// 		}
	// 		setAllRoles(arr)
	// 	} catch (error) {}
	// }

	// useEffect(() => {
	// 	getAllMembersData()
	// 	//getAllRolesData()
	// }, [])

	// const [members, setMembers] = useState([])
	// const removeMember = (id) =>
	// 	setMembers((prev) => prev.filter((member) => member.id !== id))

	//const [selectedRoles, setSelectedRoles] = useState([])
	// const handleSelect = (targetRoles, memberID) => {
	// 	// for edit of project
	// 	let arr = [...selectedRoles]
	// 	if (arr.find((el) => el?.id === memberID)) {
	// 		let changedPreviousRole = arr.map((item) => {
	// 			if (item?.id === memberID) {
	// 				return { id: memberID, roleId: targetRoles }
	// 			}
	// 			return item
	// 		})
	// 		setSelectedRoles([...changedPreviousRole])
	// 	} else {
	// 		// let arr = [...selectedRoles]
	// 		setSelectedRoles([...arr, { id: memberID, roleId: targetRoles }])
	// 	}
	// }

	const handleOperation = async (formData) => {
		if (operation === 'Edit') {
			/* Send a put request */
			const res = await editRole({
				id: editRoleId,
				name: formData?.role,
				roleId: 1
			})
		} else {
			/* Send a post request */
			//console.log('Creation')
			const res = await createRole({ name: formData?.role, roleId: 1 })
		}
		getData()
		onClose()
	}

	return (
		<ModalComponent
			title={
				<div className="d-flex flex-column">
					<span
						className="font-roboto fw-bolder fs-5"
						style={{ color: '#434343' }}
					>
						{operation === 'Edit' ? 'Edit a role' : 'Create a Role'}
					</span>
				</div>
			}
			width="40%"
			open={open}
			onClose={onClose}
		>
			<>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					enableReinitialize={true}
					onSubmit={(values) => {
						handleOperation(values)
					}}
				>
					{({ handleSubmit, values, resetForm, isValid, dirty }) => {
						return (
							<Form>
								<div className="mt-4">
									<div className="row">
										<div className="col-12 col-md-6">
											<FormComponent
												name="role"
												label="Role Name"
												placeholder="Enter role name"
												control="input"
											/>
										</div>
									</div>
									{/*<label className="fs-14 fw-bold mb-2 font-roboto integration-content">
										{' '}
										Assign members this role
									</label>
									Member Component Render
									{memberSelect()}

									<InviteMember />
								</div>
								<div className="mt-4">
									{!members?.length && (
										<>
											<h6
												style={{ color: '#555' }}
												className="fw-semibold font-roboto txt-color-primary fs-14"
											>
												Add members as
											</h6>
											<p className="font-roboto fs-12 ">
												Define role for each members. You can also customise or
												add new roles in{' '}
												<span role="button" style={{ color: '#204464' }}>
													Settings
												</span>
											</p>
										</>
									)}
									{members.map((member) => {
										return (
											<div
												className="d-flex align-items-center  justify-content-between mb-4 "
												key={member.id}
											>
												<div className="d-flex  align-items-center ">
													{member.avatar ? (
														<Avatar
															className="me-3"
															size={40}
															src={member.avatar}
														/>
													) : (
														<Avatar className="me-3" size={40}>
															{member.name}
														</Avatar>
													)}
													<div>
														<h6 className="fs-12 mb-0 font-roboto leading-1 ">
															{member.name}
														</h6>
														<span className="font-roboto fs-11 fw-normal">
															{member.email}
														</span>
													</div>
												</div>

												<div className="d-flex align-items-center">
													<img
														src={deletedIcon}
														alt=""
														className="cursor-pointer ms-3"
														onClick={() => removeMember(member.id)}
														onClick={() => removeMember(selectedValues)}
													/>
												</div>
											</div>
										)
									})}*/}
								</div>
								<div className="d-flex justify-content-end align-items-center mt-2">
									<ButtonComponent
										variant="dark"
										text="Save"
										click={handleSubmit}
									/>
								</div>
							</Form>
						)
					}}
				</Formik>
			</>
		</ModalComponent>
	)
}

export default CreateOrEditRoleModal
