import React from 'react'
import { Routes, Route } from 'react-router'
import Home from '../pages/Home'
import Terms from '../pages/Terms'
import Privacy from '../pages/Privacy'
import AuthLayout from '../components/Layout/AuthLayout'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import DashboardLayout from '../components/Layout/DashboardLayout'
import Dashboard from '../pages/Dashboard'
import ForgetPassword from '../pages/ForgetPassword'
import NewPassword from '../pages/NewPassword'
import OTPVerify from '../pages/OTPVerify'
import CreateProfile from '../pages/CreateProfile'
import Company from '../pages/Company'
import ManageApp from '../pages/ManageApp'
import Projects from '../pages/Projects'
import ProjectDetails from '../pages/ProjectDetails'
import IssuesDetails from '../pages/IssuesDetails'
import EmployeeDetails from '../pages/EmployeeDetails'
import ManageCallLogs from '../pages/ManageCallLogs'
import ManageClients from '../pages/ManageClients'
import ManageAccounts from '../pages/ManageAccounts'
import RolesAndPermissions from '../pages/RolesAndPermissions'
import SignUpWithInvite from '../pages/SignUpWithInvite'
import ClientDetails from '../pages/ClientDetails'
import Blog from '../pages/Blog'
import Calendar from '../pages/Calendar'
import PrivateRoute from './PrivateRoute'
import AuthRoute from './AuthRoutes'

const AppRoutes = () => {
	return (
		<Routes>
			<Route exact path="/" element={<Home />} />
			<Route exact path="/terms" element={<Terms />} />
			<Route exact path="/privacy" element={<Privacy />} />
			<Route exact path="/blog" element={<Blog />} />
			<Route path="/" element={<AuthRoute />}>
				<Route exact path="/auth" element={<AuthLayout />}>
					<Route exact path="/auth/login" element={<Login />} />
					<Route exact path="/auth/signup" element={<Signup />} />
					<Route exact path="/auth/invite" element={<SignUpWithInvite />} />
					<Route exact path="/auth/company" element={<Company />} />
					<Route
						exact
						path="/auth/forget-password"
						element={<ForgetPassword />}
					/>
					<Route exact path="/auth/reset-password" element={<NewPassword />} />
					{/* <Route exact path="/auth/verify-otp" element={<OTPVerify />} /> */}
					<Route
						exact
						path="/auth/create-profile"
						element={<CreateProfile />}
					/>
				</Route>
			</Route>
			<Route path="/" element={<PrivateRoute />}>
				<Route exact path="/dashboard" element={<DashboardLayout />}>
					<Route exact path="/dashboard/main" element={<Dashboard />} />
					{/* <Route exact path="/dashboard/integrations" element={<ManageApp />} /> */}
					<Route
						exact
						path="/dashboard/call-logs"
						element={<ManageCallLogs />}
					/>
					<Route exact path="/dashboard/projects" element={<Projects />} />
					<Route
						exact
						path="/dashboard/projects/:id"
						element={<ProjectDetails />}
					/>
					<Route
						exact
						path="/dashboard/projects/issue-details/:id"
						element={<IssuesDetails />}
					/>
					<Route
						exact
						path="/dashboard/projects/employee-details/:id"
						element={<EmployeeDetails />}
					/>
					<Route
						exact
						path="/dashboard/manage-clients"
						element={<ManageClients />}
					/>
					<Route
						exact
						path="/dashboard/manage-clients/client-details/:clientId"
						element={<ClientDetails />}
					/>
					<Route
						exact
						path="/dashboard/manage-accounts"
						element={<ManageAccounts />}
					/>
					<Route
						exact
						path="/dashboard/roles-and-permissions"
						element={<RolesAndPermissions />}
					/>
					<Route exact path="/dashboard/calendar" element={<Calendar />} />
				</Route>
			</Route>
		</Routes>
	)
}

export default AppRoutes
