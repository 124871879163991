import React, { useState, useEffect } from 'react'
// import { getAllMembers } from '../../../../../API/MembersAPI'
// import { useMember } from '../../../../../utils/componentUtils/memberUtils/useAddMember'
// import useInviteInput from '../../../../../hooks/useInviteInput'
import dayjs from 'dayjs'
// import { createTask, handleTaskStatus } from '../../../../../API/TasksAPI'
// import AvatarGroupComponent from '../../../AvatarGroupComponent'
import { IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
// import ButtonComponent from '../../../ButtonComponent'
import useMessage from 'antd/es/message/useMessage'
import { useMember } from '../../utils/componentUtils/memberUtils/useAddMember'
import useInviteInput from '../../hooks/useInviteInput'
import { getAllMembers } from '../../API/MembersAPI'
import { createTask, handleTaskStatus } from '../../API/TasksAPI'
import AvatarGroupComponent from '../shared/AvatarGroupComponent'
import ButtonComponent from '../shared/ButtonComponent'
import { useNavigate } from 'react-router-dom'

const MemberFormCalendar = ({
	editNewTask = false,
	formData,
	clientId,
	projectMembers,
	getData,
	onClose,
	handleTabChange,
	setFormData,
	dataSelected = [],
	originComp = ''
}) => {
	const [members, setMembers] = useState(
		editNewTask ? dataSelected?.members : []
	)
	const { memberSelect, selectedValues, setSelectedValues } = useMember()
	const { invited, getInviteInput } = useInviteInput()
	const navigate = useNavigate()
	const [messageApi, contextHolder] = useMessage()

	const removeMember = (id) => {
		setMembers((prev) => prev.filter((member) => member.id !== id))
		setSelectedValues((prev) => prev.filter((member) => member !== id))
	}

	const [allMembers, setAllMembers] = useState([])
	useEffect(() => {
		// if (!editNewTask) {

		let addedNewMember = allMembers?.filter((item) => {
			if (selectedValues?.includes(item?.id)) {
				return item
			}
		})
		setMembers(addedNewMember)
		// }
	}, [selectedValues, allMembers])

	const getMembers = async () => {
		try {
			const res = await getAllMembers()
			const { data = [] } = res
			setAllMembers(
				data?.map((member) => {
					return {
						id: member?.id,
						name: member?.name,
						avatar: member?.imageUrl,
						subTitle: 'Project Lead',
						email: member?.email
					}
				})
			)
		} catch (error) {
			console.log('Error while fetching members ', error)
		}
	}

	useEffect(() => {
		getMembers()
	}, [])

	useEffect(() => {
		if (editNewTask) {
			setSelectedValues(projectMembers?.map((member) => member?.id))
		}
	}, [editNewTask])

	const addNewTask = async () => {
		const body = {
			name: formData?.taskName,
			statusId: formData?.statusId,
			description: formData?.taskDescription,
			projectId: formData?.projectId,
			startDate: dayjs(formData?.startDate).format('YYYY-MM-DD'),
			dueDate: dayjs(formData?.dueDate).format('YYYY-MM-DD'),
			members: members?.map((item) => ({ id: item?.id })),
			priority: formData?.priority || 'Medium',
			clientId: clientId,
			status: formData?.status || '',
			id: dataSelected[0]?.id
		}

		try {
			let res
			if (editNewTask) {
				res = await handleTaskStatus(body)
				//setEditNewTask(false)
			} else {
				res = await createTask(body)
				if (originComp === 'Header') {
					navigate(`/dashboard/projects/${formData?.projectId}`)
				}
			}
			setFormData({})
			handleTabChange('1')
			// getData()

			onClose()
		} catch (error) {
			console.log('Error while creating a new task is ', error)
			messageApi.error('Unable to add task.')
		}
	}

	return (
		<>
			{contextHolder}
			<div className="">
				<label className="fs-14 fw-bold mb-2 font-roboto integration-content">
					{' '}
					Add Members
				</label>
				{memberSelect()}
			</div>
			<div className="mt-4">
				<h6
					style={{ color: '#555' }}
					className="fw-semibold font-roboto txt-color-primary fs-14"
				>
					Add members as
				</h6>
				<p className="font-roboto fs-12 ">
					Define role for each members. You can also customise or add new roles
					in{' '}
					<span role="button" style={{ color: '#204464' }}>
						Settings
					</span>
				</p>
				{members?.map((member) => {
					return (
						<div
							className="d-flex align-items-center  justify-content-between mb-4 "
							key={member.id}
						>
							<div className="d-flex  align-items-center ">
								<AvatarGroupComponent
									name={member?.name || member?.email || ''}
									size={40}
									imageUrl={member?.imageUrl}
								/>

								<div className="ms-2">
									<h6 className="fs-12 mb-0 font-roboto leading-1 ">
										{member?.name}
									</h6>
									<span className="font-roboto fs-11 fw-normal">
										{member?.email}
									</span>
								</div>
							</div>
							<div className="d-flex align-items-center">
								<IconButton
									className="ms-2"
									onClick={() => removeMember(member.id)}
								>
									<Delete
										sx={{
											fontSize: '1rem'
										}}
									/>
								</IconButton>
							</div>
						</div>
					)
				})}

				<div className="mt-4">
					<h6
						style={{ color: '#555' }}
						className="fw-semibold font-roboto txt-color-primary fs-14"
					>
						Invite new members
					</h6>
					{getInviteInput()}
				</div>

				<div className="d-flex align-items-center justify-content-end mt-3">
					<ButtonComponent
						variant="light"
						text="Back"
						click={() => handleTabChange('1')}
					/>
					<ButtonComponent
						variant="dark"
						extraclassName="ms-2"
						text="Save"
						click={addNewTask}
					/>
				</div>
			</div>
		</>
	)
}

export default MemberFormCalendar
