import React, { useEffect, useState } from 'react'
// import ModalComponent from '../../../ModalComponent'
import { Tabs } from 'antd'
import TaskForm from '../shared/Modals/ClientActionsModals/AddTask/TaskForm'
import MemberForm from '../shared/Modals/ClientActionsModals/AddTask/MemberForm'
import ModalComponent from '../shared/ModalComponent'
import MemberFormCalendar from './MemberFormCalendar'
import { getAllProjects } from '../../API/projectsAPI'
import { getProjects } from '../../utils/projectOptions'

// import TaskForm from './TaskForm'
// import MemberForm from './MemberForm'

const TaskModalCalendar = ({
	open,
	onClose,
	getData = () => console.log('getData'),
	type = 'edit',
	clientId = '',
	// clientProjects,
	dataSelected = {},
	isReload,
	editNewTask = false,
	originComp = ''
}) => {
	const [activeKey, setActiveKey] = useState('1')
	const [projectOptions, setProjectOptions] = useState([])

	const handleTabChange = (key) => {
		setActiveKey(key)
	}

	const [formData, setFormData] = useState({})

	const getProjects = async () => {
		try {
			const res = await getAllProjects()
			const { data = [] } = res

			setProjectOptions(
				data?.map((project) => {
					return {
						value: project?.id,
						label: project?.name,
						clientId: project?.clientId || ''
					}
				})
			)
		} catch (error) {
			console.log('Error while fetching all projects ', error)
		}
	}

	useEffect(() => {
		// if (dataSelected?.id) {
		getProjects()
		// }
	}, [])

	const items = [
		{
			key: '1',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '1' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">1</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '1' ? '#262626' : '#9D9D9D' }}
					>
						Task Information
					</span>
				</div>
			),
			children: (
				<TaskForm
					handleTabChange={handleTabChange}
					setFormData={setFormData}
					onClose={onClose}
					dataSelected={[dataSelected]}
					editNewTask={editNewTask}
					clientId={clientId}
					clientProjects={projectOptions}
				/>
			)
		},
		{
			key: '2',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '2' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">2</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '2' ? '#262626' : '#9D9D9D' }}
					>
						Add Members
					</span>
				</div>
			),
			children: (
				<MemberFormCalendar
					handleTabChange={handleTabChange}
					formData={formData}
					onClose={onClose}
					getData={getData}
					dataSelected={[dataSelected]}
					editNewTask={editNewTask}
					clientId={''}
					setFormData={setFormData}
					projectMembers={dataSelected?.members}
					isReload={isReload}
					originComp={originComp}
				/>
			)
		}
	]

	return (
		<ModalComponent
			title={editNewTask ? 'Edit a task' : 'Create a task'}
			open={open}
			onClose={() => {
				onClose()
			}}
		>
			<div className="container-fluid">
				<Tabs
					defaultActiveKey={activeKey}
					items={items}
					activeKey={activeKey}
					className="create-project-tabs mt-4"
					onChange={handleTabChange}
				/>
			</div>
		</ModalComponent>
	)
}

export default TaskModalCalendar
