import { Table } from 'antd'
import React from 'react'

const TableComponent = (props) => {
	const {
		columns,
		dataSource,
		dataSelected = [],
		setDataSelected,
		setRowSelected,
		needRowSelection = true,
		...rest
	} = props

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			setDataSelected && setDataSelected(selectedRows)
		}
	}

	return (
		<div>
			<Table
				className="table-component"
				columns={columns}
				dataSource={dataSource}
				rowSelection={needRowSelection ? rowSelection : false}
				{...rest}
			/>
		</div>
	)
}

export default TableComponent
