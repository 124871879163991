import { Select } from 'antd'
import { ErrorMessage, Field } from 'formik'
import React, { useEffect } from 'react'
import TextError from '../../../hoc/TextError'
import { ExpandMore } from '@mui/icons-material'

const GenericSelect = ({
	options,
	name,
	label,
	formfieldClass = '',
	extraclass = '',
	...rest
}) => {
	return (
		<div className={`formField ${formfieldClass}`}>
			<label htmlFor={`${name}`}>{label}</label>
			<Field name={name} id={name}>
				{({ field, form }) => {
					const { setFieldValue } = form
					return (
						<Select
							className={`generic-select w-100 ${extraclass}`}
							style={{ width: 120 }}
							onChange={(value) => {
								setFieldValue(name, value)
							}}
							options={options}
							name={name}
							value={field?.value}
							{...rest}
							suffixIcon={
								<ExpandMore
									sx={{
										color: '#d9d9d9',
										fontSize: '1.5rem'
									}}
								/>
							}
							popupClassName="generic-select-dropdown"
						/>
					)
				}}
			</Field>
			<ErrorMessage name={name} component={TextError} />
		</div>
	)
}

export default GenericSelect
