import { useState } from 'react'
import ModalComponent from '../ModalComponent'
import { Tabs } from 'antd'
import AddProjectInformation from '../../views/Projects/NewProject/AddProjectInformation'
import AddMembers from '../../views/Projects/NewProject/AddMembers'

const CreateNewProjectModal = ({
	open,
	setOpen,
	onClose,
	setNewProjectAdded,
	title,
	isUpdate
}) => {
	const [activeKey, setActiveKey] = useState('1')

	const handleTabChange = (key) => {
		setActiveKey(key)
	}

	const [formData, setFormData] = useState({})
	const [membersData, setMembersData] = useState({})

	const items = [
		{
			key: '1',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '1' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">1</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '1' ? '#262626' : '#9D9D9D' }}
					>
						Add Project Information
					</span>
				</div>
			),
			children: (
				<AddProjectInformation
					handleTabChange={handleTabChange}
					setFormData={setFormData}
					onClose={onClose}
					formData={formData}
				/>
			)
		},
		{
			key: '2',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '2' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">2</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '2' ? '#262626' : '#9D9D9D' }}
					>
						Add Members
					</span>
				</div>
			),
			children: (
				<AddMembers
					handleTabChange={handleTabChange}
					setMembersData={setMembersData}
					formData={formData}
					setOpen={setOpen}
					setNewProjectAdded={setNewProjectAdded}
				/>
			)
		}
	]

	return (
		<ModalComponent
			title={isUpdate ? 'Edit a project' : 'Create a Project'}
			open={open}
			onClose={onClose}
		>
			<div className="container-fluid px-0">
				<Tabs
					defaultActiveKey={'1'}
					items={items}
					activeKey={activeKey}
					className="create-project-tabs mt-4"
					onChange={handleTabChange}
				/>
			</div>
		</ModalComponent>
	)
}

export default CreateNewProjectModal
