import { Checkbox, Space, message } from 'antd'
import { useEffect, useState } from 'react'
import TableComponent from '../../shared/TableComponent'
import DropdownFilter from '../../shared/DropdownFilter'
import AvatarGroupComponent from '../../shared/AvatarGroupComponent'
import { useLocation, useNavigate } from 'react-router'
import Status from './ProjectsDetails/Status'
import Priority from './IssueDetails/Priority'
import deleteFilledIcon from '../../../assets/images/delete-filled-icon.svg'
import bellIcon from '../../../assets/images/bell-icon.svg'
import editIcon from '../../../assets/images/edit-icon.svg'
import optionsIcon from '../../../assets/images/options-icon.svg'
import {
	handleTaskStatus,
	getAllTasks,
	handleTaskPriority
} from '../../../API/TasksAPI'
import dayjs from 'dayjs'
import { getProject } from '../../../API/projectsAPI'
import IndividualTaskModal from '../../shared/Modals/IndividualTaskModal'
import { getAllIssues } from '../../../API/issuesAPI'
import TaskHeaderTags from './IssueDetails/TasksHeader/TaskHeaderTags'
import SwitchScreen from './IssueDetails/TasksHeader/SwitchScreen'
import KanbanView from './IssueDetails/kanbanView/KanbanView'
import IndividualViewFilters from '../../shared/TableComponents/IndividualViewFilters'
import ContextMenu from '../../shared/ContextMenu'
import { getColoumn } from '../../../API/taskAPI'
import { Check, Clear, Delete, Edit } from '@mui/icons-material'
import { getAllMembers } from '../../../API/MembersAPI'
import ButtonComponent from '../../shared/ButtonComponent'

const Issues = ({
	activeKey,
	setDataSelected,
	setDeleteTask,
	createNew,
	setEditNewTask,
	filter
}) => {
	const [messageApi, contextHolder] = message.useMessage()

	const location = useLocation()
	const { state = {} } = location

	const [data, setData] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [isKanbanview, setIsKanbanview] = useState(false)
	const [selectedTask, setSelectedTask] = useState(null)
	const [statusOptions, setStatusOptions] = useState([])
	const [activeFilter, setActiveFilter] = useState(state?.priority || '')

	const [showIndividualTask, setShowIndividualTask] = useState(false)
	const [taskId, setTaskId] = useState()

	const [isAddBtn, setIsAddBtn] = useState(false)
	const [members, setMembers] = useState([])

	const [selectedMembers, setSelectedmembers] = useState([])
	const [initialMembers, setInitialMembers] = useState([])

	const addDaySuffix = (day) => {
		if (day >= 11 && day <= 13) {
			return day + 'th'
		}
		const lastDigit = day % 10
		if (lastDigit === 1) {
			return day + 'st'
		} else if (lastDigit === 2) {
			return day + 'nd'
		} else if (lastDigit === 3) {
			return day + 'rd'
		} else {
			return day + 'th'
		}
	}

	const formatDate = (inputDate) => {
		const date = dayjs(inputDate)

		const formattedDate = date.format('MMMM YYYY')

		// Add the 'th' suffix to the day
		const dayWithSuffix = addDaySuffix(date.format('D'))

		return `${dayWithSuffix} ${formattedDate}`
	}

	const onFilterChange = (e) => {
		setActiveFilter(e?.target?.value || '')
	}

	const priorityOptions = [
		{
			value: 'Low',
			label: 'Low'
		},
		{
			value: 'Medium',
			label: 'Medium'
		},
		{
			value: 'High',
			label: 'High'
		},
		{
			value: 'Urgent',
			label: 'Urgent'
		}
	]

	const filterOptions = [
		{
			value: '',
			key: 'All priority'
		},
		{
			value: 'Urgent',
			key: 'Urgent'
		},
		{
			value: 'High',
			key: 'High'
		},
		{
			value: 'Medium',
			key: 'Medium'
		},
		{
			value: 'Low',
			key: 'Low'
		}
	]

	const getStatusStyles = (value) => {
		switch (value) {
			case 'ToDo':
				return {
					border: '1px solid #E6E6E6',
					backgroundColor: '#E6E6E6',
					color: '#5F5F5F',
					borderRadius: '2px'
				}
			case 'To Do':
				return {
					border: '1px solid #E6E6E6',
					backgroundColor: '#E6E6E6',
					color: '#5F5F5F',
					borderRadius: '2px'
				}
			case 'Completed':
				return {
					border: '1px solid #CFEBBA',
					backgroundColor: '#F6FFED',
					color: '#52C41A',
					borderRadius: '2px'
				}
			case 'InProgress':
				return {
					border: '1px solid #C9EBFF',
					backgroundColor: '#E6F7FF',
					color: '#1890FF',
					borderRadius: '2px'
				}
			case 'In Progress':
				return {
					border: '1px solid #C9EBFF',
					backgroundColor: '#E6F7FF',
					color: '#1890FF',
					borderRadius: '2px'
				}
			default:
				return {
					border: '1px solid #D9D9D9',
					backgroundColor: 'rgba(250, 250, 250, 0.11)',
					color: '#555',
					borderRadius: '2px'
				}
		}
	}

	const getColoumnData = async () => {
		try {
			const { data } = await getColoumn()
			setStatusOptions(
				data?.map((item) => ({ value: item?.id, label: item?.name }))
			)
		} catch (error) {
			console.log(error)
		}
	}

	const getTasks = async () => {
		setIsLoading(true)
		try {
			const res = await getAllTasks(activeFilter)
			const { data = [] } = res
			setData([...data])
			let temp = data.map((pr) => {
				return {
					taskId: pr.id,
					members:
						pr?.members && pr?.members.length !== 0
							? pr?.members.map((mbr) => {
									return mbr.id
							  })
							: []
				}
			})
			setSelectedmembers([...temp])
			setInitialMembers([...temp])
			getMembers()
		} catch (error) {
			messageApi.error('Unable to fetch issues.')
			setIsLoading(false)
			setData([])
		}
	}

	const getMembers = async () => {
		try {
			const res = await getAllMembers()
			const { data } = res

			setMembers([...data])
			setIsLoading(false)
			setIsAddBtn(true)
		} catch (error) {
			setIsLoading(false)
		}
	}

	const handleMemberSelection = (memberId, taskId) => {
		let temp = [...selectedMembers]
		let members = selectedMembers.find((mbr) => mbr.taskId === taskId).members
		if (members.includes(memberId)) {
			let index = members.findIndex((mbr) => mbr === memberId)
			if (index !== -1) {
				members.splice(index, 1)
			}
		} else {
			members.push(memberId)
		}

		let objIndex = temp.findIndex((obj) => obj?.taskId === taskId)
		temp[objIndex].members = [...members]
		setSelectedmembers([...temp])
	}

	const addMemberToTask = async (taskId) => {
		let members = selectedMembers.find((mbr) => mbr?.taskId === taskId).members
		try {
			const res = await handleTaskStatus({
				id: taskId,
				members: members.map((mbr) => ({ id: mbr }))
			})
			getTasks()
			messageApi.success('Member update to task.')
		} catch (error) {
			messageApi.error('Unable to update member to task.')
		}
	}

	const getRemainMembers = (includedMembers, taskId) => {
		const ids = includedMembers.map((member) => member.id)

		let temp = selectedMembers.find((mbr) => mbr.taskId === taskId)

		const remainingMembers = members.filter(
			(member) => !ids.includes(member.id)
		)
		const itemsArr = [...includedMembers, ...remainingMembers].map(
			(member, index) => {
				return {
					key: index,
					label: (
						<div
							className="d-flex align-items-center px-2 py-2"
							onClick={(e) => {
								handleMemberSelection(member?.id, taskId)
								e.stopPropagation()
							}}
						>
							<Checkbox
								checked={temp.members.includes(member.id)}
								value={member?.id}
								className="me-2"
								onChange={(e, value) => {
									handleMemberSelection(e.target.value, taskId)
								}}
							/>
							<AvatarGroupComponent
								name={member?.name || member?.email || ''}
								imageUrl={member?.imageUrl || ''}
								size={'small'}
							/>
							<p className="mb-0 font-roboto fs-12 txt-color-primary ms-2">
								{member?.name || member?.email}
							</p>
						</div>
					)
				}
			}
		)

		itemsArr.push({
			key: [...includedMembers, ...remainingMembers].length,
			label: (
				<div
					className="d-flex align-items-center justify-content-end py-2"
					onClick={(e) => {
						e.stopPropagation()
					}}
				>
					<ButtonComponent
						text=""
						icon={
							<Clear
								sx={{
									fontSize: '1rem',
									margin: '0'
								}}
							/>
						}
						variant="light"
						extraclassName="me-2 px-2 py-1 d-flex align-items-center avatar-dropdown-btn"
						click={() => {
							setSelectedmembers([...initialMembers])
						}}
					/>
					<ButtonComponent
						text=""
						icon={
							<Check
								sx={{
									fontSize: '1rem',
									margin: '0'
								}}
							/>
						}
						variant="dark"
						extraclassName="px-2 py-1 d-flex align-items-center avatar-dropdown-btn"
						click={(e) => {
							addMemberToTask(taskId)
							e.stopPropagation()
						}}
					/>
				</div>
			)
		})

		return itemsArr
	}

	const handleStatusChange = async (data, newStatus) => {
		const statusName = statusOptions?.filter((el) => el.value === newStatus)
		try {
			const res = await handleTaskStatus({
				id: data?.taskDetail?.taskId,
				statusId: newStatus,
				status: statusName[0]?.label
			})
			getTasks()
		} catch (error) {
			message.error('Unable to update task.')
		}
	}

	const handlePriorityChange = async (data, newPriority) => {
		try {
			const res = await handleTaskPriority({
				id: data?.taskDetail?.taskId,
				priority: newPriority
			})
		} catch (error) {
			message.error('Unable to update task.')
		}
	}

	useEffect(() => {
		getTasks()
		getColoumnData()
	}, [activeKey, createNew, isKanbanview, activeFilter])

	const columns = [
		{
			title: 'Tasks',
			dataIndex: 'taskDetail',
			key: 'taskDetail',
			render: (text, record) => (
				<div
					className="d-flex justify-content-equal align-items-center"
					style={{ columnGap: '10px' }}
				>
					<div className="">
						<p
							className="font-roboto fw-500 fs-12 mb-1"
							style={{ color: '#434343' }}
						>
							{record?.taskDetail?.taskName}
						</p>
						<p className="font-roboto fw-400 fs-12 mb-1 tasksData">
							{record?.taskDetail?.taskDescription
								? record?.taskDetail?.taskDescription
								: ''}
						</p>
						<p className="font-roboto fw-500 fs-10 mb-0 tasksData">
							Started on {formatDate(record?.startDate)}
						</p>
					</div>
				</div>
			)
		},
		{
			title: 'Priority',
			dataIndex: 'priority',
			key: 'priority',
			render: (text, record) => {
				return (
					<Space size="middle">
						<div className="" onClick={(e) => e.stopPropagation()}>
							<Priority
								value={record?.priority || ''}
								defaultValue={record?.priority}
								priorityOptions={priorityOptions}
								select={(newPriority) => {
									handlePriorityChange(record, newPriority)
								}}
							/>
						</div>
					</Space>
				)
			}
		},
		{
			title: 'Sprint Name',
			dataIndex: 'sprint',
			key: 'sprint',
			render: (sprint) => (
				<span className="font-roboto fw-500 fs-12 mb-1">
					{sprint?.sprintName}
				</span>
			)
		},
		{
			title: 'Due Date',
			dataIndex: 'dueDate',
			key: 'dueDate',
			sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
			render: (dueDate) => (
				<span className="fs-12 fw-500 font-roboto tasksData">
					{formatDate(dueDate)}
				</span>
			),
			sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
		},
		{
			title: 'Client Name',
			dataIndex: 'clientName',
			key: 'clientName',
			render: (clientName) => (
				<span className="font-roboto fw-500 fs-12 mb-1">{clientName}</span>
			)
		},
		{
			title: 'Project name',
			dataIndex: 'projectName',
			key: 'projectName',
			render: (projectName) => (
				<span className="font-roboto fw-500 fs-12 mb-1 tasksData text-capitalize">
					{projectName}
				</span>
			)
		},
		{
			title: 'Members',
			dataIndex: 'members',
			key: 'members',
			render: (text, record) => {
				const { members, id } = record
				if (members && members.length !== 0) {
					const items = getRemainMembers(members, id)
					return (
						<AvatarGroupComponent
							maxCount={3}
							data={members}
							isAddBtn={isAddBtn || members?.length !== 0 || items.length !== 0}
							items={items}
						/>
					)
				} else {
					return <span className="fs-12 fw-500 font-roboto">----</span>
				}
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text, record) => (
				<div onClick={(e) => e.stopPropagation()}>
					<Status
						value={record.statusDetail?.name}
						statusOptions={statusOptions}
						select={(newStatus) => {
							handleStatusChange(record, newStatus)
						}}
						getStyles={getStatusStyles}
					/>
				</div>
			)
		},
		// {
		// 	title: 'Estimate Time',
		// 	dataIndex: 'taskEstimate',
		// 	key: 'taskEstimate',
		// 	render: (taskEstimate) => (
		// 		<span className="font-roboto fw-500 fs-12 mb-1">
		// 			{taskEstimate && `In ${taskEstimate} hrs`}
		// 		</span>
		// 	),
		// 	width: '10%'
		// },
		// {
		// 	title: ' ',
		// 	dataIndex: 'contactLead',
		// 	key: 'contactLead',
		// 	render: (text, record) => (
		// 		<a
		// 			href="/"
		// 			className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none"
		// 		>
		// 			Contact Assignee
		// 		</a>
		// 	),
		// 	width: '10%'
		// },
		{
			title: ' ',
			render: (text, record) => {
				const options = [
					{
						key: '1',
						label: (
							<div
								className=""
								onClick={() => {
									setSelectedTask(record)
									setDataSelected([record])
									setDeleteTask(true)
								}}
							>
								<div className="context-menu-option">
									<div className="d-flex align-items-center">
										<Delete className="context-menu-icon" />

										<span className="fs-14 fw-semibold txt-color-primary font-roboto">
											Delete
										</span>
									</div>
								</div>
							</div>
						)
					},
					{
						key: '2',
						label: (
							<div
								className=""
								onClick={() => {
									setSelectedTask(record)
									createNew()
									setDataSelected([record])
									setEditNewTask(true)
								}}
							>
								<div className="context-menu-option">
									<div className="d-flex align-items-center">
										<Edit className="context-menu-icon" />

										<span className="fs-14 fw-semibold txt-color-primary font-roboto">
											Edit
										</span>
									</div>
								</div>
							</div>
						)
					}
				]
				return (
					<>
						<div
							onClick={(e) => {
								e.stopPropagation()
							}}
							style={{
								width: 'fit-content',
								height: 'fit-content',
								borderRadius: '50%'
							}}
							className="d-flex justify-content-center align-items-center"
						>
							<ContextMenu items={options} />
						</div>
					</>
				)
			}
		}
	]

	const dataSource =
		data &&
		data.length !== 0 &&
		data.map((task, index) => {
			return {
				key: index.toString(),
				clientName: task?.clientName || '----',
				id: task?.id,
				name: task?.name,
				description: task?.description,
				taskDetail: {
					taskId: task?.id,
					taskName: task?.name,
					taskDescription: task?.description
				},
				startDate: task?.startDate,
				dueDate: task?.dueDate,
				statusDetail: {
					id: task?.statusDetail?.id,
					name: task?.statusDetail?.name,
					userId: task?.statusDetail?.userId,
					color: task?.statusDetail?.color
				},
				members:
					task?.members && task?.members.length !== 0
						? task?.members.map((mbr) => {
								return {
									name: mbr?.name || '',
									email: mbr?.email || '',
									imageUrl: mbr?.imageUrl || '',
									id: mbr?.id
								}
						  })
						: [],
				priority: task?.priority,
				taskEstimate: task?.taskEstimate,
				projectName: task?.project?.name,
				projectId: task?.projectId,
				project: task?.project,
				sprint: {
					sprintName: task?.sprint?.name || '----',
					sprintId: task?.sprint?.id || null
				}
			}
		})

	const onChange = (pagination, filters, sorter, extra) => {}

	return (
		<>
			{contextHolder}

			<div className="container-fluid px-0">
				{showIndividualTask && (
					<IndividualTaskModal
						isOpen={showIndividualTask}
						setIsOpen={setShowIndividualTask}
						taskId={taskId}
					/>
				)}
				<div className="mt-1 mb-3 d-flex flex-row justify-content-between">
					<div className="">
						<IndividualViewFilters
							filtersData={filterOptions}
							activeFilter={activeFilter}
							onchange={onFilterChange}
						/>
						{/* <TaskHeaderTags setSelectedHeaderTag={setSelectedHeaderTag} selectedHeaderTag={selectedHeaderTag} /> */}
					</div>

					{/* <div className="">
						<SwitchScreen
							setIsKanbanview={setIsKanbanview}
							isKanbanview={isKanbanview}
						/>
					</div> */}
				</div>
				{isKanbanview ? (
					<KanbanView />
				) : (
					<>
						<TableComponent
							dataSource={dataSource}
							columns={columns}
							onChange={onChange}
							loading={isLoading}
							setDataSelected={setDataSelected}
							// onRow={(record) => {
							// 	return {
							// 		onClick: (e) => {
							// 			e.stopPropagation()
							// 			navigate(
							// 				`/dashboard/projects/issue-details/${record?.issueId}`,
							// 				{
							// 					state: {
							// 						data: {
							// 							...record
							// 						}
							// 					}
							// 				}
							// 			)
							// 		}
							// 	}
							// }}
						/>
					</>
				)}
				{showIndividualTask && (
					<IndividualTaskModal
						open={showIndividualTask}
						setIsOpen={setShowIndividualTask}
						taskId={taskId}
					/>
				)}
			</div>
		</>
	)
}

export default Issues
