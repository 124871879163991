import axios from 'axios'
import { getRequest, postRequest } from '.'
import { getUser } from './UserAPI'
import Cookies from 'js-cookie'

const loginAPI = async (body) => {
	const res = await postRequest('/v1/auth/signin', body)
	return res
}

const signUpAPI = async (body) => {
	const res = await postRequest('/v1/auth/signup', body)
	return res
}

const refreshAccessTokenNode = async () => {
	const res = await postRequest('/central-auth/auth/refresh-token')
	return res
}

const refreshAccessTokenJava = async (data) => {
	const res = await postRequest('/v1/auth/refresh-token', data)
	return res
}

// for searching data on auth part
const verifyIfUserExists = async (email) => {
	const res = await getRequest(`/v1/user/search?email=${email}`)

	return res
}

const sendForgetPasswordEmail = async (email) => {
	const res = await postRequest(`/v1/auth/forgot-password?email=${email}`)
	return res
}

const verifyForgetToken = async (token) => {
	const res = await postRequest(
		`/v1/auth/check-forgot-password-token-validity?token=${token}`
	)
	return res
}

const resetUserPassword = async (body) => {
	const res = postRequest(`/v1/auth/reset-password`, body)
	return res
}

export {
	loginAPI,
	signUpAPI,
	refreshAccessTokenNode,
	refreshAccessTokenJava,
	verifyIfUserExists,
	sendForgetPasswordEmail,
	verifyForgetToken,
	resetUserPassword
}
