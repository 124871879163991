import Cookies from 'js-cookie'
import { deleteRequest, getRequest, postRequest, putRequest } from '.'

export const getTask = async () => {
	const res = await getRequest(`/v1/task`)
	return res
}
export const createTask = async (id, data) => {
	const res = await postRequest(`/v1/task`)
	return res
}

export const getColoumn = async () => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''

	const res = await getRequest(
		`/v1/task/color?companyDetailsId=${companyDetailsId}`
	)
	return res
}

export const createColoumn = async (data) => {
	const res = await postRequest(`/v1/task/color`, data)
	return res
}
export const delteColoumn = async (id) => {
	const res = await deleteRequest(`/v1/task/color/multiple?ids=${id}`)
	return res
}
export const updateColoumn = async (data) => {
	const res = await putRequest(`/v1/task/color`, data)
	return res
}
