import { Select } from 'antd'
import { useEffect, useState } from 'react'
import './status.css'
import { ExpandMore } from '@mui/icons-material'

const Status = ({
	value: defaultValue,
	data,
	statusOptions,
	select,
	getStyles
}) => {
	const [status, setStatus] = useState(defaultValue ?? '')
	const [style, setStyle] = useState({})

	useEffect(() => {
		const obj = getStyles(status)
		setStyle({ ...obj })
	}, [status])

	useEffect(() => {
		setStatus(defaultValue)
	}, [defaultValue])

	return (
		<Select
			onChange={(newValue) => {
				setStatus(newValue)
				select(newValue)
			}}
			value={status}
			options={statusOptions}
			className="status-select w-100"
			style={{
				...style,
				maxWidth: '6rem'
			}}
			popupClassName="status-select-dropdown"
			suffixIcon={
				<ExpandMore
					sx={{
						fontSize: '16px'
					}}
					style={{ color: style?.color }}
				/>
			}
		/>
	)
}

export default Status
