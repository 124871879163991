import { DateRange, ExpandMore } from '@mui/icons-material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { Card, Checkbox, Divider, Dropdown } from 'antd'
import dayjs from 'dayjs'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import Tags from '../shared/Cards/Kanban Cards/Tags'
import AvatarGroupComponent from '../shared/AvatarGroupComponent'

import AddClientTask from '../shared/Modals/ClientActionsModals/AddTask'
import { handleTaskStatus } from '../../API/TasksAPI'
import { getClient } from '../../API/clientAPI'
import TaskModalCalendar from './TaskModalCalendar'
import DeleteTaskModal from '../shared/Modals/DeleteTaskModal'

const TaskCardComponent = ({
	task,
	cardActionRef,
	cardRef,
	setIsReload,
	setSelectedEvent,
	isReload
}) => {
	const menuItems = [
		{
			label: 'Status 1',
			key: '0'
		},
		{
			label: 'Status 2',
			key: '1'
		},
		{
			label: 'Status 3',
			key: '2'
		}
	]

	const addDaySuffix = (day) => {
		if (day >= 11 && day <= 13) {
			return day + 'th'
		}
		const lastDigit = day % 10
		if (lastDigit === 1) {
			return day + 'st'
		} else if (lastDigit === 2) {
			return day + 'nd'
		} else if (lastDigit === 3) {
			return day + 'rd'
		} else {
			return day + 'th'
		}
	}

	const [clientData, setClientData] = useState({})
	const [deleteTask, setDeleteTask] = useState(false)

	const getClientData = async (clientId) => {
		// setIsLoading(true)
		try {
			const res = await getClient(clientId)
			const { data = {} } = res
			setClientData(data)
			// setClientStatus(data?.status || 'Inactive')
			// setIsLoading(false)
		} catch (error) {
			debugger
			// messageApi.error('Unable to fetch client details.')
			// setClientData([])
			// setIsLoading(false)
		}
	}

	useEffect(() => {
		if (task?.clientId) {
			getClientData(task?.clientId)
		}
	}, [task?.clientId])

	const formatDate = (inputDate) => {
		const date = dayjs(inputDate)

		const formattedDate = date.format('MMMM YYYY')

		// Add the 'th' suffix to the day
		const dayWithSuffix = addDaySuffix(date.format('D'))

		return `${dayWithSuffix} ${formattedDate}`
	}

	const [isEdit, setIsEdit] = useState(false)

	const updateTaskData = async (body) => {
		try {
			const res = await handleTaskStatus(body)
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<>
			<div
				className="card generic-card w-100 rounded-0 px-3 py-3 mb-0"
				style={{
					borderColor: '#EBEBEB',
					width: '22.5rem'
				}}
			>
				<div className="d-flex align-items-center justify-content-between mb-3">
					<Dropdown
						//overlay={menu}
						menu={{ items: menuItems }}
						trigger={['click']}
						className={`d-flex align-items-center justify-content-center`}
					>
						<span
							className="filter-dropdown text-decoration-none align-items-center fs-10"
							style={{
								borderRadius: '10px',
								border: '1px solid #C9EBFF',
								backgroundColor: '#E6F7FF',
								color: '#1890FF',
								padding: '3px 8px'
							}}
						>
							Medium
							<ExpandMore
								className="ms-1"
								sx={{
									fontSize: '14px'
								}}
							/>
						</span>
					</Dropdown>
					{/* <Checkbox className="generic-checkbox" /> */}
				</div>
				<p className="fs-14 mb-2 font-roboto txt-color-tertiary fw-semibold">
					{task?.project?.name}
				</p>
				<p className="fs-14 mb-2 font-roboto txt-color-tertiary fw-normal">
					{task?.name}
				</p>
				{/* <p className="font-roboto fs-12 txt-color-fourth mb-1">{task?.projectId}</p> */}
				<p className="fs-12 font-roboto txt-color-primary mb-0">
					{task?.description}
				</p>
				<Divider
					className="card-divider"
					style={{
						margin: '1rem 0',
						borderColor: '#e9e9e9'
					}}
				/>
				<div
					className="row align-items-center justify-content-between gap-1"
					style={{
						width: 'max-content !important'
					}}
				>
					<div className="col-5 text-center   align-items-center d-flex  dateRange  ">
						<DateRangeIcon
							style={{
								color: 'rgba(123, 123, 123, 0.8)',
								fontSize: '1rem'
							}}
						/>
						<p className="fs-10   m-0">
							{/* <span className="txt-color-secondary">Start Date: </span> */}
							<span className="">Start Date: </span>
							<strong>
								<span className="">{formatDate(task?.startDate)}</span>
							</strong>
						</p>
					</div>
					<div className="col-1 text-center   align-items-center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="39"
							height="4"
							viewBox="0 0 39 4"
							fill="none"
						>
							<path
								d="M0.5 2C0.5 3.10457 1.39543 4 2.5 4C3.60457 4 4.5 3.10457 4.5 2C4.5 0.89543 3.60457 0 2.5 0C1.39543 0 0.5 0.89543 0.5 2ZM34.5 2C34.5 3.10457 35.3954 4 36.5 4C37.6046 4 38.5 3.10457 38.5 2C38.5 0.89543 37.6046 0 36.5 0C35.3954 0 34.5 0.89543 34.5 2ZM2.5 2.375H3.44444V1.625H2.5V2.375ZM5.33333 2.375H7.22222V1.625H5.33333V2.375ZM9.11111 2.375H11V1.625H9.11111V2.375ZM12.8889 2.375H14.7778V1.625H12.8889V2.375ZM16.6667 2.375H18.5556V1.625H16.6667V2.375ZM20.4444 2.375H22.3333V1.625H20.4444V2.375ZM24.2222 2.375H26.1111V1.625H24.2222V2.375ZM28 2.375H29.8889V1.625H28V2.375ZM31.7778 2.375H33.6667V1.625H31.7778V2.375ZM35.5556 2.375H36.5V1.625H35.5556V2.375Z"
								fill="#B1B1B1"
							/>
						</svg>
					</div>

					<div className="col-5 text-center  align-items-center d-flex dateRange ">
						<DateRangeIcon
							style={{
								color: 'rgba(123, 123, 123, 0.8)',
								fontSize: '1rem'
							}}
						/>
						<p className="fs-10   m-0">
							<span className="">Due: </span>
							<strong>
								<span className="">{formatDate(task?.dueDate)}</span>
							</strong>
						</p>
					</div>
				</div>
				<Divider
					className="card-divider"
					style={{
						margin: '1rem 0',
						borderColor: '#e9e9e9'
					}}
				/>
				<div className="">
					<Tags name={task?.sprint?.name} />
					<Tags name={`Estimate ${task?.estimate} days`} />
					<Tags name={task?.clientName} />
				</div>
				{/* card footer */}
				<Divider
					className="card-divider"
					style={{
						marginTop: '1rem',
						borderColor: '#e9e9e9'
					}}
				/>
				<div className="d-flex align-items-center justify-content-between ">
					<div className="">
						{task?.members && task?.members?.length !== 0 ? (
							<AvatarGroupComponent data={task?.members} />
						) : null}
					</div>
					<div className="d-flex gap-1">
						{/* <div className="">
						<ContentCopyIcon color="action" />
					</div>
					<div className="">
						<NotificationsActiveIcon color="action" />
					</div> */}
						{/* <IconButton onClick={(e) => deleteTask(task.id)}> */}
						<IconButton
							onClick={(e) => {
								setDeleteTask(true)
								e.stopPropagation()
							}}
						>
							<DeleteIcon
								color="action"
								sx={{
									fontSize: '1.25rem'
								}}
							/>
						</IconButton>
						<IconButton
							onClick={(e) => {
								setIsEdit(true)
								e.stopPropagation()
							}}
						>
							<CreateIcon
								color="action"
								sx={{
									fontSize: '1.25rem'
								}}
							/>
						</IconButton>
						{/* <div className="">
						<CheckCircleIcon color="action" />
					</div> */}
					</div>
				</div>
			</div>

			{isEdit && (
				<div className="" ref={cardActionRef}>
					<TaskModalCalendar
						open={isEdit}
						onClose={() => {
							setIsEdit(false)
							cardActionRef.current = null
							setIsReload(!isReload)
							setSelectedEvent(null)
						}}
						getData={updateTaskData}
						dataSelected={task}
						clientId={task?.clientId}
						clientProjects={
							clientData?.projects && clientData.projects.length !== 0
								? clientData.projects.map((pr) => ({
										value: pr?.projectId,
										label: pr?.project?.name
								  }))
								: []
						}
						isReload={isReload}
						editNewTask={isEdit}
					/>
				</div>
			)}

			{deleteTask && (
				<div className="" ref={cardActionRef}>
					<DeleteTaskModal
						isModalOpen={deleteTask}
						onClose={() => {
							setIsReload(!isReload)
							cardActionRef.current = null
							setSelectedEvent(null)
						}}
						setIsModalOpen={setDeleteTask}
						dataSelected={[task]}
						setDataSelected={() => console.log('first')}
					/>
				</div>
			)}
		</>
	)
}

export default TaskCardComponent
