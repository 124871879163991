import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

export const loginLogout = createSlice({
	name: 'loginLogout',
	initialState: {
		token: '',
		isLoggedIn: false,
		isRemember: false
	},
	reducers: {
		login: (state, action) => {
			return {
				...state,
				...action.payload,
				isLoggedIn: true
			}
		},
		logOut: (state) => {
			return {
				...state,
				token: '',
				isLoggedIn: false
			}
		},
		setIsRemember: (state) => {
			return {
				...state,
				isRemember: true
			}
		},

		checkData: (state) => {
			let token = Cookies.get('pms-access-token')
				? Cookies.get('pms-access-token')
				: ''
			let loggedStatus = Cookies.get('user-status') ? true : false

			return {
				...state,
				token: token,
				isLoggedIn: loggedStatus
			}
		}
	}
})

export const { login, logOut, checkData, setIsRemember } = loginLogout.actions

export default loginLogout.reducer
