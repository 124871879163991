import PlusIcon from '@mui/icons-material/Add'
import { useEffect, useMemo, useState } from 'react'

import ColumnContainer from './ColumnContainer'
import {
	DndContext,
	DragEndEvent,
	DragOverEvent,
	DragOverlay,
	DragStartEvent,
	PointerSensor,
	useSensor,
	useSensors
} from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { createPortal } from 'react-dom'
import TaskCard from './TaskCard'
import {
	createColoumn,
	delteColoumn,
	getColoumn,
	getTask,
	updateColoumn
} from '../../../../../API/taskAPI'
import GenericDelete from '../../../../shared/Modals/GenericDelete'
import { useModel } from '../../../../../hooks/useModel'
import {
	deleteTasks,
	getAllTasks,
	handleTaskStatus
} from '../../../../../API/TasksAPI'
import DeleteTaskModal from '../../../../shared/Modals/DeleteTaskModal'
import Cookies from 'js-cookie'

const KanbanBoard = () => {
	const [columns, setColumns] = useState([])
	const columnsId = useMemo(() => columns?.map((col) => col?.id), [columns])
	const { isOpen, setIsOpen } = useModel('Coloumn')
	const [tasks, setTasks] = useState([])
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''

	const [activeColumn, setActiveColumn] = useState({
		id: 71,
		name: 'Completed',
		companyDetailsId: companyDetailsId,
		color: 'red'
	})

	const [activeTask, setActiveTask] = useState(null)
	const [isCreateTask, setIsCreateTask] = useState(false)
	const [dataSelected, setDataSelected] = useState(false)
	const [editNewTask, setEditNewTask] = useState(false)
	const [deleteTaskModal, setDeleteTaskModal] = useState(false)

	useEffect(() => {
		getColouumnData()
		getTaskData()
	}, [])

	async function getColouumnData() {
		try {
			const { data } = await getColoumn()
			setColumns(data)
		} catch (error) {
			console.log(error)
		}
	}
	async function getTaskData() {
		try {
			const { data } = await getAllTasks()
			setTasks(data)
		} catch (error) {
			console.log(error)
		}
	}
	async function updateTaskData(updatedData) {
		try {
			const { data } = await handleTaskStatus(updatedData)
			// setTasks(data)
		} catch (error) {
			console.log(error)
		}
	}

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 10
			}
		})
	)

	return (
		<>
			<div className="overflow-style d-flex w-100 align-items-center ">
				<DndContext
					sensors={sensors}
					onDragStart={onDragStart}
					onDragEnd={onDragEnd}
					onDragOver={onDragOver}
				>
					<div className="mx-auto d-flex gap-4  ">
						<div className="d-flex gap-4  ">
							<SortableContext items={columnsId}>
								{columns?.map((col) => (
									<ColumnContainer
										key={col?.id}
										column={col}
										deleteColumn={deleteColumn}
										updateColumn={updateColumn}
										createTask={createTask}
										deleteTask={deleteTask}
										updateTask={updateTask}
										setIsOpen={setIsOpen}
										isOpen={isOpen}
										updateColumnApi={updateColumnApi}
										isCreateTask={isCreateTask}
										setIsCreateTask={setIsCreateTask}
										dataSelected={dataSelected}
										editNewTask={editNewTask}
										setEditNewTask={setEditNewTask}
										getTaskData={getTaskData}
										tasks={tasks?.filter(
											(task) => task.statusDetail?.id === col.id
										)}
									/>
								))}
							</SortableContext>
						</div>
						<button
							onClick={() => {
								createNewColumn()
							}}
							className="fs-14  fw-semibold txt-color-primary bg-white cursor-pointer  py-3 d-flex align-items-center justify-content-center kanban-view-column-btn font-roboto"
							style={{
								height: 'fit-content',
								border: '1px solid #EBEBEB',
								boxShadow: 'none'
							}}
						>
							<PlusIcon
								className="me-2"
								sx={{
									color: 'inherit',
									fontSize: '16px'
								}}
							/>
							Add Column
						</button>
					</div>

					{createPortal(
						<DragOverlay>
							{activeColumn && (
								<ColumnContainer
									column={activeColumn}
									deleteColumn={deleteColumn}
									updateColumn={updateColumn}
									createTask={createTask}
									deleteTask={deleteTask}
									updateTask={updateTask}
									setIsOpen={setIsOpen}
									isOpen={isOpen}
									updateColumnApi={updateColumnApi}
									isCreateTask={isCreateTask}
									setIsCreateTask={setIsCreateTask}
									dataSelected={dataSelected}
									editNewTask={editNewTask}
									setEditNewTask={setEditNewTask}
									tasks={tasks.filter(
										(task) => task.statusDetail?.id === activeColumn.id
									)}
								/>
							)}
							{activeTask && (
								<TaskCard
									task={activeTask}
									deleteTask={deleteTask}
									updateTask={updateTask}
								/>
							)}
						</DragOverlay>,
						document.body
					)}
				</DndContext>
				{deleteTaskModal && (
					<DeleteTaskModal
						isModalOpen={deleteTaskModal}
						getData={getTaskData}
						setIsModalOpen={setDeleteTaskModal}
						dataSelected={dataSelected}
						setDataSelected={setDataSelected}
					/>
				)}
			</div>
		</>
	)

	function createTask(columnId) {
		setIsCreateTask(columnId)
		let coloumnFilter = columns?.filter((el) => el?.id === columnId)
		setDataSelected(
			coloumnFilter?.map((item) => ({ value: item?.id, label: item?.name }))
		)

		let arr = [...tasks]

		// for updating the code during add
		if (editNewTask) {
			if (columnId?.hasOwnProperty('id')) {
				const newTasks = tasks.map((task) => {
					if (task.id !== columnId?.id) return task
					return { ...columnId }
				})
				setTasks(newTasks)
			} else {
				if (columnId?.hasOwnProperty('id')) {
					arr.push(columnId)
					setTasks(arr)
				}
			}
		}
	}
	function deleteTask(id) {
		setDeleteTaskModal(true)
		// const newTasks = tasks.filter((task) => task.id !== id)
		const selected = tasks.filter((task) => task.id === id)
		setDataSelected(selected)
		// setTasks(newTasks)
		// return (
		// 	<>
		// 	</>
		// )
	}

	function updateTask(id, taskId) {
		// for open paticular status with edit
		setIsCreateTask(id)
		// let coloumnFilter = columns?.filter(el => el?.id === id)
		let taskFilter = tasks?.filter((el) => el?.id === taskId)

		setDataSelected(taskFilter)
		setEditNewTask(true)

		// const newTasks = tasks.map((task) => {
		// 	if (task.id !== id) return task
		// 	return { ...task, content }
		// })

		// setTasks(newTasks)
	}

	async function createNewColoumnApi(columnData) {
		try {
			const res = await createColoumn(columnData)

			const { data } = res
			return data
		} catch (error) {
			console.log(error)
			return false
		}
	}

	async function createNewColumn() {
		let arr = [...columns]

		const columnToAdd = {
			name: `Column ${arr.length + 1}`,
			companyDetailsId: companyDetailsId,
			color: 'red'
		}
		const res = await createNewColoumnApi(columnToAdd)

		if (res) {
			arr.push(res)
		} else {
			/////handle error here
		}

		setColumns([...arr])
	}

	async function deleteColumn(id) {
		try {
			const res = await delteColoumn(id)
			if (res?.data?.includes('successfully')) {
				const filteredColumns = columns.filter((col) => col.id !== id)
				setColumns(filteredColumns)
				const newTasks = tasks.filter((t) => t.statusDetail?.id !== id)
				setTasks(newTasks)
				setIsOpen(false)
			}
		} catch (error) {
			console.log(error)
		}
	}

	async function updateColumnApi(colData, name) {
		colData['name'] = name
		try {
			const res = await updateColoumn(colData)
		} catch (error) {}
	}
	function updateColumn(id, name) {
		let obj = {}
		obj['id'] = id
		obj['name'] = name

		const newColumns = columns.map((col) => {
			if (col.id !== id) return col
			return obj
		})

		setColumns(newColumns)
	}

	function onDragStart(event) {
		if (event.active.data.current?.type === 'Column') {
			setActiveColumn(event.active.data.current.column)
			return
		}

		if (event.active.data.current?.type === 'Task') {
			setActiveTask(event.active.data.current.task)
			return
		}
	}

	function onDragEnd(event) {
		setActiveColumn(null)
		setActiveTask(null)

		const { active, over } = event

		if (!over) return

		const activeId = active.id
		const overId = over.id

		if (activeId === overId) return

		if (
			active.data.current?.type === 'Task' &&
			over.data.current?.type === 'Task'
		) {
			const activeTaskIndex = tasks.findIndex((col) => col.id === activeId)
			const overTaskIndex = tasks.findIndex((col) => col.id === overId)
			let columnID = columns?.filter(
				(col) => col?.name === tasks[overTaskIndex]?.statusDetail?.name
			)
			updateTaskData({
				id: tasks[activeTaskIndex]?.id,
				statusId: columnID?.[0]?.id
			})
		}

		if (
			active.data.current?.type === 'Task' &&
			over.data.current?.type === 'Column'
		) {
			const activeTaskIndex = tasks.findIndex((col) => col.id === activeId)
			const overTaskIndex = columns.findIndex((col) => col.id === overId)
			updateTaskData({
				id: tasks[activeTaskIndex]?.id,
				statusId: columns[overTaskIndex]?.id
			})
		}

		const isActiveAColumn = active.data.current?.type === 'Column'
		if (!isActiveAColumn) return

		setColumns((columns) => {
			const activeColumnIndex = columns.findIndex((col) => col.id === activeId)

			const overColumnIndex = columns.findIndex((col) => col.id === overId)

			return arrayMove(columns, activeColumnIndex, overColumnIndex)
		})
	}

	function onDragOver(event) {
		const { active, over } = event
		if (!over) return

		const activeId = active.id
		const overId = over.id

		if (activeId === overId) return

		const isActiveATask = active.data.current?.type === 'Task'
		const isOverATask = over.data.current?.type === 'Task'

		if (!isActiveATask) return

		// Im dropping a Task over another Task
		if (isActiveATask && isOverATask) {
			setTasks((tasks) => {
				const activeIndex = tasks.findIndex((t) => t.id === activeId)
				const overIndex = tasks.findIndex((t) => t.id === overId)

				if (
					tasks[activeIndex].statusDetail?.id !=
					tasks[overIndex].statusDetail?.id
				) {
					// Fix introduced after video recording
					tasks[activeIndex].statusDetail.id = tasks[overIndex].statusDetail?.id
					return arrayMove(tasks, activeIndex, overIndex - 1)
				}

				return arrayMove(tasks, activeIndex, overIndex)
			})
		}

		const isOverAColumn = over.data.current?.type === 'Column'

		// Im dropping a Task over a column
		if (isActiveATask && isOverAColumn) {
			setTasks((tasks) => {
				const activeIndex = tasks.findIndex((t) => t.id === activeId)

				tasks[activeIndex].statusDetail.id = overId
				return arrayMove(tasks, activeIndex, activeIndex)
			})
		}
	}
}

function generateId() {
	/* Generate a random number between 0 and 10000 */
	return Math.floor(Math.random() * 10001)
}

export default KanbanBoard
