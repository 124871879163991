import { Button } from 'antd'

const ButtonComponent = (props) => {
	const {
		variant,
		text,
		click,
		extraclassName = '',
		icon = '',
		...rest
	} = props

	return (
		<Button
			className={`generic-btn font-roboto ${variant} ${extraclassName} `}
			onClick={click}
			{...rest}
			icon={icon}
		>
			{/* {text} */}
			<span className="font-roboto fs-14 fw-semibold">{text}</span>
		</Button>
	)
}

export default ButtonComponent
