import React, { useEffect, useState } from 'react'
import moment from 'moment'
import TableComponent from '../../../shared/TableComponent'
import AvatarGroupComponent from '../../../shared/AvatarGroupComponent'
import { useParams } from 'react-router'
import { getLinkedIssues } from '../../../../API/issuesAPI'

const LinkedIssues = () => {
	const params = useParams()
	const { id } = params

	const [data, setData] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	const getData = async () => {
		try {
			const res = await getLinkedIssues(id)
			const { data } = res
			setData([...data])
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getData()
	}, [])

	const columns = [
		{
			title: 'Issue',
			dataIndex: 'linkedIssues',
			key: 'linkedIssues',
			render: (childIssue) => {
				return (
					<div className="d-flex align-items-center">
						<img
							src={childIssue?.priorityIcon}
							alt="priority-icon"
							width={16}
							height={16}
							className="me-3"
						/>
						<div>
							<p className="mb-1 font-roboto fs-12 fw-500 txt-color-tertiary">
								{childIssue?.issueKey}
							</p>
							<p className="mb-0 font-roboto fs-10 fw-normal txt-color-primary">
								{childIssue?.issueDescription}
							</p>
						</div>
					</div>
				)
			},
			width: '14%'
		},
		{
			title: 'Created On',
			dataIndex: 'createdAt',
			key: 'createdAt'
		},
		{
			title: 'Due Date',
			dataIndex: 'dueDate',
			key: 'dueDate',
			render: (dueDate) => {
				return <span className="fs-12 fw-bold font-roboto">{dueDate}</span>
			}
		},
		{
			title: 'Assignee',
			dataIndex: 'assignee',
			key: 'assignee',
			render: (assignee) => {
				return (
					<AvatarGroupComponent
						name={assignee?.assigneeName}
						imageUrl={assignee?.ssigneeAvatarUrl}
					/>
				)
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status) => (
				<div
					className=" d-flex justify-content-center"
					style={{ border: '1px solid red', padding: '6px 8px' }}
				>
					{status}
				</div>
			)
		},
		{
			title: 'Estimate Time',
			dataIndex: 'estimateTime',
			key: 'estimateTime',
			render: (estimateTime) => {
				return <span className="fs-12 fw-bold font-roboto">{estimateTime}</span>
			}
		},
		{
			title: ' ',
			dataIndex: 'contactAssignee',
			key: 'contactAssignee',
			render: (text) => (
				<a
					href="/"
					className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none"
				>
					Contact Assignee
				</a>
			)
		}
	]

	const dataSource =
		data &&
		data.length !== 0 &&
		data.map((obj, index) => ({
			key: index.toString(),
			linkedIssues: {
				issueKey: obj?.issueKey,
				issueDescription: obj?.issueDescription || '',
				priorityIcon: obj?.priorityIcon
			},
			createdAt: moment(obj?.createdDate).format('Do MMMM [at] HH:mmA'),
			dueDate: moment(obj?.dueDate).format('Do MMMM, YYYY'),
			label: obj?.label,
			status: obj?.status,
			assignee: {
				assigneeName: obj?.assigneeName,
				assigneeAvatarUrl: obj?.assigneeAvatarUrl
			},
			estimateTime: `${obj?.estimatedTimeLeft.toFixed(0)} ${
				obj?.estimatedTimeLeftUnits
			}`
		}))

	const onChange = (pagination, filters, sorter, extra) => {}

	return (
		<TableComponent
			dataSource={dataSource}
			columns={columns}
			onChange={onChange}
			// onRow={(record) => {
			// 	return {
			// 		onClick: () => {
			// 			navigate(`/dashboard/projects/${record?.projectId}`)
			// 		}
			// 	}
			// }}
			loading={isLoading}
		/>
	)
}

export default LinkedIssues
