import editIcon from '../../../assets/images/edit-icon.svg'

const ChatComponent = ({ content }) => {
	return (
		<div className="d-flex justify-content-center align-items-center">
			<img src={content?.personAvatar} alt="person-logo" />
			<div className="d-flex flex-column ms-1">
				<div className="d-flex justify-content-between align-items-center">
					<div className="d-flex justify-content-start align-items-center">
						<span
							className="font-roboto fs-12 fw-500"
							style={{ color: '#555555' }}
						>
							{content?.personName}
						</span>
						<span
							className="font-roboto fs-10 fw-normal ms-2"
							style={{ color: '#9D9D9D' }}
						>
							{content?.messageDetails?.date.format('YYYY-MM-DD HH:mm:ss')}
						</span>
					</div>
					<div className="d-flex justify-content-center align-items-center">
						<img />
						<img />
						<span
							className="font-roboto fs-12 fw-500"
							style={{ color: '#4796DF' }}
						>
							Reply
						</span>
					</div>
				</div>
				<span
					className="fs-12 fw-normal font-roboto"
					style={{ color: '#7B7B7B' }}
				>
					{content?.messageDetails?.content}
				</span>
			</div>
		</div>
	)
}

export default ChatComponent
