import React, { useEffect, useState } from 'react'
import { Button, Dropdown, message, Modal, Select, Tabs } from 'antd'
import IndividualViewFilters from '../components/shared/TableComponents/IndividualViewFilters'
import TableComponent from '../components/shared/TableComponent'
import {
	createDomain,
	deleteCallLog,
	getActiveCalls,
	getAdminEmail,
	getCallLogs,
	getDomainDetails,
	getDomains,
	resendDomainOtp,
	updateCallLog
} from '../API/callLogsAPI'
import {
	activeCallIcon,
	incomingCallIcon,
	missedCallsIcons,
	voiceCallIcon
} from '../assets/svg'
import moment from 'moment'
import AvatarGroupComponent from '../components/shared/AvatarGroupComponent'
import ButtonComponent from '../components/shared/ButtonComponent'
import {
	AddCircleOutlineOutlined,
	ArrowBack,
	Delete,
	Edit,
	ErrorOutlineOutlined,
	ExpandMore,
	Key,
	Launch,
	NotificationsActive,
	PhoneCallback,
	PhoneForwarded
} from '@mui/icons-material'
import ModalComponent from '../components/shared/ModalComponent'
import CallLogForm from '../components/views/Forms/CallLogForm'
import ContextMenu from '../components/shared/ContextMenu'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import FormComponent from '../components/shared/forms'

import callLogImg from '../assets/images/call-log-form-img.png'
import callLogImg2 from '../assets/images/call-log-img-2.png'
import OTPInput from 'react-otp-input'
import { IconButton } from '@mui/material'

const ManageCallLogs = () => {
	const [messageApi, contextHolder] = message.useMessage()
	const [activeKey, setActiveKey] = useState('1')
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isUpdate, setIsUpdate] = useState(false)
	const [updateData, setUpdateData] = useState({})
	const [isLoading, setIsLoading] = useState(true)
	const [activeFilter, setActiveFilter] = useState('ALL')
	const [data, setData] = useState([])
	const [paginate, setPaginate] = useState(1)

	const [domains, setDomains] = useState([])
	const [showRecords, setShowRecords] = useState(false)
	const [selectedDomain, setSelectedDomain] = useState('')
	const [email, setEmail] = useState('')
	const [next, setNext] = useState(false)
	const [otp, setOtp] = useState('')
	const [domainName, setDomainName] = useState('')
	const [domainOptions, setDomainOptions] = useState([])

	const getEmail = async (domain, resetForm) => {
		setDomainName(domain.toLowerCase())
		try {
			const res = await getAdminEmail(`${domain.toLowerCase()}.23357.service`)

			const { data } = res
			if (data?.email && data?.email !== '') {
				setEmail(data?.email)
				setNext(true)
				sendOtp(domain.toLowerCase(), data?.email)
			} else {
				messageApi.error('Domain name not found.')
			}
			resetForm()
		} catch (error) {
			messageApi.error('Domain name not found.')
			resetForm()
		}
	}

	const getDomainsData = async () => {
		try {
			const res = await getDomains()
			const { data = [] } = res
			setDomains([...data])
			if (data.length !== 0) {
				setShowRecords(true)
				getData(data[0].domain)
				setSelectedDomain(data[0].domain)
				setDomainOptions(
					data &&
						data.map((domainObj) => {
							let domainName = domainObj?.domain.split('.')

							return {
								value: domainObj?.domain,
								label: domainName[0]
							}
						})
				)
			} else {
				setShowRecords(false)
			}
		} catch (error) {}
	}

	const getData = async (domain = selectedDomain) => {
		setIsLoading(true)
		let currentDate = new Date()
		let startDate = currentDate.setFullYear(currentDate.getFullYear() - 1)

		try {
			const res = await getCallLogs({
				type: activeFilter,
				startTimeUnix: startDate,
				endTimeUnix: Math.floor(new Date().getTime() / 1000),
				sort: 'desc',
				domain: domain
			})
			const { data = [] } = res
			setData([...data])
			setIsLoading(false)
		} catch (error) {
			message.error('Unable to get call logs.')
			setIsLoading(false)
			setData([])
		}
	}

	const getActiveCallsData = async () => {
		try {
			const res = await getActiveCalls(selectedDomain)
			const { data = [] } = res
			setData([...data])
			setIsLoading(false)
		} catch (error) {
			message.error('Unable to get call logs.')
			setIsLoading(false)
			setData([])
		}
	}

	useEffect(() => {
		let interval
		if (domains.length !== 0) {
			if (activeFilter === 'ACTIVE') {
				setIsLoading(true)
				getActiveCallsData()
				interval = setInterval(() => {
					getActiveCallsData()
				}, 1500)
			} else {
				getData()
				clearInterval(interval)
			}
		}
		return () => clearInterval(interval)
	}, [activeFilter, selectedDomain])

	useEffect(() => {
		getDomainsData()
	}, [])

	const deleteRecord = async (id) => {
		try {
			const res = await deleteCallLog(id)

			getData()
			messageApi.success('Call log deleted successfully.')
		} catch (error) {
			messageApi.error('Unable to delete call log.')
		}
	}

	const items = [
		{
			key: '1',
			label: 'All Calls',
			children: (
				<AllCalls
					activeFilter={activeFilter}
					setActiveFilter={setActiveFilter}
					isLoading={isLoading}
					data={data}
					deleteRecord={deleteRecord}
					setUpdateData={setUpdateData}
					setIsModalOpen={setIsModalOpen}
					setIsUpdate={setIsUpdate}
					setPaginate={setPaginate}
					paginate={paginate}
					setSelectedDomain={setSelectedDomain}
					domainOptions={domainOptions}
					selectedDomain={selectedDomain}
				/>
			)
		}
	]

	const initialValues = {
		domainName: ''
	}

	const validationSchema = Yup.object({
		domainName: Yup.string().required('Required')
	})

	const submitOtp = async () => {
		if (otp.length !== 6) {
			messageApi.warning('OTP must be of 6 digits.')
			return false
		}
		try {
			const res = await createDomain({
				domain: `${domainName.toLowerCase()}.23357.service`,
				otp: otp
			})
			setNext(false)
			setOtp('')
			messageApi.success('Domain linked successfully.')
			getDomainsData()
		} catch (error) {
			debugger
		}
	}

	const sendOtp = async (domain, email) => {
		try {
			const res = await resendDomainOtp({
				email,
				domain: `${domain}.23357.service`
			})
			messageApi.success('Email sent to email.')
		} catch (error) {
			messageApi.error('Not able to send email.')
		}
	}

	return (
		<>
			{contextHolder}
			<div className="container-fluid p-4">
				<div className="d-flex justify-content-between mb-4">
					<h1 className="fs-3 dashboard-primary-header">Call Logs</h1>
					{showRecords ? (
						<div className="d-flex align-items-center justify-content-end">
							<ButtonComponent
								variant="light"
								text="Add different domain"
								icon={<AddCircleOutlineOutlined />}
								click={() => {
									setShowRecords(false)
								}}
							/>

							<ButtonComponent
								variant="dark"
								text="Create a call log"
								icon={<AddCircleOutlineOutlined />}
								click={() => {
									setIsModalOpen(true)
								}}
								extraclassName="ms-3"
							/>
						</div>
					) : null}
				</div>

				{showRecords ? (
					<Tabs
						defaultActiveKey={activeKey}
						items={items}
						className="dashboard-tabs"
						onChange={(key) => {
							setActiveKey(key)
						}}
					/>
				) : (
					<div
						className="bg-white w-100 text-center py-5 position-relative"
						style={{
							minHeight: '60vh',
							height: '100%'
						}}
					>
						{domains && domains.length !== 0 ? (
							<IconButton
								onClick={() => {
									setShowRecords(true)
									setNext(false)
								}}
								sx={{
									position: 'absolute',
									left: '2rem',
									top: '2rem'
								}}
							>
								<ArrowBack />
							</IconButton>
						) : null}

						<img
							src={next ? callLogImg2 : callLogImg}
							alt="call-log"
							className="mb-5"
						/>
						<h2 className="fs-4 font-roboto fw-bold txt-color-tertiary">
							{next
								? 'Please check your office manager email for an OTP'
								: 'Kindly input a domain name to gain access'}
						</h2>
						<p className="fs-12 font-roboto fw-normal txt-color-tertiary mb-4">
							{next
								? `Please enter a verification code sent to your office manager email address ${email}`
								: `Your seamless access experience begins with the entry of a
							designated domain name, ensuring a secure and <br /> efficient
							connection to our services`}
						</p>

						{next ? (
							<>
								<div className="d-flex align-items-start justify-content-center">
									<div
										style={{
											width: '20rem'
										}}
									>
										<OTPInput
											value={otp}
											onChange={setOtp}
											numInputs={6}
											className="d-flex align-items-center justify-content-between mb-5"
											renderInput={(props) => (
												<input
													{...props}
													className="otpInput fs-3 text-black mb-5"
													type="number"
												/>
											)}
											containerStyle={{
												justifyContent: 'space-between'
											}}
										/>
									</div>
								</div>
								<ButtonComponent
									variant="dark"
									text="Verify"
									icon={<Key />}
									click={submitOtp}
								/>
							</>
						) : (
							<>
								<Formik
									initialValues={initialValues}
									validationSchema={validationSchema}
									onSubmit={(values, { resetForm }) => {
										getEmail(values?.domainName, resetForm)
									}}
								>
									{({ handleSubmit }) => (
										<Form>
											<div className="d-flex align-items-start justify-content-center">
												<div
													style={{
														width: '13rem'
													}}
												>
													<FormComponent
														name="domainName"
														control="input"
														type="text"
														placeholder="Enter a domain descriptive name"
													/>
												</div>

												<p className="txt-color-primary font-roboto fs-6 fw-semibold mb-0 ms-3 mt-1">
													23357. service
												</p>
											</div>
											<ButtonComponent
												variant="dark"
												text="Request Access"
												icon={<Key />}
												click={handleSubmit}
											/>
										</Form>
									)}
								</Formik>
							</>
						)}
					</div>
				)}

				<ModalComponent
					open={isModalOpen}
					onClose={() => {
						setIsModalOpen(false)
						setUpdateData({})
						setIsUpdate(false)
					}}
					title={isUpdate ? 'Edit call log' : 'Add call log'}
				>
					<CallLogForm
						isUpdate={isUpdate}
						getData={getData}
						updateData={updateData}
						setIsModalOpen={setIsModalOpen}
					/>
				</ModalComponent>
			</div>
		</>
	)
}

const AllCalls = ({
	activeFilter,
	setActiveFilter,
	isLoading,
	data,
	deleteRecord,
	setUpdateData,
	setIsModalOpen,
	setIsUpdate,
	paginate,
	setPaginate,
	setSelectedDomain,
	domainOptions,
	selectedDomain
}) => {
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [selectedId, setSelectedId] = useState('')
	const [domainNumber, setDomainNumber] = useState('')

	const getDomainNumber = async () => {
		try {
			const res = await getDomainDetails(selectedDomain)
			debugger
		} catch (error) {
			debugger
		}
	}

	// useEffect(() => {
	// 	getDomainNumber()
	// }, [selectedDomain])

	const filtersData = [
		{
			key: 'Missed calls',
			value: 'MISSED'
		},
		{
			key: 'Active calls',
			value: 'ACTIVE'
		},
		{
			key: 'Incoming calls',
			value: 'INCOMING'
		},
		{
			key: 'Outgoing calls',
			value: 'OUTGOING'
		}
	]

	const onFilterChange = (e) => {
		setActiveFilter(e?.target?.value || 'ALL')
	}

	const columns = [
		{
			title: 'Caller Name',
			dataIndex: 'callerDetails',
			key: 'callerDetails',
			render: (callerDetails) => {
				return (
					<div className="d-flex align-items-center">
						<span className="me-3">{getStatusIcon(callerDetails?.status)}</span>
						<div>
							<p className="mb-1 font-roboto fs-12 fw-500 txt-color-tertiary">
								{callerDetails?.callerName}
							</p>
							<p className="mb-0 font-roboto fs-10 fw-normal txt-color-primary">
								Phone No.:{' '}
								{callerDetails?.phoneNumber.includes('+')
									? `${callerDetails?.phoneNumber}`
									: `+${callerDetails?.phoneNumber}`}
							</p>
						</div>
					</div>
				)
			},
			width: '15%'
		},
		{
			title: 'Extension',
			dataIndex: 'extension',
			key: 'extension',
			render: (extension) => {
				return (
					<span className="fs-12 fw-500 font-roboto txt-color-primary">
						{extension}
					</span>
				)
			}
		},
		{
			title: 'Phone Number',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			render: (phoneNumber) => (
				<Button
					href={`tel:${phoneNumber}`}
					className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none border-0 bg-transparent p-0 d-flex align-items-center gap-2"
					onClick={(e) => {
						e.stopPropagation()
					}}
					style={{
						boxShadow: 'none',
						height: '100%'
					}}
				>
					<span className="font-roboto">
						{' '}
						{phoneNumber && phoneNumber !== '---'
							? phoneNumber.includes('+')
								? `${phoneNumber}`
								: `+${phoneNumber}`
							: '----'}
					</span>
					<Launch
						sx={{
							fontSize: 'inherit',
							color: 'inherit'
						}}
					/>
				</Button>
			)
		},
		{
			title: 'Time Of Call',
			dataIndex: 'timeOfCall',
			key: 'timeOfCall',
			render: (timeOfCall) => {
				return <span className="fs-12 fw-bold font-roboto">{timeOfCall}</span>
			}
		},
		{
			title: 'Call Duration',
			dataIndex: 'duration',
			key: 'duration',
			render: (duration) => {
				const durationInSeconds = duration // Replace this with your duration in seconds

				// Convert to a Moment.js duration
				const newDuration = moment.duration(durationInSeconds, 'seconds')

				// Format the duration as HH:mm:ss
				const formattedDuration = moment
					.utc(newDuration.asMilliseconds())
					.format('HH:mm:ss')
				return (
					<span className="fs-12 fw-bold font-roboto">{formattedDuration}</span>
				)
			}
		},
		{
			title: 'Client Name',
			dataIndex: 'clientName',
			key: 'clientName',
			render: (clientName) => (
				<span className="fs-12 fw-bold font-roboto">{clientName}</span>
			)
		},
		{
			title: 'Project Name',
			dataIndex: 'projectName',
			key: 'projectName',
			render: (projectName) => (
				<span className="fs-12 fw-bold font-roboto">{projectName}</span>
			)
		},
		{
			title: 'Recieved By',
			dataIndex: 'assignedTo',
			key: 'assignedTo',
			render: (assignedTo) => {
				if (assignedTo && assignedTo !== '') {
					return (
						<AvatarGroupComponent
							name={assignedTo ?? ''}
							// imageUrl={assigneeDetails?.assigneeAvatarUrl || ''}
						/>
					)
				} else {
					return <span className="fs-12 fw-bold font-roboto">---</span>
				}
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status) => {
				return (
					<div
						className="font-robot fs-10 fw-bold text-center"
						style={{
							...getStatusColor(status),
							padding: '6px 8px',
							maxWidth: '6rem'
						}}
					>
						{status}
					</div>
				)
			}
		},
		{
			title: 'Call Record',
			dataIndex: 'callRecord',
			key: 'callRecord',
			render: (callRecord) => {
				return <span className="fs-12 fw-bold font-roboto">{callRecord}</span>
			}
		},
		{
			title: '',
			dataIndex: 'rowData',
			key: 'rowData',
			render: (rowData) => {
				const itemsForSynccosRecord = [
					{
						key: '1',
						label: (
							<div
								className="context-menu-option"
								onClick={() => {
									setOpenDeleteModal(true)
									setSelectedId([rowData?.id])
								}}
							>
								<div className="d-flex align-items-center">
									<Delete className="context-menu-icon" />

									<span className="fs-14 fw-semibold txt-color-primary font-roboto">
										Delete
									</span>
								</div>
							</div>
						)
					},
					{
						key: '2',
						label: (
							<div
								className="context-menu-option"
								onClick={() => {
									setUpdateData({ ...rowData })
									setIsUpdate(true)
									setIsModalOpen(true)
								}}
							>
								<div className="d-flex align-items-center">
									<Edit className="context-menu-icon" />

									<span className="fs-14 fw-semibold txt-color-primary font-roboto">
										Edit
									</span>
								</div>
							</div>
						)
					},
					{
						key: '3',
						label: (
							<div className="context-menu-option">
								<div className="d-flex align-items-center">
									<NotificationsActive className="context-menu-icon" />

									<span className="fs-14 fw-semibold txt-color-primary font-roboto">
										Add Reminder
									</span>
								</div>
							</div>
						)
					}
				]

				const itemsForSkySwitch = [
					{
						key: '1',
						label: (
							<div
								className="context-menu-option"
								onClick={() => {
									setUpdateData({ ...rowData })
									setIsUpdate(true)
									setIsModalOpen(true)
								}}
							>
								<div className="d-flex align-items-center">
									<Edit className="context-menu-icon" />

									<span className="fs-14 fw-semibold txt-color-primary font-roboto">
										Edit
									</span>
								</div>
							</div>
						)
					}
					// {
					// 	key: '2',
					// 	label: (
					// 		<div className="context-menu-option">
					// 			<div className="d-flex align-items-center">
					// 				<NotificationsActive className="context-menu-icon" />

					// 				<span className="fs-14 fw-semibold txt-color-primary font-roboto">
					// 					Add Reminder
					// 				</span>
					// 			</div>
					// 		</div>
					// 	)
					// }
				]
				return (
					<ContextMenu
						items={rowData?.cdrId ? itemsForSkySwitch : itemsForSynccosRecord}
					/>
				)
			}
		}
	]

	const columnDataActive = [
		{
			title: 'Caller Name',
			dataIndex: 'callerDetails',
			key: 'callerDetails',
			render: (callerDetails) => {
				return (
					<div className="d-flex align-items-center">
						<span className="me-3">{getStatusIcon('Active call')}</span>
						<div>
							<p className="mb-1 font-roboto fs-12 fw-500 txt-color-tertiary">
								{callerDetails?.callerName}
							</p>
							<p className="mb-0 font-roboto fs-10 fw-normal txt-color-primary">
								{callerDetails?.fromSkySwitch
									? 'To Skyswitch'
									: `Phone No.: +${callerDetails?.phoneNumber}`}
							</p>
						</div>
					</div>
				)
			}
		},
		{
			title: 'Extension',
			dataIndex: 'extension',
			key: 'extension',
			render: (extension) => {
				return (
					<span className="fs-12 fw-500 font-roboto txt-color-primary">
						{extension}
					</span>
				)
			}
		},
		{
			title: 'Phone Number',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			render: (phoneNumber) => (
				<Button
					href={`tel:${phoneNumber}`}
					className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none border-0 bg-transparent p-0 d-flex align-items-center gap-2"
					onClick={(e) => {
						e.stopPropagation()
					}}
					style={{
						boxShadow: 'none',
						height: '100%'
					}}
				>
					<span className="font-roboto">
						{phoneNumber && phoneNumber !== '---' ? `+ ${phoneNumber}` : ''}
					</span>
					<Launch
						sx={{
							fontSize: 'inherit',
							color: 'inherit'
						}}
					/>
				</Button>
			)
		},
		{
			title: 'Time Of Call',
			dataIndex: 'timeOfCall',
			key: 'timeOfCall',
			render: (timeOfCall) => {
				return <span className="fs-12 fw-bold font-roboto">{timeOfCall}</span>
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status) => {
				return (
					<div
						className="font-robot fs-10 fw-bold text-center"
						style={{
							...getStatusColor('Active call'),
							padding: '6px 8px',
							maxWidth: '6rem'
						}}
					>
						Active
					</div>
				)
			}
		}
	]

	const dataSource =
		data &&
		data.length !== 0 &&
		data.map((obj, index) => ({
			key: index.toString(),
			assignedTo: obj?.assignedTo || '',
			status: activeFilter === 'ACTIVE' ? obj?.ani || '---' : obj?.type || '',
			extension:
				activeFilter === 'ACTIVE'
					? obj?.orig_user || '---'
					: obj?.extension || '---',
			callerDetails: {
				callerName:
					activeFilter === 'ACTIVE'
						? obj?.orig_sub_name || '---'
						: obj?.callerName || '---',
				createdOn: obj?.createdOn || '--',
				status: obj?.type,
				phoneNumber:
					activeFilter === 'ACTIVE'
						? obj?.ani || '---'
						: obj?.phoneNumber || '---',
				fromSkySwitch:
					activeFilter === 'ACTIVE' && obj?.ani === obj?.orig_user
						? true
						: false
			},
			phoneNumber:
				activeFilter === 'ACTIVE'
					? obj?.dnis || '---'
					: obj?.phoneNumber || '---',
			timeOfCall:
				activeFilter === 'ACTIVE'
					? obj?.time_begin || '---'
					: obj?.timeOfCall || '---',
			duration: obj?.duration || '---',
			clientName: obj?.client?.name || '---',
			projectName: obj?.project?.name || '---',
			rowData: { ...obj },
			callRecord: obj?.cdrId && obj?.cdrId !== '' ? 'Automatic' : 'Manual'
		}))

	const getStatusIcon = (status) => {
		switch (status) {
			case 'Incoming call':
				return (
					<PhoneCallback
						sx={{
							color: '#1890FF',
							fontSize: '1.25rem'
						}}
					/>
				)
			case 'Outgoing call':
				return (
					<PhoneForwarded
						sx={{
							color: '#E19A0C',
							fontSize: '1.25rem'
						}}
					/>
				)
			case 'Missed call':
				return missedCallsIcons
			case 'Voice call':
				return voiceCallIcon
			case 'Active call':
				return activeCallIcon
			default:
				return incomingCallIcon
		}
	}

	const getStatusColor = (status) => {
		switch (status) {
			case 'Incoming call':
				return {
					borderRadius: '2px',
					border: '1px solid #C9EBFF',
					backgroundColor: '#E6F7FF',
					color: '#1890FF'
				}
			case 'Outgoing call':
				return {
					borderRadius: '2px',
					border: '1px solid #FFEBC9',
					backgroundColor: '#FFF7E6',
					color: '#E19A0C'
				}
			case 'Missed call':
				return {
					borderRadius: '2px',
					border: '1px solid #FFCAE2',
					backgroundColor: '#FFF0F6',
					color: '#EB2F96'
				}
			case 'Voice call':
				return {
					borderRadius: '2px',
					border: '1px solid #E6E6E6',
					backgroundColor: '#E6E6E626',
					color: '#5F5F5F'
				}
			case 'Active call':
				return {
					borderRadius: '2px',
					border: '1px solid #CFEBBA',
					backgroundColor: '#F6FFED',
					color: '#52C41A'
				}
			default:
				return {
					borderRadius: '2px',
					border: '1px solid #C9EBFF',
					backgroundColor: '#E6F7FF',
					color: '#1890FF'
				}
		}
	}

	return (
		<>
			<div className="d-flex align-items-center justify-content-between mb-4">
				<IndividualViewFilters
					filtersData={filtersData}
					activeFilter={activeFilter}
					onchange={onFilterChange}
				/>
				<div className="d-flex align-items-center justify-content-end">
					<p className="mb-0 font-roboto fw-500 txt-color-fourth fs-12">
						Current Domain:{' '}
					</p>
					<Select
						className="table-filter-select"
						options={domainOptions}
						value={selectedDomain}
						defaultValue={selectedDomain}
						onChange={(e) => {
							setSelectedDomain(e)
						}}
						popupClassName="table-filter-select-dropdown"
						suffixIcon={
							<ExpandMore
								sx={{
									color: '#555'
								}}
							/>
						}
					></Select>
					{activeFilter !== 'ALL' ? (
						<button
							type="button"
							className="p-0 border-0 font-roboto fw-500 fs-14 bg-transparent txt-color-blue-1 ms-3"
							onClick={() => {
								setActiveFilter('ALL')
							}}
						>
							Clear Filter
						</button>
					) : null}
				</div>
			</div>
			<TableComponent
				dataSource={dataSource}
				columns={activeFilter === 'ACTIVE' ? columnDataActive : columns}
				pagination={{
					total: dataSource.length,
					current: paginate,
					pageSize: 10,
					onChange: (value) => setPaginate(value)
				}}
				loading={isLoading}
				onRow={(record) => {
					return {
						onClick: (e) => {
							setUpdateData({ ...record?.rowData })
							setIsUpdate(true)
							setIsModalOpen(true)
						}
					}
				}}
			/>
			<ModalComponent
				open={openDeleteModal}
				onClose={() => {
					setOpenDeleteModal(false)
				}}
				title={
					<div className="d-flex align-items-center">
						<ErrorOutlineOutlined
							className="me-2"
							style={{
								width: '2rem',
								height: '2rem'
							}}
						/>
						<span>Delete Call Log ?</span>
					</div>
				}
			>
				<div>
					<p className="fs-6 txt-color-tertiary mb-3 mt-2 fw-normal font-roboto">
						Are you sure to delete call log?
					</p>
					<p className="fs-6 txt-color-tertiary mb-2 fw-normal font-roboto">
						No need to worry, you can restore them within 30 days if you change
						your mind. For now, they will be archived, but after this period,
						they'll be permanently deleted.
					</p>
					<div className="d-flex align-items-center justify-content-end">
						<ButtonComponent
							text="Cancel"
							variant="light"
							extraclassName="me-3"
							click={() => {
								setOpenDeleteModal(false)
							}}
						/>
						<ButtonComponent
							type="button"
							text="Delete"
							variant="dark"
							click={() => {
								deleteRecord(selectedId)
								setOpenDeleteModal(false)
							}}
						/>
					</div>
				</div>
			</ModalComponent>
		</>
	)
}

export default ManageCallLogs
