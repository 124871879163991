import { ChevronLeftOutlined, FilterAlt } from '@mui/icons-material'
import { message, Input, Progress, Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import TableComponent from '../components/shared/TableComponent'
import AvatarGroupComponent from '../components/shared/AvatarGroupComponent'
import { getEmployeeDetails } from '../API/employeeAPI'
import { greenBadge, redBadge } from '../assets/svg'

const { Search } = Input

const EmployeeDetails = () => {
	const [messgaeApi, contextHolder] = message.useMessage()
	const params = useParams()
	const navigate = useNavigate()
	const { id } = params

	const [empData, setEmpData] = useState(null)
	const [isLoading, setIsLoading] = useState(true)

	const getData = async () => {
		try {
			const res = await getEmployeeDetails(id)
			const { data } = res
			setEmpData(data ?? {})
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getData()
	}, [])

	const onSearch = (value) => console.log(value)

	const onChange = (pagination, filters, sorter, extra) => {}

	const columns = [
		{
			title: 'Project Name',
			dataIndex: 'projectDetail',
			key: 'projectDetail',
			render: (projectDetail) => (
				<div className="d-flex align-items-center">
					{projectDetail?.isBestPerformer ? (
						<span className="me-2">{greenBadge}</span>
					) : projectDetail?.isDefaulter ? (
						<span className="me-2">{redBadge}</span>
					) : (
						<span className="me-4 ms-1"></span>
					)}

					<span className="fw-bold font-roboto">
						<AvatarGroupComponent
							size={'large'}
							imageUrl={projectDetail.projectAvatarUrl}
							extraclass="me-2"
						/>{' '}
						{projectDetail.projectName}
					</span>
				</div>
			),
			width: '15%'
		},
		{
			title: 'Project Lead',
			dataIndex: 'projectLeadDetail',
			key: 'projectLeadDetail',
			render: (projectLeadDetail) => (
				<span className="fw-bold font-roboto">
					<AvatarGroupComponent
						size={'large'}
						imageUrl={projectLeadDetail.projectLeadAvatarUrl}
						extraclass="me-2"
					/>{' '}
					{projectLeadDetail.projectLead}
				</span>
			)
		},
		{
			title: 'Working hours',
			dataIndex: 'workingHours',
			key: 'workingHours',
			render: (workingHours) => (
				<span className="font-roboto fw-500 fs-12">
					{workingHours > 1
						? workingHours.toFixed(0) + ' hrs'
						: workingHours > 0
						? '1 hr'
						: '-'}
				</span>
			)
		},
		{
			title: 'Workload',
			dataIndex: 'workLoadDetails',
			key: 'workLoadDetails',
			render: (workLoad) => (
				<div className="d-flex gap-2 align-items-center fs-10">
					{' '}
					<span className="font-roboto fw-500 fs-12">
						{workLoad?.workLoad.toFixed(0)}%
					</span>
					{workLoad?.isOverload ? (
						<span
							className="rounded-pill"
							style={{
								padding: '4px 6px',
								background: '#FFFFFF',
								color: '#EC9534',
								border: '1px solid #EC9534'
							}}
						>
							overload
						</span>
					) : (
						''
					)}
				</div>
			)
		},

		{
			title: 'Client Name',
			dataIndex: 'clientName',
			key: 'clientName'
		},
		{
			title: 'Progress',
			dataIndex: 'projectProgress',
			key: 'projectProgress',
			render: (progress) => (
				<Progress
					percent={progress?.toFixed(2)}
					strokeColor={'#3571A7'}
					className="table-progress"
				/>
			),
			width: '15%'
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status) => (
				<div
					className="d-flex justify-content-center p-1 align-items-center border border-1"
					style={{
						color: '#11A40D',
						backgroundColor: 'rgba(218, 255, 181, 0.15)'
					}}
				>
					Active
				</div>
			)
		},

		{
			title: ' ',
			dataIndex: 'contactLead',
			key: 'contactLead',
			render: (text, record) => (
				<a
					href="/"
					className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none"
				>
					Contact Employee
				</a>
			)
		}
	]

	const dataSource =
		empData &&
		empData?.projectDetails &&
		empData?.projectDetails.length !== 0 &&
		empData?.projectDetails?.map((project, index) => ({
			key: index.toString(),
			projectDetail: {
				projectName: project?.projectName || '',
				projectAvatarUrl: project?.projectAvatarUrl || '',
				isBestPerformer: project?.isBestPerformer,
				isDefaulter: project?.isDefaulter
			},
			projectLeadDetail: {
				projectLead: project?.projectLeadName || '',
				projectLeadAvatarUrl: project?.projectLeadAvatarUrl || ''
			},
			projectProgress: project?.progress,
			contactLead: 'Contact Project Lead',
			clientName: project?.clientName,
			workingHours: project?.workingTime,
			workLoadDetails: {
				workLoad: project?.workload,
				isOverload: project?.isOverload
			},
			isActive: project?.isActive
		}))

	return (
		<>
			{contextHolder}
			<div className="container-fluid p-4">
				<div className="d-flex justify-content-between row w-100">
					<div className="col-12 col-md-5">
						<div className="d-flex align-items-center mb-2">
							<button
								className="p-0 border-0 bg-white"
								onClick={() => {
									navigate('/dashboard/projects')
								}}
							>
								<ChevronLeftOutlined
									sx={{
										color: '#323232'
									}}
								/>
							</button>
							<div>
								<h1 className="fs-3 mb-2 dashboard-primary-header">
									{isLoading ? (
										<Skeleton.Button
											className=""
											style={{
												width: '10rem',
												height: '2rem'
											}}
										/>
									) : (
										empData?.name
									)}
								</h1>
								<a
									href="/"
									className="txt-color-blue-1 fs-12 fw-normal text-decoration-none"
								>
									{empData?.email || ''}
								</a>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-7">
						<div className="d-flex align-items-center w-100 justify-content-end h-100">
							<Link className="font-roboto txt-color-blue-1 fs-14 text-decoration-none fw-500">
								Manage Issue
							</Link>
						</div>
					</div>
				</div>
				<div className="bg-white px-4 py-3 mb-4 mt-4">
					<div className="row p-0 m-0">
						<div className="col-12 col-md-2">
							<h6 className="font-roboto txt-color-secondary fs-12">
								Total Sprints
							</h6>
							<p className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
								{isLoading ? (
									<Skeleton.Button
										className=""
										style={{
											width: '2rem',
											height: '1rem'
										}}
									/>
								) : (
									empData?.totalSprints
								)}
							</p>
						</div>
						<div className="col-12 col-md-2">
							<h6 className="font-roboto txt-color-secondary fs-12">
								Average Workload
							</h6>
							<p className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
								{isLoading ? (
									<Skeleton.Button
										className=""
										style={{
											width: '2rem',
											height: '1rem'
										}}
									/>
								) : (
									`${empData?.avgWorkload.toFixed(2)} %`
								)}
							</p>
						</div>
						<div className="col-12 col-md-2">
							<h6 className="font-roboto txt-color-secondary fs-12">
								Average Working hours
							</h6>
							<p className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
								{isLoading ? (
									<Skeleton.Button
										className=""
										style={{
											width: '2rem',
											height: '1rem'
										}}
									/>
								) : (
									`${empData?.avgWorkTime} hr`
								)}
							</p>
						</div>
						<div className="col-12 col-md-2">
							<h6 className="font-roboto txt-color-secondary fs-12">
								Total Issues Completed
							</h6>
							<p className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
								{isLoading ? (
									<Skeleton.Button
										className=""
										style={{
											width: '2rem',
											height: '1rem'
										}}
									/>
								) : (
									`${empData?.totalIssuesCompleted || 'N/A'}`
								)}
							</p>
						</div>
						<div className="col-12 col-md-2">
							<h6 className="font-roboto txt-color-secondary fs-12">
								Total Issues pending
							</h6>
							<p className="txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
								{isLoading ? (
									<Skeleton.Button
										className=""
										style={{
											width: '2rem',
											height: '1rem'
										}}
									/>
								) : (
									`${empData?.totalIssuesPending || 'N/A'}`
								)}
							</p>
						</div>
						<div className="col-12 col-md-2">
							<div className="d-flex align-items-end justify-content-end me-2 mt-3">
								<p className="d-flex align-items-center txt-color-tertiary fw-500 font-roboto fs-6 mb-0 me-3">
									<span className="d-flex me-1">{greenBadge}</span>{' '}
									{empData?.bestPerformerCount}
								</p>
								<p className="d-flex align-items-center txt-color-tertiary fw-500 font-roboto fs-6 mb-0">
									<span className="d-flex me-1">{redBadge}</span>{' '}
									{empData?.defaulterCount}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="d-flex align-items-center justify-content-between mb-3">
					<p className="fs-12 txt-color-blue-2 fw-bold font-roboto">
						{isLoading ? (
							<Skeleton.Button
								className=""
								style={{
									width: '5rem',
									height: '1rem'
								}}
							/>
						) : (
							`Projects (${empData?.projectDetails?.length || 0})`
						)}
					</p>
					<div className="d-flex align-items-center">
						<Search
							placeholder="Search"
							onSearch={onSearch}
							style={{ width: 140 }}
							className="header-search bg-transparent"
						/>
						<button className="bg-transparent border-0 font-roboto fw-500 txt-color-fourth fs-12">
							<span>
								<FilterAlt className="txt-color-fourth fs-14" />
							</span>{' '}
							Filter
						</button>
					</div>
				</div>
				<TableComponent
					dataSource={dataSource}
					columns={columns}
					onChange={onChange}
					loading={isLoading}
				/>
			</div>
		</>
	)
}

export default EmployeeDetails
