import { useEffect, useState } from 'react'
import ModalComponent from '../ModalComponent'
import ButtonComponent from '../ButtonComponent'
import '../../../styles/SelectListStyle.css'
import { Select } from 'antd'
import CloseIcon from '@mui/icons-material/Close'
import { useMember } from '../../../utils/componentUtils/memberUtils/useAddMember'
import {
	bulkCreateMember,
	getAllMembers,
	inviteMembers
} from '../../../API/MembersAPI'
import AvatarGroupComponent from '../AvatarGroupComponent'
import { IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { getAllProjects } from '../../../API/projectsAPI'
import { getRoles } from '../../../API/rolesApi'
import useInviteInput from '../../../hooks/useInviteInput'
import useMessage from 'antd/es/message/useMessage'

const CreateNewMemberModal = ({ open, onClose }) => {
	const { memberSelect, selectedValues, setSelectedValues } = useMember()
	const { getInviteInput, invited } = useInviteInput()

	const [message, contextHolder] = useMessage()

	const [allMembers, setAllMembers] = useState([])
	const [allProjects, setAllProjects] = useState([])
	const [allRoles, setAllRoles] = useState([])

	const [dataSet, setDataSet] = useState([])

	const getData = async () => {
		try {
			const res = await getAllMembers()
			const { data } = res
			setAllMembers([...data])
		} catch (error) {
			debugger
		}
	}

	const getProjectData = async () => {
		try {
			const res = await getAllProjects()
			const { data = [] } = res
			setAllProjects([...data])
		} catch (error) {
			debugger
		}
	}

	const getAllRoles = async () => {
		try {
			const res = await getRoles()
			const { data = [] } = res
			setAllRoles([...data])
		} catch (error) {}
	}

	///Initiates initial data structure
	const makeDataStructure = () => {
		const updatedArr2 = selectedValues.map((id) => {
			const existingItem = dataSet.find((item) => item.id === id)
			if (existingItem) {
				return { ...existingItem }
			} else {
				// Create a new object with additional properties for the added value
				return {
					id
				}
			}
		})
		setDataSet([...updatedArr2])
	}

	/////Add project data inb data structure
	const handleProjects = (memberId, projectId) => {
		let tempProjects =
			dataSet?.find((data) => data?.id === memberId)?.projects || []
		let tempDataSet = [...dataSet]

		if (tempProjects.length === 0) {
			tempProjects.push({
				projectId: projectId
			})
		} else {
			for (let i = 0; i < tempProjects.length; i++) {
				if (tempProjects[i]?.id === projectId) {
					tempProjects = tempProjects.filter((obj) => obj.id !== projectId)
				} else {
					tempProjects.push({
						projectId: projectId
					})
					break
				}
			}
		}

		dataSet.forEach((data, i) => {
			if (data?.id === memberId) {
				tempDataSet[i].projects = tempProjects
			}
		})
		setDataSet([...tempDataSet])
	}

	const removeProject = (memberId, projectId) => {
		let tempProjects =
			dataSet?.find((data) => data?.id === memberId)?.projects || []
		let tempDataSet = [...dataSet]

		let index = tempProjects.findIndex((pr) => pr.projectId === projectId)

		if (index !== -1) {
			tempProjects.splice(index, 1)
		}

		dataSet.forEach((data, i) => {
			if (data?.id === memberId) {
				tempDataSet[i].projects = tempProjects
			}
		})
		setDataSet([...tempDataSet])
	}

	////To Do:- Integrate roles in data structure
	const handleRoles = (memberId, projectId, roleId) => {
		let tempProjects =
			dataSet?.find((data) => data?.id === memberId)?.projects || []
		let tempDataSet = [...dataSet]

		let updatedProjects = tempProjects.map((pr) => {
			if (pr?.projectId === projectId) {
				return {
					projectId: projectId,
					roleId: roleId
				}
			} else {
				return { ...pr }
			}
		})

		dataSet.forEach((data, i) => {
			if (data?.id === memberId) {
				tempDataSet[i].projects = updatedProjects
			}
		})
		setDataSet([...tempDataSet])
	}

	/////Function to send invites
	const submitInvites = async () => {
		if (invited && invited.length === 0) {
			return false
		}
		try {
			const res = await inviteMembers(invited)

			message.success('Invites send successfully.')
			onClose()
		} catch (error) {
			message.error('Unable to invite members.')
			onClose()
		}
	}

	useEffect(() => {
		makeDataStructure()
	}, [selectedValues])

	useEffect(() => {
		getData()
		getProjectData()
		getAllRoles()
	}, [])

	const handleCreateMember = async () => {
		if (dataSet.length === 0) {
			if (invited?.length === 0) {
				return false
			} else {
				submitInvites()
				return false
			}
		}

		try {
			const res = await bulkCreateMember(dataSet)
			message.success('Member added successfully.')
			submitInvites()
			onClose()
			getData()
		} catch (error) {
			message.error('Unable to add members.')
			onClose()
		}
	}

	return (
		<>
			{contextHolder}
			<ModalComponent title="Create a member" open={open} onClose={onClose}>
				<>
					<div className="mt-4">
						<h6
							style={{ color: '#555' }}
							className="fw-semibold font-roboto txt-color-primary fs-14"
						>
							Add Members
						</h6>
						{memberSelect()}
					</div>
					<div className="mt-4">
						{!!selectedValues.length && (
							<>
								<h6
									style={{ color: '#555' }}
									className="fw-semibold font-roboto txt-color-primary fs-14"
								>
									Add members as
								</h6>
								<p className="font-roboto fs-12 ">
									Define role for each members. You can also customise or add
									new roles in{' '}
									<span role="button" style={{ color: '#204464' }}>
										Settings
									</span>
								</p>
							</>
						)}
						{allMembers &&
							selectedValues &&
							selectedValues.length !== 0 &&
							allMembers.length !== 0 &&
							allMembers.map((member, index) => {
								if (selectedValues.includes(member?.id)) {
									let memberProjects = member?.projects || []
									let selectedProjects =
										dataSet.find((data) => data?.id === member.id)?.projects ||
										[]

									let projectIds = selectedProjects?.map((pr) => pr?.id)

									let remainProjects = allProjects.filter(
										(allPr) => !projectIds.includes(allPr.id)
									)

									return (
										<div key={member.id}>
											<div className="d-flex align-items-center  justify-content-between mb-4 ">
												<div className="d-flex  align-items-center ">
													<AvatarGroupComponent
														name={member?.name || ''}
														imageUrl={member?.imageUrl || ''}
													/>
													<div className="ms-2">
														<h6 className="fs-12 mb-0 font-roboto leading-1 ">
															{member?.name}
														</h6>
														<span className="font-roboto fs-11 fw-normal">
															{member?.email}
														</span>
													</div>
												</div>

												<div className="d-flex align-items-center">
													<Select
														className="select-wrapper mb-0 fs-10 generic-select"
														placeholder="Project Name"
														style={{
															width: 91,
															fontSize: 10
														}}
														popupClassName="generic-select-dropdown"
														onChange={(value) => {
															handleProjects(member?.id, value)
															value = ''
														}}
														options={remainProjects
															.filter(
																(allPr) =>
																	!memberProjects.some(
																		(mbPr) => mbPr.id === allPr.id
																	)
															)
															?.map((pr) => {
																return {
																	label: pr?.name,
																	value: pr?.id
																}
															})}
													/>
													<IconButton
														className="ms-2"
														onClick={() => {
															let id = member?.id
															let temp = [...selectedValues]
															let index = selectedValues.findIndex(
																(mbr) => mbr === id
															)
															if (index !== -1) {
																temp.splice(index, 1)
															}
															setSelectedValues([...temp])
														}}
													>
														<Delete
															sx={{
																fontSize: '1rem'
															}}
														/>
													</IconButton>
												</div>
											</div>

											{allProjects?.map?.((pr, prIndex) => {
												if (selectedProjects && selectedProjects.length !== 0) {
													return selectedProjects?.map((slPr) => {
														if (slPr.projectId === pr?.id) {
															return (
																<div
																	style={{
																		borderColor: '1px solid #D9D9D9',
																		borderRadius: 2
																	}}
																	className="d-flex align-items-center border p-2 rounded-1 mb-3 justify-content-between"
																	key={pr.id}
																>
																	<div className="d-flex align-items-center">
																		<AvatarGroupComponent
																			imageUrl={pr?.imageUrl || ''}
																			name={pr?.name || ''}
																		/>
																		<span className="ms-2 font-roboto fs-10">
																			{pr.name}
																		</span>
																	</div>

																	<div className="d-flex align-items-center">
																		<Select
																			options={
																				allRoles &&
																				allRoles.length !== 0 &&
																				allRoles.map((role) => {
																					return {
																						label: role?.name,
																						value: role?.id
																					}
																				})
																			}
																			style={{ color: '#555555' }}
																			bordered={false}
																			className="select-wrapper select-box mb-0 border-0"
																			placeholder="Select a role"
																			onChange={(value) => {
																				handleRoles(member?.id, pr?.id, value)
																			}}
																		/>
																		<IconButton className="ms-1">
																			<CloseIcon
																				sx={{
																					fontSize: '1rem',
																					color: '#555'
																				}}
																				onClick={() => {
																					removeProject(member?.id, pr?.id)
																				}}
																			/>
																		</IconButton>
																	</div>
																</div>
															)
														} else {
															return null
														}
													})
												} else {
													return null
												}
											})}
										</div>
									)
								} else {
									return null
								}
							})}
					</div>
					<div className="mt-4">
						<h6
							style={{ color: '#555' }}
							className="fw-semibold font-roboto txt-color-primary fs-14"
						>
							Invite new members
						</h6>
						{getInviteInput()}

						<div className="d-flex align-items-center justify-content-end mt-3">
							<ButtonComponent variant="light" text="Back" click={onClose} />
							<ButtonComponent
								variant="dark"
								extraclassName="ms-2"
								text="Save"
								click={handleCreateMember}
								disabled={
									!Boolean(dataSet?.length) && !Boolean(invited?.length)
								}
							/>
						</div>
					</div>
				</>
			</ModalComponent>
		</>
	)
}

export default CreateNewMemberModal
