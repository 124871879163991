import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import { Form, Formik } from 'formik'

import FormComponent from '../../../forms'
import ButtonComponent from '../../../ButtonComponent'

import { getSprints } from '../../../../../API/sprintAPI'
import { getColoumn } from '../../../../../API/taskAPI'
import useMessage from 'antd/es/message/useMessage'

const TaskForm = ({
	editNewTask = false,
	dataSelected = [],
	onClose,
	setFormData,
	handleTabChange,
	clientId,
	clientProjects
}) => {
	const [statusOptions, setStatusOptions] = useState([])
	const [sprintOptions, setSprints] = useState([])
	const [projectId, setProjectId] = useState(
		editNewTask ? dataSelected?.[0]?.project?.id : ''
	)
	const [sprintId, setSprintId] = useState(
		editNewTask ? dataSelected?.[0]?.sprintId : ''
	)

	const [messageApi, contextHolder] = useMessage()

	const getAllColoumn = async () => {
		try {
			const res = await getColoumn()
			const { data = [] } = res

			setStatusOptions(
				data?.map((project) => {
					return {
						value: project?.id,
						label: project?.name
					}
				})
			)
		} catch (error) {
			console.log('Error while fetching all projects ', error)
		}
	}

	useEffect(() => {
		if (dataSelected?.[0]?.sprintId && dataSelected?.[0]?.sprintId !== '') {
			getSprintData(dataSelected[0]?.project?.id)
		}
	}, [])

	const getSprintData = async (projectId) => {
		setSprints([])
		setSprintId('')

		try {
			const res = await getSprints(projectId)
			const { data = [] } = res

			if (data && data.length !== 0) {
				setSprints(
					data && data.length !== 0
						? data.map((spr) => {
								return {
									value: spr.id,
									label: spr?.name
								}
						  })
						: []
				)
				// messageApi.success('Sprints fetched.')
			}
			//  else {
			// 	messageApi.error('No sprints found for this project.')
			// }
		} catch (error) {
			messageApi.error('Unable to get sprints.')
		}
	}

	useEffect(() => {
		//getProjects()
		getAllColoumn()
	}, [])

	const formInitialValues = {
		taskName: editNewTask ? dataSelected?.[0]?.name : '',
		taskStatus: dataSelected?.[0]?.statusDetail?.name
			? dataSelected?.[0]?.statusDetail?.name
			: dataSelected[0]?.label
			? dataSelected[0]?.label
			: null,
		taskDescription: editNewTask ? dataSelected?.[0]?.description : '',
		projectId: editNewTask ? dataSelected[0]?.project?.id : null,
		startDate: editNewTask ? dayjs(dataSelected[0]?.startDate) : null,
		dueDate: editNewTask ? dayjs(dataSelected[0]?.dueDate) : null,
		statusId: dataSelected[0]?.value ? dataSelected[0]?.value : null,
		priority: editNewTask ? dataSelected?.[0]?.priority : null,
		sprintId: editNewTask ? dataSelected?.[0]?.sprintId || '' : '',
		projectName: editNewTask ? dataSelected?.[0]?.project?.id : ''
	}
	const formValidationSchema = Yup.object({
		taskName: Yup.string().required('Task name is required'),
		taskStatus: Yup.string().required('Task status is required'),
		taskDescription: Yup.string(),
		projectId: Yup.number().required('Project name is required'),
		startDate: Yup.date().required('Start date is required'),
		dueDate: Yup.date()
			.required('End date is required')
			.min(Yup.ref('startDate'), 'Due date should not be before start date.')
		// sprintId: Yup.string().required('Required')
	})

	const priorityOptions = [
		{
			label: 'High',
			value: 'High'
		},
		{
			label: 'Low',
			value: 'Low'
		},
		{
			label: 'Medium',
			value: 'Medium'
		},
		{
			label: 'Urgent',
			value: 'Urgent'
		}
	]

	return (
		<>
			{contextHolder}
			<Formik
				initialValues={formInitialValues}
				validationSchema={formValidationSchema}
				// enableReinitialize={true}
				onSubmit={(values) => {
					let obj = { ...values }
					let filteredAsPerString

					if (typeof obj?.taskStatus !== 'number') {
						filteredAsPerString = statusOptions?.filter(
							(el) => el?.label === obj?.taskStatus
						)
						obj.statusId = filteredAsPerString[0]?.value
						obj['status'] = filteredAsPerString[0]?.label
					} else {
						obj.statusId = obj?.taskStatus
						let statusName = statusOptions?.filter(
							(el) => el?.value === obj?.taskStatus
						)
						obj['status'] = statusName[0]?.label
					}

					if (statusOptions?.find((el) => el?.label === obj?.taskStatus)) {
					}

					// need to check if condition
					// if (editNewTask) {
					// 	obj['projectId'] = dataSelected[0]?.projectId
					// 	obj['id'] = dataSelected[0]?.statusDetail?.id
					// } else {
					// 	obj['projectId'] = values?.projectName
					// }
					setFormData(obj)
					handleTabChange('2')
				}}
			>
				{({ handleSubmit, values, setFieldValue }) => {
					console.log('values', values)
					return (
						<Form onSubmit={handleSubmit}>
							<div className="d-flex flex-column">
								<div className="row">
									<div className="col-12 col-md-6">
										<FormComponent
											name="taskName"
											placeholder="Enter task name"
											control="input"
											label="Task Name"
										/>
									</div>
									<div className="col-12 col-md-6">
										<FormComponent
											name="taskStatus"
											placeholder="Select Status"
											control="generic-select"
											options={statusOptions}
											multiple={false}
											label="Task Status"
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-12">
										<FormComponent
											name="taskDescription"
											placeholder="Type your summary"
											control="input"
											label="Task Description (optional)"
											labelClass="create-project-headings"
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-12 col-md-6">
										<FormComponent
											name="projectName"
											placeholder="Select a project"
											control="generic-select"
											label="Select Project"
											value={projectId}
											options={clientProjects}
											onChange={(e) => {
												setFieldValue('projectId', e)
												getSprintData(e)
												setProjectId(e)
											}}
										/>
									</div>
									<div className="col-12 col-md-6">
										<FormComponent
											name="sprintId"
											placeholder="Select a sprint"
											control="generic-select"
											label="Add to sprint (optional)"
											multiple={false}
											value={sprintId}
											onChange={(e) => {
												setFieldValue('sprintId', e)
												// getSprintData(e)
												setSprintId(e)
											}}
											options={sprintOptions}
											// disabled={
											// 	values?.projectId &&
											// 	values?.projectId !== null &&
											// 	sprintOptions.length !== 0
											// 		? false
											// 		: true
											// }
										/>
									</div>
									<div className="col-12 col-md-6">
										<FormComponent
											name="priority"
											placeholder="Select a priority"
											control="generic-select"
											label="Add Priority"
											multiple={false}
											options={priorityOptions}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-12 col-md-6">
										<FormComponent
											name="startDate"
											placeholder="Enter start date"
											control="date-picker"
											label="Start Date"
										/>
									</div>
									<div className="col-12 col-md-6">
										<FormComponent
											name="dueDate"
											placeholder="Select due date"
											control="date-picker"
											label="Due Date"
										/>
									</div>
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-end mt-4">
								<ButtonComponent
									variant="light"
									text="Cancel"
									click={onClose}
								/>
								<ButtonComponent
									variant="dark"
									type="submit"
									extraclassName="ms-2"
									text={editNewTask ? 'Save' : 'Add a task'}
									click={() => {
										handleSubmit(values)
										// handleTabChange('2')
									}}
								/>
							</div>
						</Form>
					)
				}}
			</Formik>
		</>
	)
}

export default TaskForm
