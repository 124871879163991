import React, { useEffect, useState } from 'react'

import { Button, Select } from 'antd'
import { Delete, ExpandMore } from '@mui/icons-material'

import { IconButton } from '@mui/material'
import { getAllProjects } from '../../../API/projectsAPI'
import AvatarGroupComponent from '../../../components/shared/AvatarGroupComponent'

const { Option } = Select

export const useProject = (intialValue = []) => {
	const [data, setData] = useState([])
	const [isFail, setIsFail] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

	const [selectedProjects, setSelectedProjects] = useState(intialValue)

	const getData = async () => {
		try {
			const res = await getAllProjects()
			const { data } = res

			setIsLoading(false)
			if (data.length < 1) {
				setIsFail(true)
			} else {
				setData([...data])
			}
		} catch (error) {
			debugger
			setIsFail(true)
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getData()
	}, [])

	const projectSelect = () => {
		return (
			<div className="formField">
				{
					<Select
						mode="multiple"
						value={selectedProjects}
						className="member-select"
						menuItemSelectedIcon={false}
						onChange={(value) => {
							setSelectedProjects([...value])
						}}
						suffixIcon={<ExpandMore />}
						optionFilterProp="label"
						popupClassName="member-select-popup"
						loading={isLoading}
						placeholder="Add to new projects"
					>
						{data &&
							data.map((project) => {
								return (
									<Option
										key={project?.id}
										value={project?.id}
										label={`${project?.name}`}
									>
										<div className="d-flex align-items-center justify-content-between px-2 py-2">
											<div className="d-flex align-items-center">
												<AvatarGroupComponent
													size={40}
													imageUrl={project?.profileImg || ''}
													name={project?.name || ''}
												/>
												<div className="ms-2">
													<p className="fs-12 fw-500 font-roboto txt-color-primary mb-0">
														{project?.name &&
															project?.name !== '' &&
															project?.name}
													</p>
													{/* <p
														className="fw-400 font-roboto mb-0 txt-color-primary"
														style={{
															fontSize: '11px'
														}}
													>
														{member?.role || 'Member Role'}
													</p> */}
												</div>
											</div>
											{selectedProjects.includes(project?.id) ? (
												<IconButton>
													<Delete
														sx={{
															fontSize: '16px',
															color: '#555'
														}}
													/>
												</IconButton>
											) : (
												<Button className="add-member-btn border-0 rounded-0 px-2 py-1 font-roboto fs-10 txt-color-primary">
													Add
												</Button>
											)}
										</div>
									</Option>
								)
							})}
					</Select>
				}
			</div>
		)
	}
	return { projectSelect, selectedProjects, setSelectedProjects }
}
