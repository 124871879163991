import React from 'react'
import blogImg from '../assets/images/blog-img.png'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material'
import {
	facebookIconColor,
	instagramIconColor,
	linkedInIconColor,
	twitterIconColor
} from '../assets/svg'

const Blog = () => {
	return (
		<div className="container text-center mt-4">
			<img src={blogImg} alt="" />
			<h1
				className="fs-48 fw-bold mt-4"
				style={{
					color: '#14141'
				}}
			>
				We are launching this feature soon!
			</h1>
			<p
				className="font-roboto font-mulish fs-5"
				style={{
					color: '#7d7d7d'
				}}
			>
				Our dedicated team has been hard at work to bring you this amazing
				<br /> feature, and we can't wait to share it with you
			</p>
			<Link
				className="fw-14 fw-semibold mt-3"
				to="/"
				style={{
					color: '#3952c4'
				}}
			>
				Go back to home page
			</Link>
			<p
				className="font-poppins fs-5 fw-normal mt-4"
				style={{
					color: '#141414'
				}}
			>
				Follow us to get regular updates
			</p>
			<div className="d-flex align-items-center justify-content-center gap-4">
				<IconButton
					onClick={() => {
						window.open(
							'https://www.facebook.com/profile.php?id=100090749470482',
							'_blank'
						)
					}}
					sx={{
						backgroundColor: 'rgba(57,82,196,6%)',
						width: '2.5rem',
						height: '2.5rem'
					}}
				>
					{facebookIconColor}
				</IconButton>
				<IconButton
					onClick={() => {
						window.open('https://twitter.com/SynccosApp', '_blank')
					}}
					sx={{
						backgroundColor: 'rgba(57,82,196,6%)',
						width: '2.5rem',
						height: '2.5rem'
					}}
				>
					{twitterIconColor}
				</IconButton>
				<IconButton
					onClick={() => {
						window.open('https://www.instagram.com/synccosapp/', '_blank')
					}}
					sx={{
						backgroundColor: 'rgba(57,82,196,6%)',
						width: '2.5rem',
						height: '2.5rem'
					}}
				>
					{instagramIconColor}
				</IconButton>
				<IconButton
					onClick={() => {
						window.open(
							'https://www.linkedin.com/company/synccos-inc',
							'_blank'
						)
					}}
					sx={{
						backgroundColor: 'rgba(57,82,196,6%)',
						width: '2.5rem',
						height: '2.5rem'
					}}
				>
					{linkedInIconColor}
				</IconButton>
			</div>
		</div>
	)
}

export default Blog
