import { useState, useRef } from 'react'
import { Tabs, message } from 'antd'
import Cookies from 'js-cookie'
import AllProjects from '../components/views/Projects/AllProjects'
import Issues from '../components/views/Projects/Issues'
import Employees from '../components/views/Projects/Employees'
import searchIcon from '../assets/images/search-icon.svg'
import filterIcon from '../assets/images/filter-icon.svg'
import reminderIcon from '../assets/images/reminder-icon.svg'
import deleteIcon from '../assets/images/delete-icon.svg'
import ProjectsFilter from '../components/shared/Drawers/ProjectsFilter'
import ButtonComponent from '../components/shared/ButtonComponent'
import CreateNewProjectModal from '../components/shared/Modals/CreateNewProjectModal'
import CreateNewTaskModal from '../components/shared/Modals/CreateNewTaskModal'
import CreateNewMemberModal from '../components/shared/Modals/CreateNewMemberModal'
import { useLocation, useNavigate } from 'react-router'
import DeleteProjectModal from '../components/shared/Modals/DeleteProjectModal'
import DeleteTaskModal from '../components/shared/Modals/DeleteTaskModal'
import DeleteMemberModal from '../components/shared/Modals/DeleteMemberModal'
import { AddCircleOutlineOutlined, Delete } from '@mui/icons-material'

const Projects = () => {
	const ref = useRef()

	const location = useLocation()
	const { state = {} } = location

	const [messageApi, contextHolder] = message.useMessage()
	const [activeKey, setActiveKey] = useState(state?.activeTab || '1')

	const [dataSelected, setDataSelected] = useState([])

	const [deleteProject, setDeleteProject] = useState(false)
	const [deleteTask, setDeleteTask] = useState(false)
	const [deleteMember, setDeleteMember] = useState(false)

	const [applyFilter, setApplyFilter] = useState(false)

	const [createNewProject, setCreateNewProject] = useState(false)
	const [createNewTask, setCreateNewTask] = useState(false)
	const [createNewMember, setCreateNewMember] = useState(false)

	const [editNewTask, setEditNewTask] = useState(false)

	const [newProjectAdded, setNewProjectAdded] = useState(false)
	const [newTaskAdded, setNewTaskAdded] = useState(false)

	const deletion = () => {
		switch (activeKey) {
			case '1':
				setDeleteProject(true)
				break
			case '2':
				setDeleteTask(true)
				break
			case '3':
				setDeleteMember(true)
				break
			default:
				break
		}
	}

	const getMembersData = () => {
		//To be called when a member is deleted
		ref.current.getMembers()
	}

	const createNew = () => {
		switch (activeKey) {
			case '1':
				setCreateNewProject(true)
				break
			case '2':
				setCreateNewTask(true)
				break
			case '3':
				setCreateNewMember(true)
				break
			default:
				break
		}
	}

	const items = [
		{
			key: '1',
			label: 'All Projects',
			children: (
				<AllProjects
					activeKey={activeKey}
					newProjectAdded={newProjectAdded}
					setNewProjectAdded={setNewProjectAdded}
					newTaskAdded={newTaskAdded}
					setNewTaskAdded={setNewTaskAdded}
					setDataSelected={setDataSelected}
					setDeleteProject={setDeleteProject}
					deleteProject={deleteProject}
				/>
			)
		},
		{
			key: '2',
			label: 'Task',
			children: (
				<Issues
					activeKey={activeKey}
					setDataSelected={setDataSelected}
					setDeleteTask={setDeleteTask}
					createNew={createNew}
					setEditNewTask={setEditNewTask}
				/>
			)
		},
		{
			key: '3',
			label: 'Members',
			children: (
				<Employees
					activeKey={activeKey}
					setDataSelected={setDataSelected}
					ref={ref}
					createNewMember={createNewMember}
				/>
			)
		}
	]

	return (
		<>
			{contextHolder}
			<div className="container-fluid p-4">
				<div className="d-flex justify-content-between align-items-center mb-4">
					<h1 className="fs-3 dashboard-primary-header">Projects</h1>
					<ButtonComponent
						variant="dark"
						disabled={dataSelected && dataSelected.length !== 0 ? true : false}
						text={`Create a ${
							activeKey === '1'
								? 'project'
								: activeKey === '2'
								? 'task'
								: 'member'
						}`}
						icon={
							<AddCircleOutlineOutlined
								sx={{
									fontSize: '1.25rem'
								}}
							/>
						}
						click={createNew}
						extraclassName="d-flex align-items-center"
						style={{ borderRadius: '2px' }}
					/>
				</div>
				<div>
					<div
						style={{
							marginBottom: '-35px',
							float: 'right',
							zIndex: '1',
							position: 'relative'
						}}
					>
						{dataSelected?.length === 0 ? (
							<>
								{/* <button className="ms-4 operations-on-table">
									<img src={searchIcon} alt="search-icon" className="px-1" />
									<span className="ms-1 fs-14 font-roboto">Search</span>
								</button>
								<button
									className="ms-4 operations-on-table"
									onClick={filterApplied}
								>
									<img src={filterIcon} alt="filter-icon" className="px-1" />
									<span className="ms-1 fs-14 font-roboto">Filter</span>
								</button> */}
							</>
						) : (
							<>
								{/* <button className="ms-4 operations-on-table">
									<img src={reminderIcon} alt="search-icon" className="px-1" />
									<span className="ms-1 fs-14 font-roboto">Add Reminder</span>
								</button> */}
								<button
									className="ms-4 operations-on-table d-flex align-items-center"
									onClick={deletion}
								>
									<Delete
										sx={{
											fontSize: '1rem'
										}}
									/>
									<span className="ms-1 fs-14 font-roboto">Delete</span>
								</button>
							</>
						)}
					</div>
					<Tabs
						defaultActiveKey={activeKey}
						items={items}
						className="dashboard-tabs"
						onChange={(key) => {
							setActiveKey(key)
						}}
					/>
					{deleteProject && (
						<DeleteProjectModal
							isModalOpen={deleteProject}
							setIsModalOpen={setDeleteProject}
							dataSelected={dataSelected}
							setDataSelected={setDataSelected}
						/>
					)}
					{deleteTask && (
						<DeleteTaskModal
							isModalOpen={deleteTask}
							setIsModalOpen={setDeleteTask}
							dataSelected={dataSelected}
							setDataSelected={setDataSelected}
						/>
					)}
					{deleteMember && (
						<DeleteMemberModal
							isModalOpen={deleteMember}
							setIsModalOpen={setDeleteMember}
							dataSelected={dataSelected}
							getMembersData={getMembersData}
						/>
					)}
					{applyFilter && (
						<ProjectsFilter
							applyFilter={applyFilter}
							setApplyFilter={setApplyFilter}
						/>
					)}
					{createNewProject && (
						<CreateNewProjectModal
							open={createNewProject}
							setOpen={setCreateNewProject}
							onClose={() => setCreateNewProject(false)}
							setNewProjectAdded={setNewProjectAdded}
						/>
					)}
					{createNewTask && (
						<CreateNewTaskModal
							open={createNewTask}
							// for on createNew it will not store the previous data
							onClose={() => {
								setCreateNewTask(false)
								setEditNewTask(false)
								setDataSelected([])
							}}
							dataSelected={dataSelected}
							editNewTask={editNewTask}
							setEditNewTask={setEditNewTask}
						/>
					)}
					{createNewMember && (
						<CreateNewMemberModal
							open={createNewMember}
							onClose={() => setCreateNewMember(false)}
						/>
					)}
				</div>
			</div>
		</>
	)
}

export default Projects
