import { useEffect, useState } from 'react'
import '../../../../styles/SelectListStyle.css'
import { Avatar, Select } from 'antd'
import deletedIcon from '../../../../assets/images/delete_outline.svg'
import ButtonComponent from '../../../shared/ButtonComponent'
import { updateProject } from '../../../../API/projectsAPI'
import { getAllMembers, inviteMembers } from '../../../../API/MembersAPI'
import { getRoles } from '../../../../API/rolesApi'
import { useMember } from '../../../../utils/componentUtils/memberUtils/useAddMember'
import useInviteInput from '../../../../hooks/useInviteInput'
import AvatarGroupComponent from '../../../shared/AvatarGroupComponent'

const AddMembers = ({
	formData,
	selectedProject,
	setProjectEdited,
	setOpen,
	dataSource
}) => {
	const [allMembers, setAllMembers] = useState([])
	const [allRoles, setAllRoles] = useState([])
	const [selectedRoles, setSelectedRoles] = useState([])

	const { invited, getInviteInput } = useInviteInput()

	// handling and selecting member during search
	const { memberSelect, selectedValues, setSelectedValues } = useMember()

	const removeMember = (id) => {
		// setMembers((prev) => prev.filter((member) => member.id !== id))
		setSelectedRoles((prev) => prev.filter((member) => member.id !== id))
		setSelectedValues((prev) => prev.filter((member) => member !== id))
	}
	useEffect(() => {
		setSelectedValues(selectedProject?.members?.map((item) => item?.id))
		setSelectedRoles(selectedProject?.members)
	}, [selectedProject])

	useEffect(() => {
		// filtering selected memebr for all memeber data
		let newMemberFromSearch = allMembers?.filter((item) => {
			if (selectedValues?.includes(item?.id)) {
				return item
			}
		})

		let alreadyRoleAssigned = selectedRoles?.map((item) => item?.id)

		// handling if member was previously in project but for current remove and add again
		let previouslyAddedRole = newMemberFromSearch?.filter((item) => {
			if (!alreadyRoleAssigned?.includes(item?.id)) {
				return item
			}
		})

		if (previouslyAddedRole?.length > 0) {
			setSelectedRoles((prev) => [...prev, ...previouslyAddedRole])
		} else {
			// in else reciving all data which are new adding

			// collecting ids of all memeber present in the project
			let alreadySelectedMembers = selectedProject?.members?.map(
				(item) => item?.id
			)

			// adding the new memeber whoes not poresent in project data from edit project data
			// ==>it gives the data which are not present in the edit projectdata
			let newMemberNotPresentInProject = newMemberFromSearch?.filter((item) => {
				if (!alreadySelectedMembers?.includes(item?.id)) {
					return item
				}
			})

			// check for member if role is already assign
			// ==> it give only that member which was not previously assigned
			let newMemberNotPresentRoles = newMemberNotPresentInProject?.filter(
				(item) => {
					if (!alreadyRoleAssigned?.includes(item?.id)) {
						return item
					}
				}
			)

			if (newMemberNotPresentRoles?.length > 0) {
				setSelectedRoles((prev) => [...prev, ...newMemberNotPresentRoles])
			}
		}
	}, [selectedValues])

	useEffect(() => {
		getAllMembersData()
		getAllRolesData()
	}, [])

	const getAllMembersData = async () => {
		try {
			const res = await getAllMembers()
			setAllMembers(res?.data)
		} catch (error) {}
	}
	const getAllRolesData = async () => {
		try {
			const res = await getRoles()
			const membersList = res?.data
			let arr = []
			for (let i = 0; i < membersList?.length; i++) {
				let obj = {}
				obj['value'] = membersList[i]?.id
				obj['label'] = membersList[i]?.name
				arr.push(obj)
			}
			setAllRoles(arr)
		} catch (error) {}
	}

	const editCurrentProject = async () => {
		const body = {
			id: selectedProject?.projectId,
			name: formData?.projectName,
			status: formData?.projectStatus,
			description: formData?.projectDescription,
			startDate: formData?.startDate,
			dueDate: formData?.dueDate,
			members: selectedRoles,
			imageUrl: formData?.projectImage,
			clientId: formData?.clientId
		}

		let invitedArr
		// for inviting member in project
		if (invited?.length > 0) {
			invitedArr = [...invited]
			invitedArr[0]['projectId'] = selectedProject?.projectId
		}

		try {
			const res = await updateProject(body)
			if (invitedArr?.length > 0) {
				const resInvite = await inviteMembers(invitedArr)
			}

			setProjectEdited(true)
			setOpen(false)
		} catch (error) {
			console.log('Error while adding new project ', error)
		}
	}

	const handleSelect = (targetRoles, member) => {
		setAllRoles((prev) => prev.filter((item) => item?.value !== targetRoles))

		if (selectedRoles.find((el) => el?.id === member?.id)) {
			let changedPreviousRole = selectedRoles.map((item) => {
				if (item?.id === member?.id) {
					let obj = { ...item }
					obj['roleId'] = targetRoles
					return obj
				}
				return item
			})
			setSelectedRoles([...changedPreviousRole])
		} else {
		}
	}
	return (
		<>
			<div className="">
				<h6
					style={{ color: '#555' }}
					className="fw-semibold font-roboto txt-color-primary fs-14"
				>
					Add Members
				</h6>
				{memberSelect()}
			</div>
			<div className="mt-4">
				{!!selectedRoles.length && (
					<>
						<h6
							style={{ color: '#555' }}
							className="fw-semibold font-roboto txt-color-primary fs-14"
						>
							Add members as
						</h6>
						<p className="font-roboto fs-12 ">
							Define role for each members. You can also customise or add new
							roles in{' '}
							<span role="button" style={{ color: '#204464' }}>
								Settings
							</span>
						</p>
					</>
				)}

				{selectedRoles.map((member) => {
					return (
						<div
							className="d-flex align-items-center  justify-content-between mb-4 "
							key={member?.id}
						>
							<div className="d-flex  align-items-center ">
								<AvatarGroupComponent
									name={member?.name || member?.email || ''}
									imageUrl={member?.imageUrl}
								/>
								<div className="ms-2">
									<h6 className="fs-12 mb-0 font-roboto leading-1 ">
										{member?.name}
									</h6>
									<span className="font-roboto fs-11 fw-normal">
										{member?.email}
									</span>
								</div>
							</div>

							<div className="d-flex align-items-center">
								<Select
									className="select-wrapper mb-0 fs-10"
									placeholder="Select a Designation"
									style={{
										width: 91,
										fontSize: 10
									}}
									defaultValue={{
										value: member?.role?.id,
										label: member?.role?.name
									}}
									onSelect={(value) => handleSelect(value, member)}
									options={allRoles}
								/>
								<img
									src={deletedIcon}
									alt=""
									className="cursor-pointer ms-3"
									onClick={() => removeMember(member.id)}
								/>
							</div>
						</div>
					)
				})}

				<div className="mt-4">
					<h6
						style={{ color: '#555' }}
						className="fw-semibold font-roboto txt-color-primary fs-14"
					>
						Invite new members
					</h6>
					{getInviteInput()}
				</div>

				<div className="d-flex align-items-center justify-content-end mt-3">
					<ButtonComponent variant="light" text="Back" />
					<ButtonComponent
						// disabled={!Boolean(members.length)}
						variant="dark"
						extraclassName="ms-2"
						text="Save"
						click={editCurrentProject}
					/>
				</div>
			</div>
		</>
	)
}

export default AddMembers
