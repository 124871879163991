import { Drawer, Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

const DrawerComponent = ({
	drawerTitle,
	drawerWidth,
	isDrawerOpen,
	setIsDrawerOpen,
	children
}) => {
	// const showDrawer = () => {
	//   setIsDrawerOpen(false)
	// };

	const onClose = () => {
		setIsDrawerOpen(false)
	}

	const drawerHeaderStyle = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '4px'
	}

	return (
		<Drawer
			title=""
			placement="right"
			onClose={onClose}
			open={isDrawerOpen}
			width={drawerWidth}
			style={{ top: '50px' }}
			mask={false}
			closable={false}
		>
			<div style={drawerHeaderStyle}>
				<div className="fw-bolder fs-4 font-roboto">{drawerTitle}</div>
				<Button
					onClick={onClose}
					icon={<CloseOutlined />}
					style={{ border: '0px' }}
				/>
			</div>
			{children}
		</Drawer>
	)
}

export default DrawerComponent
