import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import TextError from '../hoc/TextError'
import { Button, Input, Select, Spin } from 'antd'
import { Delete, ExpandMore, Link } from '@mui/icons-material'
import md5 from 'md5'
import useMessage from 'antd/es/message/useMessage'
import { getUserProfile } from '../API/MembersAPI'
import AvatarGroupComponent from '../components/shared/AvatarGroupComponent'
import { IconButton } from '@mui/material'
import Cookies from 'js-cookie'
import { getRoles } from '../API/rolesApi'

const useInviteInput = () => {
	const [invited, setInvited] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [roles, setRoles] = useState([])

	const [message, contextHolder] = useMessage()

	const initialValues = {
		email: ''
	}

	const validationSchema = Yup.object({
		email: Yup.string().email('Invalid email.')
	})

	const getRolesData = async () => {
		try {
			const res = await getRoles()
			const { data } = res

			setRoles(
				data && data?.length !== 0
					? data.map((role) => {
							return {
								value: role?.id,
								label: role?.name
							}
					  })
					: []
			)
		} catch (error) {
			debugger
		}
	}

	useEffect(() => {
		getRolesData()
	}, [])

	const onSubmit = async (values, resetForm) => {
		setIsLoading(true)

		let flag = false

		for (let i = 0; i < invited.length; i++) {
			if (values?.email === invited[i].email) {
				flag = true
				break
			}
		}

		if (flag) {
			message.warning('Email already in list.')
			setIsLoading(false)

			return false
		}

		let temp = [...invited]
		const companyDetailsId = Cookies.get('companyDetailsId') ?? ''

		try {
			const res = await getUserProfile(values?.email)

			const { data = {} } = res
			const { entry = [] } = data
			temp.push({
				name: entry[0]?.displayName || '',
				email: values?.email,
				companyDetailsId: +companyDetailsId,
				imageUrl: entry[0]?.thumbnailUrl || '',
				roleId: 3
			})
			setInvited([...temp])
			setIsLoading(false)
			resetForm()
		} catch (error) {
			const { response } = error
			const { data } = response

			if (data?.message && data?.message.includes('Member already exists')) {
				message.error('User already exist.')
			} else {
				temp.push({
					email: values?.email,
					companyDetailsId: +companyDetailsId,
					imageUrl: '',
					roleId: 3
				})
				setInvited([...temp])
			}
			setIsLoading(false)
			resetForm()
		}
	}

	const handleSelect = (value, email) => {
		let temp = [...invited]
		temp.forEach((member) => {
			if (member?.email === email) {
				member.roleId = value
			}
		})

		setInvited([...temp])
	}

	const getInviteInput = () => {
		return (
			<>
				{contextHolder}
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
				>
					{({ values, isValid, resetForm, dirty, validateForm }) => {
						return (
							<Form
								className="invite-form d-flex align-items-center"
								onSubmit={(e) => {
									e.preventDefault()
									if (values?.email && values?.email === '') {
										message.warning('Empty field.')
										return false
									}
									if (isValid) {
										onSubmit(values, resetForm)
									}
								}}
							>
								<div className={`formField m-0 w-100`}>
									<Field
										name="email"
										className="invite-form-field rounded-0 font-roboto fs-12 txt-color-secondary"
										placeholder="Enter email"
										as={Input}
										disabled={isLoading}
									></Field>
									<ErrorMessage name={'email'} component={TextError} />
								</div>

								<Button
									type="submit"
									className={`invite-form-btn generic-btn dark rounded-0 ms-2`}
									onClick={() => {
										if (values?.email && values?.email === '') {
											message.warning('Empty field.')
											return false
										}

										if (isValid) {
											onSubmit(values, resetForm)
										}
									}}
									style={{
										border: '1px solid #d9d9d9'
									}}
									disabled={!isValid || !dirty}
									icon={
										<Link
											sx={{
												color: '#d9d9d9',
												fontSize: '1.25rem'
											}}
										/>
									}
								></Button>
							</Form>
						)
					}}
				</Formik>
				<div className="invites-list w-100 mt-3">
					{invited &&
						invited.length !== 0 &&
						invited.map((data) => {
							return (
								<div
									className="d-flex align-items-center  justify-content-between mb-3 pt-3 pb-2 px-3"
									style={{
										border: '1px solid #D9D9D9'
									}}
								>
									<div className="d-flex  align-items-center ">
										<AvatarGroupComponent
											name={data?.name || data?.email || ''}
											imageUrl={data?.imageUrl || ''}
										/>
										<div className="ms-2">
											<h6 className="fs-12 mb-0 font-roboto leading-1 ">
												{data?.name}
											</h6>
											<span className="font-roboto fs-11 fw-normal">
												{data?.email}
											</span>
										</div>
									</div>

									<div className="d-flex align-items-center">
										<Select
											className="role-select mb-0 fs-10"
											placeholder="Select a Designation"
											options={roles}
											onChange={(value) => handleSelect(value, data?.email)}
											suffixIcon={
												<ExpandMore
													sx={{
														color: '#555',
														fontSize: '1.25rem'
													}}
												/>
											}
											popupClassName="generic-select-dropdown"
											value={data?.roleId || ''}
										/>
										<IconButton
											className="ms-2"
											onClick={() => {
												let email = data?.email
												let temp = [...invited]
												let index = invited.findIndex(
													(mbr) => mbr?.email === email
												)
												if (index !== -1) {
													temp.splice(index, 1)
												}
												setInvited([...temp])
											}}
										>
											<Delete
												sx={{
													fontSize: '1rem'
												}}
											/>
										</IconButton>
									</div>
								</div>
							)
						})}

					{isLoading ? (
						<div
							className="d-flex align-items-center justify-content-center mt-2 py-4"
							style={{
								width: '100%',
								backgroundColor: 'aliceblue'
							}}
						>
							<Spin size="small" />
						</div>
					) : null}
				</div>
			</>
		)
	}

	return { getInviteInput, invited }
}

export default useInviteInput
