import { useState } from 'react'
import ModalComponent from '../ModalComponent'
import { Tabs } from 'antd'
import ComponentsStyles from '../../../styles/ComponentsStyles.css'
import AddMembers from '../../views/Projects/NewTask/AddMembers'
import AddTaskInformation from '../../views/Projects/NewTask/AddTaskInformation'

const CreateNewTaskModal = ({
	open,
	onClose,
	setOpen,
	// createTask,
	dataSelected = [],
	editNewTask,
	setEditNewTask,
	role = '',
	getData = () => console.log('callled')
}) => {
	const [activeKey, setActiveKey] = useState('1')

	const handleTabChange = (key) => {
		setActiveKey(key)
	}

	const [formData, setFormData] = useState({})
	const [membersData, setMembersData] = useState({})
	const [projectOptions, setProjectOptions] = useState([])

	const items = [
		{
			key: '1',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '1' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">1</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '1' ? '#262626' : '#9D9D9D' }}
					>
						Task Information
					</span>
				</div>
			),
			children: (
				<AddTaskInformation
					handleTabChange={handleTabChange}
					setActiveKey={setActiveKey}
					setFormData={setFormData}
					onClose={onClose}
					dataSelected={dataSelected}
					editNewTask={editNewTask}
					projectOptions={projectOptions}
					setProjectOptions={setProjectOptions}
				/>
			)
		},
		{
			key: '2',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '2' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">2</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '2' ? '#262626' : '#9D9D9D' }}
					>
						Add Members
					</span>
				</div>
			),
			children: (
				<AddMembers
					handleTabChange={handleTabChange}
					setMembersData={setMembersData}
					formData={formData}
					onClose={onClose}
					getData={getData}
					dataSelected={dataSelected}
					editNewTask={editNewTask}
					setEditNewTask={setEditNewTask}
					setFormData={setFormData}
					role={role}
					projectOptions={projectOptions}
				/>
			)
		}
	]
	return (
		<ModalComponent
			title={editNewTask ? 'Edit a task' : 'Create a task'}
			open={open}
			onClose={() => {
				onClose()
			}}
		>
			<div className="container-fluid">
				<Tabs
					defaultActiveKey={activeKey}
					items={items}
					activeKey={activeKey}
					className="create-project-tabs mt-4"
					onChange={handleTabChange}
				/>
			</div>
		</ModalComponent>
	)
}

export default CreateNewTaskModal
