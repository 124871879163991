import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import Avatar from '../../../assets/images/Avatar.svg'
import ChatComponent from './ChatComponent'
import { Divider } from 'antd'
import './chats.css'

const Chatbox = () => {
	const [allChats, setAllChats] = useState([])

	useEffect(() => {
		setAllChats([
			{
				id: '1',
				personId: '1',
				personName: 'Dianne Russell',
				personAvatar: Avatar,
				messageDetails: {
					content:
						'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, to',
					date: dayjs(),
					time: dayjs(),
					replies: [
						// {
						// 	id: '1',
						// 	personId: '2',
						// 	personName: 'Dianne Russell',
						// 	personAvatar: Avatar,
						// 	messageDetails: {
						// 		content:
						// 			'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, to',
						// 		date: dayjs(),
						// 		time: dayjs()
						// 	}
						// }
					]
				},
				addReply: false
			},
			{
				id: '1',
				personId: '1',
				personName: 'Dianne Russell',
				personAvatar: Avatar,
				messageDetails: {
					content:
						'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, to',
					date: dayjs(),
					time: dayjs(),
					replies: [
						{
							id: '1',
							personId: '2',
							personName: 'Dianne Russell',
							personAvatar: Avatar,
							messageDetails: {
								content:
									'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, to',
								date: dayjs(),
								time: dayjs()
							}
						}
					]
				},
				addReply: false
			},
			{
				id: '1',
				personId: '1',
				personName: 'Dianne Russell',
				personAvatar: Avatar,
				messageDetails: {
					content:
						'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, to',
					date: dayjs(),
					time: dayjs(),
					replies: [
						{
							id: '1',
							personId: '2',
							personName: 'Dianne Russell',
							personAvatar: Avatar,
							messageDetails: {
								content:
									'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, to',
								date: dayjs(),
								time: dayjs()
							}
						}
					],
					addReply: false
				}
			}
		])
	}, [])

	return (
		<div>
			{allChats?.map((chat) => {
				return (
					<>
						<ChatComponent content={chat} />
						<div className="ms-5 mt-3">
							{chat?.messageDetails?.replies?.length === 0
								? // <div style={{backgroundColor:'#EDF5FC',padding:'12px'}}>
								  // 	<span>Chat: </span>
								  // 	<span>Type to leave a comment</span>
								  // </div>
								  chat?.addReply && (
										<div className="reply-box">
											<input
												type="text"
												id="message-input"
												placeholder="Chat: Type to leave a comment"
												style={{
													backgroundColor: '#EDF5FC',
													padding: '12px',
													border: '0px',
													width: '100%'
												}}
											/>
										</div>
								  )
								: chat?.messageDetails?.replies?.map((reply) => {
										return <ChatComponent content={reply} />
								  })}
						</div>
						<Divider />
					</>
				)
			})}
		</div>
	)
}

export default Chatbox
