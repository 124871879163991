import { getRequest } from '.'

const getAllEmployees = async () => {
	const res = await getRequest('/jira/allEmployeesDetails')
	return res
}

const getEmployeeDetails = async (employeeId) => {
	const res = await getRequest(`/jira/projects/${employeeId}`)
	return res
}

export { getAllEmployees, getEmployeeDetails }
