import React from 'react'
import { Radio } from 'antd'
const IndividualViewFilters = ({ activeFilter, filtersData, onchange }) => {
	return (
		<div className="individual-filters">
			<Radio.Group
				defaultValue={activeFilter}
				value={activeFilter}
				onChange={onchange}
			>
				{filtersData?.map((filter, index) => {
					return (
						<Radio.Button
							value={filter?.value}
							key={filter?.value}
							className="fs-10 txt-color-secondary font-roboto"
						>
							{filter?.key}
						</Radio.Button>
					)
				})}
			</Radio.Group>
		</div>
	)
}

export default IndividualViewFilters
