import { useState, useEffect } from 'react'
import TableComponent from '../../../shared/TableComponent'
import { Error, Launch, Stars } from '@mui/icons-material'
import { Button, Progress } from 'antd'
import Roles from '../MembersDetails/Roles'
import { editMember, getAllMembers } from '../../../../API/MembersAPI'
import AvatarGroupComponent from '../../../shared/AvatarGroupComponent'

const Members = ({ membersData, activeKey }) => {
	const roleOptions = [
		{
			value: 8,
			label: 'Project lead'
		},
		{
			value: 9,
			label: 'Sales executive'
		},
		{
			value: 10,
			label: 'Viewer'
		}
	]

	const handleMemberRole = async (record, newRole) => {
		try {
			const res = await editMember({
				id: record?.employeeDetails?.assigneeId,
				roleId: newRole
			})
		} catch (error) {
			console.log('Error while updating member role ', error)
		}
	}

	const dataSource =
		membersData &&
		membersData?.length !== 0 &&
		membersData?.map((member, index) => {
			console.log('member data', member)
			return {
				key: index.toString(),
				employeeDetails: {
					assigneeName: member?.name,
					assigneeId: member?.id,
					assigneeEmail: member?.email,
					performanceStatus: member?.performanceStatus || ''
				},
				role: member?.roleId,
				projects: member?.tasks || [],
				workload: {
					workloadRate: member?.workloadRate || 0,
					workloadStatus: member?.workloadStatus || ''
				},
				workingHours: member?.workingHours,
				status: member?.status || 'Not Given',
				tasksCompletedPercent: member?.tasksCompletedPercent
			}
		})

	const columns = [
		{
			title: 'Employee Name',
			dataIndex: 'employeeDetails',
			key: 'employeeDetails',
			render: (employeeDetails) => (
				<div className="d-flex align-items-center">
					<span
						className={`${
							employeeDetails?.performanceStatus === '' ? 'me-4' : 'me-2'
						}`}
					>
						{employeeDetails?.performanceStatus === 'DEFAULTER' ? (
							<Error
								sx={{
									color: '#DE5959',
									fontSize: '1.25rem'
								}}
							/>
						) : employeeDetails?.performanceStatus === 'BEST_PERFORMER' ? (
							<Stars
								sx={{
									color: '#49BA46',
									fontSize: '1.25rem'
								}}
							/>
						) : (
							''
						)}
					</span>
					<div className="d-flex align-items-center gap-2">
						<AvatarGroupComponent
							name={
								employeeDetails?.assigneeName ||
								employeeDetails?.assigneeEmail ||
								''
							}
							imageUrl={employeeDetails?.assigneeImageUrl}
						/>
						<div className="d-flex flex-column" style={{ color: '#555555' }}>
							<span className="font-roboto fw-500 fs-12">
								{employeeDetails.assigneeName}
							</span>
							<span className="font-roboto fs-12 fw-normal">
								{employeeDetails.assigneeEmail}
							</span>
						</div>
					</div>
				</div>
			)
		},
		{
			title: 'Workload rate',
			dataIndex: 'workload',
			key: 'workload',
			render: (workload) => (
				<div className="d-flex gap-2 align-items-center fs-10">
					{' '}
					<span className="font-roboto fw-500 fs-12">
						{workload?.workloadRate ? workload?.workloadRate?.toFixed(0) : 0}%
					</span>
					{workload?.workloadStatus &&
					workload?.workloadStatus === 'OVERLOADED' ? (
						<span
							className="rounded-pill"
							style={{
								padding: '4px 6px',
								background: '#FFFFFF',
								color: '#EC9534',
								border: '1px solid #EC9534'
							}}
						>
							overload
						</span>
					) : null}
				</div>
			)
		},
		// {
		// 	title: 'Working hours',
		// 	dataIndex: 'workingHours',
		// 	key: 'workingHours',
		// 	render: (workingHours) => (
		// 		<span className="font-roboto fw-500 fs-12">
		// 			{workingHours && workingHours > 1
		// 				? workingHours?.toFixed(0) + ' hrs'
		// 				: workingHours > 0
		// 				? '1 hr'
		// 				: workingHours === 0
		// 				? '0hrs'
		// 				: '-'}
		// 		</span>
		// 	)
		// },
		// {
		// 	title: 'Roles',
		// 	dataIndex: 'role',
		// 	render: (text, record) => (
		// 		<div onClick={(e) => e.stopPropagation()}>
		// 			<Roles
		// 				value={record.role}
		// 				memberId={record.key}
		// 				roleOptions={roleOptions}
		// 				select={(newRole) => handleMemberRole(record, newRole)}
		// 			/>
		// 		</div>
		// 	)
		// },
		{
			//title: 'Issues Completed',
			title: 'Tasks Completed',
			dataIndex: 'tasksCompletedPercentage',
			key: 'tasksCompletedPercentage',
			render: (tasksCompletedPercentage) => (
				<Progress
					percent={
						tasksCompletedPercentage && tasksCompletedPercentage?.toFixed(0)
					}
					strokeColor={'#3571A7'}
					className="table-progress"
				/>
			)
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status) => (
				<div
					className="d-flex justify-content-center p-1 align-items-center border border-1"
					style={{
						color: '#11A40D',
						backgroundColor: 'rgba(218, 255, 181, 0.15)'
					}}
				>
					{status}
				</div>
			)
		},
		{
			title: ' ',
			dataIndex: 'contactMember',
			key: 'contactMember',
			render: (text, record) => {
				if (
					record?.employeeDetails?.assigneeEmail &&
					record?.employeeDetails?.assigneeEmail !== ''
				) {
					return (
						<Button
							href={`mailto:${record?.employeeDetails.assigneeEmail}`}
							className="txt-color-blue-1 font-roboto fs-12 fw-500 text-decoration-none border-0 bg-transparent p-0 d-flex align-items-center gap-2"
							onClick={(e) => {
								e.stopPropagation()
							}}
							style={{
								boxShadow: 'none',
								height: '100%'
							}}
						>
							<span className="font-roboto">Contact Member </span>
							<Launch
								sx={{
									fontSize: 'inherit',
									color: 'inherit'
								}}
							/>
						</Button>
					)
				} else {
					return <span className="font-roboto fw-500 fs-12">----</span>
				}
			}
		}
	]
	return (
		<TableComponent
			columns={columns}
			dataSource={dataSource}
			// onRow={(record) => {
			// 	return {
			// 		onClick: () => {
			// 			// navigate(
			// 			// 	`/dashboard/projects/employee-details/${record?.assigneeId}`, {
			// 			// 		state: {
			// 			// 			data: {
			// 			// 				...record
			// 			// 			}
			// 			// 		}
			// 			// 	}
			// 			// )
			// 			ShowMemberAnalysis(record)
			// 		}
			// 	}
			// }}
		/>
	)
}

export default Members
