import Cookies from 'js-cookie'
import { deleteRequest, getRequest, postRequest, putRequest } from '.'

const getAllTasks = async (priority = '') => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const email = Cookies.get('user-email')
	const res = await getRequest(
		`/v1/task?companyDetailsId=${companyDetailsId}&priority=${priority}&email=${email}`
	)
	return res
}

const getTask = async (id) => {
	const res = await getRequest(`/v1/task/${id}`)
	return res
}

const getProjectTasks = async (projectId) => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const email = Cookies.get('user-email')
	const res = await getRequest(
		`/v1/task?projectId=${projectId}&companyDetailsId=${companyDetailsId}&email=${email}`
	)
	return res
}

const handleTaskStatus = async (options) => {
	const res = await putRequest(`/v1/task`, options)
	return res
}

const handleTaskPriority = async (options) => {
	const res = await putRequest(`/v1/task`, options)
	return res
}

const deleteTasks = async (ids) => {
	const res = await deleteRequest(`/v1/task/multiple?ids=${ids}`)
	return res
}

const createTask = async (body) => {
	const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
	const res = await postRequest(`/v1/task`, {
		...body,
		companyDetailsId: companyDetailsId
	})
	return res
}

export {
	handleTaskStatus,
	handleTaskPriority,
	getAllTasks,
	deleteTasks,
	getProjectTasks,
	createTask,
	getTask
}
