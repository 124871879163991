import Cookies from 'js-cookie'
import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoute = () => {
	const isLoggedin = Cookies.get('user-status') ? true : false

	return isLoggedin ? <Outlet /> : <Navigate to="/auth/login" />
}

export default PrivateRoute
