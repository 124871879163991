import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import FormHeader from '../components/shared/forms/FormHeader'
import FormComponents from '../components/shared/forms/index'
import { Link, useNavigate } from 'react-router-dom'
import { sendForgetPasswordEmail, verifyIfUserExists } from '../API/authAPI'
import { useDispatch } from 'react-redux'
import ButtonComponent from '../components/shared/ButtonComponent'
import { message } from 'antd'

const ForgetPassword = () => {
	const [messageApi, contextHolder] = message.useMessage()

	const validationSchema = Yup.object({
		email: Yup.string().email('Incorrect Email').required('Required')
	})

	const initialValues = {
		email: ''
	}

	const onSubmit = async (values) => {
		try {
			const res = await verifyIfUserExists(values.email)

			try {
				const resp = await sendForgetPasswordEmail(values.email)
				messageApi.success(
					'Link to update the password successfully sent to the email!'
				)
			} catch (err) {
				messageApi.error('Error while sending the email!')
			}
		} catch (err) {
			messageApi.error('User does not exist.')
		}
	}

	return (
		<>
			{contextHolder}
			<div className="auth-form-div">
				<div className="pt-5">
					<FormHeader
						head="Forget Password ?"
						text="No worries, we'll send you reset instructions."
					/>
				</div>

				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
				>
					{({ handleSubmit }) => (
						<Form onSubmit={handleSubmit}>
							<FormComponents
								name="email"
								type="email"
								label="Email Address"
								control="input"
								defaultValue="sample@company.com"
							/>

							<ButtonComponent
								type="submit"
								text="Continue"
								extraclassName="mt-3 w-100"
								variant="dark"
								click={handleSubmit}
							/>
						</Form>
					)}
				</Formik>

				<p className="redirect-txt text-center mt-3">
					Back To <Link to="/auth/login">Login</Link>
				</p>
			</div>
		</>
	)
}

export default ForgetPassword
