import React, { useState } from 'react'
import ModalComponent from '../../../ModalComponent'
import { Tabs } from 'antd'
import TaskForm from './TaskForm'
import MemberForm from './MemberForm'

const AddClientTask = ({
	open,
	onClose,
	getData = () => console.log('getData'),
	clientId,
	clientProjects
}) => {
	const [activeKey, setActiveKey] = useState('1')

	const handleTabChange = (key) => {
		setActiveKey(key)
	}

	const [formData, setFormData] = useState({})

	const items = [
		{
			key: '1',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '1' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">1</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '1' ? '#262626' : '#9D9D9D' }}
					>
						Task Information
					</span>
				</div>
			),
			children: (
				<TaskForm
					handleTabChange={handleTabChange}
					setFormData={setFormData}
					onClose={onClose}
					dataSelected={[]}
					editNewTask={false}
					clientId={clientId}
					clientProjects={clientProjects}
				/>
			)
		},
		{
			key: '2',
			label: (
				<div className="d-flex">
					<div
						style={{
							backgroundColor: activeKey === '2' ? '#262626' : '#9D9D9D'
						}}
						className="create-project-tab-numbers"
					>
						<span className="font-roboto fs-12 fw-normal">2</span>
					</div>
					<span
						className="ms-2 font-roboto fs-12 fw-bolder"
						style={{ color: activeKey === '2' ? '#262626' : '#9D9D9D' }}
					>
						Add Members
					</span>
				</div>
			),
			children: (
				<MemberForm
					handleTabChange={handleTabChange}
					formData={formData}
					onClose={onClose}
					getData={getData}
					dataSelected={[]}
					editNewTask={false}
					clientId={clientId}
					setFormData={setFormData}
				/>
			)
		}
	]

	return (
		<ModalComponent
			title={'Create a task'}
			open={open}
			onClose={() => {
				onClose()
			}}
		>
			<div className="container-fluid">
				<Tabs
					defaultActiveKey={activeKey}
					items={items}
					activeKey={activeKey}
					className="create-project-tabs mt-4"
					onChange={handleTabChange}
				/>
			</div>
		</ModalComponent>
	)
}

export default AddClientTask
