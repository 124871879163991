import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import FormComponent from '../../../shared/forms'
import ButtonComponent from '../../../shared/ButtonComponent'
import { getAllProjects } from '../../../../API/projectsAPI'
import useMessage from 'antd/es/message/useMessage'
import dayjs from 'dayjs'

const ClientForm = (props) => {
	const { isUpdate, formData, setFormData, handleTabChange } = props

	const [projectsData, setProjectsData] = useState([])
	const [message, contextHolder] = useMessage()

	const getProjects = async () => {
		try {
			const res = await getAllProjects(true)
			const { data = {} } = res
			let temp = [...data]
			if (isUpdate) {
				formData &&
					formData?.projects?.length !== 0 &&
					formData?.projects.forEach((pr) => {
						temp.push(pr?.project)
					})
			}
			setProjectsData([...temp])
		} catch (error) {
			console.log('Error while fetching projects data ', error)
		}
	}

	useEffect(() => {
		getProjects()
	}, [])

	const projectOptions = (Array.isArray(projectsData) ? projectsData : []).map(
		(project) => {
			return {
				value: project?.id,
				label: project?.name
			}
		}
	)

	// const initialValues = {
	// 	name: formData?.name || '',
	// 	status: formData?.status || null,
	// 	description: formData?.description || '',
	// 	phoneNumber: formData?.phoneNumber || '',
	// 	billingAddress: formData?.billingAddress || '',
	// 	industry: formData?.industry || '',
	// 	services:
	// 		formData?.services && formData.services !== '' && !Array.isArray(formData?.services)
	// 			? formData.services.split(',')
	// 			: [],
	// 	projects:
	// 		formData?.projects && formData?.projects.length !== 0
	// 			? formData?.projects.map((project) => project?.project?.id)
	// 			: [],
	// 	contactEmail: formData?.contactEmail || '',
	// 	imageUrl: formData?.imageUrl || '',
	// 	contactName: formData?.contactName || '',
	// 	startDate:
	// 		formData?.startDate && formData?.startDate !== null
	// 			? dayjs(formData?.startDate)
	// 			: '',
	// 	clientMembers : formData?.clientMembers || []
	// }

	const initialValues = { ...formData }

	const validationSchema = Yup.object({
		name: Yup.string().required('Required'),
		status: Yup.string().required('Required'),
		description: Yup.string(),
		phoneNumber: Yup.string(),
		billingAddress: Yup.string(),
		industry: Yup.string(),
		contactEmail: Yup.string().email('Incorrect email.').required('Required'),
		contactName: Yup.string(),
		startDate: Yup.string(),
		services: Yup.array()
			.min(1, 'Field must have one option.')
			.required('Required'),
		// projects: Yup.number()
		clientMembers: Yup.array().required('Required')
	})

	const industryOptions = [
		{
			label: 'IT Industry',
			value: 'IT Industry'
		},
		{
			label: 'Fintech',
			value: 'Fintech'
		},
		{
			label: 'Oil and Natural gas',
			value: 'Oil and Natural gas'
		},
		{
			label: 'Real Estate',
			value: 'Real Estate'
		},
		{
			label: 'Healthcare',
			value: 'Healthcare'
		},
		{
			label: 'Automotive Industry',
			value: 'Automotive Industry'
		},
		{
			label: 'Others',
			value: 'Others'
		}
	]

	const statusOptions = [
		{
			label: 'Active',
			value: 'Active'
		},
		{
			label: 'Inactive',
			value: 'Inactive'
		}
	]

	const serviceOptions = [
		{
			label: 'Web Development',
			value: 'Web Development'
		},
		{
			label: 'Mobile Development',
			value: 'Mobile Development'
		},
		{
			label: 'UI/UX Design',
			value: 'UI/UX Design'
		}
	]

	return (
		<>
			{contextHolder}
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					setFormData({ ...values })
					handleTabChange('2')
				}}
				enableReinitialize={true}
			>
				{({ values, setFieldValue, handleSubmit, isValid, dirty }) => {
					return (
						<Form autoComplete="off">
							<div className="row">
								<div className="col-12 col-md-12">
									<FormComponent
										control="upload-image"
										label="Client Image"
										placeholder="Select and image for client"
										setFieldValue={setFieldValue}
										imageUrl={values?.imageUrl}
										name="imageUrl"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										control="input"
										name="name"
										label={<>Client Name</>}
										required
										placeholder="Enter client name"
										type="text"
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponent
										control="generic-select"
										name="status"
										label={<>Client Status</>}
										required
										placeholder="Select client status"
										options={statusOptions}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										control="input"
										name="contactEmail"
										label={<>Contact Email</>}
										required
										placeholder="Example: john@synccos.com"
										type="text"
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponent
										name="contactName"
										label="Contact Name (optional)"
										control="input"
										type="text"
										placeholder="Example: John Doe"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										name="phoneNumber"
										label="Phone Number (optional)"
										control="phone-input"
										type="text"
										country="us"
										onChange={(phoneNumber) =>
											setFieldValue('phoneNumber', phoneNumber)
										}
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponent
										control="date-picker"
										name="startDate"
										label="Start date (optional)"
										placeholder="Enter start date"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-12">
									<FormComponent
										control="input"
										name="billingAddress"
										label={<>Billing Address (optional)</>}
										required
										placeholder="Enter billing address"
										type="text"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-12">
									<FormComponent
										control="input"
										name="description"
										label={<>Client Description (optional)</>}
										required
										placeholder="Enter client description"
										type="text"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										control="generic-select"
										name="industry"
										label={<>Industry (optional)</>}
										placeholder="Select client industry"
										options={industryOptions}
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponent
										control="generic-select"
										name="services"
										label={<>Services required</>}
										placeholder="Select services"
										options={serviceOptions}
										mode="multiple"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponent
										control="generic-select"
										name="projects"
										label={<>Add Projects (optional)</>}
										placeholder="Select projects"
										options={projectOptions}
										mode="multiple"
									/>
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-end">
								<ButtonComponent
									type="button"
									text={'Next'}
									variant="dark"
									click={handleSubmit}
									disabled={!dirty || !isValid}
								/>
							</div>
						</Form>
					)
				}}
			</Formik>
		</>
	)
}

export default ClientForm
