import { DateRange, ExpandMore } from '@mui/icons-material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { Card, Checkbox, Divider, Dropdown } from 'antd'
import dayjs from 'dayjs'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AvatarGroupComponent from '../../AvatarGroupComponent'
import { IconButton } from '@mui/material'

const TaskCardComponent = ({
	task,
	deleteTask,
	updateTask,
	setIsCreateTask,
	coloumnId,
	setEditNewTask,
	editNewTask
}) => {
	const menuItems = [
		{
			label: 'Status 1',
			key: '0'
		},
		{
			label: 'Status 2',
			key: '1'
		},
		{
			label: 'Status 3',
			key: '2'
		}
	]

	const addDaySuffix = (day) => {
		if (day >= 11 && day <= 13) {
			return day + 'th'
		}
		const lastDigit = day % 10
		if (lastDigit === 1) {
			return day + 'st'
		} else if (lastDigit === 2) {
			return day + 'nd'
		} else if (lastDigit === 3) {
			return day + 'rd'
		} else {
			return day + 'th'
		}
	}

	const formatDate = (inputDate) => {
		const date = dayjs(inputDate)

		const formattedDate = date.format('MMMM YYYY')

		// Add the 'th' suffix to the day
		const dayWithSuffix = addDaySuffix(date.format('D'))

		return `${dayWithSuffix} ${formattedDate}`
	}

	return (
		<div
			className="card generic-card w-100 rounded-0 px-3 py-3 mb-0"
			style={{
				borderColor: '#EBEBEB',
				width: '22.5rem'
			}}
		>
			<div className="d-flex align-items-center justify-content-between mb-3">
				<Dropdown
					//overlay={menu}
					menu={{ items: menuItems }}
					trigger={['click']}
					className={`d-flex align-items-center justify-content-center`}
				>
					<span
						className="filter-dropdown text-decoration-none align-items-center fs-10"
						style={{
							borderRadius: '10px',
							border: '1px solid #C9EBFF',
							backgroundColor: '#E6F7FF',
							color: '#1890FF',
							padding: '3px 8px'
						}}
					>
						Medium
						<ExpandMore
							className="ms-1"
							sx={{
								fontSize: '14px'
							}}
						/>
					</span>
				</Dropdown>
				{/* <Checkbox className="generic-checkbox" /> */}
			</div>
			<p className="fs-14 mb-2 font-roboto txt-color-tertiary fw-semibold">
				{task?.project?.name}
			</p>
			{/* <p className="font-roboto fs-12 txt-color-fourth mb-1">{task?.projectId}</p> */}
			<p className="fs-12 font-roboto txt-color-primary mb-0">
				{task?.description}
			</p>
			<Divider
				className="card-divider"
				style={{
					margin: '1rem 0',
					borderColor: '#e9e9e9'
				}}
			/>
			<div className="row align-items-center justify-content-between ">
				<div className="col-6 text-center   align-items-center d-flex  dateRange  ">
					<DateRangeIcon
						style={{
							color: 'rgba(123, 123, 123, 0.8)'
						}}
					/>
					<p className="fs-10   m-0">
						{/* <span className="txt-color-secondary">Start Date: </span> */}
						<span className="">Start Date: </span>
						<strong>
							<span className="">{formatDate(task?.startDate)}</span>
						</strong>
					</p>
				</div>

				<div className="col-6 text-center  align-items-center d-flex dateRange ">
					<DateRangeIcon
						style={{
							color: 'rgba(123, 123, 123, 0.8)'
						}}
					/>
					<p className="fs-10   m-0">
						<span className="">Due: </span>
						<strong>
							<span className="">{formatDate(task?.dueDate)}</span>
						</strong>
					</p>
				</div>
			</div>
			<Divider
				className="card-divider"
				style={{
					margin: '1rem 0',
					borderColor: '#e9e9e9'
				}}
			/>
			<div className="">
				<div
					className="d-inline-flex align-items-center mb-2 me-2"
					style={{
						padding: '3px 16px',
						border: '1px solid #D9D9D9',
						background: '#FAFAFA',
						borderRadius: '100px'
					}}
				>
					<span className="fs-10 font-roboto txt-color-primary">
						Sprint: SYN Sprint 6
					</span>
				</div>
				<div
					className="d-inline-flex align-items-center mb-2 me-2"
					style={{
						padding: '3px 16px',
						border: '1px solid #D9D9D9',
						background: '#FAFAFA',
						borderRadius: '100px'
					}}
				>
					<span className="fs-10 font-roboto txt-color-primary">
						Estimate 4 days
					</span>
				</div>
				<div
					className="d-inline-flex align-items-center mb-2 me-2"
					style={{
						padding: '3px 16px',
						border: '1px solid #D9D9D9',
						background: '#FAFAFA',
						borderRadius: '100px'
					}}
				>
					<span className="fs-10 font-roboto txt-color-primary">
						Client Name
					</span>
				</div>
			</div>
			{/* card footer */}
			<Divider
				className="card-divider"
				style={{
					marginTop: '1rem',
					borderColor: '#e9e9e9'
				}}
			/>
			<div className="d-flex align-items-center justify-content-between ">
				<div className="">
					{task?.members && task?.members?.length !== 0 ? (
						<AvatarGroupComponent data={task?.members} />
					) : null}
				</div>
				<div className="d-flex gap-1">
					{/* <div className="">
						<ContentCopyIcon color="action" />
					</div>
					<div className="">
						<NotificationsActiveIcon color="action" />
					</div> */}
					<IconButton onClick={(e) => deleteTask(task.id)}>
						<DeleteIcon
							color="action"
							sx={{
								fontSize: '1.25rem'
							}}
						/>
					</IconButton>
					{/* <IconButton>
						<CreateIcon
							color="action"
							sx={{
								fontSize: '1.25rem'
							}}
						/>
					</IconButton> */}
					{/* <div className="">
						<CheckCircleIcon color="action" />
					</div> */}
				</div>
			</div>
		</div>
	)
}

export default TaskCardComponent
