import { CloseOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Modal } from 'antd'
import React from 'react'

const ModalComponent = (props) => {
	const {
		title,
		onClose,
		open,
		children = 'No Data found',
		width = '38.125rem'
	} = props

	return (
		<Modal
			open={open}
			width={width}
			title={false}
			maskClosable={true}
			onCancel={onClose}
			centered
			footer={false}
			className="generic-modal"
			closeIcon={false}
			styles={{
				backgroundColor: '#555555b3'
			}}
			style={{
				overflowY: 'auto'
			}}
		>
			<div className="generic-modal-header d-flex align-items-center justify-content-between bg-white">
				<h2 className="mb-0 txt-color-primary fs-4 font-roboto fw-bold">
					{title}
				</h2>
				<IconButton onClick={onClose}>
					<CloseOutlined
						sx={{
							color: '#555',
							width: '1.25rem',
							height: '1.25rem'
						}}
					/>
				</IconButton>
			</div>
			<div className="generic-modal-content-body">{children}</div>
		</Modal>
	)
}

export default ModalComponent
