/* eslint-disable no-lone-blocks */
import React from 'react'
import { Avatar, Dropdown, Tooltip } from 'antd'
import { Add, PersonOutline } from '@mui/icons-material'
import addAvatar from '../../assets/images/addAvatar.svg'

const getInitials = (fullName) => {
	const names = fullName ? fullName?.split(' ') : []
	const firstNameInitial = names[0]?.charAt(0).toUpperCase() || ''
	const lastNameInitial = names[names.length - 1]?.charAt(0).toUpperCase() || ''
	return `${firstNameInitial} ${lastNameInitial}`
}

const ProjectAvatar = ({ maxCount, size, extraclass, data }) => {
	return (
		<>
			<Avatar.Group maxCount={maxCount} size={size}>
				{data?.map((item, index) => {
					return (
						<Avatar
							key={index}
							style={{
								backgroundColor: '#EDF5FC',
								color: '#434343',
								marginRight: 0
							}}
							className={`font-roboto fs-12 ${extraclass}`}
							src={item?.imageUrl}
						>
							{!item?.imageUrl && item?.name !== '' ? (
								item?.name !== null ? (
									getInitials(item?.name)
								) : (
									getInitials(`${item?.email?.[0]} ${item?.email?.[1]}`)
								)
							) : (
								<PersonOutline
									sx={{
										color: 'rgb(123, 123, 123)'
									}}
								/>
							)}
						</Avatar>
					)
				})}
			</Avatar.Group>
		</>
	)
}

const AvatarGroupComponent = ({
	data,
	size,
	name,
	imageUrl,
	extraclass = '',
	maxCount = 3,
	type = 'circle',
	caseType,
	showAddAvatar = false,
	isAddBtn = false,
	items = [],
	cancelFunc = () => console.log('cancel func')
}) => {
	switch (caseType) {
		case 'project':
			return (
				<>
					<ProjectAvatar
						maxCount={maxCount}
						size={size}
						extraclass={extraclass}
						data={data}
					/>
					{/* {showAddAvatar && <img src={addAvatar}/>} */}
				</>
			)

		default:
			if (Array.isArray(data) && data.length > 0) {
				return (
					<div className="d-flex align-items-center">
						<Avatar.Group maxCount={maxCount} size={size}>
							{data.map((item, index) => {
								{
									if (
										(item?.name || item?.email) &&
										(item?.name !== '' || item?.email !== '')
									) {
										return (
											<>
												<Tooltip
													title={item?.name || item?.email}
													className="avatar-tooltip"
													color="#fff"
													overlayInnerStyle={{
														width: 'fit-content',
														color: '#434343',
														textTransform: 'capitalize',
														fontFamily: 'Roboto',
														fontSize: '12px',
														fontWeight: '500'
													}}
												>
													<Avatar
														key={index}
														style={{
															backgroundColor: '#EDF5FC',
															color: '#434343',
															marginRight: 0,
															cursor: 'pointer'
														}}
														className={`font-roboto generic-avatar fs-12 ${extraclass}`}
														src={item?.imageUrl}
														size
													>
														{!item?.imageUrl &&
														item?.name &&
														item.name !== '' ? (
															getInitials(item?.name || item?.email)
														) : item?.email && item?.email !== '' ? (
															getInitials(item?.email)
														) : (
															<PersonOutline
																sx={{
																	color: 'rgb(123, 123, 123)'
																}}
															/>
														)}
													</Avatar>
												</Tooltip>
											</>
										)
									} else {
										return (
											<>
												<Avatar
													key={index}
													style={{
														backgroundColor: '#EDF5FC',
														color: '#434343',
														marginRight: 0,
														cursor: 'pointer'
													}}
													className={`font-roboto generic-avatar fs-12 ${extraclass}`}
													src={item?.imageUrl}
												>
													{!item?.imageUrl && item?.name && item.name !== '' ? (
														getInitials(item?.name || item?.email)
													) : item?.email && item?.email !== '' ? (
														getInitials(item?.email)
													) : (
														<PersonOutline
															sx={{
																color: 'rgb(123, 123, 123)'
															}}
														/>
													)}
												</Avatar>
											</>
										)
									}
								}
							})}
						</Avatar.Group>
						{isAddBtn ? (
							<Dropdown
								trigger={['hover']}
								menu={{ items }}
								placement="bottomRight"
								overlayStyle={{
									maxHeight: '250px',
									overflow: 'auto'
								}}
								className="avatar-add-dropdown"
								overlayClassName="avatar-add-dropdown-overlay"
								onOpenChange={(open) => {
									if (!open) {
										cancelFunc()
									}
								}}
							>
								<Avatar
									style={{
										backgroundColor: '#EDF5FC',
										color: '#434343',
										marginRight: 0,
										cursor: 'pointer',
										marginLeft: '-10px'
									}}
									className={`font-roboto generic-avatar fs-12 ${extraclass}`}
									size
								>
									<Add
										sx={{
											fontSize: '1.25rem',
											color: 'inherit'
										}}
									/>
								</Avatar>
							</Dropdown>
						) : null}
					</div>
				)
			} else {
				if (name && name !== '') {
					return (
						<Tooltip
							className="avatar-tooltip"
							title={name}
							color="#fff"
							overlayInnerStyle={{
								width: 'fit-content',
								color: '#434343',
								textTransform: 'capitalize',
								fontFamily: 'Roboto',
								fontSize: '12px',
								fontWeight: '500'
							}}
						>
							<Avatar
								src={imageUrl}
								style={{
									backgroundColor: '#EDF5FC',
									color: '#434343',
									cursor: 'pointer'
								}}
								className={`font-roboto generic-avatar fs-12 ${extraclass}`}
								size={size}
								shape={type}
							>
								{!imageUrl && name && name !== '' ? (
									getInitials(name)
								) : (
									<PersonOutline
										sx={{
											color: 'rgb(123, 123, 123)'
										}}
									/>
								)}
							</Avatar>
							{/* {showAddAvatar && <img src={addAvatar}/>} */}
						</Tooltip>
					)
				} else {
					return (
						<>
							<Avatar
								src={imageUrl}
								style={{ backgroundColor: '#EDF5FC', color: '#434343' }}
								className={`font-roboto generic-avatar fs-12 ${extraclass}`}
								size={size}
								shape={type}
							>
								{!imageUrl && name && name !== '' ? (
									getInitials(name)
								) : (
									<PersonOutline
										sx={{
											color: 'rgb(123, 123, 123)'
										}}
									/>
								)}
							</Avatar>
							{/* {showAddAvatar && <img src={addAvatar}/>} */}
						</>
					)
				}
			}
	}
}

export default AvatarGroupComponent
